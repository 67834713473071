<template>
    <div>
        <v-dialog v-model="edit_calendar_dialog" max-width="500px" persistent>
            <v-card v-if="current_edit_calendar">
                <v-card-title>Kalender bearbeiten</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="current_edit_calendar.name"
                        label="Name"
                        outlined
                        dense
                    ></v-text-field>
                    
                    <v-text-field
                        v-model="current_edit_calendar.url"
                        label="Kalender-URL (iCal/ICS/webcal)"
                        outlined
                        dense
                    ></v-text-field>
                    
                    <div class="d-flex flex-column justify-center align-start mb-3">
                        <span class="mr-2">Farbe:</span>
                        <v-chip-group
                            center-active
                            mandatory
                            show-arrows
                            column
                        >
                            <v-chip
                                v-for="color in colors"
                                small
                                :key="color"
                                :color="color"
                                class="color-chip pa-0"
                                @click="current_edit_calendar.color = color"
                            >
                                <v-icon x-small v-if="current_edit_calendar.color === color" color="white">
                                    mdi-check-bold
                                </v-icon>
                            </v-chip>
                        </v-chip-group>
                    </div>
                </v-card-text>
                <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text @click="edit_calendar_dialog = false">Abbrechen</v-btn>
                    <v-btn :color="$store.state.theme.green" outlined @click="updateSubscribedCalendar">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <p class="d-flex align-center mb-3">
            <span class="text-subtitle-1 black--text font-weight-medium">Angezeigter Zeitraum</span>
            <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon right v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                Hier kannst du den Anzeigebereich deines Kalenders anpassen.
                Termine die außerhalb dieses Zeitraums liegen, werden im Kalender ganz oben angezeigt.
            </v-tooltip>
            </p>
            <v-row class="my-0 py-0">
                <v-col class="my-0 py-0">
                    <v-text-field 
                        dense outlined 
                        v-model="workingHoursStartProxy" 
                        label="Von" value="00:00:00"
                        type="time" :rules="timeRule" 
                        @change="$emit('updateIntervalHeight')" 
                    />
                </v-col>
                <v-col class="my-0 py-0">
                    <v-text-field 
                        dense outlined 
                        v-model="workingHoursEndProxy" 
                        label="Bis" value="23:00:00" 
                        type="time" :rules="timeRule" 
                        @change="$emit('updateIntervalHeight')" 
                    />
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                    <v-btn @click="saveWorkingHours" block outlined :color="$store.state.theme.green"
                        :disabled="workingHoursStart === $store.state.client.kalender_start && workingHoursEnd === $store.state.client.kalender_ende"
                        :loading="updating_working_hours">
                        Änderungen Speichern
                    </v-btn>
                </v-col>
            </v-row>

            <div class="d-flex align-center mt-3">
                <v-switch 
                    class="mr-1" 
                    v-model="show_holidays" 
                    dense inset 
                    :color="$store.state.theme.green" 
                    label="Feiertage anzeigen"
                />
            </div>
            
            <p class="d-flex align-center text-subtitle-1 font-weight-medium black--text my-3">
                Google Kalender Verknüpfung
                <v-divider class="ml-4"></v-divider>
                <v-btn v-if="identities.length === 2" text small color="red" @click="removeGoogleLinking" class="ml-2">
                    <v-icon small left>
                        mdi-autorenew-off
                    </v-icon>
                    Entfernen
                </v-btn>
            </p>

            <div v-if="googleCalenderAuthState === 'synced'" class="d-flex flex-column mb-0 pb-0">
                <div class="d-flex align-center">
                    <span class="mr-2">Verknüpft mit:</span>
                    <v-chip 
                        small
                        close
                        @click:close="removeGoogleCalendar()"
                    >
                        {{ $store.state.client.google_calendar }}
                    </v-chip>
                </div>

                <div class="d-flex align-center mt-0">
                    <v-switch
                        dense
                        v-model="anonymize_clients"
                        :label="`Klienten anonymisieren: ${anonymize_clients ? 'Ja' : 'Nein'}`"
                        inset
                        :color="$store.state.theme.green"
                        :loading="loading_anonymize_clients"
                        @change="saveAnonymizeClients"
                    />
                </div>

                <v-row v-if="anonymize_clients" class="mt-0 pt-0">
                    <v-col cols="12" class="my-0 py-0">
                        <v-radio-group
                            class="mt-0"
                            v-model="anonymizationFormat"
                            label="Anonymisierungsformat"
                            column
                            @change="saveAnonymizationFormat"
                            :disabled="loading_anonymization_format"
                        >
                            <v-radio :value="null" label="Zwei Buchstaben des Nach- und Vornamens"></v-radio>
                            <v-radio value="first_last" label="Zwei Buchstaben des Vor- und Nachnamens"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </div>
            <p v-else-if="googleCalenderAuthState === 'authorized'" class="d-flex flex-column mb-0 pb-0">
                <v-select @click="loadGoogleCalendars" v-model="selected_calendar" :loading="loading_calendars"
                    :items="calendars" item-text="name" item-value="id" label="Kalender" outlined dense return-object
                    clearable clear-icon="mdi-close-circle" class="mb-0 pb-0">
                    <template v-slot:item="{ item }">
                    <div class="d-flex align-center justify-center mr-2">
                        <div
                        :style="{ backgroundColor: item.color, borderRadius: '6px', width: '24px', height: '24px' }">
                        </div>
                    </div>
                    {{ item.name }}
                    </template>
                    <template v-slot:no-data>
                    <v-progress-circular class="ml-5 mr-2" indeterminate color="primary" size="15"
                        width="3"></v-progress-circular>
                    <span>Lade Kalender</span>
                    </template>
                </v-select>
                <v-btn :disabled="selected_calendar === null" :color="$store.state.theme.green" outlined
                    @click="saveAndLoadCalendar()" block>
                    Kalender verknüpfen
                </v-btn>
            </p>
            <div v-else-if="googleCalenderAuthState === 'linked' || googleCalenderAuthState === 'unlinked'" class="text-justify mb-0 pb-0">
                <p>
                    Um deinen Google Kalender zu verknüpfen,
                    benötigt ZEIPSY die notwendigen Berechtigungen.
                    Klicke auf "Google Kalender verbinden", um den Vorgang zu starten.
                </p>
                <v-btn class="gsi-material-button" @click="signInWithGoogle" block outlined>
                    <div class="gsi-material-button-content-wrapper">
                        <div class="gsi-material-button-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            version="1.1" id="Livello_1" x="0px" y="0px" viewBox="0 0 200 200"
                            enable-background="new 0 0 200 200" xml:space="preserve">
                            <g>
                                <g transform="translate(3.75 3.75)">
                                <path fill="#FFFFFF"
                                    d="M148.882,43.618l-47.368-5.263l-57.895,5.263L38.355,96.25l5.263,52.632l52.632,6.579l52.632-6.579    l5.263-53.947L148.882,43.618z" />
                                <path fill="#1A73E8"
                                    d="M65.211,125.276c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c0.829,3.158,2.276,5.605,4.342,7.342    c2.053,1.737,4.553,2.592,7.474,2.592c2.987,0,5.553-0.908,7.697-2.724s3.224-4.132,3.224-6.934c0-2.868-1.132-5.211-3.395-7.026    s-5.105-2.724-8.5-2.724h-5.276v-9.039H76.5c2.921,0,5.382-0.789,7.382-2.368c2-1.579,3-3.737,3-6.487    c0-2.447-0.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684,0-4.816,0.711-6.395,2.145s-2.724,3.197-3.447,5.276    l-9.039-3.763c1.197-3.395,3.395-6.395,6.618-8.987c3.224-2.592,7.342-3.895,12.342-3.895c3.697,0,7.026,0.711,9.974,2.145    c2.947,1.434,5.263,3.421,6.934,5.947c1.671,2.539,2.5,5.382,2.5,8.539c0,3.224-0.776,5.947-2.329,8.184    c-1.553,2.237-3.461,3.947-5.724,5.145v0.539c2.987,1.25,5.421,3.158,7.342,5.724c1.908,2.566,2.868,5.632,2.868,9.211    s-0.908,6.776-2.724,9.579c-1.816,2.803-4.329,5.013-7.513,6.618c-3.197,1.605-6.789,2.421-10.776,2.421    C73.408,129.263,69.145,127.934,65.211,125.276z" />
                                <path fill="#1A73E8"
                                    d="M121.25,79.961l-9.974,7.25l-5.013-7.605l17.987-12.974h6.895v61.197h-9.895L121.25,79.961z" />
                                <path fill="#EA4335"
                                    d="M148.882,196.25l47.368-47.368l-23.684-10.526l-23.684,10.526l-10.526,23.684L148.882,196.25z" />
                                <path fill="#34A853"
                                    d="M33.092,172.566l10.526,23.684h105.263v-47.368H43.618L33.092,172.566z" />
                                <path fill="#4285F4"
                                    d="M12.039-3.75C3.316-3.75-3.75,3.316-3.75,12.039v136.842l23.684,10.526l23.684-10.526V43.618h105.263    l10.526-23.684L148.882-3.75H12.039z" />
                                <path fill="#188038"
                                    d="M-3.75,148.882v31.579c0,8.724,7.066,15.789,15.789,15.789h31.579v-47.368H-3.75z" />
                                <path fill="#FBBC04"
                                    d="M148.882,43.618v105.263h47.368V43.618l-23.684-10.526L148.882,43.618z" />
                                <path fill="#1967D2"
                                    d="M196.25,43.618V12.039c0-8.724-7.066-15.789-15.789-15.789h-31.579v47.368H196.25z" />
                                </g>
                            </g>
                            </svg>
                        </div>
                        <span class="gsi-material-button-contents ml-2">Google Kalender verbinden</span>
                    </div>
                </v-btn>
            </div>

            <p class="d-flex align-center text-subtitle-1 font-weight-medium black--text mt-6 mb-1">
                Abonnierte Kalender
                <v-divider class="ml-4"></v-divider>
            </p>

            <div v-if="subscribed_calendars.length > 0">
            <v-list dense class="mt-0 mb-3 py-0">
                <v-list-item v-for="calendar in subscribed_calendars" :key="calendar.id">
                <v-list-item-avatar class="my-0">
                    <v-avatar size="20" :color="calendar.color"></v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ calendar.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                    <v-tooltip bottom open-delay="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" @click="openEditCalendarDialog(calendar)">
                        <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Bearbeiten</span>
                    </v-tooltip>
                </v-list-item-action>
                <v-list-item-action class="my-0">
                    <v-tooltip bottom open-delay="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" @click="removeSubscribedCalendar(calendar.id)">
                        <v-icon small color="red">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Entfernen</span>
                    </v-tooltip>
                </v-list-item-action>
                </v-list-item>
            </v-list>
            </div>

            <v-btn 
                rounded 
                small 
                elevation="0" 
                v-if="!show_subscription_form"
                @click="toggleSubscriptionForm"
            >
            <v-icon left>
                mdi-plus
            </v-icon>
                {{ subscribed_calendars.length > 0 ? 'Weiteren' : 'Neuen' }} Kalender abonnieren
            </v-btn>

            <v-form v-if="show_subscription_form" @submit.prevent="addSubscribedCalendar">
            <v-text-field
                v-model="new_calendar_url"
                label="Kalender-URL (iCal/ICS/webcal)"
                outlined
                dense
                :loading="adding_calendar"
            ></v-text-field>
            
            <div class="d-flex flex-column justify-center align-start mb-3">
                <span class="mr-2">Farbe:</span>
                <v-chip-group
                    center-active
                    mandatory
                    show-arrows
                    column
                >
                    <v-chip
                        v-for="color in colors"
                        small
                        :key="color"
                        :color="color"
                        class="color-chip pa-0"
                        @click="new_calendar_color = color"
                    >
                        <v-icon x-small v-if="new_calendar_color === color" color="white">
                            mdi-check-bold
                        </v-icon>
                    </v-chip>
                </v-chip-group>
            </div>
            
            <div class="d-flex mt-0">
                <v-btn
                    outlined
                    :color="$store.state.theme.green"
                    type="submit"
                    :loading="adding_calendar"
                    :disabled="!new_calendar_url"
                    :dark="!!new_calendar_url"
                    block
                >
                <v-icon left>mdi-calendar-plus</v-icon>
                    Kalender hinzufügen
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { supabase } from '../supabase';
import connector from '../helpers/supabase-connector.js';
import cipher from '@/helpers/cipher';
import calendarService from '@/helpers/calendarService.js';

export default {
    name: 'CalendarSettingsPanel',
  
    props: {
        session: {
            type: Object,
            required: true
        },
        workingHoursStart: String,
        workingHoursEnd: String,
        googleCalendar: Object,
        settingsVisible: {
            type: Boolean,
            required: true
        },
    },

    emits: ['updateIntervalHeight'],
  
    data() {
        return {

            subscribed_calendars: [],

            show_holidays: (parseInt(localStorage.getItem('hide_holidays')) || 0) === 0,
            anonymize_clients: this.$store.state.client.google_calendar_anonymize,

            show_subscription_form: false,
            new_calendar_url: null,
            new_calendar_color: this.$store.state.theme.primary,
            adding_calendar: false,

            current_edit_calendar: null,
            edit_calendar_dialog: false,

            loading_anonymize_clients: false,
            loading_anonymization_format: false,

            identities: [],
            linked_google_account: false,
            calendars: [],
            selected_calendar: null,
            loading_calendars: false,

            updating_working_hours: false,

            timeRule: [
                v => !!v || 'Uhrzeit wird benötigt',
                v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](?::\d{2})?$/.test(v) || 'Uhrzeit muss das Format HH:MM haben.',
            ],

            colors: [
                this.$store.state.theme.primary,
                '#7885cb',
                '#9475cc',
                '#4cd0e0',
                '#81c784',
                '#ffb64c',
                '#f77b72',
                '#ef6191',
                '#4cb5ab',
                '#ffd351',
                '#bbbbbb',
                '#8fa4ad'
            ],
        }
    },

    async mounted() {
        this.linked_google_account = await this.listLinkedIdentities();
        this.subscribed_calendars = await calendarService.loadSubscribedCalendars(this);
    },

    methods: {

        toggleSubscriptionForm() {
            this.show_subscription_form = !this.show_subscription_form;
            if (!this.show_subscription_form) {
                this.new_calendar_url = '';
            }
        },

        closeSubscriptionForm() {
            this.show_subscription_form = false;
            this.new_calendar_url = '';
        },

        async saveWorkingHours() {

            this.updating_working_hours = true;

            let updated = await connector.update(this, 'kunden', {
                'kalender_start': this.workingHoursStart,
                'kalender_ende': this.workingHoursEnd
            }, this.session.user.id);

            if (updated) {
                this.$store.state.client.kalender_start = this.workingHoursStart;
                this.$store.state.client.kalender_ende = this.workingHoursEnd;
            }

            this.updating_working_hours = false;
        },

        async listLinkedIdentities() {
            try {
                // retrieve all identities linked to a user
                let result = await supabase.auth.getUserIdentities();
                
                if (result && result.data && result.data.identities) {
                    const {
                        data: { identities },
                    } = result;

                    this.identities = identities;

                    const googleIdentity = identities.find((identity) => identity.provider === "google");
                    if (googleIdentity) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    // handle error
                    this.$emit("showError", {
                        message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                        timeout: 5000,
                    });
                    return false;
                }
            } catch (error) {
                this.$emit("showError", {
                    message: "Fehler beim Abrufen der verknüpften Google-Accounts. Bitte lade die Seite neu.",
                    timeout: 5000,
                    error: error
                });
                return false;
            }
        },

        async signInWithGoogle() {

            const redirectUrl = process.env.NODE_ENV === 'development' 
                ? 'http://localhost:8080/kalender?calendar_settings=true' 
                : 'https://app.zeipsy.com/kalender?calendar_settings=true';

            const options = {
                redirectTo: redirectUrl,
                scopes: ['https://www.googleapis.com/auth/calendar.calendarlist.readonly', 'https://www.googleapis.com/auth/calendar.events'],
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent', // The refresh-token gets returned only immediately after consent. 
                    // It will not be re-issued on sessionRefresh or Login.        
                    // Therefore, "force" consent on re-login as refresh-token is not in local storage.
                },
            };

            let data, error;
    
            if (this.googleCalenderAuthState === 'unlinked') {
                ({ data, error } = await supabase.auth.linkIdentity({
                    provider: 'google',
                    options: options,
                }));
            } else {
                ({ data, error } = await supabase.auth.signInWithOAuth({
                    provider: 'google',
                    options: options,
                }));
            }

            if (error) {
                localStorage.removeItem('scopes');
                localStorage.removeItem('provider_refresh_token');
                this.$emit('showError', { 
                    message: 'Fehler beim Verbinden mit Google. Bitte versuche es erneut.',
                    timeout: 8000,
                    error: error
                });
            }
            if (data) {
                localStorage.setItem('scopes', 'https://www.googleapis.com/auth/calendar.calendarlist.readonly,https://www.googleapis.com/auth/calendar.events');
                // localStorage.setItem('provider_refresh_token', data.provider_refresh_token);
            }
        },

        async removeGoogleLinking() {

            try {
                // find the google identity linked to the user
                const googleIdentity = this.identities.find((identity) => identity.provider === 'google');

                // Remove the linked calendar first (this updates the database and clears the calendar info in the store)
                await this.removeGoogleCalendar();

                // unlink the google identity from the user
                const { data, error } = await supabase.auth.unlinkIdentity(googleIdentity);
                if (error) {
                    this.$emit('showError', { 
                        message: 'Fehler beim Entknüpfen des Google Kontos. Fehlercode: ' + error,
                        timeout: 10000,
                        error: error
                    });
                }
                if (data) {
                    localStorage.removeItem('scopes');
                    localStorage.removeItem('provider_refresh_token');

                    this.linked_google_account = await this.listLinkedIdentities();

                     // Update the local state to reflect that the Google account is no longer linked
                    this.linked_google_account = false;
                    this.selected_calendar = null;
                    this.calendars = [];

                    this.linked_google_account = false;
                    this.$emit('showInfo', {
                        message: 'Dein Google Konto und Kalender wurden erfolgreich getrennt.',
                        timeout: 5000,
                    });
                }
            } catch(error) {
                this.$emit('showError', {
                    message: 'Dein Google Konto und Kalender konnte nicht entfernt werden. Bitte versuche es erneut.',
                    timeout: 5000,
                    error: error
                });
            }
        },

        async removeGoogleCalendar() {
            let updated = await connector.update(this, 'kunden', {
                id: this.session.user.id,
                google_calendar: null,
            }, this.session.user.id);

            if (updated === null) {
                // error has already been shown
                return;
            }

            this.$store.state.client.google_calendar = null;
            this.$emit('removeGoogleCalendar');
            this.$emit('showInfo', {
                message: 'Google Kalender erfolgreich getrennt.',
                timeout: 5000,
            });
        },

        async loadGoogleCalendars() {
            this.loading_calendars = true;
            if (this.googleCalendar) {
                this.calendars = await this.googleCalendar.getCalendars();
            } else {
                this.calendars = [];
            }
            this.loading_calendars = false;
        },

        async saveAndLoadCalendar() {
            // save calendar id to klienten
            const updated = await connector.update(this, 'kunden', {
                id: this.session.user.id,
                google_calendar: this.selected_calendar.id,
            }, this.session.user.id);
            if (updated === null) {
                // error has already been displayed
                return;
            }

            this.$store.state.client.google_calendar = this.selected_calendar.id;

            // store calendar color in localStorage if available
            if (this.selected_calendar.color) {
                localStorage.setItem('google_calendar_color', this.selected_calendar.color);
            }

            this.$emit('fetchGoogleAppointments');
        },

        async saveAnonymizeClients(anonymize) {
            this.loading_anonymize_clients = true;
            try {
                const updated = await connector.update(this, 'kunden', {
                    id: this.session.user.id,
                    google_calendar_anonymize: anonymize,
                }, this.session.user.id);
                if (updated === null) {
                    // error has already been updated
                    this.loading_anonymize_clients = false;
                    return;
                }
            } catch (error) {
                // since error is handled in connector, we don't need to do anything here
                this.loading_anonymize_clients = false;
            }
            this.loading_anonymize_clients = false;
            this.$store.state.client.google_calendar_anonymize = anonymize;
        },

        async saveAnonymizationFormat() {
            this.loading_anonymization_format = true;
            try {
                const updated = await connector.update(this, 'kunden', {
                    id: this.session.user.id,
                    google_calendar_anonymization_format: this.anonymizationFormat,
                }, this.session.user.id);
                if (updated === null) {
                    this.loading_anonymization_format = false;
                    return;
                }
            } catch (error) {
                // since error is handled in connector, we don't need to do anything here
                this.loading_anonymization_format = false;
            }
            this.loading_anonymization_format = false;
        },

        openEditCalendarDialog(calendar) {
            this.current_edit_calendar = { ...calendar };
            this.edit_calendar_dialog = true;
        },

        async updateSubscribedCalendar() {
            try {
                const encrypted_url = await cipher.encryptDataJSON(this.current_edit_calendar.url, this.$store.state.aes_key_file);
                const updated = await connector.update(this, 'kalender', {
                    name: this.current_edit_calendar.name,
                    color: this.current_edit_calendar.color,
                    url: encrypted_url
                }, this.current_edit_calendar.id);
                
                if (updated) {
                    this.subscribed_calendars = await calendarService.loadSubscribedCalendars(this);
                    this.edit_calendar_dialog = false;
                    this.$emit('fetchCalendarEvents');
                    this.$emit('showInfo', { 
                        message: 'Kalender erfolgreich aktualisiert.', 
                        timeout: 5000 
                    });
                }
            } catch (error) {
                this.$emit('showError', { 
                    message: 'Fehler beim Aktualisieren des Kalenders. Bitte versuche es erneut.', 
                    timeout: 7000,
                    error: error
                });
            }
        },

        async removeSubscribedCalendar(id) {
            try {
                const deleted = await connector.delete(this, 'kalender', 'id', id);
                
                if (deleted) {
                    this.subscribed_calendars = this.subscribed_calendars.filter(cal => cal.id !== id);
                    this.$emit('removeCalendarEvents', id);
                    this.$emit('showInfo', { 
                        message: 'Der Kalender wurde erfolgreich entfernt.', 
                        timeout: 5000 
                    });
                }
            } catch (error) {
                this.$emit('showError', { 
                    message: 'Fehler beim Entfernen des Kalenders. Bitte versuche es erneut.', 
                    timeout: 7000,
                    error: error
                });
            }
        },

        async addSubscribedCalendar() {
            if (!this.new_calendar_url) {
                this.$emit('showError', { 
                    message: 'Bitte gib eine Kalender-URL ein.', 
                    timeout: 5000 
                });
                return;
            }
            
            this.adding_calendar = true;
            
            try {
                // Validate the URL
                const validation = await calendarService.validateCalendarUrl(this.new_calendar_url);
                
                if (!validation.valid) {
                    throw new Error(validation.error || 'Ungültige Kalender-URL');
                }

                // encrypt URL
                const encrypted_url = await cipher.encryptDataJSON(this.new_calendar_url, this.$store.state.aes_key_file);
                
                // Insert the calendar into the database
                const newCalendar = await connector.insert(this, 'kalender', {
                    uid: this.session.user.id,
                    url: encrypted_url,
                    name: validation.title || 'Abonnierter Kalender',
                    color: this.new_calendar_color
                });
                
                if (newCalendar) {
                    this.subscribed_calendars = await calendarService.loadSubscribedCalendars(this);
                    this.$emit('fetchCalendarEvents');
                    this.toggleSubscriptionForm();
                    this.new_calendar_url = null;
                    this.new_calendar_color = this.$store.state.theme.primary;
                    this.$emit('showInfo', { 
                        message: 'Kalender erfolgreich hinzugefügt.', 
                        timeout: 5000 
                    });
                }
            } catch (error) {
                this.$emit('showError', { 
                    message: error.message || 'Fehler beim Hinzufügen des Kalenders. Bitte versuche es erneut.', 
                    timeout: 7000,
                    error: error
                });
            } finally {
                this.adding_calendar = false;
            }
        },
    },

    computed: {

        workingHoursStartProxy: {
            get() {
                return this.workingHoursStart;
            },
            set(newVal) {
                this.$emit('update:workingHoursStart', newVal);
                this.$emit('updateIntervalHeight');
            }
        },
        
        workingHoursEndProxy: {
            get() {
                return this.workingHoursEnd;
            },
            set(newVal) {
                this.$emit('update:workingHoursEnd', newVal);
                this.$emit('updateIntervalHeight');
            }
        },

        googleCalenderAuthState() {
            let linked_google_account = this.linked_google_account;
            let linked_google_calendar = this.$store.state.client.google_calendar;
            let provider_refresh_token = localStorage.provider_refresh_token;

            if (provider_refresh_token && linked_google_calendar) {
                return 'synced';
            } else if (provider_refresh_token && !linked_google_calendar) {
                return 'authorized';
            } else if (linked_google_account) {
                return 'linked';
            } else {
                return 'unlinked';
            }
        },

        anonymizationFormat: {
            get() {
                return this.$store.state.client.google_calendar_anonymization_format;
            },
            set(value) {
                this.$store.commit('setGoogleCalendarAnonymizationFormat', value);
            }
        }
    },

    watch: {
        show_holidays(newValue) {
            if (newValue) localStorage.setItem('hide_holidays', 0);
            else localStorage.setItem('hide_holidays', 1);

            this.$store.state.show_holidays = newValue;
        },

        settingsVisible: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    // When the dialog is closed, ensure the subscription form is closed.
                    this.closeSubscriptionForm();
                }
            }
        }
    },
}
</script>

<style scoped>
.color-chip {
  width: 24px;
  justify-content: center;
  min-width: 24px;
}

.gsi-material-button {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
  justify-content: center;
}
</style>