<template>
  <v-container fluid>
    <v-dialog v-model="dialog_show_safe_key_reminder" max-width="600px" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-toolbar :color="$store.state.theme.primary" dark>
          <v-toolbar-title>Erinnerung</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="px-4">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="text-h6">Dein persönlicher Safe-Schlüssel</div>
                <p class="subtitle-1 text-justify mt-2">
                  Dies ist eine Erinnerung, dass du deinen Safe-Schlüssel sicher & auffindbar aufbewahren solltest.
                  Dein Safe-Schlüssel wird verwendet, um die personenbezogenen Daten (z.B. Name, Adresse, E-Mail Adresse
                  etc.) deiner Klienten
                  zu verschlüsseln.
                  <!-- Diesen Schlüssel hast du bei der Registrierung einmalig festgelegt, und kann nachträglich nicht mehr geändert oder wiederhergestellt werden. -->
                </p>
                <v-alert class="font-weight-bold" type="info" outlined>
                  Falls du deinen Safe-Schlüssel noch nicht notiert hast, solltest du dies jetzt tun!
                  Wenn du ihn vergisst, können deine Daten nicht wiederhergestellt werden.
                </v-alert>

                <v-text-field class="mt-5" v-model="$store.state.data_key" label="Safe-Schlüssel" readonly outlined dense hide-details />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="px-6 pb-5 pt-0">
          <v-row class="mt-0 pt-0">
            <v-col cols="12" md="6"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-0 pt-0' : 'd-flex justify-start mt-0 pt-0'">
              <v-btn :color="$store.state.theme.red" text :block="$vuetify.breakpoint.smAndDown"
                @click="safeKeyReminderNeverAgain">
                Erinnerung nicht mehr anzeigen
              </v-btn>
            </v-col>
            <v-col cols="12" md="6"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-0 pt-0' : 'd-flex justify-end mt-0 pt-0'">
              <v-btn :color="$store.state.theme.green" outlined :block="$vuetify.breakpoint.smAndDown"
                @click="safeKeyReminerOK">
                Verstanden
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
      <DialogInvoicePaid 
        @close="close" 
        @initialize="initialize" 
        @showError="(arg) => $emit('showError', arg)" 
        @showInfo="(arg) => $emit('showInfo', arg)" 
        :editedItem="editedItem" 
        :editedIndex="editedIndex" 
        :session="session" 
      />
    </v-dialog>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col class="d-flex align-center py-5">
            <span :class="$vuetify.breakpoint.xs ? 'subtitle-1' : 'text-h5'">Heutige & kommende Termine</span>
          </v-col>
        </v-row>
        <v-card elevation="1">
          <v-skeleton-loader
            v-if="loading_appointments"
            type="heading, table-row-divider@5"
            height="298px"
            class="pa-5"
          />
          <v-card-text v-else-if="error_loading_appointments" class="text-body-1">
            <v-icon left>mdi-connection</v-icon>
            Die Termine konnten nicht geladen werden.<br/>
            <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="initialize">
                <v-icon left>
                    mdi-refresh
                </v-icon>
                Neu Laden
            </v-btn>
          </v-card-text>
          <v-sheet v-else-if="displayNextClients.length === 0" class="d-flex flex-column justify-center align-center" height="298px">
            <v-icon x-large>
              mdi-check-circle
            </v-icon>
            <span class="mt-2">
              Aktuell sind keine Termine geplant.
            </span>
          </v-sheet>
          <v-data-table
            height="298px" fixed-header v-else
            :headers="[
                { text: 'Uhrzeit', value: 'datetime' },
                { text: 'Klient', value: 'name' },
              ]"
            :items="displayNextClients"
            disable-sort
            item-key="id"
            group-by="date"
            :items-per-page="-1"
            hide-default-footer
            mobile-breakpoint="0"
            class="next-appointments-table"
          >
          <template v-slot:group.header="{ group, headers, toggle, isOpen, items }">
            <td :colspan="headers.length" @click="toggle" class="cursor-pointer">
                <v-btn  small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{ items[0].dayAndDate }} ({{ items.length }} Termin{{ items.length > 1 ? 'e' : '' }})
            </td>
          </template>
            <template v-slot:item.datetime="{ item }">
              <div class="d-flex flex-column justify-center align-start py-2" style="white-space: nowrap;">
                <div :class="{'past-appointment': item.isPast}">
                  <span>{{ formatTime(item.datetime) }}</span><br/>
                  <span>{{ getTimeWithAddedMinutes(item.datetime, item.duration).format("HH:mm") }}</span>
                </div>
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <v-tooltip bottom open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <div :class="['d-flex align-center justify-space-between cursor-pointer', {'past-appointment': item.isPast}]" @click="navigateToDocumentation(item.fk_klienten_id)" v-bind="attrs" v-on="on">
                    <div class="d-flex flex-column ml-2" style="white-space: nowrap;"> 
                        <span class="font-weight-medium">{{ item.name }}</span>
                        <span class="text-caption">{{ item.service }}</span>
                    </div>
                    <v-icon class="ml-2">
                      mdi-chevron-right
                    </v-icon>
                  </div>
                </template>
                <span>Zur Dokumentation des Klienten wechseln</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col class="d-flex align-center py-5">
            <span :class="$vuetify.breakpoint.xs ? 'subtitle-1' : 'text-h5'">Unbezahlte Rechnungen</span>
          </v-col>
        </v-row>
        <v-card elevation="1">
          <v-skeleton-loader
            v-if="loading_invoices"
            type="heading, table-row-divider@5"
            height="298px"
            class="pa-5"
          />
          <v-card-text v-else-if="error_loading_invoices" class="text-body-1">
            <v-icon left>mdi-connection</v-icon>
            Die Rechnungen konnten nicht geladen werden.<br/>
            <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="initialize">
                <v-icon left>
                    mdi-refresh
                </v-icon>
                Neu Laden
            </v-btn>
          </v-card-text>
          <v-simple-table v-else-if="unpaidInvoices.length > 0" class="unpaid-invoices-table" height="298px" fixed-header>
            <thead>
              <tr>
                <th class="text-left pl-5">Rechnung</th>
                <th v-if="$vuetify.breakpoint.smAndUp" class="text-left">Datum</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in unpaidInvoices" :key="invoice.id">
                <td class="py-2 pl-5">
                  <span class="font-weight-medium">{{ invoice.fk_klienten_id ? invoice.name : invoice.empfänger_name }}</span>
                  <div class="d-flex align-center">
                    <v-icon small left>mdi-invoice-text-outline</v-icon>
                    <span>{{ invoice.nummer }}: {{ invoice.rechnungs_betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}</span>
                  </div>
                  <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex align-center">
                    <v-icon small left>mdi-calendar</v-icon>
                    <span>{{ new Date(invoice.datum).toLocaleString([], {
                      year:'numeric',
                      month: '2-digit',
                      day: '2-digit'
                      }) }}
                    </span>
                  </div>
                </td>
                <td v-if="$vuetify.breakpoint.smAndUp">
                  {{ new Date(invoice.datum).toLocaleString([], {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                  }) }}
                </td>
                <td class="text-center">
                  <v-btn small rounded color="red" dark outlined elevation="0" @click="openInvoice(invoice)">
                    <v-icon v-if="$vuetify.breakpoint.smAndUp" left>
                      mdi-hand-coin-outline
                    </v-icon>
                    <span>Unbezahlt</span>
                    <v-menu bottom offset-y open-on-hover right nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right class="row-mark-paid-btn" v-bind="attrs" v-on="on" @click.stop>
                          mdi-chevron-down
                        </v-icon>
                      </template>
                      <v-list dense>
                        <v-list-item @click="openInvoice(invoice)">
                          <v-list-item-title>
                            <v-icon left :color="$store.state.theme.green">mdi-cash-check</v-icon>
                            Rechnung wurde bezahlt
                          </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item :disabled="item.fk_institution_id !== null" @click="openSendEmail(item)">
                          <v-list-item-icon>
                            <v-icon>
                              mdi-email-newsletter
                            </v-icon> 
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ item.versendet ? 'Rechnung erneut verschicken' : 'Rechnung per E-Mail verschicken' }}
                          </v-list-item-title>
                        </v-list-item> -->
                      </v-list>
                    </v-menu>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-sheet v-else class="d-flex flex-column justify-center align-center" height="298px">
            <v-icon x-large>mdi-check-circle</v-icon>
            <span class="mt-2">Alle Rechnungen sind bezahlt.</span>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex align-center py-5">
        <span :class="$vuetify.breakpoint.xs ? 'subtitle-1 mr-3' : 'text-h5 mr-3'">Finanz-Übersicht</span>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small elevation="0" text v-bind="attrs" v-on="on">
              
              <span class="mr-2 text-body-1">
                {{ $store.state.selected_year }}
              </span>
              <v-icon>mdi-calendar-search</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-overline pb-0">Jahr</v-card-title>
            <v-card-text class="mb-0 pb-0 px-5">
              <v-radio-group v-model="$store.state.selected_year" class="my-0 pb-0" @change="changedYear">
                <v-radio v-for="year in $store.state.active_years" :key="year.jahr" :label="year.jahr.toString()" :value="year.jahr" :color="$store.state.theme.primary" class="mt-1" />
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-card class="mx-auto" elevation="0">
      <v-sheet elevation="1">
        <v-row no-gutters :style="{backgroundColor: $store.state.theme.background_tabs}">
          <v-col cols="12" md="10">
            <v-tabs v-model="selected_chart" :background-color="$store.state.theme.background_tabs" :color="$store.state.theme.primary" show-arrows center-active>
                <v-tab v-for="item in chart_buttons" :key="item.id">
                  <v-icon left>{{ item.icon }}</v-icon>
                  {{ item.title }}
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-chart-donut</v-icon>
                  Jahresansicht
                </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-divider/>
        
        <v-card outlined class="ma-4" v-if="selected_chart === 0">
          <v-card-title class="overline mb-0 pb-0 pt-1">Jahressummen</v-card-title>
          <v-card-text class="d-flex align-center justify-center mt-0 pt-0">
            <v-row class="d-flex align-center justify-center flex-wrap pb-2">
              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon>
                  mdi-sigma
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Geleistet: {{ (bezahlte_rechnungen.betrag + offene_rechnungen.betrag + nicht_verrechnet.betrag).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>

              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.green">
                  mdi-cash-check
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Bezahlt: {{ bezahlte_rechnungen.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>

              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.red">
                  mdi-hand-coin-outline
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Unbezahlt: {{ offene_rechnungen.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>

              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.orange">
                  mdi-invoice-text-edit-outline
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Nicht verrechnet: {{ nicht_verrechnet.betrag.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined class="ma-4" v-if="selected_chart === 1">
          <v-card-title class="overline mb-0 pb-0 pt-1">Jahressummen</v-card-title>
          <v-card-text class="d-flex align-center justify-center mt-0 pt-0">
            <v-row class="d-flex align-center justify-center flex-wrap pb-2">
              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon>
                  mdi-sigma
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Geleistet: {{ (bezahlte_rechnungen.anzahl + offene_rechnungen.anzahl + nicht_verrechnet.anzahl) }}
                </span>
              </v-col>

              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.green">
                  mdi-cash-check
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Bezahlt: {{ bezahlte_rechnungen.anzahl }}
                </span>
              </v-col>

              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.red">
                  mdi-hand-coin-outline
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Unbezahlt: {{ offene_rechnungen.anzahl }}
                </span>
              </v-col>

              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.orange">
                  mdi-invoice-text-edit-outline
                </v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Nicht verrechnet: {{ nicht_verrechnet.anzahl }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined class="ma-4" v-if="selected_chart === 2">
          <v-card-title class="overline mb-0 pb-0 pt-1">Jahressummen</v-card-title>
          <v-card-text class="d-flex align-center justify-center mt-0 pt-0">
            <v-row class="d-flex align-center justify-center flex-wrap pb-2">
              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.green">mdi-cash-multiple</v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Einnahmen: {{ computedEinnahmen.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>
              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left :color="$store.state.theme.red">mdi-cart-variant</v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  Ausgaben: {{ computedAusgaben.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>
              <v-col cols="12" xs="6" sm="auto" class="d-flex align-center justify-center my-0 pb-0">
                <v-icon left>mdi-plus-minus-variant</v-icon>
                <span class="text-xs-caption text-sm-subtitle-1">
                  {{ (computedEinnahmen - computedAusgaben) >= 0 ? 'Gewinn: ' : 'Verlust: ' }} {{ (computedEinnahmen - computedAusgaben).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <apexchart v-show="selected_chart < chart_buttons.length" ref="chart" height="350" type="bar" color="red" :options="options" :series="series" />
        <div v-if="selected_chart === chart_buttons.length" class="d-flex justify-center">
          <apexchart type="donut" :width="donutWidthAndHeight" :height="donutWidthAndHeight" :options="donutChartOptions" :series="donutSeries" class="pa-5" />
        </div>
      </v-sheet>
    </v-card>

    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <div class="d-flex justify-space-between">
          <span :class="$vuetify.breakpoint.xs ? 'subtitle-1' : 'text-h5'">Geburtstage</span>
        </div>
        <v-card class="mt-2" elevation="1">
          <v-list v-if="displayBirthdays.length > 0">
            <v-list-item v-for="client in displayBirthdays" :key="client.id">
              <v-list-item-icon>
                {{ new Date(client.birthday).toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
                }) }}
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ client.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <span class="d-flex align-center">
                  <v-icon left>mdi-cake-variant-outline</v-icon>
                  {{ getAgeOnBirthday(client.birthday) }} Jahre
                </span>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-sheet v-else class="d-flex flex-column justify-center align-center" height="200px">
            <v-icon x-large>mdi-cake-variant-outline</v-icon>
            <span class="mt-2">Aktuell stehen keine Geburtstage an.</span>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DialogInvoicePaid from '../components/DialogInvoicePaid.vue'
  import apexchart from 'vue-apexcharts'
  import connector from '../helpers/supabase-connector.js'
  import cipher from '../helpers/cipher.js'
  import dayjs from 'dayjs'
  import 'dayjs/locale/de'; // import German locale
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import timezone from 'dayjs/plugin/timezone';
  import utc from 'dayjs/plugin/utc';
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale('de');

  export default {
    name: 'Home',
    props: ['session'],
    components: {DialogInvoicePaid, apexchart, },

    data() {
      return {

        chartDataEinnahmenTotal: 0,
        chartDataAusgabenTotal: 0,

        dialog_show_safe_key_reminder: false,
        dialog: false,
        editedIndex: -1,
        editedItem: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          selected_customer: null,
          selected_appointments: [],
          nachname: null,
          vorname: null,
          datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
          nummer: null,
          bezahlt: null,
          bar: null,
          rechnungs_betrag: null,
          anzahl_termine: null
        },
        defaultItem: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          selected_customer: null,
          selected_appointments: [],
          nachname: null,
          vorname: null,
          datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
          nummer: null,
          bezahlt: null,
          bar: null,
          rechnungs_betrag: null,
          anzahl_termine: null
        },

        selected_chart: 0,
        chart_buttons: [
          { id: 1, title: 'Geleistet (€)', icon: 'mdi-sofa-single-outline', value: 'workedAmount', active: true },
          { id: 2, title: 'Geleistet (#)', icon: 'mdi-sofa-single-outline', value: 'workedSessions', active: false },
          { id: 3, title: 'E/A', icon: 'mdi-account-cash', value: 'ea', active: false },
        ],
        offene_rechnungen: { betrag: 0, anzahl: 0 },
        bezahlte_rechnungen: { betrag: 0, anzahl: 0 },
        nicht_verrechnet: { betrag: 0, anzahl: 0 },

        customers: [],
        invoices: [],
        loading_birthday: false,
        loading_invoices: false,
        loading_appointments: false,
        error_loading_appointments: false,
        error_loading_invoices: false,

        birthdays: [],

        donutSeries: [100],
        donutChartOptions: {
          colors: [this.$store.state.theme.green, this.$store.state.theme.red, this.$store.state.theme.orange, this.$store.state.theme.primary],
          dataLabels: {
            enabled: false
          },
          legend: {
            position: this.$vuetify.breakpoint.xsOnly ? "bottom": "right",
            show: true,
            fontSize: '14px',
          },
          tooltip: {
            theme: 'light'
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    label: 'Geleistet',
                    fontSize: '26px', 
                    formatter: function (w) {
                      // Get the sum of all values
                      const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0) || 0;

                      // check if seriesTotal contains three values
                      // if so, return total value
                      // if not, return nothing
                      if (w?.globals?.seriesTotals?.length === 3) {
                        return `${total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`;
                      } else {
                        return '0 €';
                      }
                    },
                  }
                }
              }
            }
          },
          labels: ['Bezahlt', 'Unbezahlt', 'Nicht Verrechnet'],
          chart: {
            type: 'donut',
          },
        },

        chart_default_values: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        },
        options: {
          chart: {
            id: 'vuechart-overview',
            toolbar: {
              show: false
            },
            stacked: true,
          },
          xaxis: {
            categories: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
          },
          fill: {
            colors: [],
          },
          colors: [],
          dataLabels: {
            enabled: !this.$vuetify.breakpoint.xsOnly,
          },
          annotations: {
            points: [],
          },
          title: {}
        },
        series: [],
      }
    },

    created () {
      this.initialize()

    },

    mounted () {
      this.showSafeKeyReminder()
    },

    watch: {
      '$vuetify.breakpoint.xsOnly': function (val) {
        this.options = {
          ...this.options,
          dataLabels: {
            enabled: !val
          }
        };

        this.donutChartOptions = {
          ...this.donutChartOptions,
          legend: {
            ...this.donutChartOptions.legend,
            position: (val ? "bottom" : "right")
          }
        };
      },

      selected_chart(newIndex) {
        // Only fetch chart data if the selected chart is one of the first three.
        if (newIndex < this.chart_buttons.length) {
          const config = this.getChartConfig(newIndex);
          if (config) {
            connector.getChartData(this, config.dataView, this.$store.state.selected_year)
              .then((data) => {
                if (config.chartType === 'ea') {
                  this.prepareEAChartData(data);
                } else {
                  this.prepareChartData(data, config.chartType);
                }
              });
          }
        }
      },
    },

    computed: {

      donutWidthAndHeight() {
        return this.$vuetify.breakpoint.xsOnly ? 320 : 500
      },

      computedEinnahmen() {
        return this.chartDataEinnahmenTotal || 0;
      },

      computedAusgaben() {
        return this.chartDataAusgabenTotal || 0;
      },

      displayNextClients() {
        // Get the current date (only date part without time)
        let today = dayjs().startOf('day')
        let now = dayjs().tz("Europe/Berlin") // Current date and time in German timezone for checking if an appointment is past
        
        // Filter clients for today and future dates
        let next_clients = this.customers.filter((client) => {
          let client_date = dayjs(client.datum).startOf('day')
          
          // Include if date is today or in the future
          return client_date.isSame(today) || client_date.isAfter(today)
        })

        // Map filtered clients to include a flag for past appointments
        let mapped_clients = next_clients.map((client) => {
          let client_datetime = dayjs(client.datum)
          let isPast = client_datetime.isBefore(now)
          
          return {
            id: client.id,
            name: client.nachname + ' ' + client.vorname,
            service: client.bezeichnung,
            datetime: client.datum,
            dayAndDate: dayjs(client.datum).format('dddd, DD.MM.YY'),
            date: dayjs(client.datum).format('YYYY-MM-DD'),
            duration: client.dauer,
            fk_klienten_id: client.fk_klienten_id,
            isPast: isPast
          }
        })

        return mapped_clients.sort((a, b) => dayjs(a.datetime).diff(dayjs(b.datetime)))
      },

      unpaidInvoices() {
        let unpaid_invoices = this.invoices.map((invoice) => {
          let original_entry = Object.assign({}, invoice);
          original_entry['name'] = invoice.nachname + ' ' + invoice.vorname;
          return original_entry;
        }).sort((a, b) => {
          return dayjs(a.datum).diff(dayjs(b.datum));
        })

        return unpaid_invoices;
      },

      displayBirthdays() {
        let now = new Date()
        let start_of_week = new Date(now.setDate(now.getDate() - 7))
        let current_year = start_of_week.getFullYear()
        let next_clients = this.birthdays.filter((client) => {
          // parse geburtsdatum to dayjs object in format DD.MM.YYYY
          if (client.geburtsdatum === null || client.geburtsdatum === undefined || client.geburtsdatum === '') {
            return false
          }
          let client_date = dayjs(client.geburtsdatum, 'DD.MM.YYYY').year(current_year)
          // check if client's birthday is in the next seven days
          return client_date >= start_of_week 
        })

        let next_three_clients = next_clients.map((client) => {
          return {
            id: client.id,
            name: client.nachname + ' ' + client.vorname,
            birthday: dayjs(client.geburtsdatum, 'DD.MM.YYYY'),
            birthdayThisYear: dayjs(client.geburtsdatum, 'DD.MM.YYYY').year(now.getFullYear()),
          }
        })

        return next_three_clients.sort((a, b) => a.birthdayThisYear - b.birthdayThisYear).slice(0, 5)
      },

    },

    methods: {
      formatTime(dateString) {
        return dayjs(dateString).format('HH:mm');
      },

      getTimeWithAddedMinutes(date, duration) {
        return dayjs(date).add(duration, 'minute');
      },

      navigateToDocumentation(id) {
        this.$router.push({
            path: `/dokumentation/${id}`
        });
      },

      async showSafeKeyReminder() {

        // check if user has already seen the reminder
        if (localStorage.show_safe_key_reminder === 'false') {
          return 
        }

        // check if the user has a data key
        if (!this.$store.state.data_key) {
          return 
        }

        // check when the user received the reminder the last time
        let last_reminder = localStorage.last_safe_key_reminder

        // check if the key is correct by trying to decrypt some client data
        //let passwordKey = await cipher.getPasswordKey(this.$store.state.data_key);
        //let aesKey = await cipher.deriveKey(passwordKey, ['encrypt', 'decrypt']);

        let keys = await cipher.getAESKeys(this);
        let aesKey = keys['aes_key'];

        let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true, null, 1);
        if (customers === -1) {
          // error has already been shown
          return;
        }

        if (customers.length === 0) {
          // in this case, the user has no clients yet, so we do not show the reminder
          return;
        }

        let decrypted_customer = await cipher.decryptObject(this, aesKey, customers[0], false);
        let correct_key = decrypted_customer['nachname'] !== "********";

        if (!correct_key) {
          // in this case, the user has entered a wrong key, so we do not show the reminder
          return;
        }

        // check if last reminder was less than 30 days ago, if so, do not show the reminder
        if (last_reminder && dayjs().diff(dayjs(last_reminder), 'days') < 30) {
          return;
        }
        else {
          this.dialog_show_safe_key_reminder = true;
          
          // log that the reminder was shown
          if (this.session && this.session.user && this.session.user.id) {
            connector.logError(this, {
              uid: this.session.user.id,
              message: 'LOG: Safe key reminder shown',
            });
          }
        }
      },

      safeKeyReminerOK() {
        localStorage.last_safe_key_reminder = dayjs().toISOString()
        this.dialog_show_safe_key_reminder = false
      },

      safeKeyReminderNeverAgain() {
        localStorage.show_safe_key_reminder = false
        this.dialog_show_safe_key_reminder = false
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      openInvoice (item) {
        this.only_show_paid = false
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item)

        // set the default paid date to today
        this.editedItem.bezahlt = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)

        // properly set the selected_customer
        if (this.editedItem.fk_klienten_id) {
          this.editedItem.selected_customer = {
            type: 'client',
            name: this.editedItem.nachname + ' ' + this.editedItem.vorname,
            fk_klienten_id: this.editedItem.fk_klienten_id,
            item_id: `client-${this.editedItem.fk_klienten_id}`,
            vorname: this.editedItem.vorname,
            nachname: this.editedItem.nachname,
            merkmale: this.editedItem.merkmale
          }
        } else {
          this.editedItem.selected_customer = {
            type: 'institution',
            name: this.editedItem.empfänger_name,
            fk_institution_id: this.editedItem.fk_institution_id,
            item_id: `institution-${this.editedItem.fk_institution_id}`,
            merkmale: []
          }
        }

        this.dialog = true
      },

      getAgeOnBirthday(date) {
          // Adjust the birth date's year to the current year
          const birthdayThisYear = dayjs(date).year(dayjs().year());          
          return birthdayThisYear.diff(dayjs(date), 'years');
      },

      async initialize() {

        this.loading_appointments = true;
        this.loading_invoices = true;
        this.error_loading_appointments = false;
        this.error_loading_invoices = false;

        let customers = await connector.getDataOnlyFiltered(this, 'vwtermine', 'gte', 'datum', dayjs().format('YYYY-MM-DD'), 'datum', true, 'id,vorname,nachname,bezeichnung,datum,dauer,fk_klienten_id');
        if (customers === -1) {
          // error has already been shown
          this.error_loading_appointments = true;
          customers = [];
        }

        this.customers = await cipher.decryptDataSync(this, customers);
        this.loading_appointments = false;
        
        let invoices = await connector.getDataOnlyFiltered(this, 'vwrechnungen', 'is', 'bezahlt', null, 'datum', true);
        if (invoices === -1) {
          // error has already been shown
          this.error_loading_invoices = true;
          invoices = [];
        }

        this.invoices = await cipher.decryptDataSync(this, invoices);
        this.loading_invoices = false;
        
        // In initialize, after loading other data:
        if (this.selected_chart < this.chart_buttons.length) {
          const config = this.getChartConfig(this.selected_chart);
          connector.getChartData(this, config.dataView, this.$store.state.selected_year)
            .then((data) => {
              if (config.chartType === 'ea') {
                this.prepareEAChartData(data);
              } else {
                this.prepareChartData(data, config.chartType);
              }
            });
        }

        this.bezahlte_rechnungen = await connector.getStatsCardData(this, 'vwbezahlterechnungen', this.$store.state.selected_year);
        this.offene_rechnungen = await connector.getStatsCardData(this, 'vwoffenerechnungen', this.$store.state.selected_year);
        this.nicht_verrechnet = await connector.getStatsCardData(this, 'vwnichtverrechnetetermine', this.$store.state.selected_year);

        // check user already has appointments, if not show demo data
        if (this.bezahlte_rechnungen.betrag + this.offene_rechnungen.betrag + this.nicht_verrechnet.betrag === 0) {
          this.donutSeries = [1]
        } else {
          this.donutSeries = [this.bezahlte_rechnungen.betrag, this.offene_rechnungen.betrag, this.nicht_verrechnet.betrag];
        }
        
        connector.getDataOnly(this, 'vwaktivejahre', 'jahr', false)
          .then((years) => years.length > 0 ? this.$store.state.active_years = years : false )

        let birthdays = await connector.getDataOnly(this, 'vwgeburtstage', 'id', true)
        cipher.decryptDataAsync(this, birthdays, 'birthdays', 'loading_birthday')

      }, 

      getChartConfig(index) {
        // Note: our donut chart is in an extra tab; so only use this config for index < chart_buttons.length.
        const configs = [
          { dataView: 'vwumsatzprojahrundmonat', chartType: 'workedAmount' },
          { dataView: 'vwtermineprojahrundmonat', chartType: 'workedSessions' },
          { dataView: 'vweinnahmenausgabenprojahrundmonat', chartType: 'ea' },
        ];
        return configs[index] || null;
      },

      prepareEAChartData(data) {

        let add_example_data_text = false;
        if (data === 0) {
          // we do not have data yet, display dummy data.
          data = [
            {jahr: 2023, monat: 1, einnahmen: 400, ausgaben: 200, gewinn: 200},
            {jahr: 2023, monat: 2, einnahmen: 640, ausgaben: 680, gewinn: -40},
            {jahr: 2023, monat: 3, einnahmen: 480, ausgaben: 710, gewinn: -230},
            {jahr: 2023, monat: 4, einnahmen: 1360, ausgaben: 300, gewinn: 1060},
            {jahr: 2023, monat: 5, einnahmen: 1240, ausgaben: 710, gewinn: 530},
            {jahr: 2023, monat: 6, einnahmen: 0, ausgaben: 1200, gewinn: -1200},
            {jahr: 2023, monat: 7, einnahmen: 880, ausgaben: 875, gewinn: 5},
            {jahr: 2023, monat: 8, einnahmen: 1460, ausgaben: 580, gewinn: 880},
            {jahr: 2023, monat: 9, einnahmen: 0, ausgaben: 100, gewinn: -100},
            {jahr: 2023, monat: 10, einnahmen: 0, ausgaben: 300, gewinn: -300},
            {jahr: 2023, monat: 11, einnahmen: 100, ausgaben: 2320, gewinn: -2220},
            {jahr: 2023, monat: 12, einnahmen: 1620, ausgaben: 403.45, gewinn: 1216.55},
          ];
          add_example_data_text = true;
        }

        // Initialize arrays for the 12 months:
        let earnings = [];    // Einnahmen per month
        let expenses = [];    // Ausgaben per month
        let profitLoss = [];  // Gewinn/Verlust per month

        // Initialize totals for the summary card:
        let totalEarnings = 0;
        let totalExpenses = 0;

        // For each month (1-12), get the data from your backend
        let count = 0;
        for (let i = 1; i <= 12; i++) {
          // Look for an entry with the matching month
          let monthData = data.find(d => Number(d.monat) === i) || { einnahmen: 0, ausgaben: 0 };

          // Push values into arrays (ensure numbers!)
          let einnahmen = Number(monthData.einnahmen) || 0;
          let ausgaben = Number(monthData.ausgaben) || 0;
          let diff = einnahmen - ausgaben;

          if (einnahmen !== 0 || ausgaben !== 0) {
            count++;
          }

          earnings.push(einnahmen);
          expenses.push(ausgaben);
          profitLoss.push(diff);

          totalEarnings += einnahmen;
          totalExpenses += ausgaben;
        }

        // Compute average Gewinn/Verlust over 12 months:
        let avgProfitLoss = profitLoss.reduce((a, b) => a + b, 0) / count;

        // Save the totals to be used in the summary card (see Step 4):
        this.chartDataEinnahmenTotal = totalEarnings;
        this.chartDataAusgabenTotal = totalExpenses;

        // Set the chart series: two series – one for Einnahmen and one for Ausgaben.
        this.series = [
          {
            name: 'Einnahmen',
            data: earnings
          },
          {
            name: 'Ausgaben',
            data: expenses
          }
        ];

        let options = {
          ...this.options,
          chart: {
            ...(this.options.chart || {}),
            stacked: false
          },
          fill: {
            colors: [this.$store.state.theme.green, this.$store.state.theme.red],
          },
          annotations: {
            points: [],
            yaxis: [
              {
                y: avgProfitLoss,
                borderColor: '#000000',
                strokeDashArray: 6,
                width: '100%',
                label: {
                  borderColor: '#000000',
                  text: (avgProfitLoss >= 0 ? 'Ø Gewinn: ' : 'Ø Verlust: ') + avgProfitLoss.toFixed(0),
                  style: {
                    fontSize: '12px',
                    fontWeight: 600,
                    color: '#000'
                  }
                }
              }
            ]
          }
        };

        if (add_example_data_text) {
          options.title = {
            text: ['Hierbei handelt es sich um Beispiel-Werte.', 'Erstelle deine ersten Rechnungen um deine Statistik zu sehen.'],
            align: 'center',
            style: {
              fontSize: this.$vuetify.breakpoint.xsOnly ? '10px' : '16px',
            }
          }
        } else {
          options.title = {}
        }

        this.options = options;
      },

      prepareChartData(data, chart_type) {
        
        if (!this.$store.getters.hasAppointments) {
          // display demo data
          if (chart_type === 'workedAmount') {
            data = [
              {"jahr":2023,"monat":1,"wert":640,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":640,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":2,"wert":960,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":960,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":3,"wert":1120,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":1120,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":4,"wert":1280,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":1280,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":5,"wert":800,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":800,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":6,"wert":160,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":160,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":7,"wert":1400,"wert_geplant":0,"wert_unbezahlt":480,"wert_bezahlt":740,"wert_nicht_verrechnet":180},
              {"jahr":2023,"monat":8,"wert":80,"wert_geplant":80,"wert_unbezahlt":0,"wert_bezahlt":0,"wert_nicht_verrechnet":80},
              {"jahr":2023,"monat":9,"wert":0,"wert_geplant":320,"wert_unbezahlt":0,"wert_bezahlt":0,"wert_nicht_verrechnet":0}
            ]
          } else if (chart_type === 'workedSessions') {
            data = [
              {"jahr":2023,"monat":1,"wert":20,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":20,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":2,"wert":3,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":3,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":3,"wert":8,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":8,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":4,"wert":14,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":14,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":5,"wert":6,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":6,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":6,"wert":16,"wert_geplant":0,"wert_unbezahlt":0,"wert_bezahlt":16,"wert_nicht_verrechnet":0},
              {"jahr":2023,"monat":7,"wert":16,"wert_geplant":0,"wert_unbezahlt":3,"wert_bezahlt":5,"wert_nicht_verrechnet":8},
              {"jahr":2023,"monat":8,"wert":2,"wert_geplant":2,"wert_unbezahlt":0,"wert_bezahlt":0,"wert_nicht_verrechnet":2},
              {"jahr":2023,"monat":9,"wert":0,"wert_geplant":12,"wert_unbezahlt":0,"wert_bezahlt":0,"wert_nicht_verrechnet":0}
            ]
          }
        }

        let chart_data = Object.assign({}, this.chart_default_values)
        let chart_data_planned = Object.assign({}, this.chart_default_values)
        let chart_data_unpaid = Object.assign({}, this.chart_default_values)
        let chart_data_paid = Object.assign({}, this.chart_default_values)
        let chart_data_not_invoiced = Object.assign({}, this.chart_default_values)

        // in case there is no data, getChartData returns 0
        let points = []

        if (data !== 0) {
          for (let i in data) {
            chart_data[data[i].monat] = data[i].wert
            chart_data_planned[data[i].monat] = data[i].wert_geplant
            if('wert_unbezahlt' in data[i]) {
              chart_data[data[i].monat] = data[i].wert - data[i].wert_unbezahlt - data[i].wert_bezahlt - data[i].wert_nicht_verrechnet

              chart_data_unpaid[data[i].monat] = data[i].wert_unbezahlt
              chart_data_paid[data[i].monat] = data[i].wert_bezahlt
              chart_data_not_invoiced[data[i].monat] = data[i].wert_nicht_verrechnet

              points.push({
                x: this.options.xaxis.categories[data[i].monat - 1],
                y: data[i].wert + data[i].wert_geplant,
                label: {
                  text: data[i].wert + data[i].wert_geplant,
                  offsetY: -5,
                  borderColor: 'transparent',
                  style: {
                    fontWeight: 600,
                    fontSize: '12px',
                  }

                },
                marker: {
                  size: 0
                }
              });
            }
          }
        }

        let chart_array = []
        let chart_array_planned = []
        let chart_array_unpaid = []
        let chart_array_paid = []
        let chart_array_not_invoiced = []

        for (let i = 1; i < 13; i++) { 
          chart_array.push(chart_data[i])
          chart_array_planned.push(chart_data_planned[i])
          chart_array_unpaid.push(chart_data_unpaid[i])
          chart_array_paid.push(chart_data_paid[i])
          chart_array_not_invoiced.push(chart_data_not_invoiced[i])
        }

        let colors = []
        if (chart_type === 'workedAmount' || chart_type === 'workedSessions') {
          this.series = [
            {
              name: 'Bezahlt',
              data: [...chart_array_paid],
            },
            {
              name: 'Unbezahlt',
              data: [...chart_array_unpaid],
            },
            {
              name: 'Nicht verrechnet',
              data: [...chart_array_not_invoiced],
            },
            {
              name: 'Geplant',
              data: [...chart_array_planned],
            },
          ]

          colors = [this.$store.state.theme.green, this.$store.state.theme.red, this.$store.state.theme.orange, this.$store.state.theme.primary]

        } else {
          this.series = [
            {
              name: 'Betrag',
              data: [...chart_array],
            },
          ]

          colors = [this.$store.state.theme.green]
        }

        let mean = points.map((point) => point.y).reduce((acc, curr) => acc + curr, 0) / points.length;
        let y_axis_mean = [];

        if (mean && (chart_type === 'workedAmount' || chart_type === 'workedSessions')) {
          y_axis_mean = [
                {
                  y: mean,
                  borderColor: '#000000',
                  strokeDashArray: 6,
                  width: '100%',
                  label: {
                    borderColor: '#000000',
                    // offsetX: -90,
                    style: {
                      fontWeight: 600,
                      fontSize: '12px',
                    },
                    text: 'Ø ' + Math.round(mean),
                  }
                }
              ];
        }

        let options = {
          ...this.options,
          chart: {
            ...(this.options.chart || {}),
            stacked: true
          },
          annotations: {
            points: points,
            yaxis: y_axis_mean,
          },
          colors: colors,
          fill: {
            colors: colors,
          },
        }

        if (!this.$store.getters.hasAppointments) {
          options.title = {
            text: ['Hierbei handelt es sich um Beispiel-Werte.', 'Erstelle deine ersten Termine um deine Statistik zu sehen.'],
            align: 'center',
            style: {
              fontSize: this.$vuetify.breakpoint.xsOnly ? '10px' : '16px',
            }
          }
        } else {
          options.title = {};
        }

        // update chart to show annotations and colors
        this.options = options;

      },

      changedYear() {
        localStorage.selected_year = parseInt(this.$store.state.selected_year);
        this.initialize();
      },
    }
  }
</script>

<style scoped>
.year-selector {
  max-width: 200px !important;
}

.v-sheet.v-card {
  border-radius: 6px;
}

.cursor-pointer {
  cursor: pointer;
}

.next-appointments-table >>> tbody tr .row-documentation-btn {
  border: 1px solid transparent;
  border-radius: 20px;
}
.next-appointments-table >>> tbody tr:hover .row-documentation-btn {
  border-color: currentColor;
  border-radius: 20px;
}

.unpaid-invoices-table >>> tbody tr .row-mark-paid-btn {
  border: 1px solid transparent;
  border-radius: 20px;
}
.unpaid-invoices-table >>> tbody tr:hover .row-mark-paid-btn {
  border-color: currentColor;
  border-radius: 20px;
}

.past-appointment {
  color: rgba(0, 0, 0, 0.38);
}
</style>