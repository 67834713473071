<template>
    <v-dialog v-model="show" max-width="600px" persistent>
        <v-card>
            <v-card-title class="text-h5">
                Soll diese Rechnung wirklich gelöscht werden?
            </v-card-title>
            <v-card-text v-if="item.beleg_nummer" class="text-body-1">
                Wenn du diese Rechnung löschst, wird auch der zugehörige Beleg gelöscht.
            </v-card-text>
            <v-card-actions class="px-5 pb-5">
                <v-spacer />
                <v-btn :color="$store.state.theme.primary" :disabled="deleting_invoice" text
                    @click="$emit('close')">Nein</v-btn>
                <v-btn :color="$store.state.theme.red" :loading="deleting_invoice" outlined
                    @click="deleteItemConfirm">Rechnung löschen</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import connector from '../helpers/supabase-connector.js';

export default {
    name: 'DeleteInvoiceDialog',
    
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    
    emits: ['close', 'invoiceDeleted', 'showInfo', 'showError'],

    data() {
        return {
            deleting_invoice: false,
        };
    },

    methods: {
        async deleteItemConfirm() {
            this.deleting_invoice = true;
            let deleted = await connector.delete(this, 'rechnungen', 'id', this.item.id);
            if (!deleted) {
                // error was already shown
                this.deleting_invoice = false;
                return;
            } 
                
            this.$emit('invoiceDeleted', this.item.id);
            this.$emit('close');
            this.deleting_invoice = false;
            this.$emit('showInfo', {
                message: 'Die Rechnung wurde erfolgreich gelöscht.',
                timeout: 5000
            });
        },
    }
}

</script>