import { supabase } from "../supabase";
import connector from "../helpers/supabase-connector.js";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import InspectModule from "docxtemplater/js/inspect-module";
import { saveAs } from "file-saver";
import json from "../assets/ustZusatzItems.json";
import dayjs from "dayjs";
import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError } from "@supabase/supabase-js";

export default {

    updateInvoiceNumberToCurrentYear(invoiceNumber, invoiceDate) {
        try {
            // 1) Get the current invoice format
            const format = this.getInvoiceFormat(invoiceNumber);
            
            // 2) Get the year from the invoice date
            const invoiceDateYear = dayjs(invoiceDate).year();
            
            // 3) Format the new invoice number with the same number but updated year
            return {
                status: "success",
                invoiceNumber: this.formatInvoiceNumber(1, invoiceDateYear, format)
            };
            
        } catch (error) {
            return {
                status: "error",
                message: 'Die Rechnungsnummer konnte nicht aktualisiert werden. Bitte passe sie manuell an.',
                error: error
            };
        }
    },

    isValidFormat(format) {
        // Erlaubte Platzhalter und Trennzeichen
        const validPlaceholders = ["{NR}", "{JAHR}"];
        const validSeparators = ["/", "-"];

        // Überprüfen, ob ein gültiges Trennzeichen im Format enthalten ist
        const separator = validSeparators.find((sep) => format.includes(sep));
        if (!separator) {
            return false; // Kein gültiges Trennzeichen gefunden
        }

        // Platzhalter trennen
        const parts = format.split(separator);
        if (parts.length !== 2) {
            return false; // Falsche Anzahl an Teilen
        }

        // Überprüfen, ob beide Teile gültige Platzhalter sind
        const [part1, part2] = parts;
        if (!validPlaceholders.includes(part1) || !validPlaceholders.includes(part2)) {
            return false; // Ungültige Platzhalter
        }

        // Überprüfen, ob die Platzhalter korrekt angeordnet sind
        if (part1 === part2) {
            return false; // Platzhalter dürfen nicht identisch sein
        }

        return true;
    },

    // HOTFIX helper function to get invoice number format.
    // We should store the format when creating the invoice along with the invoice.
    // in this case, we need to manually update the existing invoices or use this as fallback.
    getInvoiceFormat(invoiceNumber) {
        const separator = invoiceNumber.includes('/') ? '/' : '-';
        const [part1, part2] = invoiceNumber.split(separator);
        
        // Check if the first part is a year (assumes years are 2000 or later)
        if (part1.length === 4 && parseInt(part1) >= 2020 && parseInt(part1) <= 2026) {
            return `{JAHR}${separator}{NR}`;
        } else {
            return `{NR}${separator}{JAHR}`;
        }
    },

    generateInvoiceRegex(format) {
        const yearPart = "20\\d{2}";
        const numberPart = "\\d+";
        const separator = format.includes("/") ? "/" : "-";

        if (format === "{NR}" + separator + "{JAHR}") {
            return new RegExp(`^${numberPart}${separator}${yearPart}$`);
        } else if (format === "{JAHR}" + separator + "{NR}") {
            return new RegExp(`^${yearPart}${separator}${numberPart}$`);
        }

        throw new Error("Ungültiges Format.");
    },

    parseInvoiceNumber(invoiceNumber, format) {
        const regex = this.generateInvoiceRegex(format);
        if (!regex.test(invoiceNumber)) throw new Error("Ungültiges Format.");

        const separator = format.includes("/") ? "/" : "-";
        const [part1, part2] = invoiceNumber.split(separator);

        if (format === `{NR}${separator}{JAHR}`) {
            return { number: parseInt(part1), year: parseInt(part2) };
        } else if (format === `{JAHR}${separator}{NR}`) {
            return { year: parseInt(part1), number: parseInt(part2) };
        } else {
            throw new Error("Ungültiges Format.");
        }
    },

    formatInvoiceNumber(number, year, format) {
        const separator = format.includes("/") ? "/" : "-";

        if (format === `{NR}${separator}{JAHR}`) {
            return `${number}${separator}${year}`;
        } else if (format === `{JAHR}${separator}{NR}`) {
            return `${year}${separator}${number}`;
        } else {
            throw new Error("Ungültiges Format.");
        }
    },

    getNextInvoiceNumber(invoices, format = "{NR}/{JAHR}") {
        if (invoices.length === 0) {
            return this.formatInvoiceNumber(1, new Date().getFullYear(), format);
        } else {
            let maxYear = -1;
            let maxNumber = 0;

            invoices.forEach((invoice) => {
                try {
                    const { number, year } = this.parseInvoiceNumber(invoice.nummer, format);

                    if (year > maxYear) {
                        maxYear = year;
                        maxNumber = number;
                    } else if (year === maxYear) {
                        if (number > maxNumber) {
                            maxNumber = number;
                        }
                    }
                } catch (error) {
                    // we skip the invoice if it has an unsupported format
                    if (error.message !== "Ungültiges Format.") {
                        throw error;
                    }
                }
            });

            if (maxYear === -1 || maxNumber === 0) {
                throw new Error("Es kann keine Rechnungsnummer vorgeschlagen werden, da keine gültigen Rechnungsnummern gefunden wurden.");
            }

            maxNumber += 1;
            return this.formatInvoiceNumber(maxNumber, maxYear, format);
        }
    },

    formatDate(date) {
        if (!date) return null;
        return dayjs(date).format("DD.MM.YYYY");
    },

    checkTags(recipientTags, templateTags) {
        let missingTags = [];

        for (let key in recipientTags) {
            let tagGroups = recipientTags[key];
            let tagsFound = false;

            for (let tagGroup of tagGroups) {
                if (tagGroup.every((tag) => templateTags.includes(tag))) {
                    tagsFound = true;
                    break;
                }
            }

            if (!tagsFound) {
                // check if there are partial tags present in the templateTags
                let partialsFound = false;
                for (let tagGroup of tagGroups) {
                    let partials = tagGroup.filter((tag) => templateTags.includes(tag));
                    if (partials.length > 0) {
                        partialsFound = true;
                        let missing = tagGroup.filter((tag) => !partials.includes(tag));
                        missingTags = missingTags.concat(missing);
                        break;
                    }
                }

                if (!partialsFound) {
                    missingTags = missingTags.concat(tagGroups[0]);
                }
            }
        }

        return missingTags;
    },

    async getInvoiceTemplates(component) {
        let templates = [];

        // only if the global default template is not set, also show the ZEIPSY template
        // otherwise, the ZEIPSY template is not shown (as it leads to strange behavior otherwise)
        if (component.$store.state.client.standard_vorlage === null) {
            templates.push({
                id: null,
                created_at: null,
                updated_at_formatted: null,
                updated_at: null,
                name: "invoice-template-with-services-and-ust.docx",
                displayName: "ZEIPSY-Vorlage",
                size: null,
                mimetype: null,
                uploading: false,
                previewing: false,
                downloading: false,
            });
        }

        try {
            let files = await connector.listFilesInBucket(component, "invoice-templates", component.session.user.id + "/");
            if (files === -1) {
                // error has already been displayed
                files = []; // return empty array
                // TODO: improve error state in UI for getInvoiceTemplates.
            }
            let filtered_files = [...files.filter((file) => file.id !== null && file.name !== ".emptyFolderPlaceholder")];
            filtered_files = filtered_files
                .map((file) => {
                    file.updated_at_formatted = dayjs(file.updated_at).format("DD.MM.YY HH:mm");
                    file.updated_at = dayjs(file.updated_at).toISOString();
                    if (file.name === "custom-invoice-template-with-services-and-ust.docx") {
                        file.displayName = "Eigene-Vorlage";
                    } else {
                        // remove the .docx extension if it ends with it
                        file.displayName = file.name.endsWith(".docx") ? file.name.slice(0, -5) : file.name;
                    }
                    file.size = this.bytesToSize(file.metadata.size);
                    file.uploading = false;
                    file.previewing = false;
                    file.downloading = false;
                    return file;
                })
                .filter((file) => file.name !== "custom-invoice-template.docx");
            return templates.concat(filtered_files);
        } catch (error) {
            component.$emit("showError", { 
                message: "Fehler beim Laden der Vorlagen. Bitte versuche es erneut.", 
                error: error
            });
            return templates;
        }
    },

    bytesToSize(bytes) {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    getLatestReceiptNumber(invoices) {
        if (invoices.length === 0) {
            return 1;
        } else {
            let receipt_numbers = invoices.map((invoice) => invoice.beleg_nummer).filter((num) => num !== null && num !== undefined);

            if (receipt_numbers.length === 0) {
                return 1; // Return 1 if all values are null or undefined
            }

            let max_receipt_number = Math.max(...receipt_numbers);
            return max_receipt_number + 1;
        }
    },

    async createNewReceipt(component, item, invoices) {

        let new_receipt_number = this.getLatestReceiptNumber(invoices);
        
        let updated = await connector.update(
            component,
            "rechnungen",
            {
                beleg_nummer: new_receipt_number,
            },
            item.id
        );
        
        if (updated === null) {
            // error has already been displayed
            return {
                status: "error",
                type: "database_error",
            };
        }
        
        return {
            status: "success",
            beleg_nummer: new_receipt_number,
        };
    },

    async getReceiptDocument(component, item) {
        let invoice = Object.assign({}, item);
        invoice.datum = this.formatDate(invoice.bezahlt);
        invoice.praxis_name = component.$store.state.client.name;
        invoice.bezeichnung = component.$store.state.client.bezeichnung;
        invoice.praxis_adresse = component.$store.state.client.adresse;
        invoice.praxis_plz = component.$store.state.client.plz;
        invoice.praxis_ort = component.$store.state.client.ort;
        invoice.praxis_telefon = component.$store.state.client.telefon;

        let bucket = "public-templates";
        let filename = "receipt-template.docx";
        let path = "invoices/";

        let content = await connector.downloadFileFromBucket(component, bucket, path, filename);

        let undefined_tags = [];

        const iModule = InspectModule();
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [iModule],
            parser: function (tag) {
                return {
                    get: function (scope) {
                        let result = null;
                        if (tag === ".") {
                            result = scope;
                        } else {
                            result = scope[tag.toLowerCase()];
                        }
                        return result;
                    },
                };
            },
            nullGetter: function (part) {
                if (!part.module) {
                    if (part.type === "placeholder" && part.value.toLowerCase() === "termin_dauer") {
                        return "-";
                    }
                    undefined_tags.push(part);
                    return "";
                }
                if (part.module === "rawxml") {
                    return "";
                }
                return "";
            },
        });

        const tags = iModule.getAllTags();
        let template_tags = Object.keys(tags);

        // Check if all template tags are set in invoice object
        let missing_tag = false;
        let warning_text = "";

        template_tags.forEach((tag) => {
            tag = tag.toLowerCase();

            // optional tags, svnr and geburtsdatum, then we can skip them,
            // as they are not required and therefore not triggering the warning
            if (!(tag in invoice) || invoice[tag] === null || invoice[tag] === undefined || invoice[tag] === "") {
                let location_where_to_change = "";

                if (tag.startsWith("praxis_")) {
                    location_where_to_change = '. Bitte vervollständige die Daten unter "Einstellungen" -> "Praxis-Informationen"';
                    //this.button_title_location = 'Einstellungen'
                }

                warning_text = "Es fehlen folgende Daten für einen vollständigen Beleg: " + tag + location_where_to_change;
                missing_tag = true;
                return;
            }
        });
        if (missing_tag) {
            // this.dialog_warning = true
            this.$emit("showError", { message: warning_text });
            return {
                status: "error",
                type: "missing_tags",
            };
        }

        // Render the document
        doc.render(invoice);

        // Handle undefined tags
        if (undefined_tags.length > 0) {
            component.$emit("showError", {
                message:
                    "Die folgenden Platzhalter wurden nicht definiert: " +
                    undefined_tags.map((tag) => "{" + tag.value + "}").join(", ") +
                    ". Bitte wende dich an contact@zeipsy.com für Unterstützung.",
                error: "This should never happen in Belege!",
            });
            return {
                status: "error",
                type: "tag_error",
            };
        }

        const blob = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        let invoice_name = "Beleg_" + invoice.beleg_nummer;

        // Convert to PDF and save
        return await this.convertToPdf(component, blob, invoice_name, true);
    },

    async createInvoice(component, invoice, to_pdf, template) {
        component.$set(invoice, "downloading_invoice", true);
        let invoice_document = await this.createInvoiceDocument(component, invoice, to_pdf, true, template);
        component.$set(invoice, "downloading_invoice", false);
        return invoice_document;
    },

    async createInvoiceDocument(component, item, to_pdf, download = true, template = null) {
        let ust_zusatz_items = json.ust_zusatz_items;

        let invoice = Object.assign({}, item);
        invoice.datum = this.formatDate(invoice.datum);
        invoice.praxis_name = component.$store.state.client.name;
        invoice.bezeichnung = component.$store.state.client.bezeichnung;
        invoice.praxis_adresse = component.$store.state.client.adresse;
        invoice.praxis_plz = component.$store.state.client.plz;
        invoice.praxis_ort = component.$store.state.client.ort;
        invoice.praxis_telefon = component.$store.state.client.telefon;
        invoice.praxis_iban = component.$store.state.client.iban;
        invoice.praxis_bic = component.$store.state.client.bic;

        if (invoice.bezahlt && invoice.bar) {
            if (component.$store.state.client.zahlungsziel_bar) {
                invoice.zahlungsziel = component.$store.state.client.zahlungsziel_bar;
            } else {
                invoice.zahlungsziel = "Betrag dankend erhalten.";
            }
        } else if (invoice.bezahlt) {
            // Added this, so that if the invoice is paid via wire transfer, and the amount was received, this is also noted on the invoice.
            // Did not use custom zahlungsziel here, as some customers already use the term "Betrag dankend in bar erhalten", which might then 
            // not be true, if it was paid via wire transfer. So we always use the below text.
            invoice.zahlungsziel = "Betrag dankend erhalten.";
        } else {
            if (component.$store.state.client.zahlungsziel_bank) {
                invoice.zahlungsziel = component.$store.state.client.zahlungsziel_bank;
            } else {
                invoice.zahlungsziel = "Bitte um Überweisung innerhalb von 14 Tagen ab Erhalt der Rechnung.";
            }
        }

        // check if all termine have the same ust_befreiung
        let ust_befreiung = invoice.termine[0].termin_ust_befreiung;
        let ust_befreiung_same = invoice.termine.every((termin) => termin.termin_ust_befreiung === ust_befreiung);
        if (!ust_befreiung_same) {
            // get distinct ust_befreiung values
            let ust_befreiung_values = invoice.termine.map((termin) => termin.termin_ust_befreiung).filter((value) => value !== null);
            let distinct_ust_befreiung_values = [...new Set(ust_befreiung_values)];
            invoice.ust_befreiung = "Diese Leistung ist gemäß § 6 Abs. 1 ";

            // iterate through all distinct ust_befreiung values and add them to the text
            distinct_ust_befreiung_values.forEach((ust_befreiung_value, index) => {
                if (index === 0 && distinct_ust_befreiung_values.length === 1) {
                    // if there is only one ust_befreiung value, then we don't need to add "und" or ",".
                    invoice.ust_befreiung += "Z " + ust_zusatz_items[ust_befreiung_value] + " UstG umsatzsteuerbefreit.";
                } else if (index === 0) {
                    invoice.ust_befreiung += "Z " + ust_zusatz_items[ust_befreiung_value];
                } else if (index === distinct_ust_befreiung_values.length - 1) {
                    invoice.ust_befreiung += " und " + ust_zusatz_items[ust_befreiung_value] + " UstG umsatzsteuerbefreit.";
                } else {
                    invoice.ust_befreiung += ", " + ust_zusatz_items[ust_befreiung_value];
                }
            });
        } else {
            if (!ust_befreiung) {
                // in this case, ust_befreiung is null as it is nowhere set (old invoices), so we set it to 1, which is the Behandlung Ust-Befreiung
                ust_befreiung = 1;
            }
            invoice.ust_befreiung = "Diese Leistung ist gemäß § 6 Abs. 1 Z " + ust_zusatz_items[ust_befreiung] + " UstG umsatzsteuerbefreit.";
        }

        let bucket = "public-templates";
        let filename = "invoice-template-with-services-and-ust.docx";
        let path = "invoices/";

        if (template && template.name && template.id !== null) {
            if (template.updated_at) {
                let updated_template_at = '?updated_at=' + template.updated_at;
                filename = template.name + updated_template_at;
            } else {
                filename = template.name;
            }
            path = component.session.user.id + "/";
            bucket = "invoice-templates";
        }

        let content = await connector.downloadFile(component, bucket, path, filename);
        if (content === null) {
            // error has already been shown
            return {
                status: "error",
                type: "load_failed",
            };
        }

        let undefined_tags = [];

        const iModule = InspectModule();
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [iModule],
            parser: function (tag) {
                return {
                    get: function (scope) {
                        let result = null;
                        if (tag === ".") {
                            result = scope;
                        } else {
                            result = scope[tag.toLowerCase()];
                        }
                        return result;
                    },
                };
            },
            nullGetter: function (part, scopeManager) {
                let optional_tags = [
                    "svnr",
                    "geburtsdatum",
                    "zusatztext",
                    "versicherungsträger",
                    "versicherter_versicherungsträger",
                    "versicherter_svnr",
                    "titel_vorgestellt",
                    "titel_nachgestellt",
                    "empfänger_titel_vorgestellt",
                    "empfänger_titel_nachgestellt",
                    "versicherter_titel_vorgestellt",
                    "versicherter_titel_nachgestellt",
                ];
                if (!part.module) {
                    if (part.type === "placeholder" && part.value.toLowerCase() === "termin_dauer") {
                        return "-";
                    } else if (part.type === "placeholder" && optional_tags.includes(part.value.toLowerCase())) {
                        return "";
                    } else if (part.type === "placeholder" && part.value.toLowerCase() === "termin_bezeichnung") {
                        return "";
                    }
                    undefined_tags.push(part);
                    return "";
                }
                if (part.module === "rawxml") {
                    return "";
                }
                return "";
            },
        });

        const tags = iModule.getAllTags();
        let template_tags = Object.keys(tags);

        if (invoice.empfänger_name === null) {
            // replace the tag 'empfänger_name' with 'empfänger_vorname' and 'empfänger_nachname'
            // replace the tag 'empfänger_anschrift' with 'empfänger_adresse', 'empfänger_plz' and 'empfänger_ort'
            template_tags = template_tags
                .map((tag) => {
                    if (tag === "empfänger_name") {
                        return ["empfänger_vorname", "empfänger_nachname"];
                    } else if (tag === "empfänger_anschrift") {
                        return ["empfänger_adresse", "empfänger_plz", "empfänger_ort"];
                    } else {
                        return tag;
                    }
                })
                .flat();
        }

        // check if empfänger_name is set (by institution), if not, use empfänger_vorname and empfänger_nachname
        if (invoice.empfänger_name === null) {
            invoice.empfänger_name =
                (invoice.empfänger_titel_vorgestellt || "") +
                (invoice.empfänger_vorname || "") +
                " " +
                (invoice.empfänger_nachname || "") +
                (invoice.empfänger_titel_nachgestellt || "");
        }

        // check if empfänger_anschrift is set, if not, use empfänger_adresse, empfänger_plz and empfänger_ort
        if (invoice.empfänger_anschrift === null) {
            invoice.empfänger_anschrift = (invoice.empfänger_adresse || "") + "\n" + (invoice.empfänger_plz || "") + " " + (invoice.empfänger_ort || "");
        }

        // check if empfänger_anschrift is set, if not, use empfänger_adresse, empfänger_plz and empfänger_ort
        if (invoice.empfänger_email === null) {
            invoice.empfänger_email = invoice.email;
        }

        // Check if all template tags are set in invoice object
        let missing_tag = false;
        let missing_tags = [];
        let optional_tags = [
            "svnr",
            "geburtsdatum",
            "zusatztext",
            "versicherungsträger",
            "versicherter_versicherungsträger",
            "versicherter_svnr",
            "titel_vorgestellt",
            "titel_nachgestellt",
            "empfänger_titel_vorgestellt",
            "empfänger_titel_nachgestellt",
            "versicherter_titel_vorgestellt",
            "versicherter_titel_nachgestellt",
        ];

        if (invoice.rechnungs_empfänger === "institution") {
            // check if the template tags include one of the empfänger_ vorname, nachname, adresse, plz, ort tags, if so, raise a warning
            let client_tags = [
                "empfänger_titel_vorgestellt",
                "empfänger_titel_nachgestellt",
                "empfänger_vorname",
                "empfänger_nachname",
                "empfänger_adresse",
                "empfänger_plz",
                "empfänger_ort",
            ];
            if (template_tags.some((tag) => client_tags.includes(tag))) {
                component.$emit("showError", {
                    message:
                        "Du hast eine Institution als Rechnungsempfänger angegeben, allerdings enthält die ausgewählte Rechnungsvorlage Platzhalter, welche ausschließlich für Personen als Rechnungsempfänger verwendet werden können." +
                        " Bitte wähle eine andere Rechnungsvorlage oder ändere den Rechnungsempfänger." +
                        " Achte darauf, dass die Vorlage die richtigen Platzhalter für Institutionen enthält. Diese sind: {empfänger_name}, {empfänger_anschrift}." +
                        " Die folgenden Platzhalter sind nicht für Institutionen geeignet: " +
                        client_tags.join(", ") +
                        "." +
                        " Solltest du Hilfe benötigen, kontaktiere uns gerne unter contact@zeipsy.com. Wir helfen dir schnell & gerne weiter.",
                });
                return {
                    status: "error",
                    type: "wrong_tags",
                    // missing_tags: client_tags,
                    // client_id: invoice.fk_klienten_id,
                };
            }
        }

        template_tags.forEach((tag) => {
            tag = tag.toLowerCase();

            // optional tags, svnr and geburtsdatum, then we can skip them,
            // as they are not required and therefore not triggering the warning
            if (optional_tags.includes(tag)) {
                return;
            }

            if (!(tag in invoice)) {
                // skip undefined tags, those are handled below seperately
                return; 
            }

            if (invoice[tag] === null || invoice[tag] === undefined || invoice[tag] === "") {
                if (invoice.rechnungs_empfänger && tag.startsWith("empfänger_")) {
                    // e.g. vater_ort, since tag = empfänger_ort and rechnungs_empfänger = vater
                    missing_tags.push(invoice.rechnungs_empfänger + "_" + tag.split("_")[1]);
                } else {
                    if (tag.startsWith("empfänger_")) {
                        missing_tags.push(tag.split("_")[1]);
                    } else {
                        missing_tags.push(tag);
                    }
                }

                missing_tag = true;
                return;
            }
        });

        if (missing_tag) {
            // connector.logError(component, {
            //     uid: component.session.user.id,
            //     message: "Fehlende Angaben auf Rechnung: " + missing_tags.join(", "),
            // });
            return {
                status: "error",
                type: "missing_tags",
                missing_tags: missing_tags,
                client_id: invoice.fk_klienten_id,
            };
        }

        // Render the document (Replace variables)
        doc.render(invoice);
        // check if there are undefined tags
        if (undefined_tags.length > 0) {
            return {
                status: "error",
                type: "undefined_tags",
                undefined_tags: undefined_tags.map(tag => '{' + tag.value + '}'),
            };
        }

        const blob = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        let invoice_name = (invoice.nachname + "_" + invoice.vorname + "_" + invoice.nummer).replaceAll(" ", "_");
        if (component.$store.state.client.schema_invoice_filename === '{NR}_{NAME}') {
            invoice_name = (invoice.nummer + "_" + invoice.nachname + "_" + invoice.vorname).replaceAll(" ", "_");
        }

        if (to_pdf) {
            return await this.convertToPdf(component, blob, invoice_name, download);
        } else {
            await saveAs(blob, invoice_name + ".docx");
            return {
                status: "success",
                blob: blob,
                filename: invoice_name + ".docx"
            };
        }
    },

    async convertToPdf(component, blob, filename, download) {
        const formdata = new FormData();
        formdata.append("files", new File([blob], "invoice.docx"));

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + component.session.access_token);
        myHeaders.append("Accept", "*/*");
        myHeaders.append("x-client-info", "supabase-js/2.8.0");
        myHeaders.append("x-region", "eu-central-1");

        const requestOptions = {
            headers: myHeaders,
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        let attempts = 2; // Including the initial attempt
        const retryDelay = 500; // 500 ms

        while (attempts > 0) {
            try {
                let response = await fetch("https://api.zeipsy.com/functions/v1/proxy/forms/libreoffice/convert", requestOptions);
                if (response.ok) {
                    const pdfBlob = await response.blob();
                    if (download) {
                        await saveAs(pdfBlob, filename + ".pdf");
                    }
                    return {
                        status: "success",
                        blob: pdfBlob,
                        filename: filename + ".pdf"
                    };
                } else {
                    throw new Error(response.status);
                }
            } catch (error) {

                const errorMessage = error.message || '';
                const shouldRetry = errorMessage.includes('Load failed') || 
                                    errorMessage.includes('Failed to fetch') ||
                                    errorMessage.includes('NetworkError') ||
                                    errorMessage.includes('503') ||
                                    errorMessage.includes('504') ||
                                    errorMessage.includes('500');

                if (shouldRetry && attempts > 1) {
                    attempts--;
                    if (component.session && component.session.user && component.session.user.id) {
                        connector.logError(component, {
                            uid: component.session.user.id,
                            message: "Silent Error during invoice2pdf: " + error,
                        });
                    }
                    await new Promise((resolve) => setTimeout(resolve, retryDelay));
                    continue;
                }
                let document_type = filename.startsWith("Beleg") ? "Der Beleg" : "Die Rechnung";
                component.$emit("showError", {
                    message: `${document_type} konnte nicht heruntergeladen werden. Bitte versuche es erneut.`,
                    error: error,
                    timeout: 10000,
                });
                return {
                    status: "error",
                    type: "conversion_failed",
                };
            }
        }
    },

    async sendInvoiceMail(component, email) {
        let responseJson = {};
        const maxAttempts = 2;
        const retryDelay = 500; // 500 ms

        for (let attempts = 1; attempts <= maxAttempts; attempts++) {
            try {
                const { data, error } = await supabase.functions.invoke("send-email", {
                    body: email,
                });

                if (error) {
                    throw error;
                }

                responseJson = data;
                return responseJson;
            } catch (error) {
                if (error instanceof FunctionsFetchError && attempts < maxAttempts) {
                    connector.logError(component, {
                        uid: component.$store.state.client.id,
                        message: "LOG: Silently fail calling send-email-new function.",
                    });
                    await new Promise((resolve) => setTimeout(resolve, retryDelay));
                } else {
                    component.$emit("showError", { 
                        message: "Die Rechnung konnte nicht versendet werden.",
                        timeout: 10000,
                        error: error
                    });
                    return null;
                }
            }
        }
    },

    async getSampleInvoicePDF(component, invoice_template) {
        let invoice = {};
        invoice.datum = "2023-04-12";

        invoice.titel_vorgestellt = "Dr. ";
        invoice.titel_nachgestellt = "";
        invoice.vorname = "Max";
        invoice.nachname = "Mustermann";
        invoice.adresse = "Musterstraße 1";
        invoice.plz = "1020";
        invoice.ort = "Wien";

        invoice.empfänger_titel_vorgestellt = "Dr. ";
        invoice.empfänger_titel_nachgestellt = "";
        invoice.empfänger_vorname = "Max";
        invoice.empfänger_nachname = "Mustermann";
        invoice.empfänger_name =
            invoice.empfänger_titel_vorgestellt + invoice.empfänger_vorname + " " + invoice.empfänger_nachname + invoice.empfänger_titel_nachgestellt;
        invoice.empfänger_adresse = "Musterstraße 1";
        invoice.empfänger_plz = "1020";
        invoice.empfänger_ort = "Wien";
        invoice.empfänger_anschrift = invoice.empfänger_adresse + "\n" + invoice.empfänger_plz + " " + invoice.empfänger_ort;

        invoice.versicherter_titel_vorgestellt = "Dr. ";
        invoice.versicherter_titel_nachgestellt = "";
        invoice.versicherter_vorname = "Max";
        invoice.versicherter_nachname = "Mustermann";
        invoice.versicherter_svnr = "1234 010190";
        invoice.versicherter_versicherungsträger = "ÖGK";

        invoice.svnr = "1234 010190";
        invoice.versicherungsträger = "ÖGK";
        invoice.geburtsdatum = "01.01.1990";
        invoice.nummer = "1/2023";
        invoice.zusatztext = "Diagnose: F43.2 - Anpassungsstörung";
        invoice.termine = [
            {
                termin_bezeichnung: "Klinisch-psychologische Behandlung (Einzel)",
                termin_datum: "06.04.2023",
                termin_dauer: "50",
                termin_preis: "80",
                termin_ust_befreiung: 1,
            },
            {
                termin_bezeichnung: "Klinisch-psychologische Behandlung (Einzel)",
                termin_datum: "12.04.2023",
                termin_dauer: "50",
                termin_preis: "80",
                termin_ust_befreiung: 1,
            },
        ];
        invoice.rechnungs_betrag = "160";
        invoice.gesamt_dauer_minuten = "100";
        invoice.gesamt_dauer_stunden = "1,67";

        if (component.$store.state.client.zahlungsziel_bank) {
            invoice.zahlungsziel = component.$store.state.client.zahlungsziel_bank;
        } else {
            invoice.zahlungsziel = "Bitte um Überweisung innerhalb von 14 Tagen ab Erhalt der Rechnung.";
        }

        let converted_invoice = await this.createInvoiceDocument(component, invoice, true, false, invoice_template);
        if (converted_invoice && "status" in converted_invoice && converted_invoice.status === "success") {
            return converted_invoice;
        } else if (converted_invoice && "status" in converted_invoice && converted_invoice.status === "error" && converted_invoice.type === "missing_tags") {
            let missing_tags = converted_invoice.missing_tags;
            if (missing_tags.filter((tag) => tag.startsWith("praxis_") || tag === "bezeichnung").length > 0) {
                if (component.$router.currentRoute.name === "Einstellungen") {
                    component.$emit("showError", {
                        message: "Es fehlen noch Angaben zu deiner Praxis. Bitte fülle die fehlenden Angaben aus, um eine Rechnungsvorschau zu erstellen.",
                    });

                    component.$router.push({
                        query: { missing: missing_tags.filter((tag) => tag.startsWith("praxis_") || tag === "bezeichnung").join(",") },
                    });

                    missing_tags.forEach((field) => {
                        component.$refs[field].validate(true);
                    });

                    component.$refs["praxis_informationen"].scrollIntoView({ behavior: "smooth" });
                    return converted_invoice;
                } else {
                    return converted_invoice;
                }
            } else {
                component.$emit("showError", {
                    message:
                        "Die Rechnungsvorschau konnte nicht erstellt werden, da die folgenden Pflichtfelder fehlen: " +
                        missing_tags.map((tag) => "{" + tag + "}").join(", "),
                });
                return null;
            }
        } else if (converted_invoice && "status" in converted_invoice && converted_invoice.status === "error" && converted_invoice.type === "undefined_tags") {
            component.$emit("showError", {
                message:
                    "Die folgenden Platzhalter existieren in ZEIPSY nicht: " +
                    converted_invoice.undefined_tags.map((tag) => tag).join(", ") +
                    ". Bitte überprüfe ob diese Platzhalter auf der Vorlage richtig geschrieben sind. Im Hilfe-Center (https://zeipsy.com/anleitungen/) findest du eine detailierte Anleitung. Du kannst dich auch gerne an contact@zeipsy.com für Unterstützung wenden.",
            });
            return null;
        } else {
            component.$emit("showError", { message: "Fehler beim Erstellen der Rechnung. Bitte versuche es erneut.", timeout: 5000 });
            return null;
        }
    },
};
