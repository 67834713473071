import vuex from 'vuex';
import Vue from 'vue';
import dayjs from 'dayjs';
import connector from '../helpers/supabase-connector';

Vue.use(vuex);

export const store = new vuex.Store({
    state: {
        data_key: localStorage.data_key ? localStorage.data_key : '',
        aes_key: null,
        aes_key_file: null,
        theme: {
            primary: '#897f7c',
            red: '#f44336',
            green: '#00a152',
            orange: '#ff9800',
            background: '#f5f5f5',
            background_tabs: '#f9f9f9',
        },
        client: {
            id: null,
            name: null,
            bezeichnung: null,
            telefon: null,
            adresse: null,
            plz: null,
            ort: null,
            iban: null,
            bic: null,
            vpnr: null,
            std_preis: null,
            std_dauer: null,
            konto_einbuchung: null,
            svs_zahlung_am: null,
            svs_quartal_betrag: null,
            svs_prozent_ruecklagen: null,
            eigene_vorlage: null,
            eigenes_schema: null,
            google_calendar: null,
            google_calendar_anonymize: null,
            google_calendar_anonymization_format: null,
            beta_circle: null,
            subscription_tier: null,
            has_stripe_customer_id: null,
            registration_date: null,
            empfehlungscode: null,
            empfehlungen: null,
            email_betreff: null,
            email_nachricht: null,
            salt: null,
            zahlungsziel_bank: null,
            zahlungsziel_bar: null,
            rechnungsnummer_format: null,
            standard_vorlage: null,
            accepted_sms_terms: null,
            kalender_start: null,
            kalender_ende: null,
            schema_invoice_filename: null,
            last_downgrade_on: null,
            last_upgrade_on: null,
            default_invoice_send_method: null,
            provider_refresh_token: null
        },
        active_years: [],
        selected_year: localStorage.selected_year ? parseInt(localStorage.selected_year) : new Date().getFullYear(),
        session: null,
        cache: {},
        number_of_reserved_bookings: 0,
        show_holidays: (parseInt(localStorage.getItem('hide_holidays')) || 0) === 0,

        onboarding: {
            has_clients: true,
            has_appointments: true,
            has_services: true,
        },
    },

    getters: {
        isInTrialPeriod: (state) => {
            const registrationDate = dayjs(state.client.registration_date);
            let trial_duration = 1; // change this for different trial duration

            // check if registration date is was before 2024-01-12, then set trial duration to 2 months
            if (registrationDate.isBefore(dayjs('2024-01-12'))) {
                trial_duration = 2;
            }

            const trialEndDate = registrationDate.add(trial_duration, 'month');
            return trialEndDate.isAfter(dayjs());
        },
        remainingTrialDays: (state, getters) => {
            if (getters.isInTrialPeriod) {
                let trial_duration = 1; // change this for different trial duration
                const registrationDate = dayjs(state.client.registration_date);

                // check if registration date is was before 2024-01-12, then set trial duration to 2 months
                if (registrationDate.isBefore(dayjs('2024-01-12'))) {
                    trial_duration = 2;
                }

                const trialEndDate = registrationDate.add(trial_duration, 'month');
                return trialEndDate.diff(dayjs(), 'day');
            }
            return 0;
        },
        subscriptionTier: (state, getters) => {
            let tier = state.client.subscription_tier;
            if (tier) {
                return tier;
            } else if (state.client.beta_circle === true) {
                return 'VOLLZEIT';
            } else if (getters.isInTrialPeriod) {
                return 'VOLLZEIT';
            } else {
                return 'GERINGFÜGIG';
            }
        },
        hasActiveSubscription: (state, getters) => {
            return getters.subscriptionTier !== 'GERINGFÜGIG';
        },
        hasPaidSubscription: (state) => {
            return state.client.subscription_tier !== null && state.client.has_stripe_customer_id;
        },
        hasClients: (state) => {
            return state.onboarding.has_clients;
        },
        hasAppointments: (state) => {
            return state.onboarding.has_appointments;
        },
        hasServices: (state) => {
            return state.onboarding.has_services;
        },
        getTrueSubscriptionTier: (state) => {
            return state.client.subscription_tier;
        },

    },

    mutations: {
        setDefaultInvoiceSendMethod(state, method) {
            state.client.default_invoice_send_method = method;
        },

        setClient(state, client) {
            // store salt in local storage
            localStorage.salt = client.salt;
            state.client = Object.assign({}, client);
        },

        setAcceptedSMSTerms(state, accepted) {
            state.client.accepted_sms_terms = accepted;
        },

        setOnboardingStatus(state, status) {
            state.onboarding = Object.assign({}, status);
        },

        setOnboardingServiceStatus(state, status) {
            state.onboarding.has_services = status;
        },

        setOnboardingAppointmentStatus(state, status) {
            state.onboarding.has_appointments = status;
        },

        setOnboardingClientStatus(state, status) {
            state.onboarding.has_clients = status;
        },

        resetOnboardingStatus(state) {
            state.onboarding = {
                has_clients: false,
                has_appointments: false,
                has_services: false,
            };
        },
        
        setAesKey(state, aesKey) {
            state.aes_key = aesKey;
        },

        setAesKeyFile(state, aesKeyFile) {
            state.aes_key_file = aesKeyFile;
        },

        setGoogleCalendarAnonymizationFormat(state, format) {
            state.client.google_calendar_anonymization_format = format;
        },
    },

    actions: {
        async fetchOnboardingStatus({ commit }, component) {
          let status = await connector.checkOnboardingStatus(component);
          if (status) {
            commit('setOnboardingStatus', status);
          }
        },
    },
});
