<template>
    <v-row class="mt-0 pa-0">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <v-card outlined>
                <v-card-title class="d-flex align-center">
                    <!-- Bitte überprüfe die Rechnung vor dem Versenden der E-Mail -->
                    Vorschau
                    <v-spacer />
                    <v-btn small icon color="grey darken-2" @click="printInvoice">
                        <v-icon left>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn class="ml-3" small outlined :color="$store.state.theme.green" @click="downloadInvoiceBlob">
                        <v-icon left>mdi-arrow-down-bold-circle-outline</v-icon>
                        {{ $vuetify.breakpoint.xsOnly ? '' : 'Rechnung' }} herunterladen
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <iframe v-if="current_invoice_url" :src="current_invoice_url + '#toolbar=0'" width="100%" ref="invoiceIframe"
                        :style="{ height: computedHeight + 'px' }">
                    </iframe>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
            <v-card outlined>
                <v-card-title class="pb-2">
                    Empfänger
                </v-card-title>
                <v-container class="px-5">
                    <v-form v-model="valid_email">
                        <div class="d-flex align-items-center justify-center">
                            <v-text-field dense outlined v-model="editedItem.email" label="E-Mail Adresse"
                                :rules="emailRules" ref="recipient_email" />
                            <v-checkbox :color="$store.state.theme.primary" v-model="send_to_me"
                                label="Kopie an mich selbst senden (CC)" class="ml-5 my-auto py-0" />
                        </div>
                    </v-form>

                    <div class="d-flex flex-wrap align-center justify-space-between mt-0 mb-2">
                        <span class="text-h6 text--black">E-Mail Nachricht</span>
                        <span class="text-caption">
                            <span class="font-weight-bold">Tipp:</span> Die Vorlage für den Betreff und die Nachricht
                            kann in den Einstellungen angepasst
                            werden.
                        </span>
                    </div>
                    <v-text-field class="mt-5 mb-0 pb-0" dense outlined v-model="subject" label="Betreff" />
                    <v-textarea v-model="draft_message" :key="textareaKey" label="Nachricht" hide-details auto-grow outlined class="mb-0 pb-0"></v-textarea>

                    <v-card outlined class="mt-5 mb-4">
                        <v-alert class="mb-5" dense text
                            :icon="pdfEncryption === 'password' ? 'mdi-download-lock-outline' : 'mdi-paperclip'">
                            {{ pdfEncryption === 'password' ?
                                'Die Rechnung kann einmalig über ein sicheres Portal heruntergeladen werden.' : 'Die Rechnung wird automatisch als PDF der E-Mail angehängt.' }}
                        </v-alert>
                        <v-card-text class="my-0 py-0">
                            <v-radio-group v-model="pdfEncryption" row>
                                <span class="text-subtitle-2 mr-4">Rechnungsversand:</span>
                                <v-radio :color="$store.state.theme.primary" label="Unverschlüsselt"
                                    value="none"></v-radio>
                                <v-radio :color="$store.state.theme.primary" label="Verschlüsselt"
                                    value="password"></v-radio>
                            </v-radio-group>

                            <v-expand-transition>
                                <div v-if="pdfEncryption === 'password'" class="mt-2">
                                    <p class="text-body-1">
                                        Authentifizierungsmethode:
                                    </p>
                                    <v-radio-group v-model="passwordType" class="mt-0">
                                        <v-radio :color="$store.state.theme.primary"
                                            :disabled="!phoneNumberPerson || !$store.getters.hasPaidSubscription || !checkPhoneNumber(phoneNumberPerson)"
                                            value="3">
                                            <template #label>
                                                <div>
                                                    Einmal-Passwort per SMS {{ phoneNumberPerson ?
                                                        `(${phoneNumberPerson})` : '(Keine gültige Telefonnummer hinterlegt)' }}
                                                    <div class="text-caption text--secondary">
                                                        Rechnung ist <strong>60 Tage</strong> verfügbar
                                                        <v-chip color="success" x-small class="ml-2 text-overline">
                                                            Empfohlen
                                                        </v-chip>
                                                    </div>
                                                    <v-alert v-if="!$store.getters.hasPaidSubscription" dense outlined
                                                        type="info" class="mt-2 mb-0">
                                                        Ein aktives Abonnement ist erforderlich, um Einmal-Passwörter
                                                        per SMS zu versenden.
                                                    </v-alert>
                                                    <v-alert
                                                        v-else-if="phoneNumberPerson && !checkPhoneNumber(phoneNumberPerson)"
                                                        dense outlined type="warning" icon="mdi-alert-outline"
                                                        class="mt-2 mb-0">
                                                        Die Telefonnummer muss mit einer Ländervorwahl (z.B. +43) beginnen.
                                                    </v-alert>
                                                </div>
                                            </template>
                                        </v-radio>
                                        <v-radio :color="$store.state.theme.primary" :disabled="!svNumberInsuredPerson"
                                            value="2">
                                            <template #label>
                                                <div>
                                                    {{ 'Versicherungsnummer des Klienten' + (svNumberInsuredPerson ? `
                                                    (${svNumberInsuredPerson})` : ' (Keine gültige SV-Nummer hinterlegt)') }}
                                                    <div class="text-caption text--secondary">Rechnung ist <strong>10 Tage</strong> verfügbar</div>
                                                </div>
                                            </template>
                                        </v-radio>
                                        <v-radio :color="$store.state.theme.primary" :disabled="!birthDatePerson"
                                            value="1">
                                            <template #label>
                                                <div>
                                                    {{ 'Geburtsdatum des Klienten' + (birthDatePerson ? `
                                                    (${birthDatePerson})` : ' (Kein Geburtsdatum hinterlegt)') }}
                                                    <div class="text-caption text--secondary">Rechnung ist <strong>10 Tage</strong> verfügbar</div>
                                                </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </div>
                            </v-expand-transition>
                        </v-card-text>
                    </v-card>

                    <div class="d-flex flex-wrap justify-space-between align-center mt-2">
                        <span class="text-caption mb-3" v-if="pdfEncryption === 'password'">
                            <span v-if="pdfEncryption === 'password' && passwordType === '3'">
                                <v-tooltip bottom max-width="300" open-delay="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="tooltip-trigger">
                                            <v-icon x-small color="primary" left>mdi-information-outline</v-icon>
                                            Kosten: 0,12 €
                                        </span>
                                    </template>
                                    <span>
                                        Die SMS-Kosten werden mit deiner nächsten ZEIPSY-Abo-Rechnung über dein hinterlegtes Zahlungsmittel abgerechnet – je nach gewähltem Zahlungsintervall monatlich oder jährlich.
                                    </span>
                                </v-tooltip>
                            </span>
                            <span v-else>Kosten: 0,00 €</span> |
                            <v-icon small>mdi-lock</v-icon>
                            Sichere Datenübertragung
                        </span>
                        <div class="mb-3 ml-auto">
                            <v-btn @click="closeSendEmail" text :color="$store.state.theme.primary">
                                {{ abortName }}
                            </v-btn>
                            <v-btn class="ml-5"
                                :disabled="!valid_email || (pdfEncryption === 'password' && passwordType === '0')"
                                :color="$store.state.theme.green" @click="sendInvoice"
                                :dark="!(!valid_email || (pdfEncryption === 'password' && passwordType === '0'))"
                                :loading="sending_invoice">
                                <v-icon left>mdi-send-variant</v-icon>
                                Versenden
                            </v-btn>
                        </div>
                    </div>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import connector from "../helpers/supabase-connector.js";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import invoices from "../helpers/invoices.js";
import cipher from "@/helpers/cipher.js";
import { supabase } from "@/supabase.js";
import { validationRules } from '@/helpers/validation-rules';

export default {
    emits: ["showError", "showInfo", "loadInvoices", "close"],
    props: ["session", "abortName"],
    data() {
        return {
            computedHeight: window.innerHeight,

            editedItem: {
                id: null,
                email: null,
                vorname: null,
                nachname: null,
                nummer: null,
            },
            current_invoice_url: null,
            send_to_me: true,
            subject: "Rechnung",
            draft_message: "Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}",
            sending_invoice: false,
            valid_email: false,
            emailRules: validationRules.emailRequired,
            current_invoice_blob: null,
            current_invoice_filename: null,

            pdfEncryption: 'none',
            passwordType: '0',

            textareaKey: 0,
        };
    },
    computed: {
        svNumberInsuredPerson() {
            if ('svnr' in this.editedItem && this.editedItem.svnr) {
                let svnr = this.editedItem.svnr.replaceAll(' ', '');
                if (svnr.length === 10) {
                    return svnr;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },

        birthDatePerson() {
            if ('geburtsdatum' in this.editedItem && this.editedItem.geburtsdatum) {
                return this.editedItem.geburtsdatum;
            } else {
                return null;
            }
        },

        phoneNumberPerson() {
            if ('telefon' in this.editedItem && this.editedItem.telefon) {
                let phone = this.editedItem.telefon.replaceAll(' ', '')
                return phone;
            } else {
                return null;
            }
        }

    },

    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },

    methods: {

        printInvoice() {
            // Access the iframe using the ref
            const iframe = this.$refs.invoiceIframe;
            
            if (iframe && iframe.contentWindow) {
                // Focus the iframe and trigger print
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            } else {
                this.$emit("showError", { 
                    message: "Die Rechnungsvorschau ist noch nicht geladen oder kann nicht gedruckt werden.",
                    timeout: 7000
                 });
            }
        },

        checkPhoneNumber(phoneNumber) {
            if (!phoneNumber) {
                return false;
            }
            return /^\+((43|49|420|421|36|386|39|41|423)\d+)$/.test(phoneNumber);
        },

        handleResize() {
            this.computedHeight = window.innerHeight - 200;
        },

        async openSendEmail(item, invoice_template) {
            try {
                let response = await invoices.createInvoiceDocument(this, item, true, false, invoice_template);
                if (!("status" in response && response.status === "success")) {
                    return response;
                }
                this.current_invoice_blob = response.blob;
                this.current_invoice_url = URL.createObjectURL(response.blob);
                this.current_invoice_filename = response.filename;
                if (this.current_invoice_url) {
                    this.editedItem = Object.assign({}, item);

                    // Preselect encryption method based on settings or secure default ordering
                    if (this.$store.state.client.default_invoice_send_method) {
                        let method = this.$store.state.client.default_invoice_send_method;
                        let optionAvailable = false;

                        if (method === 'password_sms') {
                            // SMS option is available only if a valid phone number exists and the user is subscribed to a paid plan
                            optionAvailable = (this.phoneNumberPerson &&
                                this.checkPhoneNumber(this.phoneNumberPerson) &&
                                this.$store.getters.hasPaidSubscription);
                        } else if (method === 'password_svnr') {
                            // Check if a valid SV-Nummer is available
                            optionAvailable = !!this.svNumberInsuredPerson;
                        } else if (method === 'password_birthdate') {
                            // Check if the birthdate is available
                            optionAvailable = !!this.birthDatePerson;
                        }

                        if (optionAvailable) {
                            // Use the user-defined option
                            if (method === 'password_sms') {
                                this.pdfEncryption = 'password';
                                this.passwordType = '3';
                            } else if (method === 'password_svnr') {
                                this.pdfEncryption = 'password';
                                this.passwordType = '2';
                            } else if (method === 'password_birthdate') {
                                this.pdfEncryption = 'password';
                                this.passwordType = '1';
                            }
                        } else {
                            // Fall back to secure default ordering if the chosen method is not available
                            if (this.phoneNumberPerson && this.checkPhoneNumber(this.phoneNumberPerson) && this.$store.getters.hasPaidSubscription) {
                                this.pdfEncryption = 'password';
                                this.passwordType = '3';
                            } else if (this.svNumberInsuredPerson) {
                                this.pdfEncryption = 'password';
                                this.passwordType = '2';
                            } else if (this.birthDatePerson) {
                                this.pdfEncryption = 'password';
                                this.passwordType = '1';
                            } else {
                                this.pdfEncryption = 'none';
                                this.passwordType = '0';
                            }
                        }
                    } else {
                        // Null is unencrypted
                        this.pdfEncryption = 'none';
                        this.passwordType = '0';
                    }

                    // we need to set it here, as we cannot do it in the DB as we would mix unencrypted and encrypted data types
                    if (this.editedItem.rechnungs_empfänger === 'institution' && (this.editedItem.email === null || this.editedItem.email === "") && this.editedItem.empfänger_email !== null) {
                        this.editedItem.email = this.editedItem.empfänger_email;
                    }

                    // set the default message and subject
                    this.subject = this.$store.state.client.email_betreff ? this.$store.state.client.email_betreff : "Rechnung";
                    this.draft_message = this.$store.state.client.email_nachricht
                        ? this.$store.state.client.email_nachricht
                        : "Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}";

                    // replace placeholders in the message
                    this.draft_message = this.draft_message
                        .replace(/{vorname}/gi, this.editedItem.vorname || '')
                        .replace(/{nachname}/gi, this.editedItem.nachname || '')
                        .replace(/{titel_vorgestellt}/gi, this.editedItem.titel_vorgestellt || '')
                        .replace(/{titel_nachgestellt}/gi, this.editedItem.titel_nachgestellt || '')
                        .replace(/{empfänger_vorname}/gi, this.editedItem.empfänger_vorname || '')
                        .replace(/{empfänger_nachname}/gi, this.editedItem.empfänger_nachname || '')
                        .replace(/{empfänger_titel_vorgestellt}/gi, this.editedItem.empfänger_titel_vorgestellt || '')
                        .replace(/{empfänger_titel_nachgestellt}/gi, this.editedItem.empfänger_titel_nachgestellt || '')
                        .replace(/{praxis_name}/gi, this.$store.state.client.name || '')
                        .replace(/[ \t]+(\r\n|\n|\r)/g, "$1");

                    this.$nextTick(() => {
                        if ("recipient_email" in this.$refs) this.$refs["recipient_email"].validate(true);
                        this.textareaKey++;
                    });

                    return response;
                }
            } catch (error) {
                this.$emit("showError", { message: "Fehler beim Erstellen der Rechnung. " + error });
                return { status: "error" };
            }
        },

        closeSendEmail() {
            this.current_invoice_blob = null;
            this.current_invoice_filename = null;

            // to clear cache
            if (this.current_invoice_url !== null) {
                URL.revokeObjectURL(this.current_invoice_url);
                this.current_invoice_url = null;
            }

            this.$emit('close');

            this.$nextTick(() => {
                this.editedItem = {
                    id: null,
                    email: null,
                    vorname: null,
                    nachname: null,
                    nummer: null,
                };
            });
        },

        async downloadInvoiceBlob() {
            await saveAs(this.current_invoice_blob, this.current_invoice_filename);
        },

        async sendInvoice() {
            if (this.editedItem.email === null || this.editedItem.email === "") {
                this.$emit("showError", { message: "Keine E-Mail Adresse angegeben." });
                return;
            }

            if (this.subject === "" || this.subject === null) {
                this.$emit("showError", { message: "Kein Betreff angegeben." });
                return;
            }

            if (this.draft_message === "" || this.draft_message === null) {
                this.$emit("showError", { message: "Keine Nachricht angegeben." });
                return;
            }

            // send invoice
            this.sending_invoice = true;

            let invoice_binary = Buffer.from(await this.current_invoice_blob.arrayBuffer());
            let password = null;
            let salt = null;
            if (this.pdfEncryption === 'password') {
                if (this.passwordType === '1') {
                    password = this.birthDatePerson;
                } else if (this.passwordType === '2') {
                    password = this.svNumberInsuredPerson;
                } else if (this.passwordType === '3') {
                    const charset = '23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
                    const length = 8;
                    const mask = (2 << (Math.log(charset.length - 1) / Math.LN2)) - 1;
                    
                    let oneTimePassword = '';
                    while (oneTimePassword.length < length) {
                        const array = new Uint8Array(1);
                        window.crypto.getRandomValues(array);
                        const random = array[0] & mask;
                        if (random < charset.length) {
                            oneTimePassword += charset[random];
                        }
                    }
                    password = oneTimePassword;
                }

                let encrypted_invoice = await cipher.encryptFileWithKey(invoice_binary, password);
                salt = encrypted_invoice.salt;
                const jsonString = JSON.stringify(encrypted_invoice);
                // Convert JSON string to ArrayBuffer
                invoice_binary = Buffer.from(new TextEncoder().encode(jsonString).buffer);
            }

            let email = {
                name: this.$store.state.client.name,
                recipient: this.editedItem.email.trim(),
                subject: this.subject,
                content: this.draft_message.trim(),
                reply_to: this.session.user.email,
                invoice_blob: invoice_binary,
                cc: this.send_to_me ? this.session.user.email : null,
                encryption: {
                    auth: this.passwordType,
                    salt: salt,
                    file_name: 'Rechnung_' + this.editedItem.nummer + '.pdf'
                }
            };

            try {
                let response = await invoices.sendInvoiceMail(this, email);

                if (response !== null && "status" in response && response.status) {

                    // try to send SMS OTP if selected
                    if (password && this.pdfEncryption === 'password' && this.passwordType === '3') {
                        const { data, error } = await supabase.functions.invoke('send-otp-sns', {
                            body: {
                                otp: password,
                                phoneNumber: this.phoneNumberPerson
                            }
                        });

                        if (error || data?.status !== true) {
                            this.$emit("showError", { 
                                message: "Das Einmal-Passwort konnte nicht per SMS versendet werden. Bitte versuche es erneut.",
                                timeout: 10000
                            });
                            this.sending_invoice = false;
                            return;
                        } 
                    }

                    // mark invoice as sent
                    let toInsert = {
                        versendet: true,
                    };

                    let updated = await connector.update(this, "rechnungen", toInsert, this.editedItem.id);
                    if (updated === null) {
                        // error has already been shown
                        this.$emit('showError', {
                            message: 'Die E-Mail wurde erfolgreich versendet, jedoch konnte der Status des E-Mail Versands nicht gespeichert werden.',
                            timeout: 10000,
                        });
                    }
                    this.$emit("loadInvoices");
                    this.sending_invoice = false;
                    if (updated !== null) this.$emit("showInfo", { message: "Die Rechnung wurde erfolgreich versendet.", timeout: 5000 });
                } else {
                    this.$emit("showError", { message: "Die Rechnung konnte nicht versendet werden.", timeout: 10000 });
                    this.sending_invoice = false;
                    return;
                }
            } catch (error) {
                this.$emit("showError", { 
                    message: "Fehler beim Versenden der E-Mail. Bitte versuche es erneut.",
                    timeout: 10000,
                    error: error 
                });
                this.sending_invoice = false;
                return;
            }
            this.closeSendEmail();
        },
    },
};

</script>

<style scoped>
.tooltip-trigger {
  cursor: help;
  border-bottom: 1px dashed #1976D2; /* Primary color dotted underline */
  display: inline-flex;
  align-items: center;
}

.tooltip-trigger:hover {
  color: #1976D2; /* Optional: change text color on hover */
}
</style>