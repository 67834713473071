<template>
  <div>
    <FilePreview 
      ref="filePreview" 
      :session="session" 
      bucket="receipts"
      @getBucketFiles="$emit('fetchTransactionReceipts')"
      @showError="(arg) => $emit('showError', arg)" 
      @showInfo="(arg) => $emit('showInfo', arg)" 
    />
    <div v-if="uploadedReceipts.length">
      <span class="text-subtitle-1 font-weight-medium mb-2">Bereits hochgeladene Belege:</span><br/>
      <v-chip v-for="receipt in uploadedReceipts" 
        :key="receipt.id" 
        class="mr-2 mb-3 red-delete-icon" 
        outlined 
        close
        :close-icon="receipt.toDelete ? 'mdi-delete-restore': 'mdi-delete'"
        :color="getColor(receipt)"
        @click:close="removeReceipt(receipt.id)"
        @click="$refs.filePreview.openFile(receipt)"
      >
        <v-icon v-if="receipt.toDelete" left small>
          mdi-receipt-text-remove-outline
        </v-icon>
        <v-icon v-else small left>
          mdi-receipt-text-outline
        </v-icon>
        <span :class="{'text-decoration-line-through': receipt.toDelete}">{{ receipt.name }}</span>
      </v-chip>
    </div>
    <v-card class="pa-2 inline-receipt-upload d-flex flex-column align-center" :class="{'drag-active': dragOver}"
      outlined @dragenter.prevent="handleDragEnter" @dragover.prevent @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop">
      <v-icon class="mt-2" size="30">mdi-cloud-upload-outline</v-icon>
      <div v-if="!dragOver" class="d-flex flex-column align-center text-center my-2">
        <span class="mt-1">Ziehe deine Belege hierher um sie hochzuladen</span>
        <span class="mb-1">oder</span>
        <v-btn outlined small @click="selectFiles" :disabled="uploading">
          <v-icon left>mdi-plus</v-icon>
          Belege auswählen
        </v-btn>
      </div>
      <div v-else class="d-flex flex-column align-center py-2">
        <span class="my-5 text-h6">Lass einfach los.</span>
      </div>
      <v-row v-if="newReceipts.length" class="mt-4 mb-1">
        <v-col v-for="receipt in newReceipts" :key="receipt.id" cols="auto" class="py-0">
          <v-chip class="mb-2 mr-2" outlined :color="getColor(receipt)" close
            @click:close="removeReceipt(receipt.id)">
            <v-icon left small>
              mdi-receipt-text-plus-outline
            </v-icon>
            <span>{{ receipt.name }}</span>
          </v-chip>
        </v-col>
      </v-row>
      <span v-if="newReceipts.length" class="text-caption">
        <v-icon small>mdi-information-outline</v-icon>
        Belege werden nach dem Klicken auf “Speichern” gespeichert.
      </span>
      <input type="file" ref="receiptInput" hidden multiple @change="handleFiles" />
    </v-card>
  </div>
</template>

<script>
import FilePreview from '@/components/FilePreview.vue';
export default {
  name: 'InlineReceiptUpload',

  components: {
    FilePreview
  },

  props: {
    receipts: {
      type: Array,
      default: () => []
    }, 
    session: {
      type: Object,
      required: true
    }
  },

  emits:['fetchTransactionReceipts', 'showInfo', 'showError'],

  data() {
    return {
      dragOver: false,
      dragCounter: 0,
      uploading: false,
    };
  },

  computed: {
    uploadedReceipts() {
      return this.receipts.filter((receipt) => !receipt.isNew);
    }, 

    newReceipts() {
      return this.receipts.filter((receipt) => receipt.isNew);
    },

    toDeleteReceipts() {
      return this.receipts.filter((receipt) => receipt.toDelete);
    },
    
  },

  methods: {

    getColor(receipt) {
      if (receipt.isNew) {
        return this.$store.state.theme.green;
      } else if (receipt.toDelete) {
        return this.$store.state.theme.red;
      } else {
        return null;
      }
    },

    selectFiles() {
      this.$refs.receiptInput.click();
    },

    handleDragEnter(e) {
      this.dragCounter++;
      this.dragOver = true;
    },

    handleDragLeave(e) {
      this.dragCounter--;
      if (this.dragCounter <= 0) {
        this.dragOver = false;
      }
    },

    handleDrop(e) {
      this.dragCounter = 0;
      this.dragOver = false;
      this.handleFiles(e);
    },

    handleFiles(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files || files.length === 0) return;

      // Create new receipt objects from the uploaded files
      const newFiles = Array.from(files).map(file => {
        return {
          id: 'local_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9),
          file,
          name: file.name,
          size: file.size,
          isNew: true,
          toDelete: false
        };
      });

      // Instead of pushing to a local copy, emit an update event
      this.$emit('update:receipts', [...this.receipts, ...newFiles]);
      this.$refs.receiptInput.value = '';
    },

    removeReceipt(id) {
      // When removing a receipt, update the parent's receipts
      const updatedReceipts = this.receipts.map(receipt => {
        if (receipt.id === id) {
          // For already uploaded receipts, toggle the deletion flag.
          if (!receipt.isNew) {
            return { ...receipt, toDelete: !receipt.toDelete };
          }
          // For new receipts, filter them out (we remove them immediately).
          return null;
        }
        return receipt;
      }).filter(Boolean);

      this.$emit('update:receipts', updatedReceipts);
    }
  }
};
</script>
  
<style scoped>
.inline-receipt-upload {
  border: 2px dashed #B0BEC5 !important;
  border-radius: 8px;
  text-align: center;
  padding: 16px;
  width: 100%;
  min-height: 120px;
  transition: all 0.2s ease-in-out;
  background-color: #FAFAFA;
}

.inline-receipt-upload.drag-active {
  border: 2px solid #1976d2 !important;
  background-color: #E3F2FD;
}
::v-deep .v-chip .v-chip__close.v-icon {
  color: #f44336 !important;
}
</style>