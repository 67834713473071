<template>
  <v-container fluid>

    <GoogleCalendar ref="googleCalendar" :session="session" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" />

    <v-dialog persistent max-width="1000px" v-model="dialog_configure_bookings" eager>
      <v-card>
        <v-card-title>
          Online Termin-Anfragen konfigurieren
        </v-card-title>
        <v-card-text>
          <v-row class="d-flex align-center">
            <v-col>
              <v-switch v-model="booking_on_holidays" @change="toggledHolidayBookings" :loading="updating_holiday_state" :disabled="updating_holiday_state" inset label="An Feiertagen ebenso buchbar"
                :color="$store.state.theme.green"></v-switch>
            </v-col>
            <v-col class="d-flex align-center">
              <v-select label="Vorlaufzeit" class="mr-2" v-model="booking_advance" :items="booking_advance_options"
                item-text="text" item-value="value" outlined dense hide-details />
              <v-tooltip bottom open-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                Das ist die Zeit zwischen jetzt und dem frühestmöglichen Termin. Wenn zum Beispiel "1 Tag"
                eingestellt ist, kann der nächste Termin frühestens ab morgen zur gleichen Uhrzeit angefragt werden.
              </v-tooltip>
            </v-col>
            <v-col class="d-flex align-center">
              <v-select label="Maximale Zeit" class="mr-2" v-model="booking_timeframe"
                :items="booking_timeframe_options" item-text="text" item-value="value" outlined dense hide-details />
              <v-tooltip bottom open-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                </template>
                Diese Zeit bestimmt, wie weit in die Zukunft eine Termin-Anfrage maximal gestellt werden kann. 
                Bei einer Einstellung von "4 Wochen" kann ein Termin innerhalb der nächsten 4 Wochen angefragt werden.
              </v-tooltip>
            </v-col>
          </v-row>
          <v-card outlined :disabled="!appointment_booking" class="mt-3">
            <v-tabs v-model="tab" dense show-arrows color="grey darken-4" :background-color="$store.state.theme.background_tabs">
              <v-tab>
                <span class="mr-2">Wiederkehrende Termine</span>
                <v-tooltip bottom open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                  </template>
                  Wiederkehrende Termine sind regelmäßige Termine. 
                  Du kannst den Wochentag, die Uhrzeit und die Dienstleistung für diese Termine festlegen. 
                  Falls zu der gewählten Zeit schon ein anderer Termin stattfindet, wird der wiederkehrende Termin automatisch übersprungen.
                </v-tooltip>
              </v-tab>
            </v-tabs>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-row v-for="(slot, index) in recurringSlots" :key="index" class="mt-3">
                        <v-col cols="3" class="my-0 py-0">
                          <v-select v-model="slot.tag" :items="days" :rules="dayRule" label="Tag" item-text="text" item-value="value" outlined dense />
                        </v-col>
                        <v-col cols="2" class="my-0 py-0">
                          <v-text-field dense outlined v-model="slot.uhrzeit" label="Uhrzeit" value="12:00:00" type="time" :rules="timeRule" />
                        </v-col>
                        <v-col cols="7" class="d-flex my-0 py-0">
                          <v-select v-model="slot.fk_dienstleistungs_id" :items="services" label="Dienstleistung auswählen"
                            item-text="bezeichnung" item-value="id" :rules="serviceRule" outlined dense class="mr-5" />

                          <v-tooltip bottom :open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mr-2" icon v-if="slot.id" :color="$store.state.theme.primary" @click="toggleRecurringSlotState(slot, index)" 
                                :disabled="pausing_slot" v-bind="attrs" v-on="on">
                                <v-icon v-if="slot.inaktiv === null || slot.inaktiv === false">mdi-pause-circle</v-icon>
                                <v-icon v-else :color="$store.state.theme.green">mdi-play-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ (slot.inaktiv === null || slot.inaktiv === false) ? 'Den wiederkehrenden Termin vorübergehend pausieren.' : 'Den wiederkehrenden Termin wieder aktivieren.' }}</span>
                          </v-tooltip>

                          <v-tooltip bottom :open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon @click="removeRecurringSlot(slot, index)" :color="$store.state.theme.red" :disabled="deleting_slot" v-bind="attrs" v-on="on">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Den wiederkehrenden Termin löschen.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <!-- Wiederkehrende Termine -->
                      <v-row class="my-0 py-0">
                        <v-col cols="12" class="my-0 py-0">
                          <v-btn @click="addRecurringSlot()" rounded small elevation="0">
                            <v-icon left>mdi-plus</v-icon>
                            <span v-if="recurringSlots.length === 0">Neuer Termin</span>
                            <span v-else>Weiterer Termin</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="mx-2 pb-5 mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn @click="dialog_configure_bookings = false" text>
            Schließen
          </v-btn>
          <v-btn class="ml-3" @click="saveConfiguration" :disabled="!valid" :loading="saving_configuration" :color="$store.state.theme.green" outlined>
            Änderungen Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BookingRequestDialog
      :dialog="bookingRequestDialog"
      :request="selectedBookingRequest"
      @close="bookingRequestDialog = false"
      @closeRequest="closeBookingRequest"
      @deleteRequest="deleteBookingRequest"
    />

    <div class="d-flex justify-center mb-3" v-if="$vuetify.breakpoint.smAndDown">
      <v-btn class="mr-4" @click="setToday" outlined small>
        Heute
      </v-btn>
      <v-btn-toggle 
        v-model="toggle_calendar_view" 
        mandatory dense class="mr-3" 
        style="height: 28px;"
      >
        <v-btn small>
          4 Tage
        </v-btn>
        <v-btn small>
          Woche
        </v-btn>
        <v-btn small>
          Monat
        </v-btn>
      </v-btn-toggle>

      <v-dialog v-model="showCalendarSettingsDialog" fullscreen persistent>
        <v-card>
          <v-toolbar dark :color="$store.state.theme.primary">
            <v-btn icon @click="showCalendarSettingsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Kalender Einstellungen</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-5">
            <CalendarSettingsPanel 
              :working-hours-start.sync="working_hours_start"
              :working-hours-end.sync="working_hours_end"
              :session="session"
              :googleCalendar="$refs.googleCalendar"
              :settingsVisible="showCalendarSettingsDialog"
              @updateIntervalHeight="updateIntervalHeight"
              @removeCalendarEvents="removeCalendarEvents"
              @removeGoogleCalendar="removeGoogleCalendar"
              @fetchGoogleAppointments="fetchGoogleAppointments"
              @fetchCalendarEvents="fetchCalendarEvents"
              @showError="(arg) => $emit('showError', arg)" 
              @showInfo="(arg) => $emit('showInfo', arg)"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-badge
        :content="openBookingRequestsCount"
        :value="openBookingRequestsCount"
        color="red"
        bordered
        offset-x="20"
        offset-y="20"
        v-if="$store.state.client.beta_circle"
      >
        <v-btn @click="toggleBookingRequests" icon color="black" class="ml-2" :small="$vuetify.breakpoint.xsOnly">
          <v-icon>mdi-calendar-account</v-icon>
        </v-btn>
      </v-badge>
    </div>

    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp && (showBookingRequests || showCalendarSettings) ? 8 : 12">
        <v-card width="100%">
          <v-toolbar flat>
            <v-row>
              <v-col cols="12" class="d-flex justify-start align-center">
                <v-btn ref="prevButton" outlined small :color="$store.state.theme.primary" @click="prev">
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn ref="nextButton" class="ml-1" outlined small :color="$store.state.theme.primary" @click="next">
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>

                <div v-if="$vuetify.breakpoint.mdAndUp">
                  <v-btn class="mx-4" @click="setToday" small outlined>
                    Heute
                  </v-btn>
                  <v-btn-toggle v-model="toggle_calendar_view" mandatory dense :style="{'height': '28px'}">
                    <v-btn small>
                      4 Tage
                    </v-btn>
                    <v-btn small>
                      Woche
                    </v-btn>
                    <v-btn small>
                      Monat
                    </v-btn>
                  </v-btn-toggle>
                </div>
                <v-toolbar-title class="ml-5 text-body-1 font-weight-medium" v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer/>
                <span v-if="loading_appointments && $vuetify.breakpoint.smAndUp" class="mx-2 text-caption">
                    Termine werden geladen...
                </span>

                <v-btn icon @click="toggleCalendarSettings" color="black">
                  <v-icon>mdi-cog</v-icon>
                </v-btn>

                <v-badge
                  :content="openBookingRequestsCount"
                  :value="openBookingRequestsCount"
                  color="red"
                  bordered
                  offset-x="20"
                  offset-y="20"
                  v-if="$store.state.client.beta_circle"
                >
                  <v-btn @click="toggleBookingRequests" icon color="black" class="ml-2">
                    <v-icon>mdi-calendar-account</v-icon>
                  </v-btn>
                </v-badge>
              </v-col>
              <!-- <v-btn @click="writeCalenderFileToBucket('calendar.ics')" dark :color="$store.state.theme.primary" :icon="$vuetify.breakpoint.smAndDown"  elevation="1">
                <div class="hidden-sm-and-down">
                    <v-icon left >mdi-calendar-export-outline</v-icon>
                    Kalender exportieren
                  </div>
                  <div class="hidden-md-and-up">
                    <v-icon >mdi-calendar-export-outline</v-icon>
                  </div>
              </v-btn> -->
            </v-row>
            
          </v-toolbar>
          <v-fab-transition>
            <v-btn :color="$store.state.theme.green" dark fixed bottom right fab @click="newItem()">
              <v-tooltip left open-delay="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
                </template>
                <span>Einen neuen Termin erstellen</span>
              </v-tooltip>
            </v-btn>
          </v-fab-transition>

          <v-dialog v-model="dialog" persistent max-width="1000px" :fullscreen="$vuetify.breakpoint.xsOnly">
            <DialogTermin 
              :session="session" 
              :google_appointments="google_appointments" 
              :editedIndex="editedIndex"
              :editedItem="editedItem" 
              :defaultItem="defaultItem" 
              :dialog="dialog" 
              :appointments="appointments"
              :privateEvent="privateEvent"
              @close="close" 
              @refreshAndClose="refreshAndClose" 
              @updateAndClose="updateAndClose"
              @showError="$emit('showError', $event)" />
          </v-dialog>
          <v-sheet ref="calendarSheet" :height="computedHeight" elevation="0">  
            <v-progress-linear v-if="loading_appointments" indeterminate />
            <v-calendar 
              ref="calendar" 
              v-model="value" 
              :weekdays="weekdays" 
              :events="events" 
              event-start="displayStart"
              event-end="displayEnd"
              :type="calendarType"
              v-touch="{ left: () => next(), right: () => prev() }" @click:event="editItem" @click:date="navigateToWeek"
              @mousedown:time="startTime" @mousemove:time="mouseMove" @mouseleave:day="clearMove"
              @mouseleave:day-category="clearMove" @mouseleave:time="clearMove" @mouseenter:event="setOverEvent"
              @mouseleave:event="clearOverEvent" :first-time="workingHoursStart" :interval-count="intervalCount"
              :interval-height="interval_height">
              <template v-slot:day-body="{ date, week }">
                <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY }"></div>
                <v-fade-transition>
                  <v-sheet v-if="ready && hoverDate && hoverDate === date && $vuetify.breakpoint.smAndUp"
                    class="v-current-time-insert pl-1" color="grey lighten-4" rounded elevation="0"
                    :style="{ top: hoverTimePosition }">
                    <v-icon left>mdi-plus</v-icon>{{ hoverTime }}
                  </v-sheet>
                </v-fade-transition>
              </template>
              <template v-slot:event="{ event }">
                <div
                    :class="'custom-event' + (event.preview ? ' preview-event' : '')"
                    :style="{
                        borderRadius: '4px',
                        backgroundColor: event.color,
                        border: event.color && event.preview ? '2px dashed ' + event.borderColor : '',
                    }"
                    style="height: 100%">
                    <strong :class="'event-name'">{{ event.name }}</strong>
                    <div class="event-time" v-if="event.end && event.start !== event.end && event.timed">
                        {{ new Date(event.start).getHours() }}:{{ String(new Date(event.start).getMinutes()).padStart(2, "0") }} -
                        {{ new Date(event.end).getHours() }}:{{ String(new Date(event.end).getMinutes()).padStart(2, "0") }}
                    </div>
                </div>
            </template>
            </v-calendar>
            <p v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center align-center text-caption mt-2">
              <v-icon left small>mdi-lightbulb-outline</v-icon>
                Navigiere im Kalender mit den Pfeiltasten 
                <v-icon small>mdi-chevron-left</v-icon>
                <v-icon small>mdi-chevron-right</v-icon> 
                auf der Tastatur
            </p>
          </v-sheet>
        </v-card>
      </v-col>
      <!-- Calendar settings sidebar -->
      <v-col v-show="$vuetify.breakpoint.mdAndUp && showCalendarSettings" cols="4">
        <v-slide-x-transition>
          <v-card v-show="showCalendarSettings" class="calendar-settings-sidebar">
            <v-card-title class="d-flex justify-space-between">
              <span>Kalender Einstellungen</span>
              <v-btn icon @click="toggleCalendarSettings">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <CalendarSettingsPanel 
                :working-hours-start.sync="working_hours_start"
                :working-hours-end.sync="working_hours_end"
                :session="session"
                :googleCalendar="$refs.googleCalendar"
                :settingsVisible="showCalendarSettings"
                @updateIntervalHeight="updateIntervalHeight"
                @removeCalendarEvents="removeCalendarEvents"
                @removeGoogleCalendar="removeGoogleCalendar"
                @fetchGoogleAppointments="fetchGoogleAppointments"
                @fetchCalendarEvents="fetchCalendarEvents"
                @showError="(arg) => $emit('showError', arg)" 
                @showInfo="(arg) => $emit('showInfo', arg)"
              />
            </v-card-text>
          </v-card>
        </v-slide-x-transition>
      </v-col>
      <v-col v-show="$vuetify.breakpoint.mdAndUp && showBookingRequests" cols="4">
        <v-slide-x-transition>
          <v-card v-show="showBookingRequests" class="booking-requests-sidebar">
            <v-card-title class="d-flex justify-space-between">
              <span>Termin-Anfragen</span>
              <v-btn icon @click="toggleBookingRequests">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="d-flex align-center justify-start">
                <v-switch class="mr-1" v-model="appointment_booking" inset :color="$store.state.theme.green" @change="updateAppointmentBookingState" :loading="updating_appointment_booking_state" />
                <span class="d-flex align-center text-body-1 mr-3" style="flex: 1">
                  <span class="mr-2">Termin-Anfragen aktivieren</span>
                  <v-tooltip bottom open-delay="300">
                    <template v-slot:activator="{ on, attrs }" color="black">
                      <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Hier kannst du die Online Termin-Anfragen für Klienten aktivieren & deaktivieren.
                      Wenn du die Termin-Anfragen aktivierst, können Klienten unkompliziert online Termine anfragen.
                    </span>
                  </v-tooltip>
                </span>
              </div>
              <v-list>
                <v-list-item @click="showBookingConfiguration">
                  <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Termin-Anfragen konfigurieren
                    </v-list-item-title>
                </v-list-item>
                <v-list-group
                  :value="openBookingRequestsCount > 0"
                  prepend-icon="mdi-calendar-badge"
                >
                  <template v-slot:activator>
                    <v-list-item-title class="text-body-1">Offene Anfragen</v-list-item-title>
                    <v-badge class="ml-2" inline :content="openBookingRequestsCount.toString()" :color="$store.state.theme.red" />
                  </template>
                  <v-list-item v-for="request in openBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ request.bezeichnung }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small left>
                          mdi-account
                        </v-icon>
                        {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                        <v-icon small left>
                          mdi-chat-outline
                        </v-icon>
                        {{ request.decryptedDetails.message }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="openBookingRequestsCount === 0">
                    <v-list-item-content>
                      <v-list-item-title>
                        Bisher keine Anfragen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group> 
                <v-list-group
                  :value="false"
                  prepend-icon="mdi-calendar-check"
                >
                  <template v-slot:activator>
                    <v-list-item-title>Abgeschlossene Anfragen</v-list-item-title>
                    <v-badge class="ml-2" inline :content="closedBookingRequestsCount.toString()" :color="$store.state.theme.primary" />
                  </template>
                  <v-list-item v-for="request in closedBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ request.bezeichnung }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small left>
                          mdi-account
                        </v-icon>
                        {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                        <v-icon small left>
                          mdi-chat-outline
                        </v-icon>
                        {{ request.decryptedDetails.message }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="closedBookingRequestsCount === 0">
                    <v-list-item-content>
                      <v-list-item-title>
                        Bisher keine Anfragen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-slide-x-transition>
      </v-col>
    </v-row>

    <!-- Dialog for Termin-Anfragen on smAndDown screens -->
    <v-dialog v-model="showBookingRequestsDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="$store.state.theme.primary">
          <v-btn icon dark @click="showBookingRequestsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Termin-Anfragen</v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item @click="showBookingConfiguration">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Termin-Anfragen konfigurieren
              </v-list-item-title>
          </v-list-item>
          <v-list-group
            :value="openBookingRequestsCount > 0"
            prepend-icon="mdi-calendar-badge"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-body-1">Offene Anfragen</v-list-item-title>
              <v-badge class="ml-2" inline :content="openBookingRequestsCount.toString()" :color="$store.state.theme.red" />
            </template>
            <v-list-item v-for="request in openBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ request.bezeichnung }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small left>
                    mdi-account
                  </v-icon>
                  {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                  <v-icon small left>
                    mdi-chat-outline
                  </v-icon>
                  {{ request.decryptedDetails.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="openBookingRequestsCount === 0">
              <v-list-item-content>
                <v-list-item-title>
                  Bisher keine Anfragen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> 
          <v-list-group
            :value="false"
            prepend-icon="mdi-calendar-check"
          >
            <template v-slot:activator>
              <v-list-item-title>Abgeschlossene Anfragen</v-list-item-title>
              <v-badge class="ml-2" inline :content="closedBookingRequestsCount.toString()" :color="$store.state.theme.primary" />
            </template>
            <v-list-item v-for="request in closedBookingRequests" :key="'request-' + request.id" @click="openBookingRequest(request)">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-medium">{{ formatDate(request.datum) }} {{ formatTime(request.datum) }}</span><br/>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ request.bezeichnung }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small left>
                    mdi-account
                  </v-icon>
                  {{ request.decryptedDetails ? request.decryptedDetails.vorname + ' ' + request.decryptedDetails.nachname : 'Unbekannt' }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="request.decryptedDetails && request.decryptedDetails.message">
                  <v-icon small left>
                    mdi-chat-outline
                  </v-icon>
                  {{ request.decryptedDetails.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="closedBookingRequestsCount === 0">
              <v-list-item-content>
                <v-list-item-title>
                  Bisher keine Anfragen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="show_calendar_url" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Dein persönlicher Kalender
        </v-card-title>

        <v-container class="px-5">
          <p class="text-justify">
            Du kannst deinen persönlichen Kalender mit deinen Terminen in deinem Kalenderprogramm (z.B. Apple Kalender,
            Google Kalender) importieren.
          </p>
          <v-card-actions>
            <v-btn :href="calendar_url" :color="$store.state.theme.primary" dark>
              <v-icon left>mdi-calendar-plus-outline</v-icon>
              Kalender abonnieren
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :href="'https://calendar.google.com/calendar/r?cid=' + calendar_url" target="_blank"
              :color="$store.state.theme.primary" dark>
              <v-icon left>mdi-calendar-plus-outline</v-icon>
              In Google-Kalender abonnieren
            </v-btn>
          </v-card-actions>
          <p class="mt-5">
            Alternativ kannst du mit folgendem Link deinen Kalender manuell importieren.
            <br>
            <b>Wichtig:</b> Teile diesen Link nicht mit anderen Personen. Jeder mit diesem Link kann deinen Kalender
            einsehen.
          </p>
          <v-text-field dense outlined v-model="calendar_url"></v-text-field>

        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="$store.state.theme.green" text @click="show_calendar_url = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import DialogTermin from '../components/DialogTermin.vue'
import cipher from '@/helpers/cipher'
// import * as ics from 'ics'
import { supabase } from '../supabase'
import levenshtein from 'fast-levenshtein'
import Holidays from 'date-holidays'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import BookingRequestDialog from '../components/BookingRequestDialog.vue'
import GoogleCalendar from '@/components/GoogleCalendar.vue'
import CalendarSettingsPanel from '@/components/CalendarSettingsPanel.vue';
import calendarService from '@/helpers/calendarService.js';

export default {
  name: 'Kalender',
  props: ['session'],
  components: { DialogTermin, BookingRequestDialog, GoogleCalendar, CalendarSettingsPanel },

  data() {
    return {

      showCalendarSettings: false,
      subscribed_calendar_events: [],
      showCalendarSettingsDialog: false,
      
      loading_appointments: true,
      tab: 0,
      updating_holiday_state: false,
      saving_configuration: false,

      updating_appointment_booking_state: false,
      dialog_configure_bookings: false,
      appointment_booking: false,
      deleting_slot: false,
      pausing_slot: false,

      showBookingRequests: false,
      showBookingRequestsDialog: false,

      bookingRequests: [],

      selectedBookingRequest: null,
      bookingRequestDialog: false,

      // Working hours
      working_hours_start: this.$store.state.client.kalender_start || "00:00",
      working_hours_end: this.$store.state.client.kalender_ende || "23:00",

      interval_height: 48,
      
      // anonymization_format: null, // Default format: null = "last_first"

      color_mapping_events: {
        "1": "#7986CB",
        "2": "#33B679",
        "3": "#8E24AA",
        "4": "#E67C73",
        "5": "#F6BF26",
        "6": "#F4511E",
        "7": "#039BE5",
        "8": "#616161",
        "9": "#3F51B5",
        "10": "#0B8043",
        "11": "#D50000"
      },
      
      hoverDate: null,
      hoverTime: null,
      hoverTimePosition: null,
      is_over_event: false,

      profil_id: null,
      booking_advance: null,
      booking_advance_options: [
        { text: '3 Stunden', value: 3 },
        { text: '6 Stunden', value: 6 },
        { text: '12 Stunden', value: 12 },
        { text: '1 Tag', value: 24 },
        { text: '2 Tage', value: 48 },
        { text: '3 Tage', value: 72 },
      ],

      booking_timeframe: null,
      booking_timeframe_options: [
        { text: '1 Woche', value: 7 },
        { text: '2 Wochen', value: 14 },
        { text: '3 Wochen', value: 21 },
        { text: '4 Wochen', value: 28 },
        { text: '5 Wochen', value: 35 },
        { text: '6 Wochen', value: 42 },
        { text: '7 Wochen', value: 49 },
        { text: '8 Wochen', value: 56 },
        { text: '9 Wochen', value: 63 },
        { text: '10 Wochen', value: 70 },
        { text: '11 Wochen', value: 77 },
        { text: '12 Wochen', value: 84 },
      ],

      booking_on_holidays: false,
      
      valid: false,
      days: [
        { text: 'Montags', value: 1 },
        { text: 'Dienstags', value: 2 },
        { text: 'Mittwochs', value: 3 },
        { text: 'Donnerstags', value: 4 },
        { text: 'Freitags', value: 5 },
        { text: 'Samstags', value: 6 },
        { text: 'Sonntags', value: 0 },
      ],
      recurringSlots: [],
      originalRecurringSlots: [],
      services: [],
      timeRule: [
        v => !!v || 'Uhrzeit wird benötigt',
        v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](?::\d{2})?$/.test(v) || 'Uhrzeit muss das Format HH:MM haben.',
      ],

      serviceRule: [
        v => !!v || 'Dienstleistung wird benötigt',
      ],

      dayRule: [
        v => !!v || 'Wochentag wird benötigt',
      ],

      toggle_calendar_view: 1,

      weekdays: [1, 2, 3, 4, 5, 6, 0],
      appointments: [],
      google_appointments: [],
      private_appointments: [],
      today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().split('T')[0],
      value: '',
      ready: false,

      n_appointments: 0,

      dialog: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        fk_rechnungs_id: null,
        selected: null,
        vorname: null,
        nachname: null,
        uhrzeit: "12:00",
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        preis: this.$store.state.client.std_preis,
        dauer: this.$store.state.client.std_dauer,
        bezeichnung: null,
        ust_befreiung: null,
        fk_dienstleistung: null,
        termin_erinnerung: null,
        erinnerung_gesendet: null,
      },
      defaultItem: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        fk_rechnungs_id: null,
        selected: null,
        vorname: null,
        nachname: null,
        uhrzeit: "12:00",
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        preis: this.$store.state.client.std_preis,
        dauer: this.$store.state.client.std_dauer,
        bezeichnung: null,
        ust_befreiung: null,
        fk_dienstleistung: null,
        termin_erinnerung: null,
        erinnerung_gesendet: null,
      },
      customers: [],

      privateEvent: {
        id: null,
        description: null,
        allday: false,
        startDate: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('YYYY-MM-DD'),
        startDateFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        start_uhrzeit: "12:00",
        endDate: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('YYYY-MM-DD'), 
        endDateFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        end_uhrzeit: "13:00",
        color: null
      },

      defaultPrivateEvent: {
        id: null,
        description: null,
        allday: false,
        startDate: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('YYYY-MM-DD'),
        startDateFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        start_uhrzeit: "12:00",
        endDate: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('YYYY-MM-DD'), 
        endDateFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        end_uhrzeit: "13:00",
        color: null
      },
    }
  },

  computed: {

    openBookingRequestsCount() {
      return this.openBookingRequests.length;
    },

    closedBookingRequestsCount() {
      return this.closedBookingRequests.length;
    },

    openBookingRequests() {
      return this.bookingRequests.filter(request => !request.closed);
    },

    closedBookingRequests() {
      return this.bookingRequests.filter(request => request.closed);
    },

    intervalCount() {
      const startHour = parseInt(this.workingHoursStart.split(':')[0]);
      const endHour = parseInt(this.working_hours_end.split(':')[0]);
      return (endHour - startHour) + 1;
    },

    workingHoursStart() {
      if (this.working_hours_start.includes(':')) {
        let start_hour = parseInt(this.working_hours_start.split(':')[0]);
        if (start_hour > 0) {
          return `${start_hour - 1}:00`;
        } else {
          return `${start_hour}:00`;
        }
      }
      return this.working_hours_start;
    },

    computedHeight() {
      if (this.$vuetify.breakpoint.xs) {
        return '73vh'
      } else if (this.$vuetify.breakpoint.sm) {
        return '75vh'
      } else if (this.$vuetify.breakpoint.md) {
        return '75vh'
      } else if (this.$vuetify.breakpoint.lg) {
        return '85vh'
      } else if (this.$vuetify.breakpoint.xl) {
        return '85vh'
      }
    },

    events() {
      let ids_in_google = this.google_appointments.map((appointment) => {
        return appointment.id
      }).filter((id) => id !== null)

      let appointment_ids = this.appointments.map((appointment) => {
        return appointment.id
      });

      let private_appointment_ids = this.private_appointments.map((appointment) => {
        return appointment.id
      });

      let holidays = [];
      if (this.$store.state.show_holidays) {
        holidays = this.getHolidays();
      }

      let all_events = this.appointments.map((appointment) => {
        let event = {
          ...appointment,
          name: `${appointment.nachname} ${appointment.vorname}`,
          start: dayjs(appointment.datum).format('YYYY-MM-DDTHH:mm'),
          end: dayjs(appointment.datum)
            .add(appointment.dauer, 'm')
            .format('YYYY-MM-DDTHH:mm'),
          color: appointment.farbe
            ? appointment.farbe
            : ids_in_google.includes(appointment.id)
            ? this.$store.state.theme.green
            : this.$store.state.theme.primary,
        };

        return this.adjustEventForDisplay(event);
      }).concat(
        this.google_appointments.filter((google_appointment) => {
          if (google_appointment.id === null && google_appointment.pid === null) return true;
          if (google_appointment.id !== null) {
            return !appointment_ids.includes(google_appointment.id)
          } else if (google_appointment.pid !== null) {
            return !private_appointment_ids.includes(google_appointment.pid)
          }
          return true;
        }).map((google_appointment) => {
          let event = {
            ...google_appointment,
          };

          // Set default color if not already set
          let google_calendar_color = localStorage.getItem('google_calendar_color');
          if (google_calendar_color && !event.color) {
            event.color = google_calendar_color;
          }

          return this.adjustEventForDisplay(event);
        })
      ).concat(holidays).concat(
        this.bookingRequests.map((request) => {
          const color = this.services.find((service) => service.id === request.fk_dienstleistungs_id)?.farbe;
          let event = {
            id: request.id,
            name: request.decryptedDetails.nachname + ' ' +  request.decryptedDetails.vorname ,
            start: dayjs(request.datum).format('YYYY-MM-DDTHH:mm'),
            end: dayjs(request.datum).add(request.dauer, 'm').format('YYYY-MM-DDTHH:mm'),
            color: color ? this.hexToRGBA(color, 0.15) : this.hexToRGBA(this.$store.state.theme.primary, 0.15),
            borderColor: color ? color : this.$store.state.theme.primary,
            type: 'request',
            preview: true,
            timed: true,
          }
          return this.adjustEventForDisplay(event);
        })
      ).concat(
        this.private_appointments.map((appointment) => {
          let event = {
            ...appointment,
            name: appointment.description,
            start: appointment.allday
              ? dayjs(appointment.start).format('YYYY-MM-DD')
              : appointment.start,
            end: appointment.allday
              ? dayjs(appointment.end).format('YYYY-MM-DD')
              : appointment.end,
            color: appointment.color || this.$store.state.theme.primary,
            type: 'private',
            timed: !appointment.allday,
          };

          return this.adjustEventForDisplay(event);
        }
      )).concat(
        this.subscribed_calendar_events.map((event) => {
          let event_obj = {
            id: event.id,
            name: event.summary || 'Unbenannter Termin',
            start: event.start,
            end: event.end,
            color: event.color || '#4285F4',
            type: 'subscribed',
            timed: !event.allday,
            calendar_id: event.calendar_id,
            description: event.description || '',
            location: event.location || '',
          };

          return this.adjustEventForDisplay(event_obj);
        })
      );

      return all_events;
    },
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },

    calendarType() {
      if (this.toggle_calendar_view === 0) {
        return '4day'
      } else if (this.toggle_calendar_view === 1) {
        return 'week'
      } else {
        return 'month'
      }
    }
  },

  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.updateIntervalHeight);
    window.removeEventListener('keydown', this.handleKeyDown);
  },

  async mounted() {
    this.updateIntervalHeight();
    window.addEventListener('resize', this.updateIntervalHeight);
    window.addEventListener('keydown', this.handleKeyDown);

    if (this.$vuetify.breakpoint.xsOnly) {
      // default to 4 day view on mobile
      this.toggle_calendar_view = 0;
    }

    // Check for calendar_settings param in URL to reopen the settings dialog
    if (this.$route.query.calendar_settings === 'true') {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showCalendarSettingsDialog = true;
      } else {
        this.showCalendarSettings = true;
      }
      
      // Remove the parameter from URL without refreshing the page
      const query = { ...this.$route.query };
      delete query.calendar_settings;
      this.$router.replace({ query });
    }

    await this.initialize();
  },

  watch: {
    '$vuetify.breakpoint.smAndDown': {
      immediate: true,
      handler(smAndDown) {
        if (smAndDown) {
          // Small screens: move content from sidebar to dialog
          if (this.showCalendarSettings) {
            this.showCalendarSettings = false;
            this.showCalendarSettingsDialog = true;
          }
          
          if (this.showBookingRequests) {
            this.showBookingRequests = false;
            this.showBookingRequestsDialog = true;
          }
        } else {
          // Larger screens: move content from dialog to sidebar
          if (this.showCalendarSettingsDialog) {
            this.showCalendarSettingsDialog = false;
            this.showCalendarSettings = true;
          }
          
          if (this.showBookingRequestsDialog) {
            this.showBookingRequestsDialog = false;
            this.showBookingRequests = true;
          }
        }
      }
    },
  },

  methods: {

    // navigateToClient(clientId) {
    //   if (clientId) {
    //     this.$router.push(`/klienten/${clientId}`);
    //   }
    // },

    async fetchGoogleAppointments() {
      if (this.$refs.googleCalendar) {
          this.google_appointments = await this.$refs.googleCalendar.getCalendarEvents(this.$store.state.client.google_calendar);
      } else {
          this.google_appointments = [];
      }

      this.$emit('showInfo', {
          message: 'Google Kalender erfolgreich verknüpft.',
          timeout: 5000,
      });
    },

    removeGoogleCalendar() {
      this.google_appointments = [];
    },

    removeCalendarEvents(id) {
      this.subscribed_calendar_events = this.subscribed_calendar_events.filter(event => event.calendar_id !== id);
    },

    async fetchCalendarEvents() {
      this.subscribed_calendar_events = await calendarService.fetchCalendarEvents(this);
    },

    toggleCalendarSettings() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showCalendarSettingsDialog = !this.showCalendarSettingsDialog;
      } else {
        this.showCalendarSettings = !this.showCalendarSettings;
      }
    },

    handleKeyDown(e) {
      // Do not trigger navigation if the focus is in an input, textarea, or editable element.
      const tag = e.target.tagName.toLowerCase();
      if (tag === 'input' || tag === 'textarea' || e.target.isContentEditable) {
        return;
      }

      if (e.key === 'ArrowLeft') {
        // Apply a brief pressed state
        const button = this.$refs.prevButton.$el;
        button.classList.add('v-btn--active');
        this.prev();
        setTimeout(() => {
          button.classList.remove('v-btn--active');
        }, 200);
      } else if (e.key === 'ArrowRight') {
        // Get the button element
        const button = this.$refs.nextButton.$el;
        button.classList.add('v-btn--active');
        this.next();
        setTimeout(() => {
          button.classList.remove('v-btn--active');
        }, 200);
      }
    },

    adjustEventForDisplay(event) {
      let original_event = { ...event };

      const eventStart = dayjs(original_event.start);
      const eventEnd = original_event.end ? dayjs(original_event.end) : null;

      const eventDate = eventStart.format('YYYY-MM-DD');
      const eventDateEnd = eventEnd ? eventEnd.format('YYYY-MM-DD') : null;

      const workingHoursStart = dayjs(eventDate)
        .format('YYYY-MM-DD')
        .concat('T', this.workingHoursStart);

      const workingHoursEnd = eventDateEnd ? 
        dayjs(eventDateEnd).format('YYYY-MM-DD').concat('T', this.working_hours_end) :
        dayjs(eventDate).format('YYYY-MM-DD').concat('T', this.working_hours_end);

      const visibleStart = dayjs(workingHoursStart);
      const visibleEnd = dayjs(workingHoursEnd);

      // Initialize displayStart and displayEnd with the original start and end
      original_event.displayStart = original_event.start;
      original_event.displayEnd = original_event.end;

      if (original_event.timed === false) {
        // no adjustments needed
        return original_event;
      }

      // Check if the event is completely outside the visible time interval
      if ((eventEnd && eventEnd.isBefore(visibleStart)) || eventStart.isAfter(visibleEnd)) {
        // Event is outside working hours, display as all-day event
        original_event.displayStart = eventStart.format('YYYY-MM-DD');
        if (eventEnd) {
          original_event.displayEnd = eventEnd.format('YYYY-MM-DD');
        } 
        original_event.timed = true;
      } else {
        // Event is at least partially within the visible time interval
        original_event.timed = true;
      }

      return original_event;
    },

    async toggledHolidayBookings(state) {

      this.updating_holiday_state = true;

      let updated = await connector.update(this, 'buchungsprofile', {
        buchbare_feiertage: state
      }, this.session.user.id);

      if (updated === null) {
        // error has already been shown
        // revert state of toggle.
        this.booking_on_holiday = !state;
      } else {
        this.$emit('showInfo', {
          message: state ? 'Termin-Anfragen an Feiertagen erfolgreich aktiviert.' : 'Termin-Anfragen an Feiertagen erfolgreich deaktiviert.',
          timeout: 5000
        });
      }

      this.updating_holiday_state = false;

    },

    async showBookingConfiguration() {

      let profiles = await connector.getDataOnly(this, 'buchungsprofile', 'inserted_at', false);
      if (profiles !== -1 && profiles.length > 0) {
        this.profil_id = profiles[0].profil_id;
        this.appointment_booking = profiles[0].aktiv === true;
        this.booking_advance = profiles[0].vorlaufzeit;
        this.booking_timeframe = profiles[0].nachlaufzeit;
        this.booking_on_holidays = profiles[0].buchbare_feiertage === true;
      }

      // if there are no reccuringSlots, add a template slot
      if (this.recurringSlots.length === 0) {
        this.addRecurringSlot();
      }

      this.dialog_configure_bookings = true;
    },

    hexToRGBA(hex, opacity) {
        let r = parseInt(hex.substring(1, 3), 16);
        let g = parseInt(hex.substring(3, 5), 16);
        let b = parseInt(hex.substring(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },

    openBookingRequest(request) {
      this.selectedBookingRequest = request;
      this.bookingRequestDialog = true;
    },

    async closeBookingRequest(request) {

      // check if aes_key_file is set, if not then try to load it again
      if (!this.$store.state.aes_key_file) {
          let keys = await cipher.getAESKeys(this);
          this.$store.state.aes_key_file = keys['aes_key_file'];
      }

      // encrypt the details with the local user key
      let encrypted_details = await cipher.encryptObject(this.$store.state.aes_key_file, request.decryptedDetails);

      // 6. Prepare JSON object for JSONB column
      const jsonbData = encrypted_details;

      let updated = await connector.update(this, 'buchungsanfragen', {
        details: jsonbData,
        encrypted_key: {},
        closed: true,
      }, request.id);

      if (updated) {
        this.bookingRequestDialog = false;
        this.fetchBookingRequests();
        this.$emit('showInfo', { 
          message: 'Die Termin-Anfrage wurde erfolgreich geschlossen.',
          timeout: 5000
        });
      }
    },

    async deleteBookingRequest(id) {
        let deleted = await connector.delete(this, 'buchungsanfragen', 'id', id);

        if (deleted) {
          this.bookingRequestDialog = false;
          this.fetchBookingRequests();
          this.$emit('showInfo', { 
            message: 'Die Termin-Anfrage wurde erfolgreich gelöscht.',
            timeout: 5000
          });
        }
    },

    toggleBookingRequests() {
      this.showBookingRequests = !this.showBookingRequests;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showBookingRequestsDialog = !this.showBookingRequestsDialog;
      }
      if (this.showBookingRequests || this.showBookingRequestsDialog) {
        this.fetchBookingRequests();
      }
    },

    async fetchBookingRequests() {
      try {
        let { data, error } = await supabase.functions.invoke('get-bookings', {
          body: {
            'type': 'getBookingRequests'
          }
        });

        if (error) throw error;

        // check if aes_key_file is set, if not then try to load it again
        if (!this.$store.state.aes_key_file) {
            let keys = await cipher.getAESKeys(this);
            this.$store.state.aes_key_file = keys['aes_key_file'];
        }

        // Decrypt the details for each booking request
        const decryptedRequests = await Promise.all(data.map(async (request) => {

          // check if it is decrypted with user key or sever key
          if (request.decrypted_key === null) {
            // user key
            const decryptedDetails = await cipher.decryptObject(this, this.$store.state.aes_key_file, request.details);
            return { ...request, decryptedDetails };
          } else {
            // server key
            const decryptedDetails = await cipher.decryptBookingDetails(request.details, request.decrypted_key);
            return { ...request, decryptedDetails };
          }
        }));

        this.bookingRequests = decryptedRequests;
      } catch (error) {
        console.error('Error fetching booking requests:', error);
        this.$emit('showError', { 
          message: 'Fehler beim Laden der Termin-Anfragen.', 
          timeout: 10000 
        });
      }
    },

    formatDate(dateString) {
      return dayjs(dateString).locale('de').format('dddd, DD.MM.YYYY');
    },

    formatTime(dateString) {
      return dayjs(dateString).format('HH:mm');
    },

    handleBookingRequest(request) {
      // Implement the logic to handle a booking request
      // For example, open a dialog to accept or reject the request
      console.log('Handling booking request:', request);
    },


    updateIntervalHeight() {

      if (!this.$refs.calendarSheet) {
        this.interval_height = 48;
        return;
      }

      const header = this.$refs.calendar.$el.querySelector('.v-calendar-daily__head');
      // Get the header height
      let headerHeight = 100;

      if (header) {
        headerHeight = header.offsetHeight;
      }

      // Access the height directly from the ref
      const sheetHeight = this.$refs.calendarSheet.$el.clientHeight - headerHeight - 10;

      // Calculate the desired number of intervals to display
      const startHour = parseInt(this.workingHoursStart.split(':')[0]);
      const endHour = parseInt(this.working_hours_end.split(':')[0]);
      const numIntervals = endHour - startHour + 1;

      // Calculate and return the dynamic interval height
      this.interval_height = Math.max(Math.floor(sheetHeight / numIntervals), 48);
      return;
    },

    addRecurringSlot() {
      this.recurringSlots.push({
        day: null,
        time: null,
        service: null,
        menu: false,
      });
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.validate(true);
      })
    },

    async removeRecurringSlot(slot, index) {
      // check if the slot is already stored in the db, if not remove it directly.
      // this is for cases when a new slot is added, but not stored, and then removed again
      if (!('id' in slot)) {
        // just remove it and return
        this.recurringSlots.splice(index, 1);
        return;
      }

      this.deleting_slot = true;
      let deleted = await connector.delete(this, 'buchungstermine', 'id', slot.id);
      if (deleted) this.recurringSlots.splice(index, 1);
      this.deleting_slot = false;
      this.$emit('showInfo', {
        message: 'Der wiederkehrende Termin wurde erfolgreich gelöscht.',
        timeout: 5000
      })
    },

    async toggleRecurringSlotState(slot, index) {
      this.pausing_slot = true;

      let state = true;
      if (slot.inkativ === null) {
        state = true;
      } else if (slot.inaktiv === true) {
        state = false;
      }

      let updated = await connector.update(this, 'buchungstermine', {
        inaktiv: state
      }, slot.id);

      if (updated) {
        this.$set(this.recurringSlots[index], 'inaktiv', state);
        this.$emit('showInfo', {
          message: ' Der wiederkehrende Termin wurde erfolgreich ' + (state ? 'deaktiviert' : 'aktiviert') + '.',
          timeout: 5000
        });
      }
      this.pausing_slot = false;
    },

    async updateAppointmentBookingState() {
      this.updating_appointment_booking_state = true;

      let updated = await connector.update(this, 'buchungsprofile', {
        'aktiv': this.appointment_booking,
      }, this.session.user.id);

      if (updated) {
        this.$emit('showInfo', {
          message: 'Die Online Termin-Anfragen wurden erfolgreich ' + (this.appointment_booking ? 'aktiviert.' : 'deaktiviert.'),
          timeout: 5000
        });
      }
      
      this.updating_appointment_booking_state = false;
    },

    async saveConfiguration() {

      // save bookingAdvace etc.

      this.saving_configuration = true;

      let updated = await connector.update(this, 'buchungsprofile', {
        vorlaufzeit: this.booking_advance,
        nachlaufzeit: this.booking_timeframe,
        buchbare_feiertage: this.booking_on_holidays
      }, this.session.user.id);

      if (updated === null) {
        // error has already been displayed
        this.saving_configuration = false;
        return;
      }

      let error_during_upserts = false;

      // iterate over all recuring slots and check if they are modified (using originalRecurringSlots) or new and save them
      await Promise.all(this.recurringSlots.map(async (slot) => {
        if (slot.id) {
          // update
          let originalSlot = this.originalRecurringSlots.find((originalSlot) => originalSlot.id === slot.id);
          if (originalSlot.tag !== slot.tag || originalSlot.uhrzeit !== slot.uhrzeit || originalSlot.fk_dienstleistungs_id !== slot.fk_dienstleistungs_id) {
            // update
            let updated_appointment_slot = await connector.update(this, 'buchungstermine', {
              fk_dienstleistungs_id: slot.fk_dienstleistungs_id,
              tag: slot.tag,
              uhrzeit: slot.uhrzeit
            }, slot.id);

            if (updated_appointment_slot === null) {
              // error has already been displayed, the return is actually not needed
              error_during_upserts = true;
              return;
            }
          }
        } else {
          // insert
          let inserted_appointment_slot = await connector.insert(this, 'buchungstermine', {
            fk_dienstleistungs_id: slot.fk_dienstleistungs_id,
            tag: slot.tag,
            uhrzeit: slot.uhrzeit,
            uid: this.session.user.id,
            fk_profil_id: this.profil_id,
          });

          if (inserted_appointment_slot === false) {
            // error has already been displayed, the return is actually not needed
            error_during_upserts = true;
            return;
          }
        }
      }));

      if (error_during_upserts) {
        // error has already been displayed, so just return without closing
        this.saving_configuration = false;
        return;
      } else {

        // update the termine
        let recurringSlots = await connector.getDataOnly(this, 'buchungstermine', 'tag', true);
        if (recurringSlots === -1) {
          // error has already been shown
          this.saving_configuration = false;
          return;
        }

        this.recurringSlots = recurringSlots;
        this.originalRecurringSlots = JSON.parse(JSON.stringify(this.recurringSlots));

        this.saving_configuration = false;
        this.dialog_configure_bookings = false;
        this.$emit('showInfo', {
          message: 'Die Änderungen wurden erfolgreich gespeichert.',
          timeout: 5000
        });
      }
    },

    navigateToWeek(date) {
      this.value = date.date;
      if (this.toggle_calendar_view === 2 && this.$vuetify.breakpoint.xsOnly) {
        this.toggle_calendar_view = 0;
      } else if (this.toggle_calendar_view === 2 && this.$vuetify.breakpoint.smAndUp) {
        this.toggle_calendar_view = 1;
      }
    },

    startTime(tms) {
      if (this.is_over_event || this.$vuetify.breakpoint.xsOnly) {
        return;
      }

      // extract the minutes out of the time and round it to the nearest 15 minutes.
      const interval = 15;
      // Calculate total minutes from the beginning of the day
      const totalMinutes = tms.hour * 60 + tms.minute;
      // Round the total minutes to the nearest interval
      const roundedTotalMinutes = Math.round(totalMinutes / interval) * interval;

      // Calculate the new hour and minute values
      const newHours = Math.floor(roundedTotalMinutes / 60);
      const newMinutes = roundedTotalMinutes % 60;

      // create a dajys object with the date and time

      let date = dayjs(tms.date).set('hour', newHours).set('minute', newMinutes);
      this.newItem(date);
    },

    setOverEvent() {
      this.is_over_event = true;
    },

    clearOverEvent() {
      this.is_over_event = false;
    },

    clearMove() {
      this.hoverDate = null;
      this.hoverTime = null;
      this.hoverTimePosition = null;
    },

    clearMoveEvent() {
      this.hoverDate = null;
      this.hoverTime = null;
      this.hoverTimePosition = null;
    },

    mouseMove(tms) {
      if (this.is_over_event) {
        this.clearMoveEvent();
        return;
      }

      this.hoverDate = tms.date;

      // extract the minutes out of the time and round it to the nearest 15 minutes.
      const interval = 15;
      // Calculate total minutes from the beginning of the day
      const totalMinutes = tms.hour * 60 + tms.minute;
      // Round the total minutes to the nearest interval
      const roundedTotalMinutes = Math.round(totalMinutes / interval) * interval;

      // Calculate the new hour and minute values
      const newHours = Math.floor(roundedTotalMinutes / 60);
      const newMinutes = roundedTotalMinutes % 60;

      this.hoverTime = (newHours < 10 ? '0' : '') + newHours + ':' + (newMinutes < 10 ? '0' : '') + newMinutes;
      this.hoverTimePosition = this.cal ? this.cal.timeToY({
        hour: newHours,
        minute: newMinutes
      }) + 'px' : '-10px';
    },

    getHolidays() {
      const hd = new Holidays('AT', 'de-at');
      // get last year, this year, and next years holidays
      let years = [new Date().getFullYear() - 1, new Date().getFullYear(), new Date().getFullYear() + 1];
      let holidays = [];
      years.forEach((year) => {
        holidays = holidays.concat(hd.getHolidays(year));
      })
      return holidays.filter((holiday) => ['public', 'bank'].includes(holiday.type)).map((holiday) => {
        return {
          type: 'holiday',
          name: holiday.name,
          start: dayjs(holiday.start).format('YYYY-MM-DD'),
          displayStart: dayjs(holiday.start).format('YYYY-MM-DD'),
          color: this.$store.state.theme.green,
          original: holiday.start,
          timed: false,
        }
      })
    },

    async initialize(scroll_to_current_time = true) {

      try {
        this.loading_appointments = true;
        

        let appointments = await connector.getDataOnly(this, 'vwtermine', 'datum', false);
        if (appointments === -1) {
          // error has already been displayed
          appointments = [];
          this.n_appointments = appointments.length;
        } else {
          this.n_appointments = appointments.length;
          if (this.n_appointments > 0) {
            this.$store.commit('setOnboardingAppointmentStatus', true);
          } else {
            this.$store.commit('setOnboardingAppointmentStatus', false);
          }
        }
        
        this.appointments = await cipher.decryptDataSync(this, appointments);

        let private_appointments = await connector.getDataOnly(this, 'vwprivattermine', 'start', true);
        if (private_appointments === -1) {
          // error has already been displayed
          private_appointments = [];
        }

        // if (!this.$store.state.aes_key_file) {
        //     let keys = await cipher.getAESKeys(this);
        //     this.$store.state.aes_key_file = keys['aes_key_file'];
        // }
        let decrypted_appointments = await cipher.decryptArray(this, private_appointments, true);
        this.private_appointments = decrypted_appointments;

        let client = await connector.getDataOnly(this, 'vwkunden', 'id', true);

        if (client.length > 0) {
          this.$store.commit('setClient', client[0]);

          if (this.$store.state.client.kalender_start) {
            this.working_hours_start = this.$store.state.client.kalender_start;
          }

          if (this.$store.state.client.kalender_ende) {
            this.working_hours_end = this.$store.state.client.kalender_ende;
          }

          if (this.$store.state.client.kalender_start || this.$store.state.client.kalender_ende) {
            this.updateIntervalHeight();
          }
        }

        if (this.$store.state.client.google_calendar) {
          // set anomyze_clients to true if it is set in the client
          // this.anonymize_clients = this.$store.state.client.google_calendar_anonymize === true ? 1 : 0;
          if (this.$refs.googleCalendar) {
            this.google_appointments = await this.$refs.googleCalendar.getCalendarEvents(this.$store.state.client.google_calendar);
          } else {
            this.google_appointments = [];
          }
        } else {
          this.google_appointments = [];
        }

        let customers_enc = await connector.getDataOnly(this, 'vwklientenkalender', 'id', true)
        this.customers = await cipher.decryptArray(this, customers_enc)

        if (this.$store.state.client.beta_circle) {
          await this.fetchBookingRequests();

          // check if user has already a buchungsprofile
          let profiles = await connector.getDataOnly(this, 'buchungsprofile', 'inserted_at', false);
          if (profiles.length > 0) {
            this.profil_id = profiles[0].profil_id;
            this.appointment_booking = profiles[0].aktiv === true;
            this.booking_advance = profiles[0].vorlaufzeit;
            this.booking_timeframe = profiles[0].nachlaufzeit;
            this.booking_on_holidays = profiles[0].buchbare_feiertage === true;
          } else {
            // insert new buchungsprofile
            let profile = await connector.upsertRow(this, 'buchungsprofile', { id: this.session.user.id, email: this.session.user.email });
            this.profil_id = profile[0].profil_id;
          }
        }

        this.services = await connector.getDataOnly(this, 'vwdienstleistungen', 'id', true);
        this.recurringSlots = await connector.getDataOnly(this, 'buchungstermine', 'id', true);
        this.originalRecurringSlots = JSON.parse(JSON.stringify(this.recurringSlots));
        
        this.subscribed_calendar_events = await calendarService.fetchCalendarEvents(this);

        this.ready = true;
        this.loading_appointments = false;
        if (scroll_to_current_time) {
          this.scrollToTime()
        }
        this.updateTime();
        if (this.$refs.calendar) this.$refs.calendar.updateTimes();

      } catch (error) {
        throw error;
        // we throw it again for now, so that we can catch it with sentry.
      } finally {
        this.loading_appointments = false;
      }

    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 5 * 60)
      if (this.cal) {
        this.cal.scrollToTime(first);
      }
    },
    updateTime() {
      setInterval(() => {
        if (this.$refs.calendar) {
          this.$refs.calendar.updateTimes();
        }
      }, 60 * 1000)
    },
    prev() {
      this.$refs.calendar.prev();
      this.$nextTick(() => {
        this.updateIntervalHeight();
      });
    },
    next() {
      this.$refs.calendar.next();
      this.$nextTick(() => {
        this.updateIntervalHeight();
      });
    },
    setToday() {
      this.value = ''
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.privateEvent = Object.assign({}, this.defaultPrivateEvent);
      })
    },

    newItem(pre_filled_datetime = null) {
      // check if user has already some services, if not prompt him to create one first
      if (!this.$store.getters.hasServices) {
        this.$emit('showError', { 
          message: 'Um einen Termin zu erstellen, musst du zuerst eine Dienstleistung anlegen.',
          timeout: 7000
        });
        return;
      }

      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)

      if (pre_filled_datetime) {
        this.editedItem.uhrzeit = pre_filled_datetime.format('HH:mm');
        this.editedItem.datum = pre_filled_datetime.toISOString();
        this.editedItem.datumFormatted = pre_filled_datetime.format('DD.MM.YYYY')

        this.privateEvent.startDate = pre_filled_datetime.format('YYYY-MM-DD')
        this.privateEvent.endDate = pre_filled_datetime.add(1, 'hour').format('YYYY-MM-DD')
        this.privateEvent.start_uhrzeit = pre_filled_datetime.format('HH:mm')
        this.privateEvent.end_uhrzeit = pre_filled_datetime.add(1, 'hour').format('HH:mm')
        this.privateEvent.startDateFormatted = pre_filled_datetime.format('DD.MM.YYYY')
        this.privateEvent.endDateFormatted = pre_filled_datetime.format('DD.MM.YYYY')
      } 

      this.dialog = true;
    },

    findMostSimilarName(name, lastnamesArray, firstnamesArray, anonymize_clients, anonymization_format) {
      // Split the input name into tokens and filter out unwanted keywords.
      const tokens = name.split(' ');
      const filteredTokens = tokens.filter(token => {
        const lowerToken = token.toLowerCase();
        return lowerToken !== 'online' && lowerToken !== 'eg';
      });

      // Determine if we should only check against lastnames.
      const onlyCheckLastname = filteredTokens.length === 1;

      // Process the input name for comparison.
      let processedName;
      if (anonymize_clients) {
        // In anonymized mode, assume the appointment name is already in a short format.
        // If only one token remains, take its first two letters.
        processedName = name.split(' ').join('').trim().toLowerCase();
      } else {
        // For full names, sort the filtered tokens and join them.
        processedName = filteredTokens.sort().join(' ').toLowerCase();
      }

      // Build an array of candidate names from the provided first- and last-name arrays.
      let candidates = [];

      if (anonymize_clients) {
        // For anonymized matching, create a 4-letter string per client.
        candidates = lastnamesArray.map((lastname, index) => {
          const firstname = firstnamesArray[index] || '';
          const anonFirst = firstname.slice(0, 2).toLowerCase();
          const anonLast = lastname.slice(0, 2).toLowerCase();
          // Use the chosen anonymization format:
          return anonymization_format === 'first_last'
            ? anonFirst + anonLast
            : anonLast + anonFirst;
        });
      } else {
        // For full name matching, build a candidate string per client.
        candidates = lastnamesArray.map((lastname, index) => {
          if (onlyCheckLastname) {
            return lastname.trim().toLowerCase();
          } else {
            return (firstnamesArray[index] + ' ' + lastname).trim().toLowerCase();
          }
        });
        // Sort the tokens in each candidate for a more consistent comparison.
        candidates = candidates.map(candidate => candidate.split(' ').sort().join(' '));
      }

      // Use Levenshtein distance to determine the closest match.
      let minDistance = Infinity;
      let bestMatchIndex = -1;
      candidates.forEach((candidate, index) => {
        const distance = levenshtein.get(processedName, candidate);
        if (distance < minDistance) {
          minDistance = distance;
          bestMatchIndex = index;
        }
      });

      return bestMatchIndex;
    },

    testMostSimilar() {

      // For these tests, we define a common client list:
      const lastnames = [
        "Smith",       // 0
        "Johnson",     // 1
        "Williams",    // 2
        "Brown",       // 3
        "Jones",       // 4
        "Miller",      // 5
        "Davis",       // 6
        "Garcia",      // 7
        "Rodriguez",   // 8
        "Martinez",    // 9
        "O'Neil",      // 10
        "Smithson",    // 11
        "Brownstone",  // 12
        "O'Connor",    // 13
        "Mcdonald"     // 14
      ];

      const firstnames = [
        "Alice",      // 0
        "Bob",        // 1
        "Charlie",    // 2
        "David",      // 3
        "Eve",        // 4
        "Frank",      // 5
        "Grace",      // 6
        "Hannah",     // 7
        "Ivy",        // 8
        "Jack",       // 9
        "Mary-Jane",  // 10
        "John-Paul",  // 11
        "Elizabeth",  // 12
        "Christopher",// 13
        "Patricia"    // 14
      ];

      // Define 20 sophisticated test cases.
      const testCases = [
        {
          description: "1. Full name matching (basic case)",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Alice Smith",
          lastnames,
          firstnames,
          expected: 0,
        },
        {
          description: "2. Full name matching with extra spaces",
          anonymize_clients: false,
          anonymization_format: null,
          name: "  Bob   Johnson ",
          lastnames,
          firstnames,
          expected: 1,
        },
        {
          description: "3. Full name matching filtering keyword 'online' (single token remains)",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Brown online",
          lastnames,
          firstnames,
          expected: 3,
        },
        {
          description: "4. Full name matching with only lastname provided",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Miller",
          lastnames,
          firstnames,
          expected: 5,
        },
        {
          description: "5. Full name matching with mixed case",
          anonymize_clients: false,
          anonymization_format: null,
          name: "cHaRLie wILLiams",
          lastnames,
          firstnames,
          expected: 2,
        },
        {
          description: "6. Anonymized matching (first_last) for Alice Smith",
          anonymize_clients: true,
          anonymization_format: "first_last",
          name: "alsm", // 'Alice' → "al", 'Smith' → "sm" → "alsm"
          lastnames,
          firstnames,
          expected: 0,
        },
        {
          description: "7. Anonymized matching (last_first) for Bob Johnson",
          anonymize_clients: true,
          anonymization_format: "last_first",
          name: "jobo", // 'Johnson' → "jo", 'Bob' → "bo" → "jobo"
          lastnames,
          firstnames,
          expected: 1,
        },
        {
          description: "8. Anonymized matching (first_last) with extra spaces for Frank Miller",
          anonymize_clients: true,
          anonymization_format: "first_last",
          name: "  frmi  ", // 'Frank' → "fr", 'Miller' → "mi" → "frmi"
          lastnames,
          firstnames,
          expected: 5,
        },
        {
          description: "9. Anonymized matching (last_first) for Hannah Garcia",
          anonymize_clients: true,
          anonymization_format: "last_first",
          name: "gaha", // 'Garcia' → "ga", 'Hannah' → "ha" → "gaha"
          lastnames,
          firstnames,
          expected: 7,
        },
        {
          description: "10. Full name matching with reversed token order for Jack Martinez",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Martinez Jack", // sorting tokens gives "jack martinez"
          lastnames,
          firstnames,
          expected: 9,
        },
        {
          description: "11. Full name matching filtering keyword 'eg' for Grace Davis",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Grace eg Davis", // filters out "eg" → tokens: ["Grace", "Davis"]
          lastnames,
          firstnames,
          expected: 6,
        },
        {
          description: "12. Full name matching for hyphenated first name: Mary-Jane O'Neil",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Mary-Jane O'Neil",
          lastnames,
          firstnames,
          expected: 10,
        },
        {
          description: "13. Full name matching with reversed order for John-Paul Smithson",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Smithson John-Paul", // sorting tokens yields same as original candidate
          lastnames,
          firstnames,
          expected: 11,
        },
        {
          description: "14. Full name matching with extra spaces for Elizabeth Brownstone",
          anonymize_clients: false,
          anonymization_format: null,
          name: "  Elizabeth   Brownstone  ",
          lastnames,
          firstnames,
          expected: 12,
        },
        {
          description: "15. Full name matching filtering 'eg' for Christopher O'Connor",
          anonymize_clients: false,
          anonymization_format: null,
          name: "eg O'Connor Christopher", // filters out 'eg'
          lastnames,
          firstnames,
          expected: 13,
        },
        {
          description: "16. Full name matching with only lastname provided for Patricia Mcdonald",
          anonymize_clients: false,
          anonymization_format: null,
          name: "Mcdonald",
          lastnames,
          firstnames,
          expected: 14,
        },
        {
          description: "17. Anonymized matching (first_last) for Mary-Jane O'Neil",
          anonymize_clients: true,
          anonymization_format: "first_last",
          name: "mao'", // "Mary-Jane": "ma", "O'Neil": "o'" → "mao'"
          lastnames,
          firstnames,
          expected: 10,
        },
        {
          description: "18. Anonymized matching (last_first) for John-Paul Smithson",
          anonymize_clients: true,
          anonymization_format: "last_first",
          name: "smjo", // "Smithson": "sm", "John-Paul": "jo" → "smjo"
          lastnames,
          firstnames,
          expected: 11,
        },
        {
          description: "19. Anonymized matching (first_last) with extra spaces for Elizabeth Brownstone",
          anonymize_clients: true,
          anonymization_format: "first_last",
          name: "   elbr  ", // "Elizabeth": "el", "Brownstone": "br" → "elbr"
          lastnames,
          firstnames,
          expected: 12,
        },
        {
          description: "20. Anonymized matching (last_first) for Christopher O'Connor",
          anonymize_clients: true,
          anonymization_format: "last_first",
          name: "o'ch", // "O'Connor": "o'", "Christopher": "ch" → "o'ch"
          lastnames,
          firstnames,
          expected: 13,
        },
      ];

      // Run the tests.
      testCases.forEach(test => {
        // Set the context values.
        const result = this.findMostSimilarName(test.name, test.lastnames, test.firstnames, test.anonymize_clients, test.anonymization_format);
        const passFail = result === test.expected ? "PASS" : "FAIL";
        console.log(`${test.description} → Expected: ${test.expected}, Got: ${result} [${passFail}]`);
      });
    },

    editItem({ nativeEvent, event }) {
      let item = Object.assign({}, event)

      // check if item is a holiday
      if (item.type && item.type === 'holiday') {
        return;
      }

      // check if item is from google calendar
      if (item.type && (item.type === 'google' || item.type === 'subscribed' )) {
        // in this case, we ask the user if he wants to create a new appointment for this event
        // trigger custom dialog, with date and time pre-filled, and suggested customer
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.datum = item.start.split('T')[0]
        this.editedItem.datumFormatted = dayjs(item.start.split('T')[0]).format('DD.MM.YYYY')
        this.editedItem.uhrzeit = item.start.split('T')[1]
        //this.editedItem.dauer = moment(item.end).diff(moment(item.start), 'minutes')
        this.editedItem.dauer = this.$store.state.client.std_dauer // TODO: FÜR BIANCA HOTFIX!

        // only find similar client in google case.
        if (item.type === 'google') {
          let most_similar_index = this.findMostSimilarName(item.name, this.customers.map((customer) => customer.nachname), this.customers.map((customer) => customer.vorname), this.$store.state.client.google_calendar_anonymize, this.$store.state.client.google_calendar_anonymization_format);
          if (most_similar_index !== -1) {
            this.editedItem.selected = {
              name: this.customers[most_similar_index].nachname + ' ' + this.customers[most_similar_index].vorname,
              fk_klienten_id: this.customers[most_similar_index].id,
            }
          }
        }

        this.dialog = true
        return
      }

      // check if item is a booking request
      if (item.type && item.type === 'request') {
        let bookingRequestItem = this.bookingRequests.find((request) => request.id === item.id);
        if (bookingRequestItem) {
          this.openBookingRequest(bookingRequestItem);
        } else {
          console.error('Booking request not found');
        }
        return;
      }

      // check if it is a private appointment
      if (item.type && item.type === 'private') {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);

        delete item.type;
        this.privateEvent = Object.assign({}, item);
        this.privateEvent.description = item.name;
        this.privateEvent.startDate = dayjs(item.start).format('YYYY-MM-DD');
        this.privateEvent.endDate = dayjs(item.end).format('YYYY-MM-DD');
        this.privateEvent.startDateFormatted = dayjs(item.start).format('DD.MM.YYYY');
        this.privateEvent.endDateFormatted = dayjs(item.end).format('DD.MM.YYYY');
        this.privateEvent.start_uhrzeit = dayjs(item.start).format('HH:mm');
        this.privateEvent.end_uhrzeit = dayjs(item.end).format('HH:mm');
        this.privateEvent.allday = item.allday;

        this.dialog = true;
        return;
      }

      delete item.name
      delete item.color
      delete item.start
      delete item.end

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.editedItem.datumFormatted = dayjs(this.editedItem.datum).format('DD.MM.YYYY')
      this.editedItem.selected = { name: this.editedItem.nachname + ' ' + this.editedItem.vorname, fk_klienten_id: this.editedItem.fk_klienten_id }
      this.dialog = true
    },

    refreshAndClose(scroll_to_current_time = true) {
      this.initialize(scroll_to_current_time)
      this.close()
    },

    updateAndClose(toUpdate, id) {
      // just introduced in the Termine view, so when updating an appointment that we do not reload all the appointments, causing a table refresh.
      // However, in the calendar it is fine to reload all appointments, so we can just call refreshAndClose
      this.refreshAndClose(false);
    },

    // ICAL

    // writeCalenderFileToBucket(filename) {

    //   let events = this.appointments.map((appointment) => {
    //     return {
    //       title: 'Klienten-Termin',
    //       start: moment(appointment.datum).format('YYYY-M-D-H-m').split("-").map((x) => parseInt(x)),
    //       duration: { minutes: appointment.dauer },
    //       url: 'https://zeipsy.com',
    //       calName: 'ZEIPSY Kalender',
    //     }
    //   })

    //   const { error, value } = ics.createEvents(events)

    //   if (error) {
    //     console.log(error)
    //     this.$emit('showError', 'Fehler beim Erstellen des Kalendar.')
    //     return null
    //   }

    //   // add refresh-intervall to calendar
    //   let refresh = 'REFRESH-INTERVAL;VALUE=DURATION:PT1H'
    //   let calender_content = value.replace('X-PUBLISHED-TTL:PT1H', refresh + '\r\nX-PUBLISHED-TTL:P5M')

    //   let enc = new TextEncoder()
    //   let file = enc.encode(calender_content).buffer

    //   connector.upsertRow(this, 'kalender', { id: this.session.user.id })
    //     .then((data) => {
    //       if (data.length > 0) {
    //         return connector.uploadFileToBucket(this, 'public-calendar', data[0].secret_id + '/', filename, file, '60', 'text/calendar')
    //       } else {
    //         return null
    //       }
    //     })
    //     .then((result) => {
    //       if (result) {
    //         this.calendar_url = connector.getPublicURL(this, 'public-calendar', result.path)
    //         this.calendar_url = this.calendar_url.replace('https://', 'webcal://')
    //         this.show_calendar_url = true

    //       } else {
    //         this.$emit('showError', 'Fehler beim Hochladen des Kalendar.')
    //       }
    //     })
    // },

  }
}
</script>

<style scoped>
.preview-event {
    color: black;
}
.custom-event {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease;
}
.custom-event:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}
.custom-event .event-name {
    font-weight: bold;
    margin-right: 8px;
}
.custom-event .event-time {
    display: inline;
}
.booking-requests-sidebar {
  height: 100%;
  overflow-y: auto;
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}
.v-current-time-insert {
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}
.first::before {
  content: '';
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}
.calendar-settings-sidebar {
  height: 100%;
  overflow-y: auto;
}
/* .client-link {
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease;
  z-index: 2;
}
.client-link:hover {
  text-decoration: underline;
} */
</style>