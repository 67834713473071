<template>
  <v-container fluid>

    <SMSTerms ref="sms_terms_dialog" :session="session" />
    
    <v-dialog v-model="open_client_documentation" fullscreen persistent no-click-animation @keydown.stop="handleCloseDocumentation">
      <v-card>
        <v-toolbar :color="$store.state.theme.primary" dark>
          <v-btn icon @click="open_client_documentation=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          <v-toolbar-title>
            {{ editedItem.vorname }} {{ editedItem.nachname }} | Dokumentation
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <ClientDocumentation
              v-if="editedItem && editedItem.id !== null"
              :clientId="editedItem.id"
              :clientData="editedItem"
              :uploadedFiles="uploadedAppointmentFiles"
              :isEditable="true"
              :session="session"
              @select-files="selectFilesAppointments"
              @openFile="openFile"
              @showError="(arg) => $emit('showError', arg)"
              @showInfo="(arg) => $emit('showInfo', arg)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_invoice_preview" persistent fullscreen no-click-animation @keydown.stop="handleInvoicePreviewClose">
      <v-card flat tile>
          <!-- Toolbar -->
          <v-toolbar flat :color="$store.state.theme.primary">
              <v-btn icon @click="closeInvoicePreview">
                  <v-icon class="white--text">mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="white--text">
                  <span>Rechnungsvorschau</span>
              </v-toolbar-title>
          </v-toolbar>

          <!-- Content Area -->
          <v-row no-gutters>
              <!-- Image Preview -->
              <v-col cols="12" md="8" class="d-flex justify-center pa-4">
                  <iframe :src="invoice_preview_url" :style="{ width: '100%', height: '85dvh', border: 'none' }">
                  </iframe>
              </v-col>

              <!-- File Details -->
              <v-col cols="12" md="4" class="pa-4">
                  <div v-if="current_invoice" class="body-1 mb-3">
                    <p class="text-h6">
                      {{ current_invoice.displayName }}
                    </p>
                    <span v-if="current_invoice && current_invoice.updated_at_formatted">Zuletzt bearbeitet am {{ current_invoice.updated_at_formatted }}</span>
                  </div>
                  <div v-if="current_invoice && current_invoice.size" class="body-1 mb-3">Größe: {{ current_invoice.size }}</div>
              </v-col>
          </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_tags" persistent max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Merkmale verwalten</span>
        </v-card-title>

        <v-card-text>
          <p class="text-subtitle-2">Neues Merkmal erstellen</p>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-text-field v-model="newTag" label="Name des Merkmals" outlined dense></v-text-field>
              <v-btn :color="$store.state.theme.green" :dark="newTag.trim() !== ''" @click="addNewTag" class="ml-5" :disabled="newTag.trim() === ''">
                Hinzufügen
              </v-btn>
            </v-col>
          </v-row>
          <p class="text-subtitle-2">Bestehende Merkmale</p>
          <v-simple-table v-if="available_tags.length > 0" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Merkmal</th>
                  <th class="text-left">Klienten mit diesem Merkmal</th>
                  <th class="text-left">Löschen</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tag, index) in available_tags" :key="tag.id" class="py-5">
                  <td v-if="tag.editing === false">
                    <v-chip small @click="editTag(index)">
                      {{ tag.merkmal }}
                        <v-icon right small :color="$store.state.theme.primary" >
                          mdi-pencil
                        </v-icon>
                    </v-chip>
                  </td>
                  <td v-else class="d-flex align-center py-5">
                    <v-text-field v-model="tagToEdit.merkmal" class="mr-2" hide-details dense :disabled="saving_tag_changes" />
                    <v-btn class="mr-2 my-1" fab x-small elevation="1" :color="$store.state.theme.green" @click="saveTagChanges(tag)" :loading="saving_tag_changes">
                      <v-icon color="white">mdi-check</v-icon>
                    </v-btn> 
                    <v-btn class="my-1" fab x-small elevation="1" :color="$store.state.theme.red" @click="discardTagChanges(index)" :disabled="saving_tag_changes">
                      <v-icon color="white">mdi-close</v-icon>
                    </v-btn> 
                  </td>
                  <td>{{ tag.n_klienten }}</td>
                  <td>
                    <v-btn icon @click="deleteTag(tag)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p v-else class="text-body-2">Es sind noch keine Merkmale vorhanden. Erstelle oben dein
            erstes Merkmal.</p>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn outlined :disabled="inlineEditTag" @click="closeTagsDialog">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Neuer Dialog für die Löschbestätigung -->
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Merkmal löschen</v-card-title>
        <v-card-text class="text-body-1 text-justify">
          Bist du sicher, dass du das Merkmal "{{ tagToDelete.merkmal }}" löschen möchtest?
          Dies wird das Merkmal auch bei allen Klienten ({{ tagToDelete.n_klienten }}) entfernen,
          denen es zugewiesen wurde.
        </v-card-text>
        <v-card-actions class="pb-5 px-6">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDialog = false">Abbrechen</v-btn>
          <v-btn class="ml-3" :color="$store.state.theme.red" outlined @click="confirmDeleteTag">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPaidInvoice" persistent max-width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
      <DialogInvoicePaid 
          @close="closeDialogPaidInvoice"
          @initialize="loadInvoices"
          @showError="(arg) => $emit('showError', arg)"
          @showInfo="(arg) => $emit('showInfo', arg)"
          :editedItem="selectedInvoice"
          :editedIndex="selectedInvoiceIndex"
          :session="session"
      />
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialog_missing_data" persistent width="600" >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Daten unvollständig
          </v-card-title>
          <v-card-text class="my-5 text-body-1">
            Es fehlen noch folgende Angaben für eine vollständige Rechnung:
            <div class="mt-3">
              <ul>
                <li v-for="(tag, index) in missing_tags.filter(tag => tag.startsWith('praxis_') || tag === 'bezeichnung')" :key="index">
                  {{ lookup_missing_data[tag] }}
                </li>
              </ul>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.theme.red" text @click="dialog_missing_data = false" >
              Abbrechen
            </v-btn>
            <v-btn :color="$store.state.theme.green" text @click="navigateToSettings" >
              Zu Einstellungen navigieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <DialogNewAppointmentClient ref="new_appointment_dialog" :clientAppointments="editedAppointments" :session="session" :client="editedItem" :defaultItem="defaultItemAppointment" @refreshAppointments="refreshAppointments" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" />

    <SendInvoiceDialog eager ref="send_invoice_dialog" :session="session" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" @loadInvoices="loadInvoices"/>

    <DeleteInvoiceDialog
      :show="dialog_delete_invoice"
      :item="invoiceToDelete"
      @close="closeDeleteInvoice"
      @invoiceDeleted="handleInvoiceDeleted"
      @showInfo="(arg) => $emit('showInfo', arg)"
      @showError="(arg) => $emit('showError', arg)"
    />

    <v-dialog v-model="delete_institution_dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title class="text-h5">Soll diese Institution wirklich gelöscht werden?</v-card-title>
        <v-card-text class="text-body-1">
          <b>Achtung: </b>Diese Institution wird dann auch bei allen Klienten entfernt bei denen sie zugeordnet wurde.
          Wenn du die Institution nur bei diesem Klienten entfernen möchtest, dann klicke stattdessen auf "Auswahl entfernen".
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn :color="$store.state.theme.primary" :disabled="deleting_entry" text @click="delete_institution_dialog = false">Abbrechen</v-btn>
          <v-btn :color="$store.state.theme.red" :loading="deleting_entry" text @click="deleteInstitutionConfirm">Institution bei allen Klienten löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_create_invoice" persistent max-width="1000px">
      <v-card>
        <v-card-title>
            Neue Rechnung
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 py-0">
              <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-menu v-model="menu_invoice_date" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense outlined v-model="editedItemInvoice.datumFormatted" 
                              label="Rechnungsdatum" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                      </template>
                      <v-date-picker first-day-of-week="1" v-model="editedItemInvoice.datum" no-title @input="updateAndClose($event)" />
                  </v-menu>
              </v-col>
              <v-col class="my-0 py-0">
                  <v-text-field dense outlined v-model="editedItemInvoice.nummer" label="Rechnungsnummer" />
              </v-col>
          </v-row>
          <v-alert
              v-if="hasInvoiceYearMismatch"
              dense
              outlined
              color="orange"
              type="error"
              class="text-justify"
          >
              <span class="font-weight-bold">Achtung:</span>
              Die Rechnungsnummer enthält das Jahr <strong>{{ invoiceYear }}</strong>, das sich vom Jahr des Rechnungsdatums
              <strong>{{ formatInvoiceDateYear(editedItemInvoice.datum) }}</strong> unterscheidet. Bitte prüfe, ob dies korrekt ist.
              <p class="mt-3">
                  Um die Rechnungsnummer auf das Jahr <strong>{{ formatInvoiceDateYear(editedItemInvoice.datum) }}</strong> umzustellen, klicke auf <strong>Umstellen</strong>
                  oder überschreibe die vorgeschlagene Rechnungsnummer.
                  Sollte die Rechnungsnummer {{ editedItemInvoice.nummer }} richtig sein, kannst du diese Warnung ignorieren.
              </p>
              <v-btn class="mb-3" :color="$store.state.theme.green" outlined @click="updateInvoiceNumberToCurrentYear">
                  Umstellen
              </v-btn>
          </v-alert>
          <p class="mt-3 mb-3 text-h6">
            Verrechenbare Termine
            <v-chip :color="$store.state.theme.primary" dark small class="ml-2">
                <v-icon v-if="editedItemInvoice.selected_appointments.length === 0" left small> mdi-cursor-default-click-outline </v-icon>
                {{ editedItemInvoice.selected_appointments.length === 0 ? 'Wähle die zu verrechnenden Termine aus' : `${editedItemInvoice.selected_appointments.length} ausgewählt` }}
            </v-chip>
          </p>
          <v-data-table 
            v-model="editedItemInvoice.selected_appointments" 
            :headers="headers_appointments"
            :items="appointmentsForInvoice" 
            item-key="id" 
            show-select 
            class="elevation-1 clickable-rows" 
            :items-per-page="-1"
            hide-default-footer
            @click:row="toggleSelection"
          >
              <template v-slot:item.datum="{ item }">
                  <span>
                      {{ new Date(item.datum).toLocaleString([], {
                          year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
                      }) }}
                  </span>
                  <v-chip v-if="isAfterNow(item.datum)" :color="$store.state.theme.orange" dark small label class="ml-2">
                      <v-tooltip bottom :color="$store.state.theme.primary">
                          <template v-slot:activator="{ on }">
                              <span class="font-weight-medium" v-on="on">Z</span>
                          </template>
                          Dieser Termin liegt in der Zukunft.
                      </v-tooltip>
                  </v-chip>
              </template>
              <template v-slot:item.preis="{ item }">
                  <span v-if="item.dauer">{{ item.preis }}€ ({{ item.dauer }} Minuten)</span>
                  <span v-else>{{ item.preis }} €</span>
              </template>
              <template v-slot:body.append="{ headers }">
                <tr class="no-hover">
                    <td class="py-3" :colspan="headers.length - 1">
                        <div class="subtitle-1 font-weight-medium black--text">Rechnungsbetrag</div>
                        <div class="subtitle-2 font-weight-regular">= Summe der ausgewählten Termine</div>
                    </td>
                    <td class="py-3">
                        <div class="text-h5 black--text font-weight-bold">
                            {{ amountOfSelectedAppointments }} €
                        </div>
                    </td>
                </tr>
              </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mt-2 px-6 pb-5">
            <v-btn :color="$store.state.theme.red" text @click="dialog_create_invoice = false" :disabled="updating_invoice">
                Abbrechen
            </v-btn>
            <v-btn class="ml-2" :color="$store.state.theme.green" outlined @click="saveInvoice" :loading="updating_invoice">
                Speichern
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEnterSafeKey" max-width="600px" persistent>
      <v-card>
        <v-toolbar :color="$store.state.theme.primary" dark>
          <v-toolbar-title>Sichere deinen ZEIPSY Account</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_enter_safe_key = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="px-4">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="text-h6">Dein persönlicher Safe-Schlüssel</div>
                <p class="subtitle-1 text-justify mt-2">
                  Die Daten-Sicherheit deiner Klienten hat höchste Priorität. Alle
                  personenbezogenen Daten (z.B. Name, Adresse, E-Mail Adresse etc.) der Klienten
                  werden verschlüsselt abgespeichert. Lege einmalig einen Safe-Schlüssel fest.
                  <b>Wähle einen anderen Safe-Schlüssel als dein Passwort bei ZEIPSY.</b>
                </p>

                <v-text-field v-model="$store.state.data_key" label="Safe-Schlüssel"
                  :append-icon="show_key ? 'mdi-eye' : 'mdi-eye-off'" :type="show_key ? 'text' : 'password'"
                  @click:append="show_key = !show_key" :rules="[rules.required, rules.min]" outlined dense></v-text-field>

                <p class="subtitle-1 text-justify">
                  <span class="font-weight-bold">Achtung:</span>
                  Dein Safe-Schlüssel wird ausschließlich auf deinem PC gespeichert. Vergisst du
                  ihn, können die Daten deiner Klienten nicht
                  wiederhergestellt werden. Notiere deinen Safe-Schlüssel und verwahre ihn sicher.
                  <!-- Du kannst den Schlüssel nicht mehr ändern, nachdem du einen festgelegt hast. -->
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-end px-4 pb-3">
          <v-btn :color="$store.state.theme.red" text @click="dialog_enter_safe_key = false" class="mr-3">
            Abbrechen
          </v-btn>
          <v-btn :disabled="!specifiedSafeKey" :loading="loading_key" :color="$store.state.theme.green" text
            @click="save_safe_key">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent fullscreen no-click-animation @keydown="handleClose">
      <v-card color="grey lighten-4">
        <v-toolbar :color="$store.state.theme.primary" dark>
          <v-btn icon @click="close" :disabled="disable_close_client_details">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          <v-toolbar-title>
            {{ dialogTitle }}
          </v-toolbar-title>
        </v-toolbar>

        <v-row class="mx-0 pt-5 px-2 mb-0">
          <v-col cols="12" lg="8">
            <v-row no-gutters>
              <v-col>
                <v-card ref="clientData">
                  <v-card-text>
                    <p class="text-h6 mb-5 d-flex align-center black--text">
                      Stammdaten
                      <v-btn outlined small class="ml-3" @click="dialog_edit_client_data = true">
                        <v-icon left>mdi-pencil</v-icon>
                        Bearbeiten
                      </v-btn>
                    </p>
                    <v-row>
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon left small>mdi-account-circle-outline</v-icon>
                          Name                      
                        </div>
                        <span class="client-prop">
                          {{ editedItem.titel_vorgestellt }}{{ editedItem.vorname }} {{ editedItem.nachname }}{{ editedItem.titel_nachgestellt }}
                        </span>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon color="red accent-2" left small>mdi-cake-variant-outline</v-icon>
                          Geburtsdatum
                        </div>
                        <span v-if="editedItem.geburtsdatum && !inline_edit_birthday" class="client-prop">
                            {{ editedItem.geburtsdatum ? editedItem.geburtsdatum  : '-' }}
                            <v-tooltip bottom open-delay="500">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-if="editedItem.geburtsdatum" v-bind="attrs" v-on="on">
                                  ({{ getAge(editedItem.geburtsdatum) }})
                                </span>
                              </template>
                              <span>{{ getAgeYearsAndMonths(editedItem.geburtsdatum) }}</span>
                            </v-tooltip>
                        </span>
                        <div v-else class="d-flex align-start"> 
                          <v-form v-model="valid_inline_editing_birthday" @submit.prevent>
                            <v-text-field v-if="inline_edit_birthday" class="mr-2" dense filled rounded v-model="editedItem.geburtsdatum" :rules="[rules_birthday.datum]" 
                                        placeholder="TT.MM.JJJJ" persistent-placeholder @keydown.stop="keyDownInlineEditing($event, 'geburtsdatum')" />
                          </v-form>
                          <v-btn v-if="inline_edit_birthday" class="mt-1 mr-2" fab x-small elevation="1" @click="saveInlineClientInfo('geburtsdatum')" :disabled="!valid_inline_editing_birthday" :color="$store.state.theme.green">
                            <v-icon color="white">mdi-check</v-icon>
                          </v-btn> 
                          <v-btn v-if="inline_edit_birthday" class="mt-1" fab x-small elevation="1" @click="revertInlineClientInfo('geburtsdatum')" :color="$store.state.theme.red">
                            <v-icon color="white">mdi-close</v-icon>
                          </v-btn> 
                          <v-btn class="mt-2" v-else small rounded elevation="0" @click="inline_edit_birthday = true">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </div>
                      </v-col>  
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon color="teal accent-4" left small>mdi-card-account-details-outline</v-icon>
                          SV-Nr. & Versicherungsträger
                        </div>
                        <span v-if="editedItem.svnr" class="client-prop">
                          {{ editedItem.svnr }}
                          {{ editedItem.versicherungsträger ? ' - ' + selectedInsurerDisplayName : '' }}
                        </span>
                        <div v-else>
                          <v-btn class="mt-2" small rounded elevation="0" @click="addSVNumber">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </div>
                      </v-col>  

                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon left small>mdi-home-city-outline</v-icon>
                          Adresse
                        </div>
                        <span v-if="editedItem.adresse || editedItem.plz || editedItem.ort" class="client-prop">
                          {{ editedItem.adresse }}, {{ editedItem.plz }} {{ editedItem.ort }}
                        </span>
                        <div v-else>
                          <v-btn class="mt-2" small rounded elevation="0" @click="addAddress">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon color="blue" left small>mdi-email-outline</v-icon>
                          E-Mail
                        </div>
                        <span v-if="editedItem.email && !inline_edit_email" class="client-prop">
                          <a :href="`mailto:${editedItem.email}`" @click.stop>{{ editedItem.email }}</a>
                        </span>
                        <div v-else class="d-flex align-start"> 
                          <v-form v-model="valid_inline_editing_email" @submit.prevent>
                            <v-text-field v-if="inline_edit_email" class="mr-2" dense filled rounded v-model="editedItem.email" :rules="emailRules" @keydown.stop="keyDownInlineEditing($event, 'email')" />
                          </v-form>
                          <v-btn v-if="inline_edit_email" class="mt-1 mr-2" fab x-small elevation="1" @click="saveInlineClientInfo('email')" :disabled="!valid_inline_editing_email" :color="$store.state.theme.green">
                            <v-icon color="white">mdi-check</v-icon>
                          </v-btn> 
                          <v-btn v-if="inline_edit_email" class="mt-1" fab x-small elevation="1" @click="revertInlineClientInfo('email')" :color="$store.state.theme.red">
                            <v-icon color="white">mdi-close</v-icon>
                          </v-btn> 
                          <v-btn class="mt-2" v-else small rounded elevation="0" @click="inline_edit_email = true">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon color="green" left small>mdi-phone-in-talk</v-icon>
                          Telefon
                        </div>
                        <span v-if="editedItem.telefon && !inline_edit_telefon" class="client-prop">
                          {{ editedItem.telefon }}
                        </span>
                        <div v-else class="d-flex align-start"> 
                          <!-- <v-form v-model="valid_inline_editing_telefon" @submit.prevent> -->
                          <v-text-field v-if="inline_edit_telefon" class="mr-2" dense filled rounded v-model="editedItem.telefon" @keydown.stop="keyDownInlineEditing($event, 'telefon')" />
                          <!-- </v-form> -->
                          <v-btn v-if="inline_edit_telefon" class="mt-1 mr-2" fab x-small elevation="1" @click="saveInlineClientInfo('telefon')" :color="$store.state.theme.green">
                            <v-icon color="white">mdi-check</v-icon>
                          </v-btn> 
                          <v-btn v-if="inline_edit_telefon" class="mt-1" fab x-small elevation="1" @click="revertInlineClientInfo('telefon')" :color="$store.state.theme.red">
                            <v-icon color="white">mdi-close</v-icon>
                          </v-btn> 
                          <v-btn class="mt-2" v-else small rounded elevation="0" @click="inline_edit_telefon = true">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon left small>mdi-tag-multiple</v-icon>
                          <span class="mr-3">Merkmale</span>
                          <v-btn x-small outlined @click="dialog_tags = true">
                            <v-icon left>mdi-cog</v-icon>
                            Verwalten
                          </v-btn>
                        </div>                        
                        <div class="d-flex flex-wrap align-center">
                          <div class="d-flex flex-wrap">
                            <v-chip class="mr-2 mt-2" small :key="merkmal.id" v-for="merkmal in editedItem.merkmale" close
                                    @click:close="removeTagFromClient(merkmal)">
                              <v-tooltip bottom v-if="merkmal.merkmal.length > 20">
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">{{ truncateText(merkmal.merkmal, 20) }}</span>
                                </template>
                                <span>{{ merkmal.merkmal }}</span>
                              </v-tooltip>
                              <span v-else>{{ merkmal.merkmal }}</span>
                            </v-chip>
                          </div>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn small rounded elevation="0" class="mt-2" v-bind="attrs" v-on="on">
                                <v-icon left>mdi-plus</v-icon>
                                Hinzufügen
                              </v-btn>
                            </template>
                            <v-card>
                              <v-list dense>
                                <v-list-item
                                  v-for="(item, i) in availableTags"
                                    :key="`item-${i}`"
                                    @click="addTagToClient(item)"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-icon left>mdi-tag-plus</v-icon>
                                      {{ item.merkmal }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="available_tags.length === 0 || availableTags.length === 0" @click="dialog_tags = true">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-icon left>mdi-tag-plus</v-icon>
                                      {{ available_tags.length === 0 ? 'Neues ' : 'Weiteres ' }} Merkmal erstellen
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon left small>mdi-clipboard-text-search-outline</v-icon>
                          Diagnose(n)
                        </div>
                        <div class="d-flex flex-column align-start">
                          <v-btn class="mt-2" small rounded elevation="0" @click="showDiagnosisDialog">
                            <v-icon v-if="selectedICDCodes.length > 0" left>mdi-pencil</v-icon>
                            <v-icon v-else left>mdi-plus</v-icon>

                            <span v-if="selectedICDCodes.length > 0">Bearbeiten</span>
                            <span v-else>Hinzufügen</span>
                          </v-btn>
                          <v-chip class="mt-2" small :key="index" v-for="(diagnose, index) in selectedICDCodes">
                            <v-tooltip bottom v-if="diagnose.length > 40">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ truncateText(diagnose) }}</span>
                              </template>
                              <span>{{ diagnose }}</span>
                            </v-tooltip>
                            <span v-else>{{ diagnose }}</span>
                          </v-chip>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                        <div class="d-flex align-center">
                          <v-icon left small>mdi-comment-text-outline</v-icon>
                          Kommentar
                        </div>
                        <span v-if="editedItem.kommentar" class="client-prop">
                          {{ editedItem.kommentar }}
                        </span>
                        <div v-else>
                          <v-btn class="mt-2" small rounded elevation="0" @click="addComment">
                            <v-icon left>mdi-plus</v-icon>
                            Hinzufügen
                          </v-btn>
                        </div>
                      </v-col>  
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-5" no-gutters>
              <v-col>
                <UploadedFilesCard 
                  ref="clientDocuments"
                  :session="session"
                  :clientId="editedIndex"
                  @update:uploadedAppointmentFiles="uploadedAppointmentFiles = $event"
                  :smCols="6"
                  :mdCols="4"
                  @updatedFiles="updateCardWidth"
                  @showError="(arg) => $emit('showError', arg)"
                  @showInfo="(arg) => $emit('showInfo', arg)"
                >
                  <v-btn small class="ml-3" outlined @click="open_client_documentation=true">
                    <v-icon left>
                      mdi-file-document-edit-outline
                    </v-icon>
                    Dokumentation
                  </v-btn>
                </UploadedFilesCard>  
              </v-col>
            </v-row>
          </v-col>

          <!-- Termine & Rechnungen -->
          <v-col cols="12" lg="4">
            <v-card style="height: 100%;">
              <v-tabs v-model="tabs_appointments_invoices" color="grey darken-4" grow :background-color="$store.state.theme.background_tabs">
                <v-tabs-slider color="grey darken-4" />
                <v-tab>
                  Termine <v-badge inline :content="editedAppointments.length.toString()" :color="tabs_appointments_invoices === 0 ? $store.state.theme.primary : '#0000008a'" />
                </v-tab>
                <v-tab>
                  Rechnungen <v-badge inline :content="editedInvoices.length.toString()" :color="tabs_appointments_invoices === 0 ? '#0000008a' : $store.state.theme.primary" />
                </v-tab>
              </v-tabs>
              <v-divider />
              
              <v-card-text>
                <v-tabs-items v-model="tabs_appointments_invoices">
                  <v-tab-item>
                    <div class="d-flex justify-space-between" v-if="editedAppointments.length > 0">
                      <div>
                        <span class="text-overline mr-3">Sortierung:</span>
                        <v-btn icon small @click="appointment_invoice_sorting = !appointment_invoice_sorting">
                          <v-icon v-if="appointment_invoice_sorting">mdi-sort-calendar-ascending</v-icon>
                          <v-icon v-else>mdi-sort-calendar-descending</v-icon>
                        </v-btn>
                      </div>
                      <v-btn outlined small @click="newAppointment">
                        <v-icon left>mdi-plus</v-icon>
                        Neuer Termin
                      </v-btn>
                    </div>
                    <v-skeleton-loader type="table-tbody" v-if="loading_appointments" />
                    <v-list v-else-if="!loading_appointments && editedAppointments.length > 0" dense :style="{ height: tableHeight + 'px', overflowY: 'auto' }">
                      <v-hover v-slot="{ hover }" v-for="(item, index) in editedAppointmentsSorted" :key="item.id">
                        <div>
                          <v-list-item dense class="pl-0">
                            <v-list-item-avatar>
                              <v-tooltip bottom open-delay="300">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-if="item.nummer" :color="$store.state.theme.green" v-bind="attrs" v-on="on">
                                    mdi-invoice-text-check-outline
                                  </v-icon>
                                  <v-icon v-else-if="isAfterNow(item.datum)" :color="$store.state.theme.primary" v-bind="attrs" v-on="on">
                                    mdi-calendar-arrow-right
                                  </v-icon>
                                  <v-icon v-else :color="$store.state.theme.orange" v-bind="attrs" v-on="on">
                                    mdi-invoice-text-edit-outline
                                  </v-icon>
                                </template>
                                <span v-if="item.nummer">Der Termin wurde bereits verrechnet.</span>
                                <span v-else-if="isAfterNow(item.datum)">Der Termin liegt in der Zukunft.</span>
                                <span v-else>Der Termin wurde noch nicht verrechnet.</span>
                              </v-tooltip>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="d-flex flex-column">
                                <div class="d-flex align-center">
                                  <span class="text-body-1 text-truncate-file">{{ new Date(item.datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit' }) }}</span>
                                  <v-tooltip bottom open-delay="300">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon right small :color="$store.state.theme.green" v-bind="attrs" v-on="on" v-show="item.erinnerung_gesendet">
                                        mdi-bell-ring
                                      </v-icon>
                                      <v-icon right small :color="$store.state.theme.orange" v-bind="attrs" v-on="on"
                                              v-show="!item.erinnerung_gesendet && item.termin_erinnerung && isDateTimeDiffMoreThan48Hours(item.inserted_at, item.datum)">
                                        mdi-bell-outline
                                      </v-icon>
                                      <v-icon right small v-bind="attrs" v-on="on"
                                              v-show="!item.erinnerung_gesendet && item.termin_erinnerung && !isDateTimeDiffMoreThan48Hours(item.inserted_at, item.datum)">
                                        mdi-bell-off
                                      </v-icon>
                                    </template>
                                    <span v-if="item.erinnerung_gesendet">Termin-Erinnerung wurde am {{ formatUTC2Vienna(item.erinnerung_gesendet) }} Uhr versendet.</span>
                                    <span v-else-if="item.termin_erinnerung  && isDateTimeDiffMoreThan48Hours(item.inserted_at, item.datum)">Termin-Erinnerung am Vortag geplant.</span>
                                    <span v-else-if="item.termin_erinnerung">Termin-Erinnerung werden nur für Termine die mind. 48h in der Zukunft liegen versendet.</span>
                                  </v-tooltip>
                                </div>
                                <span class="text-body-2 font-weight-regular text-truncate-file">{{ item.bezeichnung }}</span>
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-menu bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn :outlined="hover" v-bind="attrs" v-on="on" icon small elevation="0" :color="$store.state.theme.primary">
                                    <v-icon >mdi-dots-horizontal</v-icon>
                                  </v-btn>
                                </template>
                                <v-list dense>
                                  <v-list-item @click="editAppointment(item)">
                                    <v-list-item-icon>
                                      <v-icon :color="$store.state.theme.primary">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Termin bearbeiten</v-list-item-title>
                                  </v-list-item>

                                  <v-list-item @click="downloadAppointmentConfirmation(item)" :disabled="item.downloading_timeconfirmation">
                                    <v-list-item-icon>
                                      <v-icon color="#2B7CD3" dark>mdi-invoice-text-clock-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Zeitbestätigung herunterladen</v-list-item-title>
                                  </v-list-item>

                                  <v-list-item @click="deleteAppointment(item)">
                                    <v-list-item-icon>
                                      <v-icon :color="$store.state.theme.red">mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Löschen</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider v-if="index < editedAppointments.length - 1" :key="index"/>
                        </div>
                      </v-hover>
                    </v-list>
                    <div v-else-if="!loading_appointments && editedAppointments.length === 0" :style="{ height: tableHeight + 'px' }" class="d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-column align-center justify-center">
                        <v-icon large>mdi-emoticon-confused-outline</v-icon>
                        <span class="text-h6 text-center">Es wurden noch keine Termine angelegt.</span>
                      </div>
                      <v-btn class="mt-3" elevation="0" dark small @click="newAppointment" :color="$store.state.theme.green">
                        <v-icon left>mdi-plus</v-icon>
                        Neuer Termin
                      </v-btn>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div class="d-flex justify-space-between" v-if="editedInvoices.length >  0">
                      <div>
                        <span class="text-overline mr-3">Sortierung:</span>
                        <v-btn icon small @click="appointment_invoice_sorting = !appointment_invoice_sorting">
                          <v-icon v-if="appointment_invoice_sorting">mdi-sort-calendar-ascending</v-icon>
                          <v-icon v-else>mdi-sort-calendar-descending</v-icon>
                        </v-btn>
                      </div>
                      <v-btn outlined small @click="newInvoice" :disabled="loading_invoices">
                        <v-icon left>mdi-plus</v-icon>
                        Neue Rechnung
                      </v-btn>
                    </div>
                    <v-skeleton-loader type="table-tbody" v-if="loading_invoices" />
                    <v-list v-else-if="!loading_invoices && editedInvoices.length > 0" dense :style="{ height: tableHeight + 'px', overflowY: 'auto' }">
                      <v-hover v-slot="{ hover }" v-for="(item, index) in editedInvoicesSorted" :key="item.id">
                        <div>
                          <v-list-item dense class="pl-0">
                            <v-list-item-avatar>
                              <v-tooltip bottom open-delay="300">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-if="item.bezahlt" :color="$store.state.theme.green" v-bind="attrs" v-on="on">
                                    {{ item.bar ? 'mdi-cash-register' : 'mdi-bank-check' }}
                                  </v-icon>
                                  <v-icon v-else :color="$store.state.theme.red" v-bind="attrs" v-on="on">
                                    mdi-hand-coin-outline
                                  </v-icon>
                                </template>
                                <span v-if="item.bezahlt && item.bar">Die Rechnung wurde in bar bezahlt.</span>
                                <span v-else-if="item.bezahlt">Die Rechnung wurde per Überweisung bezahlt.</span>
                                <span v-else>Die Rechnung wurde noch nicht bezahlt.</span>
                              </v-tooltip>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="d-flex flex-column">
                                <span class="text-body-1 text-truncate-file">{{ new Date(item.datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' }) }} ({{ item.nummer }})</span>
                                <div class="text-truncate-file text-body-2">
                                  <span class="font-weight-medium mr-2">{{ item.rechnungs_betrag }} €</span>
                                  <span>({{ item.anzahl_termine }} Termine)</span>
                                </div>
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="d-flex flex-row">
                              <v-tooltip left open-delay="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn class="mx-1" icon small elevation="0" :color="item.versendet ? $store.state.theme.green : $store.state.theme.primary" @click="openSendEmailDialog(item)" :loading="item.opening_invoice" v-bind="attrs" v-on="on" :disabled="!('termine' in item)">
                                    <v-icon>{{ item.versendet ? 'mdi-email-check' : 'mdi-email-newsletter' }}</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="item.versendet">Rechnung wurde per E-Mail verschickt.</span>
                                <span v-else>Rechnung per E-Mail verschicken.</span>
                              </v-tooltip>
                                
                              <v-tooltip left open-delay="500">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn class="mx-1" icon small elevation="0" color="#F40F02" @click="downloadInvoice(item, true)" :loading="item.downloading_invoice" v-bind="attrs" v-on="on" :disabled="!('termine' in item)">
                                    <v-icon>mdi-file-pdf-box</v-icon>
                                  </v-btn>
                                </template>
                                <span>Rechnung als PDF herunterladen</span>
                              </v-tooltip>
                              <v-menu bottom offset-y >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn :outlined="hover" v-bind="attrs" v-on="on" icon small elevation="0" :color="$store.state.theme.primary" :disabled="!('termine' in item)">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                  </v-btn>
                                </template>
                                <v-list dense>
                                  <v-list-item v-if="!item.bezahlt" @click="openDialogInvoicePaid(item)">
                                    <v-list-item-icon>
                                      <v-icon :color="$store.state.theme.green">mdi-cash-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Als bezahlt markieren</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="downloadInvoice(item, false)" :loading="item.downloading_invoice">
                                    <v-list-item-icon>
                                      <v-icon color="#2B7CD3">mdi-microsoft-word</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Als Word herunterladen</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="deleteInvoice(item)">
                                    <v-list-item-icon>
                                      <v-icon :color="$store.state.theme.red">mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Löschen</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider v-if="index < editedInvoices.length - 1" :key="index"/>
                        </div>
                      </v-hover>
                    </v-list>
                    <div v-else-if="!loading_invoices && editedInvoices.length === 0" :style="{ height: tableHeight + 'px' }" class="d-flex flex-column justify-center align-center">
                      <div class="d-flex flex-column align-center justify-center">
                        <v-icon large>mdi-emoticon-confused-outline</v-icon>
                        <span class="text-h6 text-center">Es wurden noch keine Rechnungen erstellt.</span>
                      </div>
                      <v-btn class="mt-3" elevation="0" dark small @click="newInvoice" :disabled="loading_invoices" :color="$store.state.theme.green">
                        <v-icon left>mdi-plus</v-icon>
                        Neue Rechnung
                      </v-btn>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-0 pb-3 px-2 mt-0">
          <v-col cols="12" md="6" lg="4">
            <v-card style="height: 100%;">
              <v-tabs v-model="tabs_persons_invoice_template" color="grey darken-4" grow :background-color="$store.state.theme.background_tabs">
                <v-tabs-slider color="grey darken-4" />
                  <v-tab>
                    Angehörige / Institution
                  </v-tab>
                  <v-tab>
                    Rechnungsvorlage
                  </v-tab>
              </v-tabs>
              <v-divider class="mb-3" />
              <v-card-text>
                <v-tabs-items v-model="tabs_persons_invoice_template">
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" class="my-0 pt-1 pb-2 mt-2">
                        <v-hover v-slot="{ hover }">
                          <v-card :class="hasFather ? 'py-2 px-5 mb-2 person-card' : 'py-2 px-5 mb-2 add-person-card'" :elevation="hover ? 2 : 0" outlined @click="editPerson('vater')">
                            <div class="d-flex align-center justify-space-between">
                              <div class="d-flex flex-column align-center">
                                <div>
                                  <v-icon left>mdi-human-male-girl</v-icon>
                                  <span class="text-overline">Vater</span>
                                  <span v-if="hasFather" class="text-center">
                                    ({{ editedItem.vater_titel_vorgestellt }}{{ editedItem.vater_vorname }} {{ editedItem.vater_nachname }}{{ editedItem.vater_titel_nachgestellt }})
                                  </span>
                                  <span class="text-overline" v-else>
                                    hinzufügen
                                  </span>
                                </div>
                              </div>
                              <div v-if="hasFather"  class="d-flex justify-end">
                                <v-tooltip bottom v-if="editedItem.rechnungs_empfänger === 'vater'" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-email-newsletter</v-icon>
                                  </template>
                                  <span>Als Rechnungsempfänger festgelegt</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="editedItem.mitversichert_bei === 'vater'" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-card-account-details-star-outline</v-icon>
                                  </template>
                                  <span>Mitversichert bei Vater</span>
                                </v-tooltip>
                              </div>
                              <div v-else class="d-flex justify-end">
                                <v-icon class="ml-4">mdi-account-plus</v-icon>
                              </div>
                            </div>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" class="my-0 pt-0 pb-2">
                        <v-hover v-slot="{ hover }">
                          <v-card :class="hasMother ? 'py-2 px-5 mb-2 person-card' : 'py-2 px-5 mb-2 add-person-card'" :elevation="hover ? 2 : 0" outlined @click="editPerson('mutter')">
                            <div class="d-flex align-center justify-space-between">
                              <div class="d-flex flex-column align-center">
                                <div>
                                  <v-icon left>mdi-human-female-girl</v-icon>
                                  <span class="text-overline">Mutter</span>
                                  <span v-if="hasMother" class="text-center">
                                    ({{ editedItem.mutter_titel_vorgestellt }}{{ editedItem.mutter_vorname }} {{ editedItem.mutter_nachname }}{{ editedItem.mutter_titel_nachgestellt }})
                                  </span>
                                  <span class="text-overline" v-else>
                                    hinzufügen
                                  </span>
                                </div>
                              </div>
                              <div v-if="hasMother" class="d-flex justify-end">
                                <v-tooltip bottom v-if="editedItem.rechnungs_empfänger === 'mutter'" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-email-newsletter</v-icon>
                                  </template>
                                  <span>Als Rechnungsempfänger festgelegt</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="editedItem.mitversichert_bei === 'mutter'" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-card-account-details-star-outline</v-icon>
                                  </template>
                                  <span>Mitversichert bei Mutter</span>
                                </v-tooltip>
                              </div>
                              <div v-else class="d-flex justify-end">
                                <v-icon class="ml-4">mdi-account-plus</v-icon>
                              </div>
                            </div>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" class="my-0 pt-0 pb-2">
                        <v-hover v-slot="{ hover }">
                          <v-card :class="hasPartner ? 'py-2 px-5 mb-2 person-card' : 'py-2 px-5 mb-2 add-person-card'" :elevation="hover ? 2 : 0" outlined @click="editPerson('partner')">
                            <div class="d-flex justify-space-between align-center">
                              <div class="d-flex flex-column align-center">
                                <div>
                                  <v-icon left>mdi-human-male-female</v-icon>
                                  <span class="text-overline">Partner</span>
                                  <span v-if="hasPartner" class="text-center">
                                    ({{ editedItem.partner_titel_vorgestellt }}{{ editedItem.partner_vorname }} {{ editedItem.partner_nachname }}{{ editedItem.partner_titel_nachgestellt }})
                                  </span>
                                  <span class="text-overline" v-else>
                                    hinzufügen
                                  </span>
                                </div>
                              </div>
                              <div v-if="hasPartner" class="d-flex justify-end">
                                <v-tooltip bottom v-if="editedItem.rechnungs_empfänger === 'partner'" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-email-newsletter</v-icon>
                                  </template>
                                  <span>Als Rechnungsempfänger festgelegt</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="editedItem.mitversichert_bei === 'partner'" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-card-account-details-star-outline</v-icon>
                                  </template>
                                  <span>Mitversichert bei Partner</span>
                                </v-tooltip>
                              </div>
                              <div v-else class="d-flex justify-end">
                                <v-icon class="ml-4">mdi-account-plus</v-icon>
                              </div>
                            </div>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" class="my-0 pt-0 pb-2">
                        <v-hover v-slot="{ hover }">
                          <v-card :class="hasInstitution ? 'py-2 px-5 mb-2 person-card' : 'py-2 px-5 mb-2 add-person-card'" :elevation="hover ? 2 : 0" outlined @click="dialog_edit_institution = true">
                            <div class="d-flex align-center justify-space-between">
                              <div class="d-flex flex-column align-center">
                                <div>
                                  <v-icon left>mdi-home-city-outline</v-icon>
                                  <span class="text-overline">Institution</span>
                                  <span v-if="hasInstitution" class="text-center">
                                    ({{ selectedInstitution.name }})
                                  </span>
                                  <span class="text-overline" v-else>
                                    hinzufügen
                                  </span>
                                </div>
                              </div>
                              <div v-if="hasInstitution" class="d-flex justify-end">
                                <v-tooltip bottom v-if="editedItem.rechnungs_empfänger === 'institution'">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-email-newsletter</v-icon>
                                  </template>
                                  <span>Als Rechnungsempfänger festgelegt</span>
                                </v-tooltip>
                              </div>
                              <div v-else class="d-flex justify-end">
                                <v-icon class="ml-4">mdi-home-plus</v-icon>
                              </div>
                            </div>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" :class="index === 0 ? 'my-0 pt-1 pb-2 mt-2' : 'my-0 pt-0 pb-2'" v-for="(item, index) in invoice_templates" :key="index">
                        <v-hover v-slot="{ hover }">
                          <v-card :class="isActiveTemplate(item, editedItem) ? 'py-2 px-5 mb-2 person-card active' : 'py-2 px-5 mb-2 person-card'" :elevation="hover ? 2 : 0" outlined @click="setAsDefaultTemplate(item)">
                            <div class="d-flex align-center justify-space-between">
                              <div class="d-flex flex-column align-center">
                                <div>
                                  <v-icon left v-if="isActiveTemplate(item, editedItem)">mdi-invoice-text-check-outline</v-icon>
                                  <v-icon left v-else>mdi-invoice-text-outline</v-icon>
                                  <span>
                                    {{ item.displayName }}
                                  </span>
                                </div>
                              </div>
                              <div class="d-flex justify-end">
                                <v-tooltip bottom v-if="item.id === $store.state.client.standard_vorlage" open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="ml-4" v-bind="attrs" v-on="on">mdi-star-settings</v-icon>
                                  </template>
                                  <span>Standard-Vorlage</span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="ml-4" @click.stop="previewInvoice(item)" :loading="item.previewing">
                                      <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Vorschau</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>  
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-card style="height: 100%;">
              <v-tabs color="grey darken-4"  :background-color="$store.state.theme.background_tabs">
                <v-tabs-slider color="grey darken-4" />
                <v-tab>
                  Termin-Erinnerungen
                </v-tab>
              </v-tabs>
              <v-divider class="mb-3" />
              <v-card-text class="px-5 pt-0">
                <div class="d-flex align-center">
                  <v-switch class="mr-1" v-model="reminderEnabled" inset @change="toggledReminder" :loading="updating_reminder_state" :disabled="updating_reminder_state" :color="$store.state.theme.green" />
                  <span class="text-body-1 mr-3" style="flex: 1">SMS-Erinnerung am Vortag senden</span>
                </div>
                <span class="text-body-1">Senden an:</span>
                <v-radio-group class="mt-3 px-4" v-model="editedItem.termin_erinnerung" @change="handleReminderRecipientChange">
                  <v-radio 
                    :label="`Klient (${editedItem.telefon || 'fehlende Telefonnummer'})`" 
                    :value="'telefon'" 
                    :disabled="!editedItem.telefon || !reminderEnabled"
                  />
                  <v-radio 
                    :label="`Vater (${editedItem.vater_telefon || 'fehlende Telefonnummer'})`" 
                    :value="'vater_telefon'" 
                    :disabled="!editedItem.vater_telefon || !reminderEnabled"
                  />
                  <v-radio 
                    :label="`Mutter (${editedItem.mutter_telefon || 'fehlende Telefonnummer'})`" 
                    :value="'mutter_telefon'" 
                    :disabled="!editedItem.mutter_telefon || !reminderEnabled"
                  />
                  <v-radio 
                    :label="`Partner (${editedItem.partner_telefon || 'fehlende Telefonnummer'})`" 
                    :value="'partner_telefon'" 
                    :disabled="!editedItem.partner_telefon || !reminderEnabled"
                  />
                </v-radio-group>
                <div>
                  <v-tooltip top open-delay="500" :color="$store.state.theme.primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined small v-bind="attrs" v-on="on" class="mr-5 mb-2" @click="downloadDataProtectionTemplate">
                        <v-icon left>mdi-download</v-icon>
                        Einwilligungserklärung
                      </v-btn>
                    </template>
                    <span class="text-body-1">
                      Hier kannst du eine Vorlage für die Einwilligungserklärung zum SMS-Versand herunterladen. 
                      Passe sie nach deinen Bedürfnissen an und lass sie von deinen Klienten ausfüllen und unterschreiben.
                    </span>
                  </v-tooltip>
                  <v-tooltip top open-delay="100" :color="$store.state.theme.primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small v-bind="attrs" v-on="on" class="text-overline mb-2">
                        <v-icon left>mdi-eye</v-icon>
                        SMS-Vorschau
                      </v-chip>
                    </template>
                    <span class="text-body-1">
                      Erinnerung: Sie haben einen Termin bei {{ $store.state.client.name }} am 23.05.2024 um 14:00 Uhr. Es ist keine SMS Antwort möglich.
                    </span>
                  </v-tooltip>
                </div>
              </v-card-text>
            </v-card>
          </v-col>  
          <v-col cols="12" md="6" lg="4">
              <v-card style="height: 100%;">
                <v-tabs v-model="tabs_charts" color="grey darken-4" grow  :background-color="$store.state.theme.background_tabs">
                  <v-tabs-slider color="grey darken-4" />
                  <v-tab>
                    Finanz-Übersicht
                  </v-tab>
                  <v-tab>
                    Termin-Statistik
                  </v-tab>
                </v-tabs>
                <v-divider class="mb-3" />
                <v-card-text>
                  <v-tabs-items v-model="tabs_charts">
                    <v-tab-item eager>
                      <div class="d-flex flex-column justify-center align-center" >
                        <apexchart v-if="editedAppointments.length > 0" type="donut" :height="donutWidthAndHeight" :width="donutWidthAndHeight" :options="donutChartOptions" :series="donutSeries"></apexchart>
                        <div v-else class="d-flex flex-column align-center">
                          <v-icon large>mdi-emoticon-confused-outline</v-icon>
                          <span class="text-h6 text-center">Es wurden noch keine Termine angelegt.</span>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item eager>
                      <div v-show="editedAppointments.length > 0">
                        <apexchart ref="chart" height="200" type="bar" color="red" :options="options" :series="series"></apexchart>
                        <div class="d-flex justify-space-between">
                          <span>Erster Termin: <span class="font-weight-medium">{{ firstAppointment }}</span></span>
                          <span>Letzter Termin: <span class="font-weight-medium">{{ lastAppointment }}</span></span>
                        </div>  
                      </div>
                      <div v-show="editedAppointments.length === 0" >
                        <div class="d-flex flex-column align-center justify-center">
                          <v-icon large>mdi-emoticon-confused-outline</v-icon>
                          <span class="text-h6 text-center">Es wurden noch keine Termine angelegt.</span>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-col>            
        </v-row>
      </v-card>
    </v-dialog>

    <PersonEditDialog
      v-model="personDialogState.show"
      :type="personDialogState.type"
      :editedItem="editedItem"
      :selected_receiver="selected_receiver"
      :selected_insured_with="selected_insured_with"
      :insurer="insurer"
      :rules_birthday="rules_birthday"
      :saving_persons="saving_persons"
      @select="select"
      @select-insured-with="selectInsuredWith"
      @save="handlePersonSave"
      @close="closeEditClientData"
      @showError="(arg) => $emit('showError', arg)"
    />

    <v-dialog v-model="dialog_edit_institution" max-width="1000px" persistent>
      <v-card>  
        <v-card-title class="d-flex align-center">
          <span class="mr-5">Institution auswählen</span>
          <v-btn outlined small color="green" @click="create_new_institution = true">
            <v-icon left>mdi-plus</v-icon>
            Neue Institution anlegen
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col class="d-flex align-center mt-3">
                <v-select hide-details v-model="editedItem.fk_institution_id" :items="institutions" item-text="name" item-value="id" label="Institution" dense outlined clearable clear-icon="mdi-close-circle" prepend-inner-icon="mdi-home-search" />
                <v-btn class="ml-5" v-if="editedItem.fk_institution_id" outlined @click="editedItem.fk_institution_id = null" >
                  Auswahl entfernen
                </v-btn>
              </v-col>
            </v-row>
            <v-card outlined v-if="editedItem.fk_institution_id" class="mt-5">
              <v-card-title>
                <span class="mr-3">Ausgewählte Institution</span>
                <v-btn outlined small @click="editInstitution" class="mr-5">
                  <v-icon left>mdi-pencil</v-icon>
                  Bearbeiten
                </v-btn>
                <v-btn outlined small @click="deleteInstitution" :color="$store.state.theme.red">
                  <v-icon left>mdi-delete</v-icon>
                  Löschen
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col class="my-0 pt-0 pb-3">
                    <v-checkbox :input-value="selected_receiver === 'institution'" @change="select('institution')"
                      label="Als Rechnungsempfänger festlegen" dense></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"class="my-0 py-0">
                    <v-text-field v-model="selectedInstitution.name" dense outlined label="Name" readonly />
                  </v-col>
                  <v-col cols="12" class="my-0 py-0">
                    <v-textarea v-model="selectedInstitution.anschrift" dense outlined label="Anschrift" readonly />
                  </v-col>
                  <v-col class="my-0 py-0" cols="12" sm="6">
                    <v-text-field v-model="selectedInstitution.email" dense outlined label="E-Mail" readonly />
                  </v-col>
                  <v-col class="my-0 py-0" cols="12" sm="6">
                    <v-text-field v-model="selectedInstitution.telefon" dense outlined label="Telefon" readonly />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-dialog v-model="create_new_institution" max-width="1000px" persistent>
              <v-card outlined> 
                <v-card-title class="mb-3">
                  <span class="mr-3">
                    {{ editedItemInstitution.id ? 'Institution bearbeiten' : 'Neue Institution' }}
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="my-0 py-0">
                      <v-text-field v-model="editedItemInstitution.name" dense outlined label="Name" />
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <v-textarea v-model="editedItemInstitution.anschrift" dense outlined label="Anschrift" />
                    </v-col>
                    <v-col class="my-0 py-0" cols="12" sm="6">
                      <v-text-field v-model="editedItemInstitution.email" dense outlined label="E-Mail" />
                    </v-col>
                    <v-col class="my-0 py-0" cols="12" sm="6">
                      <v-text-field v-model="editedItemInstitution.telefon" dense outlined label="Telefon" />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="px-6 pb-5">
                  <v-spacer></v-spacer>
                  <v-btn :color="$store.state.theme.primary" @click="closeInstitutionCreation" text>
                    Abbrechen
                  </v-btn>
                  <v-btn :color="$store.state.theme.green" @click="saveInstitution" outlined>
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-5 pt-0 mt-0">
          <v-btn :color="$store.state.theme.primary" text @click="closeEditClientData(revert=true)" :disabled="saving_persons">
            Abbrechen
          </v-btn>
          <v-btn :color="$store.state.theme.green" outlined @click="savePersons" :loading="saving_persons">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>    
    </v-dialog>

    <v-dialog v-model="dialog_edit_client_data" max-width="1200px" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-card-title>
          <span v-if="editedIndex > -1">Stammdaten bearbeiten</span>
          <span v-else>Neuer Klient</span>
        </v-card-title>
        <v-card-text>
        <v-form v-model="valid">
          <v-row class="mt-3">
            <v-col cols="6" sm="2" class="my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.titel_vorgestellt" label="Titel (vorgestellt)" />
            </v-col>
            <v-col cols="6" sm="4" class="my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.vorname" label="Vorname" />
            </v-col>
            <v-col cols="6" sm="4" class="my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.nachname" label="Nachname" />
            </v-col>
            <v-col cols="6" sm="2" class="my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.titel_nachgestellt" label="Titel (nachgestellt)" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0" cols="12" sm="5">
              <v-text-field dense outlined v-model="editedItem.adresse" label="Straße & Nr." :rules="adresseRules" ref="adresse" />
            </v-col>
            <v-col class="my-0 py-0" cols="6" sm="2">
              <v-text-field dense outlined v-model="editedItem.plz" label="PLZ" :rules="plzRules" ref="plz" />
            </v-col>
            <v-col class="my-0 py-0" cols="6" sm="5">
              <v-text-field dense outlined v-model="editedItem.ort" label="Ort" :rules="ortRules" ref="ort" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.email" label="E-Mail" />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex align-center my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.telefon" label="Telefon" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4" class="my-0 py-0">
              <v-text-field dense outlined v-model="editedItem.geburtsdatum" label="Geburtsdatum"
                hint="Format: TT.MM.JJJJ" prepend-inner-icon="mdi-calendar" :rules="[rules_birthday.datum]" />
            </v-col>
            <v-col cols="6" md="4" class="my-0 py-0">
              <v-text-field ref="svnr" dense outlined v-model="editedItem.svnr" hint="Format: NNNN TTMMJJ" label="Sozialversicherungsnr." />
            </v-col>
            <v-col cols="12" md="4" class="my-0 py-0">
              <v-autocomplete v-model="editedItem.versicherungsträger" :items="insurer" item-text="displayName" item-value="id" 
              label="Versicherungsträger" outlined dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0">
              <v-textarea ref="commentField" dense outlined v-model="editedItem.kommentar" :rows="commentFieldRows" label="Kommentar" auto-grow />
            </v-col>
          </v-row>
        </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end px-5 pt-0 pb-5 mt-0">
          <v-btn :color="$store.state.theme.primary" text @click="closeEditClientData(revert=true)" :disabled="saving_client">
            Abbrechen
          </v-btn>
          <v-btn :color="$store.state.theme.green" outlined @click="save" :disabled="!valid" :loading="saving_client">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_edit_diagnosis" persistent max-width="1200px" eager>
      <v-card ref="card">
        <v-card-title>
          Diagnose(n) auf der Rechnung
        </v-card-title>
        <v-card-text>
          <v-row class="mt-1">
            <v-col class="d-flex flex-column my-0 py-0">
              <v-autocomplete
                :dense="selectedICDCodes.length === 0"
                outlined
                v-model="selectedICDCodes"
                :items="icdCodes"
                item-text="code"
                item-value="code"
                label="Diagnose(n) nach ICD-10"
                multiple
                deletable-chips
                chips
                hint="Zum Suchen tippen"
                @change="addDiagnosesToText"
                placeholder="Suchbegriff eingeben"
                :menu-props="{ maxWidth: cardWidth }"
                :search-input.sync="searchICDInput"
              >
                <template v-slot:prepend-item v-if="showPrependItem">
                  <v-list-item dense :style="{ backgroundColor: $store.state.theme.background_tabs }">
                      <v-list-item-icon>
                        <v-icon>mdi-star-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Am häufigsten verwendet
                      </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense v-for="item in mostUsedICDCodes" :key="item.short_code" @click="selectDiagnosis(item)">
                    <v-list-item-content>
                      <v-list-item-title class="d-flex align-center">
                        <v-icon small left>mdi-plus-circle</v-icon>
                        <span>{{ item.code }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                  <v-list-item dense :style="{ backgroundColor: $store.state.theme.background_tabs }">
                      <v-list-item-icon>
                        <v-icon>mdi-book-search-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Alle ICD-10 Codes
                      </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0">
              <v-textarea dense outlined v-model="editedItem.zusatztext" label="Zusatztext auf der Rechnung" rows="3"
                auto-grow persistent-placeholder placeholder="z.B.: Diagnose: F43.2: Anpassungsstörungen"
                persistent-hint hint="Dieser Text wird auf der Rechnung mit dem Platzhalter {zusatztext} angezeigt." />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-5 pt-0 mt-0">
          <v-btn :color="$store.state.theme.red" text @click="closeEditClientData(revert=true)" :disabled="saving_client">
            Abbrechen
          </v-btn>
          <v-btn :color="$store.state.theme.green" outlined @click="save" :loading="saving_client">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col class="d-flex align-center justify-space-between">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Suche" single-line
          hide-details outlined dense clearable clear-icon="mdi-close-circle" />
        <v-btn v-if="$vuetify.breakpoint.smAndUp" class="ml-5" elevation="1" :color="$store.state.theme.green" dark
          @click.stop="openDialogNewClient">
          <v-icon left>mdi-plus</v-icon>
          Neuer Klient
        </v-btn>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn v-if="$vuetify.breakpoint.xsOnly" :color="$store.state.theme.green" dark fixed bottom right fab
        @click.stop="openDialogNewClient">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-data-table 
      :headers="displayHeaders" 
      :items="computedCustomers" 
      :search="search" 
      :custom-filter="filterName"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100, -1],
      }"
      sort-by="nachname" 
      class="mt-3 elevation-1" 
      @click:row="editItem"
      @update:items-per-page="saveItemsPerPage"
    >
      <template v-slot:top>
        <v-row no-gutters>
          <v-col cols="12" md="10">
            <v-tabs dense show-arrows color="grey darken-4" :background-color="$store.state.theme.background_tabs">
              <v-tabs-slider :color="$store.state.theme.primary"></v-tabs-slider>
              <v-tab @click="filterActive">
                Aktiv <v-badge inline :content="nActiveClients.toString()" :color="$store.state.theme.green" />
              </v-tab>
              <v-tab @click="filterWaitlist">
                Warteliste <v-badge inline :content="nWaitlistClients.toString()" :color="$store.state.theme.orange" />
              </v-tab>
              <v-tab  @click="filterArchived">
                Archiviert <v-badge inline :content="nArchivedClients.toString()" :color="$store.state.theme.primary" />
              </v-tab>
            </v-tabs>
          </v-col>  
          <v-col cols="12" md="2" class="d-flex justify-end align-center px-5 py-2" :style="{backgroundColor: $store.state.theme.background_tabs}"> 
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small elevation="0" text v-bind="attrs" v-on="on">
                  <span class="mr-2">Spalten</span>
                  <v-icon >mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-subheader class="text-overline mx-3">Spalten</v-subheader>
                <v-list-item v-for="header in headers.filter((header) => header.value !== 'actions')" :key="header.value" class="my-0 py-0">
                    <v-checkbox v-model="header.active" :label="header.text" :color="$store.state.theme.primary" dense @click="toggleAndSaveHeaders()" />
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-divider />
        <v-dialog v-model="dialogDelete" max-width="600px" persistent>
          <v-card>
            <v-card-title class="text-h5">Soll dieser Klient wirklich gelöscht werden?</v-card-title>
            <v-card-text class="text-body-1 text-justify">
              Alle Daten des Klienten, einschließlich der hochgeladenen Dokumente in der Dokumentation, werden unwiderruflich gelöscht.
            </v-card-text>
            <v-card-actions class="px-6 pb-5">
              <v-spacer />
              <v-btn :color="$store.state.theme.primary" :disabled="deleting_entry" text @click="closeDelete">Abbrechen</v-btn>
              <v-btn :color="$store.state.theme.red" :loading="deleting_entry" outlined @click="deleteItemConfirm">Klient löschen</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.nachname="{ item }">
        <span v-html="highlightMatch(`${item.nachname} ${item.vorname}`, search, `${item.vorname} ${item.nachname}`)"></span><br/>
        <v-chip
          v-if="shouldShowStatusChip(item)"
          small
          class="mr-2 mb-2 pointer-cell"
          :color="getStatusChip(item).color"
          dark
        >
          <v-icon left x-small>{{ getStatusChip(item).icon }}</v-icon>
          {{ getStatusChip(item).label }}
        </v-chip>
        <v-chip class="mr-2 mb-2 pointer-cell" small :key="merkmal.id" v-for="merkmal in item.merkmale">
          <v-tooltip bottom v-if="merkmal.merkmal.length > 20">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" v-html="highlightMatch(truncateText(merkmal.merkmal, 20), search)"></span>
            </template>
            <span v-html="highlightMatch(merkmal.merkmal, search)"></span>
          </v-tooltip>
          <span v-else v-html="highlightMatch(merkmal.merkmal, search)"></span>
        </v-chip>
      </template>
      <template v-slot:item.vater_nachname="{ item }">
        <div class="d-flex flex-column">
          <span v-if="item.vater_vorname || item.vater_nachname">
            <v-icon left small>mdi-human-male-girl</v-icon>
            <span v-html="highlightMatch(`${item.vater_vorname} ${item.vater_nachname}`, search, `${item.vater_nachname} ${item.vater_vorname}`)"></span>
          </span>

          <span v-if="item.mutter_vorname || item.mutter_nachname">
            <v-icon left small>mdi-human-female-girl</v-icon>
            <span v-html="highlightMatch(`${item.mutter_vorname} ${item.mutter_nachname}`, search, `${item.mutter_nachname} ${item.mutter_vorname}`)"></span>
          </span>

          <span v-if="item.partner_vorname || item.partner_nachname">
            <v-icon left small>mdi-human-male-female</v-icon>
            <span v-html="highlightMatch(`${item.partner_vorname} ${item.partner_nachname}`, search, `${item.partner_nachname} ${item.partner_vorname}`)"></span>
          </span>

        </div>
      </template>
      <template v-slot:item.adresse="{ item }">
        <div class="d-flex flex-column">
          <span v-html="highlightMatch(item.adresse, search)"></span>
          <span v-html="highlightMatch([(item.plz || ''), (item.ort || '')].filter(Boolean).join(' '), search)"></span>
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div class="d-flex flex-column">
          <span v-if="item.email">
            <v-icon small class="mr-1">mdi-email-outline</v-icon>
            <a :href="`mailto:${item.email}`" class="email-link" @click.stop v-html="highlightMatch(item.email, search)"></a>
          </span>
          <span v-if="item.telefon">
            <v-icon small class="mr-1">mdi-phone-in-talk</v-icon>
            <span v-html="highlightMatch(item.telefon, search)"></span>
          </span>
        </div>
      </template>
      <template v-slot:item.svnr="{ item }">
        <span v-html="highlightMatch(item.svnr, search)"></span>
      </template>
      <template v-slot:item.geburtsdatum="{ item }">
        <span>
          <span v-html="highlightMatch(item.geburtsdatum, search)"></span>
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <span v-if="item.geburtsdatum" v-bind="attrs" v-on="on">
                ({{ getAge(item.geburtsdatum) }})
              </span>
            </template>
            <span>{{ getAgeYearsAndMonths(item.geburtsdatum) }}</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.kommentar="{ item }">
        <v-tooltip bottom v-if="item.kommentar && item.kommentar.length > 30">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" v-html="highlightMatch(truncateText(item.kommentar, 30), search)"></span>
          </template>
          <span v-html="highlightMatch(item.kommentar, search)"></span>
        </v-tooltip>
        <span v-else v-html="highlightMatch(item.kommentar, search)"></span>
      </template>      
      <template v-slot:item.actions="{ item }">
        <div @click.stop>
          <v-tooltip v-if="!item.warteliste" left open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" icon dark small :color="$store.state.theme.orange" @click.stop="waitlistItem(item)" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-clipboard-text-clock-outline</v-icon>
                </v-btn>
              </template>
              <span>Klient auf die Warteliste setzen</span>
          </v-tooltip>
          <v-tooltip v-else left open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" icon dark small :color="$store.state.theme.green" @click.stop="unarchiveItem(item)" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-clipboard-check-outline</v-icon>
                </v-btn>
              </template>
              <span>Klient aktiv setzen</span>
          </v-tooltip>
          <v-tooltip v-if="!item.archiviert" left open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" icon dark small :color="$store.state.theme.primary" @click.stop="archiveItem(item)" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>Klient archivieren</span>
          </v-tooltip>
          <v-tooltip v-else left open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" icon dark small :color="$store.state.theme.primary" @click.stop="unarchiveItem(item)" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-archive-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>Klient aktiv setzen</span>
          </v-tooltip>
          <v-tooltip left open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" elevation="0" icon dark small color="#f44336" @click.stop="deleteItem(item)" v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Klient löschen</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:no-data>
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <div v-else>
          <p class="mt-5">Es sind noch keine Klienten angelegt.</p>
        </div>
      </template>
    </v-data-table>
    <p class="d-flex justify-center mt-5">
        <v-icon left>
            mdi-eye
        </v-icon>
        <span>Klicke auf einen Eintrag um die Daten des Klienten einzusehen.</span>
    </p>

  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import InspectModule from 'docxtemplater/js/inspect-module';
import { saveAs } from 'file-saver';

import cipher from '../helpers/cipher.js';
import connector from '../helpers/supabase-connector.js';
import moment from 'moment';
import json from '../assets/icdCodes.json';
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

import apexchart from 'vue-apexcharts';
import DialogNewAppointmentClient from '../components/DialogNewAppointmentClient.vue';
import invoiceHelper from '../helpers/invoices.js';
import SendInvoiceDialog from '../components/SendInvoiceDialog.vue';
import SMSTerms from '../components/SMSTerms.vue';
import UploadedFilesCard from '../components/UploadedFilesCard.vue';
import DeleteInvoiceDialog from '../components/DeleteInvoiceDialog.vue';
import PersonEditDialog from '../components/PersonEditDialog.vue';
import ClientDocumentation from '../components/ClientDocumentation.vue';
import timeconfirmations from '@/helpers/timeconfirmations.js';
import DialogInvoicePaid from '@/components/DialogInvoicePaid.vue';

export default {

  props: ['session'],
  components: { 
    apexchart, 
    DialogNewAppointmentClient, 
    SendInvoiceDialog, 
    SMSTerms, 
    UploadedFilesCard, 
    DeleteInvoiceDialog,
    PersonEditDialog,
    ClientDocumentation,
    DialogInvoicePaid
  },

  data() {
    return {

      dialogPaidInvoice: false,
      selectedInvoice: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        selected_customer: null,
        selected_appointments: [],
        nachname: null,
        vorname: null,
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        nummer: null,
        bezahlt: null,
        bar: null,
        rechnungs_betrag: null,
        anzahl_termine: null
      },
      selectedInvoiceIndex: -1,

      open_client_documentation: false,
      uploadedAppointmentFiles: [],

      itemsPerPage: parseInt(localStorage.getItem('items-per-page')) || 10,

      disable_close_client_details: false,

      saving_tag_changes: false,
      searchICDInput: '',

      inlineEditTag: false,
      tagToEdit: {
        id: null,
        merkmal: null,
      },

      reminderEnabled: false,
      reminder_reciever: null,
      updating_reminder_state: false,

      dialog_invoice_preview: false,
      invoice_preview_url: null,
      current_invoice: null,

      tabs_charts: 0,
      tabs_persons_invoice_template: 0,
      invoice_templates: [],

      deleting_entry: false,
      saving_client: false,
      saving_persons: false,

      create_new_institution: false,

      missing_tags: [],
      dialog_missing_data: false,

      lookup_missing_data: {
        'adresse': 'Adresse des Klienten',
        'plz': 'Postleitzahl des Klienten',
        'ort': 'Ort des Klienten',
        'vater_adresse': 'Adresse des Vaters',
        'vater_plz': 'Postleitzahl des Vaters',
        'vater_ort': 'Ort des Vaters',
        'mutter_adresse': 'Adresse der Mutter',
        'mutter_plz': 'Postleitzahl der Mutter',
        'mutter_ort': 'Ort der Mutter',
        'partner_adresse': 'Adresse des Partners',
        'partner_plz': 'Postleitzahl des Partners',
        'partner_ort': 'Ort des Partners',

        'praxis_name': 'Name deiner Praxis',
        'praxis_telefon': 'Telefonnummer deiner Praxis',
        'praxis_adresse': 'Adresse deiner Praxis',
        'praxis_plz': 'Postleitzahl deiner Praxis',
        'praxis_ort': 'Ort deiner Praxis',
        'praxis_iban': 'IBAN des Praxiskontos',
        'praxis_bic': 'BIC des Praxiskontos',
        'bezeichnung': 'Berufsbezeichnung',
      },

      dialog_create_invoice: false,
      dialog_delete_invoice: false,

      invoiceToDelete: {},

      menu_invoice_date: false,
      appointment_invoice_sorting: true,

      editedItemInvoice: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        selected_customer: null,
        selected_appointments: [],
        nachname: null,
        vorname: null,
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        nummer: null,
        bezahlt: null,
        bar: null,
        rechnungs_betrag: null,
        anzahl_termine: null
      },

      editedItemInstitution: {
        id: null,
        uid: this.session.user.id,
        name: null,
        anschrift: null,
        email: null,
        telefon: null
      },

      defaultItemInstitution: {
        id: null,
        uid: this.session.user.id,
        name: null,
        anschrift: null,
        email: null,
        telefon: null
      },

      defaultItemInvoice: {
        id: null,
        uid: this.session.user.id,
        fk_klienten_id: null,
        selected_customer: null,
        selected_appointments: [],
        nachname: null,
        vorname: null,
        datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        datumFormatted: dayjs(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).format('DD.MM.YYYY'),
        nummer: null,
        bezahlt: null,
        bar: null,
        rechnungs_betrag: null,
        anzahl_termine: null
      },

      headers_appointments: [
          { text: 'Datum', value: 'datum' },
          { text: 'Dienstleistung', value: 'bezeichnung' },
          { text: 'Preis', value: 'preis' }
      ],

      // headers_selected_diagnosis: [
      //   { text: 'ICD-10 Code', value: 'short_code' },
      //   { text: 'Bezeichnung', value: 'description' },
      //   { text: 'Aktionen', value: 'actions' }
      // ],

      updating_invoice: false,

      cardWidth: 400,
      tableHeight: 300,
      editedAppointments: [],
      editedInvoices: [],
      tabs_appointments_invoices: 0,

      loading_appointments: false,
      loading_invoices: false,

      personDialogState: {
        show: false,
        type: null // can be 'vater', 'mutter', or 'partner'
      },
      dialog_edit_diagnosis: false,
      dialog_edit_institution: false,

      delete_institution_dialog: false,

      dialog_edit_client_data: false,
      inline_edit_birthday: false,
      inline_edit_email: false,
      inline_edit_telefon: false,
      valid_inline_editing_birthday: false,
      valid_inline_editing_email: false,

      emailRules: [
        value => {
            // First, check if the value is null or matches the pattern
            if (value === null || value === undefined || value === '') return true;
            // Define the pattern
            const pattern = /.+@.+\.[A-Za-z]{2,}/;
            // Test the value against the pattern
            return pattern.test(value) || 'Gib eine gültige E-Mail Adresse an';
          }
      ],

      series: [],

      options: {
        chart: {
          id: 'vuechart-overview',
          toolbar: {
            show: false
          },
          stacked: false,
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          enabled: false,
        },
        title: {}
      },

      donutSeries: [100],
        donutChartOptions: {
          colors: [this.$store.state.theme.green, this.$store.state.theme.red, this.$store.state.theme.orange, this.$store.state.theme.primary],
          dataLabels: {
            enabled: false
          },
          legend: {
            show: true,
            fontSize: '14px',
            position: this.$vuetify.breakpoint.xsOnly ? 'bottom': 'right'
          },
          tooltip: {
            theme: 'light'
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,
                    label: 'Gesamt',
                    fontSize: '22px', 
                    formatter: function (w) {
                      // Get the sum of all values
                      const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0) || 0;

                      // check if seriesTotal contains three values
                      // if so, return total value
                      // if not, return nothing
                      if (w?.globals?.seriesTotals?.length === 4) {
                        return `${total}€`;
                      } else {
                        return '0 €';
                      }
                    },
                  }
                }
              }
            }
          },
          labels: ['Bezahlt', 'Unbezahlt', 'Verrechenbar', 'Geplant'],
          chart: {
            type: 'donut',
          },
        },

      filter_archived: false,
      filter_waitlist: false,

      dialog_tags: false,

      newTag: '',
      available_tags: [],
      confirmDeleteDialog: false,
      tagToDelete: {
        id: null,
        merkmal: null,
        n_klienten: null
      }, // Um das zu löschende Merkmal zu speichern

      // if update, also change in Einstellungen.vue
      // and also in the DB (versicherungen)
      insurer: [
        { "id": 1, "name": "ÖGK", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "ÖGK (Burgenland)" },
        { "id": 2, "name": "ÖGK", "state": "Kärnten", "address": "Kempfstraße 8", "place": "9021 Klagenfurt am Wörthersee", "displayName": "ÖGK (Kärnten)" },
        { "id": 3, "name": "ÖGK", "state": "Niederösterreich", "address": "Kremser Landstraße 3", "place": "3100 St. Pölten", "displayName": "ÖGK (Niederösterreich)" },
        { "id": 4, "name": "ÖGK", "state": "Oberösterreich", "address": "Gruberstraße 77", "place": "4021 Linz", "displayName": "ÖGK (Oberösterreich)" },
        { "id": 5, "name": "ÖGK", "state": "Salzburg", "address": "Engelbert-Weiß-Weg 10", "place": "5020 Salzburg", "displayName": "ÖGK (Salzburg)" }, 
        { "id": 6, "name": "ÖGK", "state": "Steiermark", "address": "Josef-Pongratz-Platz 1", "place": "8010 Graz", "displayName": "ÖGK (Steiermark)" },
        { "id": 7, "name": "ÖGK", "state": "Tirol", "address": "Klara-Pölt-Weg 2", "place": "6020 Innsbruck", "displayName": "ÖGK (Tirol)" },
        { "id": 8, "name": "ÖGK", "state": "Vorarlberg", "address": "Jahngasse 4", "place": "6850 Dornbirn", "displayName": "ÖGK (Vorarlberg)" },
        { "id": 9, "name": "ÖGK", "state": "Wien", "address": "Wienerbergstraße 15-19", "place": "1100 Wien", "displayName": "ÖGK (Wien)" },
        
        { "id": 10, "name": "BVAEB", "state": "Kärnten", "address": "Siebenhügelstraße 1", "place": "9020 Klagenfurt am Wörthersee", "displayName": "BVAEB (Kärnten)" },
        { "id": 11, "name": "BVAEB", "state": "Oberösterreich", "address": "Hessenplatz 14", "place": "4020 Linz", "displayName": "BVAEB (Oberösterreich)" },
        { "id": 12, "name": "BVAEB", "state": "Salzburg", "address": "Faberstraße 2A", "place": "5020 Salzburg", "displayName": "BVAEB (Salzburg)" },
        { "id": 13, "name": "BVAEB", "state": "Steiermark", "address": "Grieskai 106", "place": "8020 Graz", "displayName": "BVAEB (Steiermark)" },
        { "id": 14, "name": "BVAEB", "state": "Tirol", "address": "Meinhardstraße 1", "place": "6010 Innsbruck", "displayName": "BVAEB (Tirol)" },
        { "id": 15, "name": "BVAEB", "state": "Vorarlberg", "address": "Montfortstraße 11", "place": "6900 Bregenz", "displayName": "BVAEB (Vorarlberg)" },
        { "id": 16, "name": "BVAEB", "state": "Wien, NÖ & Burgenland", "address": "Josefstädter Straße 80", "place": "1080 Wien", "displayName": "BVAEB (Wien, NÖ & Burgenland)" },
        
        { "id": 17, "name": "SVS", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "SVS (Burgenland)" },
        { "id": 18, "name": "SVS", "state": "Kärnten", "address": "Bahnhofstraße 67", "place": "9020 Klagenfurt", "displayName": "SVS (Kärnten)" },
        { "id": 19, "name": "SVS", "state": "Niederösterreich", "address": "Neugebäudeplatz 1", "place": "3100 Sankt Pölten", "displayName": "SVS (Niederösterreich)" },
        { "id": 20, "name": "SVS", "state": "Oberösterreich", "address": "Hanuschstraße 34", "place": "4020 Linz", "displayName": "SVS (Oberösterreich)" },
        { "id": 21, "name": "SVS", "state": "Salzburg", "address": "Auerspergstraße 24", "place": "5020 Salzburg", "displayName": "SVS (Salzburg)" },
        { "id": 22, "name": "SVS", "state": "Steiermark", "address": "Körblergasse 115", "place": "8010 Graz", "displayName": "SVS (Steiermark)" },
        { "id": 23, "name": "SVS", "state": "Tirol", "address": "Klara-Pölt-Weg 1", "place": "6020 Innsbruck", "displayName": "SVS (Tirol)" },
        { "id": 24, "name": "SVS", "state": "Vorarlberg", "address": "Schloßgraben 14", "place": "6800 Feldkirch", "displayName": "SVS (Vorarlberg)" },
        { "id": 25, "name": "SVS", "state": "Wien", "address": "Wiedner Hauptstraße 84-86", "place": "1051 Wien", "displayName": "SVS (Wien)" },
        
        { "id": 26, "name": "LKUF", "state": "Oberösterreich", "address": "Leonfeldner Straße 11", "place": "4040 Linz", "displayName": "LKUF (Oberösterreich)" },
        { "id": 27, "name": "KFL", "state": "Oberösterreich", "address": "Böhmerwaldstraße 16", "place": "4020 Linz", "displayName": "KFL (Oberösterreich)" },
        { "id": 28, "name": "KFG", "state": "Oberösterreich", "address": "Friedrichstraße 11", "place": "4041 Linz", "displayName": "KFG (Oberösterreich)" },
        { "id": 29, "name": "KFA", "state": "Steiermark", "address": "Hauptplatz 1", "place": "8010 Graz", "displayName": "KFA (Graz)" },
        { "id": 30, "name": "KFA", "state": "Wien", "address": "Schlesingerplatz 5", "place": "1080 Wien", "displayName": "KFA (Wien)" },
        { "id": 31, "name": "KFA", "state": "Niederösterreich", "address": "Hauptplatz 1", "place": "2500 Baden", "displayName": "KFA (Baden)" },
        { "id": 32, "name": "KFA", "state": "Oberösterreich", "address": "Stadtplatz 27", "place": "4400 Steyr", "displayName": "KFA (Steyr)" },
        { "id": 33, "name": "KFA", "state": "Kärnten", "address": "Rathausplatz 1", "place": "9500 Villach", "displayName": "KFA (Villach)" },
        { "id": 34, "name": "KFA", "state": "Salzburg", "address": "Mirabellplatz 4", "place": "5024 Salzburg", "displayName": "KFA (Salzburg)" },
        { "id": 35, "name": "KFA", "state": "Salzburg", "address": "Schöndorferplatz 14", "place": "5400 Hallein", "displayName": "KFA (Hallein)" },
        { "id": 36, "name": "KUF", "state": "Tirol", "address": "Leopoldstraße 3", "place": "6020 Innsbruck", "displayName": "KUF (Tirol - Landesbedienstete)" },
        { "id": 37, "name": "KUF", "state": "Tirol", "address": "Adamgasse 7a", "place": "6020 Innsbruck", "displayName": "KUF (Tirol – Gemeindebeamte)" },
        { "id": 38, "name": "KFW", "state": "Oberösterreich", "address": "Stadtplatz 1", "place": "4600 Wels", "displayName": "KFA (Wels)" },
        { "id": 39, "name": "MKF", "state": "Oberösterreich", "address": "Hauptplatz 1", "place": "4041 Linz", "displayName": "MKF (Linz)" },
      ],

      selectedICDCodes: [],
      icdCodes: [],

      search: '',
      loading: false,
      dialog: false,
      show_key: true,
      rules: {
        required: (v) => !!v || 'Pflichtfeld.',
        min: (v) => (!!v && v.length >= 6) || 'Mindestens 6 Zeichen.',
      },

      loading_key: false,
      dialog_enter_safe_key: true, // default to true, so that it is shown on first load when no data_key is set and no clients are present

      dialogDelete: false,
      rules_birthday: {
        datum: value => {
          // First, check if the value is null or matches the pattern
          if (value === null || value === undefined) return true;
          // Define the pattern
          const pattern = /(^$|^\s*(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:19|20)\d{2})\s*$)/;
          // Test the value against the pattern
          return pattern.test(value) || 'Ungültiges Format: TT.MM.JJJJ';
        },
      },
      phoneNumberRule: {
        format: v => {
          // Ensure the rule explicitly returns a boolean or string
          if (this.editedItem.termin_erinnerung) {
            if (v === '' || v === null || v === undefined) {
              return 'Telefonnummer kann nicht leer sein.';
            }
            if (!/^\+((43|49|420|421|36|386|39|41|423)\d+)$/.test(v)) {
                return 'Telefonnummer muss mit einer Ländervorwahl beginnen (z.B. +43) und darf keine Leerzeichen enthalten.';
            }
            return true; // Indicates the value is valid
          } else {
            return true; // No validation applied if termin_erinnerung is false
          }
        },
      },
      valid: true,

      headers: [
        { text: 'Name', value: 'nachname', active: true, cellClass: 'pointer-cell' },
        { text: 'Adresse', value: 'adresse', active: true, cellClass: 'pointer-cell' },
        { text: 'Kontakt', value: 'email', active: true, cellClass: 'pointer-cell' },
        { text: 'Geburtsdatum', value: 'geburtsdatum', active: true, cellClass: 'pointer-cell' },
        { text: 'SV-Nr.', value: 'svnr', active: true, cellClass: 'pointer-cell' },
        { text: 'Kommentar', value: 'kommentar', active: false, cellClass: 'pointer-cell truncate-cell' },
        { text: 'Angehörige', value: 'vater_nachname', active: false, cellClass: 'pointer-cell'},
        { text: 'Aktionen', value: 'actions', active: true, sortable: false, align: 'center' },
      ],

      institutions: [],

      selected_receiver: null,
      selected_insured_with: null,
      customers: [],
      editedIndex: -1,
      editedItem: {
        id: null,
        uid: this.session.user.id,
        titel_vorgestellt: null,
        vorname: null,
        nachname: null,
        titel_nachgestellt: null,
        adresse: null,
        plz: null,
        ort: null,
        email: null,
        telefon: null,
        termin_erinnerung: null,
        telefon_erinnerung: null,
        zusatztext: null,
        geburtsdatum: null,
        svnr: null,
        versicherungsträger: null,
        vater_titel_vorgestellt: null,
        vater_vorname: null,
        vater_nachname: null,
        vater_titel_nachgestellt: null,
        vater_telefon: null,
        vater_email: null,
        vater_adresse: null,
        vater_plz: null,
        vater_ort: null,
        vater_geburtsdatum: null,
        vater_svnr: null,
        vater_versicherungsträger: null,
        mutter_titel_vorgestellt: null,
        mutter_vorname: null,
        mutter_nachname: null,
        mutter_titel_nachgestellt: null,
        mutter_telefon: null,
        mutter_email: null,
        mutter_adresse: null,
        mutter_plz: null,
        mutter_ort: null,
        mutter_geburtsdatum: null,
        mutter_svnr: null,
        mutter_versicherungsträger: null,
        partner_titel_vorgestellt: null,
        partner_vorname: null,
        partner_nachname: null,
        partner_titel_nachgestellt: null,
        partner_telefon: null,
        partner_email: null,
        partner_adresse: null,
        partner_plz: null,
        partner_ort: null,
        partner_geburtsdatum: null,
        partner_svnr: null,
        partner_versicherungsträger: null,
        merkmale: [],
        rechnungs_empfänger: null,
        mitversichert_bei: null,
        kommentar: null,
        archiviert: null,
        warteliste: null,
        fk_institution_id: null,
        fk_standard_vorlage: null,
      },
      defaultItem: {
        id: null,
        uid: this.session.user.id,
        titel_vorgestellt: null,
        vorname: null,
        nachname: null,
        titel_nachgestellt: null,
        adresse: null,
        plz: null,
        ort: null,
        email: null,
        telefon: null,
        termin_erinnerung: null,
        telefon_erinnerung: null,
        zusatztext: null,
        geburtsdatum: null,
        svnr: null,
        versicherungsträger: null,
        vater_titel_vorgestellt: null,
        vater_vorname: null,
        vater_nachname: null,
        vater_titel_nachgestellt: null,
        vater_telefon: null,
        vater_email: null,
        vater_adresse: null,
        vater_plz: null,
        vater_ort: null,
        vater_geburtsdatum: null,
        vater_svnr: null,
        vater_versicherungsträger: null,
        mutter_titel_vorgestellt: null,
        mutter_vorname: null,
        mutter_nachname: null,
        mutter_titel_nachgestellt: null,
        mutter_telefon: null,
        mutter_email: null,
        mutter_adresse: null,
        mutter_plz: null,
        mutter_ort: null,
        mutter_geburtsdatum: null,
        mutter_svnr: null,
        mutter_versicherungsträger: null,
        partner_titel_vorgestellt: null,
        partner_vorname: null,
        partner_nachname: null,
        partner_titel_nachgestellt: null,
        partner_telefon: null,
        partner_email: null,
        partner_adresse: null,
        partner_plz: null,
        partner_ort: null,
        partner_geburtsdatum: null,
        partner_svnr: null,
        partner_versicherungsträger: null,
        merkmale: [],
        rechnungs_empfänger: null,
        mitversichert_bei: null,
        kommentar: null,
        archiviert: null,
        warteliste: null,
        fk_institution_id: null,
        fk_standard_vorlage: null,
      },

      defaultItemAppointment: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          fk_rechnungs_id: null,
          selected: null,
          vorname: null,
          nachname: null,
          uhrzeit: null,
          datum: null,
          datumFormatted: null,
          preis: this.$store.state.client.std_preis,
          dauer: this.$store.state.client.std_dauer,
          bezeichnung: null,
          ust_befreiung: null,
          fk_dienstleistung: null,
          termin_erinnerung: null,
          erinnerung_gesendet: null,
          sms_error: null,
        },
    }
  },

  computed: {

    donutWidthAndHeight() {
      return this.$vuetify.breakpoint.xsOnly ? 250 : 420
    },

    invoiceYear() {
        try {
            // If there's no invoice number yet, return null to avoid errors
            if (!this.editedItemInvoice.nummer) return null;
            
            // 1) Detect the format (e.g. "{NR}/{JAHR}" or "{JAHR}-{NR}")
            const format = invoiceHelper.getInvoiceFormat(this.editedItemInvoice.nummer);

            // 2) Parse the invoice number with the detected format
            const { year } = invoiceHelper.parseInvoiceNumber(this.editedItemInvoice.nummer, format);

            // Return the parsed year (e.g. 2024)
            return year;
        } catch (error) {
            // If parsing fails (e.g., invalid format), return null
            return null;
        }
    },

    hasInvoiceYearMismatch() {
        // If we couldn’t parse a valid year, we do not show a warning
        if (!this.invoiceYear || !this.editedItemInvoice.datum) return false;

        const invoiceDateYear = dayjs(this.editedItemInvoice.datum).year();

        return this.invoiceYear !== invoiceDateYear;
    },

    mostUsedICDCodes() {
      const codes = this.icdCodes.map(icd => icd.code);
      const customers_with_diagnoses = this.customers.filter((customer) => customer.zusatztext && customer.zusatztext.length > 0);
      
      // Create a map to count occurrences of each diagnosis
      const diagnosisCounts = new Map();
      
      // Iterate over each customer and count their diagnoses
      customers_with_diagnoses.forEach(customer => {
        codes.forEach(code => {
          if (customer.zusatztext.includes(code)) {
            diagnosisCounts.set(code, (diagnosisCounts.get(code) || 0) + 1);
          }
        });
      });
      
      // Convert the map to an array, sort by count, and take the top 5
      let topDiagnoses = Array.from(diagnosisCounts.entries())
        .sort((a, b) => b[1] - a[1])
        .map(([code]) => this.icdCodes.find(icd => icd.code === code));

      topDiagnoses = topDiagnoses.filter(diagnosis => !this.selectedICDCodes.includes(diagnosis.code));

      return topDiagnoses.slice(0, 5);
    },

    showPrependItem() {
      return (this.searchICDInput === null || this.searchICDInput.length === 0) && this.mostUsedICDCodes.length > 0;
    },

    availableTags() {
      // only display the available tags, where the id is not already in the merkmale array
      return this.available_tags.filter((atag) => !this.editedItem.merkmale.map((etag) => etag.fk_merkmal_id).includes(atag.id));
    },

    selectedInstitution() {
      if (this.editedItem.fk_institution_id !== null) {
        let institution = this.institutions.find((institution) => institution.id === this.editedItem.fk_institution_id);
        if (institution) {
          return institution;
        } else {
          return {
            id: null,
            name: null,
            anschrift: null,
            email: null,
            telefon: null
          };
        }
      } else {
        return {
          id: null,
          name: null,
          anschrift: null,
          email: null,
          telefon: null
        
        };
      }
    },

    nActiveClients() {
      return this.customers.filter((customer) => !customer.archiviert && !customer.warteliste).length;
    },

    nWaitlistClients() {
      return this.customers.filter((customer) => customer.warteliste).length;
    },

    nArchivedClients() {
      return this.customers.filter((customer) => customer.archiviert).length;
    },

    nLoadersToShow() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return [1, 2, 3];
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return [1, 2];
      } else {
        return [1];
      }
    },

    editedAppointmentsSorted() {
      if (this.appointment_invoice_sorting) {
        return this.editedAppointments.sort((a, b) => new Date(b.datum) - new Date(a.datum));
      } else { 
        return this.editedAppointments.sort((a, b) => new Date(a.datum) - new Date(b.datum));
      }
    },

    editedInvoicesSorted() {
      if (this.appointment_invoice_sorting) {
        return this.editedInvoices.sort((a, b) => new Date(b.datum) - new Date(a.datum));
      } else {
        return this.editedInvoices.sort((a, b) => new Date(a.datum) - new Date(b.datum));
      }
    },

    amountOfSelectedAppointments() {
        try {
            let sum = 0
            this.editedItemInvoice.selected_appointments.forEach((appointment) => {
                sum += appointment.preis
            })
            return sum
        } catch (error) {
            // in this case, the appointments are not loaded yet, or just an emptry array or whatever
            return 0
        }
    },

    appointmentsForInvoice() {
      return this.editedAppointments.filter((appointment) => appointment.fk_rechnungs_id === null);
    },

    hasFather() {
      return this.editedItem.vater_vorname || this.editedItem.vater_nachname || this.editedItem.vater_telefon || this.editedItem.vater_email;
    },

    hasMother() {
      return this.editedItem.mutter_vorname || this.editedItem.mutter_nachname || this.editedItem.mutter_telefon || this.editedItem.mutter_email;
    },

    hasPartner() {
      return this.editedItem.partner_vorname || this.editedItem.partner_nachname || this.editedItem.partner_telefon || this.editedItem.partner_email;
    },

    hasInstitution() {
      return this.editedItem.fk_institution_id !== null;
    },

    commentFieldRows() {
      if (this.editedItem.kommentar && this.editedItem.kommentar.length > 0) {
        return Math.max(2, this.editedItem.kommentar.split('\n').length);
      } else {
        return 2;
      }
    },

    firstAppointment() {
      return this.editedAppointments.length > 0 ? new Date(this.editedAppointments[this.editedAppointments.length - 1].datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit' })  : '-';
    },

    lastAppointment() {
      return this.editedAppointments.length > 0 ? new Date(this.editedAppointments[0].datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit' }) : '-';
    },

    requiredFields() {
      if (this.$route.params.id && this.$route.query.missing) {
        return this.$route.query.missing.split(',');
      } else {
        return [];
      }
    },

    adresseRules() {
      return this.requiredFields.includes('adresse') ? [v => !!v || 'Wird benötigt'] : [];
    },
    plzRules() {
      return this.requiredFields.includes('plz') ? [v => !!v || 'Wird benötigt'] : [];
    },
    ortRules() {
      return this.requiredFields.includes('ort') ? [v => !!v || 'Wird benötigt'] : [];
    },

    computedCustomers() {
      if (this.search) {
        // When searching, show all matching clients
        return this.customers;
      }

      return this.customers.filter((customer) => {
        if (this.filter_archived) {
          return customer.archiviert
        } else if (this.filter_waitlist) {
          return customer.warteliste;
        } else {
          return !customer.archiviert && !customer.warteliste;
        }
      });
    },

    dialogEnterSafeKey() {
      return this.$store.state.aes_key === null && this.dialog_enter_safe_key
    },

    specifiedSafeKey() {
      return this.$store.state.data_key.length >= 6;
    },

    displayHeaders() {
      return this.headers.filter((header) => header.active)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Klient' : 'Klient bearbeiten'
    },

    dialogTitle() {
      return this.editedIndex === -1 ? 'Neuer Klient' : this.editedItem.vorname + ' ' + this.editedItem.nachname;
    },

    routeEditItem() {
      if (this.$route.params.id) {
        const id = this.$route.params.id;
        const customer = this.customers.find((c) => c.id == id);
        return customer;
      } else {
        return null
      }
    },

    selectedInsurerDisplayName() {
      if (this.editedItem && this.editedItem.versicherungsträger) {
        return this.insurer.find((insurer) => insurer.id === this.editedItem.versicherungsträger).name;
      } else {
        return '';
      }
    },
  },

  watch: {

    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    'editedItem.zusatztext': debounce(function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.parseICDCodesFromZusatztext();
      }
    }, 500),
    
    'editedItem.fk_institution_id': function (newVal, oldVal) {
      if (!newVal) {
        // the institution was removed
        if (this.editedItem.rechnungs_empfänger === 'institution') {
          this.editedItem.rechnungs_empfänger = null;
          this.selected_receiver = null;
        }
      }
    },

    '$vuetify.breakpoint.xsOnly': function (val) {
        this.donutChartOptions = {
          ...this.donutChartOptions,
          legend: {
            ...this.donutChartOptions.legend,
            position: (val ? "bottom" : "right")
          }
        };
      },
  },

  mounted() {
    this.icdCodes = json.icdCodes;
    window.addEventListener('resize', this.updateCardWidth);
    this.initialize();
    this.loadAvailableTags();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateCardWidth);
  },

  methods: {

    openDialogInvoicePaid(invoice) {

      this.selectedInvoiceIndex = invoice.id;
      this.selectedInvoice = Object.assign({}, invoice);

      // set the default paid date to today
      this.selectedInvoice.bezahlt = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)

      // properly set the selected_customer
      if (this.selectedInvoice.fk_klienten_id) {
        this.selectedInvoice.selected_customer = {
          type: 'client',
          name: this.selectedInvoice.nachname + ' ' + this.selectedInvoice.vorname,
          fk_klienten_id: this.selectedInvoice.fk_klienten_id,
          item_id: `client-${this.selectedInvoice.fk_klienten_id}`,
          vorname: this.selectedInvoice.vorname,
          nachname: this.selectedInvoice.nachname,
          merkmale: this.selectedInvoice.merkmale
        }
      } else {
        this.selectedInvoice.selected_customer = {
          type: 'institution',
          name: this.selectedInvoice.empfänger_name,
          fk_institution_id: this.selectedInvoice.fk_institution_id,
          item_id: `institution-${this.selectedInvoice.fk_institution_id}`,
          merkmale: []
        }
      }

      this.dialogPaidInvoice = true;
    },

    closeDialogPaidInvoice() {
      this.dialogPaidInvoice = false;
      this.$nextTick(() => {
        this.selectedInvoice = Object.assign({}, this.defaultItemInvoice);
        this.selectedInvoiceIndex = -1;
      })
    },

    getStatusChip(customer) {
      if (customer.archiviert) {
        return {
          label: 'Archiviert',
          color: this.$store.state.theme.primary,
          icon: 'mdi-archive'
        };
      } else if (customer.warteliste) {
        return {
          label: 'Warteliste',
          color: this.$store.state.theme.orange,
          icon: 'mdi-clipboard-text-clock-outline'
        };
      }
      return {
        label: 'Aktiv',
        color: this.$store.state.theme.green,
        icon: 'mdi-check-circle'
      };
    },

    shouldShowStatusChip(customer) {
      if (!this.search) return false;
      
      if (this.filter_archived) {
        return !customer.archiviert;
      } else if (this.filter_waitlist) {
        return !customer.warteliste;
      } else {
        return customer.archiviert || customer.warteliste;
      }
    },

    async downloadAppointmentConfirmation(item) {

      const index = this.editedAppointments.findIndex(i => i.id === item.id);
      this.$set(this.editedAppointments[index], 'downloading_timeconfirmation', true);

      let custom_template = await timeconfirmations.getCustomTimeconfirmationTemplate(this);
      let status = await timeconfirmations.generateTimeConfirmation(this, custom_template, item, false, true);
      if (status && status.status === 'success') {
        this.$emit('showInfo', {
          message: 'Die Zeitbestätigung wurde erfolgreich heruntergeladen.',
          timeout: 5000
        });
      }

      this.$set(this.editedAppointments[index], 'downloading_timeconfirmation', false);
    },

    openFile(file) {
        if (this.$refs.clientDocuments) {
            this.$refs.clientDocuments.openFile(file);
        }
    },

    selectFilesAppointments(id) {
        if (this.$refs.clientDocuments) {
            this.$refs.clientDocuments.selectFilesForAppointment(id);
        }
    },

    editPerson(type) {
      if (!['vater', 'mutter', 'partner'].includes(type)) {
        console.error('Invalid person type:', type);
        return;
      }
      this.openPersonDialog(type);
    },

    openPersonDialog(type) {
      this.personDialogState.type = type;
      this.personDialogState.show = true;
    },

    async handlePersonSave() {
      this.saving_persons = true;
      
      let updated = await this.updateClientInfo();
      if (updated) {
        this.personDialogState.show = false;
        this.personDialogState.type = null;
        this.dialog_edit_institution = false;
        this.$emit('showInfo', {
          message: 'Der Eintrag wurde erfolgreich gespeichert.',
          timeout: 5000
        });
      }
      this.saving_persons = false;
    },

    updateInvoiceNumberToCurrentYear() {
        const result = invoiceHelper.updateInvoiceNumberToCurrentYear(
            this.editedItemInvoice.nummer, 
            this.editedItemInvoice.datum
        );
        
        if (result.status === "success") {
            this.editedItemInvoice.nummer = result.invoiceNumber;
        } else {
            this.$emit('showError', { 
                message: result.message, 
                error: result.error 
            });
        }
    },

    formatInvoiceDateYear(dateStr) {
        if (!dateStr) return null
        return dayjs(dateStr).format('YYYY')
    },

    // Add this new method to highlight matched text
    highlightMatch(text, searchTerm, reversedText = null) {
      if (!searchTerm || !text) return text;
      let trimmedSearch = searchTerm.trim();
      const regex = new RegExp(`(${trimmedSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
      const normalHighlight = text.replace(regex, '<mark class="search-highlight">$1</mark>');

        // If we have a reversed version and the normal version didn't match, try that
      if (reversedText && !normalHighlight.includes('<mark')) {
        return reversedText.replace(regex, '<mark class="search-highlight">$1</mark>');
      }
      
      return normalHighlight;
    },

    saveItemsPerPage(value) {
      localStorage.setItem('items-per-page', value.toString());
    },

    toggleSelection(item) {
      const index = this.editedItemInvoice.selected_appointments.findIndex(appointment => appointment.id === item.id);
      if (index === -1) {
        this.editedItemInvoice.selected_appointments.push(item);
      } else {
        this.editedItemInvoice.selected_appointments.splice(index, 1);
      }
    },

    selectDiagnosis(item) {
      if (!this.selectedICDCodes.includes(item.code)) {
        this.selectedICDCodes.push(item.code);
        this.addDiagnosesToText(this.selectedICDCodes);
      }
    },

    async addTagToClient(tag) {

      let tag_to_add = {
        fk_klienten_id: this.editedItem.id,
        fk_merkmal_id: tag.id,
        uid: this.session.user.id
      };

      let inserted_id = await connector.insertRow(this, 'klienten_merkmale', tag_to_add);
      
      await this.loadAvailableTags();
      // set the updated tags to the editedItem
      let tags = await connector.getDataOnlyFiltered(this, 'vwklienten', 'eq', 'id', this.editedItem.id, 'id', false, 'merkmale');
      if (tags.length > 0) {
        this.editedItem.merkmale = tags[0].merkmale;
        // also update the tags in the customers array
        let index = this.customers.findIndex(customer => customer.id === this.editedItem.id);
        if (index > -1) {
          this.$set(this.customers, index, { ...this.customers[index], merkmale: tags[0].merkmale });
        }
      }
    },

    async removeTagFromClient(tag) {

      let deleted = await connector.delete(this, 'klienten_merkmale', 'id', tag.id);
      if (deleted) {
        await this.loadAvailableTags();
        // set the updated tags to the editedItem
        let tags = await connector.getDataOnlyFiltered(this, 'vwklienten', 'eq', 'id', this.editedItem.id, 'id', false, 'merkmale');
        if (tags.length > 0) {
          this.editedItem.merkmale = tags[0].merkmale;
          // also update the tags in the customers array
          let index = this.customers.findIndex(customer => customer.id === this.editedItem.id);
          if (index > -1) {
            this.$set(this.customers, index, { ...this.customers[index], merkmale: tags[0].merkmale });
          }
        }
      }
    },

    async saveTagChanges(tag) {  

      // check if updated name is not empty or null etc.
      if (this.tagToEdit.merkmal === '' || this.tagToEdit.merkmal === null || this.tagToEdit.merkmal === undefined) {
        this.$emit('showError', {
          message: 'Der Name des Merkmals darf nicht leer sein.',
          timeout: 5000,
        });
        return;
      }

      this.saving_tag_changes = true;
      let updated = await connector.updateRow(this, 'merkmale', { 'merkmal': this.tagToEdit.merkmal }, tag.id);
      let index = this.available_tags.findIndex((atag) => atag.id === tag.id);
      
      // update the editedItem.merkmale
      let tags = await connector.getDataOnlyFiltered(this, 'vwklienten', 'eq', 'id', this.editedItem.id, 'id', false, 'merkmale');
      if (tags.length > 0) {
        this.editedItem.merkmale = tags[0].merkmale;
      }

      // reload customers to update the tags
      let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true)
      this.customers = await cipher.decryptDataSync(this, customers);

      this.$set(this.available_tags, index, { ...this.available_tags[index], merkmal: this.tagToEdit.merkmal, editing: false});

      this.saving_tag_changes = false;
      this.inlineEditTag = false;
    },

    discardTagChanges(index) {
      this.$set(this.available_tags, index, { ...this.available_tags[index], editing: false });
      this.inlineEditTag = false;
    },

    closeTagsDialog() {
      this.tagToEdit = {
        id: null,
        merkmal: null,
      };
      this.inlineEditTag = false;
      this.dialog_tags = false;
    },

    async downloadDataProtectionTemplate() {
      let bucket = "public-templates";
      let filename = "Datenschutzinformationen_und_Einwilligung.docx";
      let path = "agreements/";

      let content = await connector.downloadFileFromBucket(this, bucket, path, filename);

      const iModule = InspectModule();
      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [iModule],
          parser: function (tag) {
            return {
                get: function (scope) {
                    let result = null;
                    if (tag === ".") {
                        result = scope;
                    } else {
                        result = scope[tag.toLowerCase()];
                    }
                    return result;
                },
            };
          },
          nullGetter: function (part, scopeManager) {
            if (!part.module) {
                return "";
            }
            if (part.module === "rawxml") {
                return "";
            }
            return "";
          },
      });

      // Render the document (Replace variables)
      doc.render({
        name: this.$store.state.client.name,
        adresse: this.$store.state.client.adresse + ", " + this.$store.state.client.plz + " " + this.$store.state.client.ort,
      });

      const blob = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      await saveAs(blob, "Vorlage_Datenschutzinformationen_und_Einwilligungserklärung.docx");
    },
    
    isDateTimeDiffMoreThan48Hours(date_inserted, date_appointment) {
      // account for timezone difference, as date1 is in UTC and date2 is in Vienna
      let diff = dayjs(date_appointment).diff(dayjs.utc(date_inserted).tz('Europe/Vienna'), 'hours');
      return diff >= 48;
    },

    async handleReminderRecipientChange(newValue, oldValue) {
      if (this.reminderEnabled && newValue) {
        const phoneNumber = this.editedItem[newValue];
        if (this.checkPhoneNumber(phoneNumber)) {
          this.editedItem.telefon_erinnerung = phoneNumber;
          await this.updateReminderInformation();
        } else {
          this.$nextTick(() => {
            this.editedItem.telefon_erinnerung = null;
            this.editedItem.termin_erinnerung = null;
          });
          this.$emit('showError', {
            message: 'Die angegebene Telefonnummer muss mit einer Ländervorwahl (z.B. +43) beginnen und darf keine Leerzeichen enthalten.',
            timeout: 10000
          });
        }
      } else if (!newValue) {
        console.log('No reminder recipient selected');
        // this.editedItem.telefon_erinnerung = null;
        // await this.updateDatabase();
      }
    },
 
    async updateReminderInformation() {
      this.updating_reminder_state = true;
      try {
        let updated = await connector.updateRow(this, 'klienten', {
          'termin_erinnerung': this.editedItem.termin_erinnerung,
          'telefon_erinnerung': this.editedItem.telefon_erinnerung
        }, this.editedItem.id);

        if (updated) {
          // update also the values in the customer list (we do not need to fetch the updated customer from the database)
          let index = this.customers.findIndex(customer => customer.id === this.editedItem.id);
          if (index > -1) {
            this.$set(this.customers[index], 'termin_erinnerung', updated[0].termin_erinnerung);
            this.$set(this.customers[index], 'telefon_erinnerung', updated[0].telefon_erinnerung);

            if (this.editedItem.termin_erinnerung && this.editedItem.telefon_erinnerung) {
              // show activation success
              this.$emit('showInfo', {
                message: 'Die Termin-Erinnerungen wurden erfolgreich aktiviert.',
                timeout: 5000
              });
            } else if (!this.reminderEnabled && this.editedItem.termin_erinnerung === null && this.editedItem.telefon_erinnerung === null) {
              // show deactivation success
              this.$emit('showInfo', {
                message: 'Die Termin-Erinnerungen wurden erfolgreich deaktiviert.',
                timeout: 5000
              });
            }
            
          } else {
            // this should never happen.
            connector.logError(this, {
                uid: this.session.user.id,
                message: 'ERROR: Could not find the customer to update the reminder.',
            });
          }
        }

      } catch (error) {
        this.$emit('showError', {
          message: 'Fehler beim Aktualisieren der Termin-Erinnerungen. Bitte versuche es erneut.',
          timeout: 5000
        });
      } finally {
        this.updating_reminder_state = false;
      }
    },

    formatUTC2Vienna(date) {
      return dayjs(date).tz('Europe/Vienna').format('DD.MM.YYYY, HH:mm');
    },

    checkPhoneNumber(phoneNumber) {
      if (!phoneNumber) {
        return false;
      }
      return /^\+((43|49|420|421|36|386|39|41|423)\d+)$/.test(phoneNumber);
    },

    async toggledReminder(state) {

      // check if termin_erinnerung is set
      // show an error message if the user has no active subscription
      if (state === true && !this.$store.getters.hasPaidSubscription) {
        this.$emit('showError', {
          message: 'Ein aktives Abonnement ist erforderlich, um Termin-Erinnerungen per SMS zu verwenden.',
          additional_button: {
              text: "Abos Anzeigen",
              target: "/upgrade",
          },
          timeout: 10000
        });
        this.$nextTick(() => {
          this.editedItem.termin_erinnerung = null;
          this.editedItem.telefon_erinnerung = null;
          this.reminderEnabled = false;
        });
        return;
      }

      if (state === true && !this.$store.state.client.accepted_sms_terms) {
        this.$refs.sms_terms_dialog.openDialog();

        this.$nextTick(() => {
          this.editedItem.termin_erinnerung = null;
          this.editedItem.telefon_erinnerung = null;
          this.reminderEnabled = false;
        });
        return;
      }

      if (state === false) {
        this.editedItem.telefon_erinnerung = null;
        this.editedItem.termin_erinnerung = null;
      }

      this.updateReminderInformation();
    },

    async previewInvoice(invoice_template) {
      invoice_template.previewing = true;
      // download the file and then display it in a dialog in an object tag
      const status = await invoiceHelper.getSampleInvoicePDF(this, invoice_template);

      if (status && status.status === "error" && status.type === "missing_tags") {
        // es fehlen Praxis Daten
        this.missing_tags = status.missing_tags;
        this.dialog_missing_data = true;

        invoice_template.previewing = false;
        return;
      } else if (status === null) {
        // silently return if there was an error, the error is already shown
        invoice_template.previewing = false;
        return;
      }
      const url = URL.createObjectURL(status.blob);
      this.current_invoice = invoice_template;
      this.invoice_preview_url = url;
      this.dialog_invoice_preview = true;
      invoice_template.previewing = false;
    },

    closeInvoicePreview() {
      this.dialog_invoice_preview = false;
      if (this.invoice_preview_url) URL.revokeObjectURL(this.invoice_preview_url);
      this.invoice_preview_url = null;
      this.current_invoice = null;
    },

    isActiveTemplate(template, customer) {
      // check if customer has a standard template set
      if (customer.fk_standard_vorlage !== null) {
        // check if the template is the same as the standard template
        return customer.fk_standard_vorlage === template.id;
      } else {
        return this.$store.state.client.standard_vorlage === template.id;
      }
    },

    async setAsDefaultTemplate(template) {

      if (this.editedItem.fk_standard_vorlage === template.id || (this.$store.state.client.standard_vorlage === template.id && this.editedItem.fk_standard_vorlage === null)) {
        // do nothing if clicked template is equal to already set template
        // or the clicked template is the default template AND currently no template is set.
        // because: If another template is already set, a user should be able to "reset" to the default template.
        return;
      }

      try {
        let data = await connector.updateRow(this, 'klienten', {
          'fk_standard_vorlage': template.id,
        }, this.editedItem.id);
        if (data && data.length > 0) {

          this.editedItem.fk_standard_vorlage = template.id;
          // also update the value in the customer list
          this.customers = this.customers.map((customer) => {
            if (customer.id === this.editedItem.id) {
              customer.fk_standard_vorlage = template.id;
            }
            return customer;
          });

          this.$emit('showInfo', {
            message: 'Die Rechnungsvorlage für diesen Klienten wurde erfolgreich geändert.',
            timeout: 5000
          });
        } else {
          this.$emit('showError', {
            message: 'Fehler beim Setzen der Rechnungsvorlage als Standard.',
          });
        }
      } catch (error) {
        this.$emit('showError', {
          message: 'Fehler beim Setzen der Rechnungsvorlage als Standard: ' + error.message,
        });
      }
    },

    editInstitution() {
      this.editedItemInstitution = Object.assign({}, this.selectedInstitution);
      this.create_new_institution = true;
    },
    
    deleteInstitution() {
      this.delete_institution_dialog = true;
    },

    closeInstitutionCreation() {
      this.editedItemInstitution = Object.assign({}, this.defaultItemInstitution);
      this.create_new_institution = false;
    },

    async saveInstitution() {
      if (this.editedItemInstitution.name === null || this.editedItemInstitution.name === '') {
        this.$emit('showError', { message: 'Bitte gib einen Namen für die Institution an.', timeout: 5000 });
        return;
      }

      if (this.editedItemInstitution.anschrift === null || this.editedItemInstitution.anschrift === '') {
        this.$emit('showError', { message: 'Bitte gib eine Anschrift für die Institution an.', timeout: 5000 });
        return;
      }

      if (this.editedItemInstitution.id) {
        // update
        let updated = await connector.updateRow(this, 'institutionen', {
          name: this.editedItemInstitution.name,
          anschrift: this.editedItemInstitution.anschrift,
          email: this.editedItemInstitution.email,
          telefon: this.editedItemInstitution.telefon
        }, this.editedItemInstitution.id);
        
        if (updated && updated.length === 1) {
          this.institutions = await connector.getDataOnly(this, 'vwinstitutionen', 'name', true);
          this.$emit('showInfo', { message: 'Institution erfolgreich aktualisiert.', timeout: 5000 });
          this.closeInstitutionCreation();
        } 

      } else {
        // insert
        let id = await connector.insertRow(this, 'institutionen', {
          uid: this.session.user.id,
          name: this.editedItemInstitution.name,
          anschrift: this.editedItemInstitution.anschrift,
          email: this.editedItemInstitution.email,
          telefon: this.editedItemInstitution.telefon
        });

        if (id) {
          this.institutions = await connector.getDataOnly(this, 'vwinstitutionen', 'name', true);
          this.editedItem.fk_institution_id = id;
          this.$emit('showInfo', { message: 'Institution erfolgreich hinzugefügt und ausgewählt.', timeout: 5000 });
          // this.editedItemInstitution = Object.assign({}, this.defaultItemInstitution);
          this.closeInstitutionCreation();
        }
      }
    },

    updateAndClose(value) {
      // check if date is properly formatted using dayjs (it comes from the calender click, therefore this format)
      if (!dayjs(value, 'YYYY-MM-DD').isValid()) {
          return;
      }    

      let date = dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD');

      this.editedItemInvoice.datum = date;
      this.editedItemInvoice.datumFormatted = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.menu_invoice_date = false;
    },

    handleClose(event) {
      // call close method if escape key is pressed
      if (event.code === 'Escape') {
        this.close();
      }
    },

    handleCloseDocumentation(event) {
      if (event.code === 'Escape') {
        this.open_client_documentation = false;
      }
    },

    handleInvoicePreviewClose(event) {
      // call close method if escape key is pressed
      if (event.code === 'Escape') {
        this.closeInvoicePreview();
      }
    },

    navigateToSettings() {
      this.$router.push({
        path: '/einstellungen',
        query: { missing: this.missing_tags.filter(tag => tag.startsWith('praxis_') || tag === 'bezeichnung').join(',') }
      });
    },

    addComment() {
      this.dialog_edit_client_data = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.commentField.focus();
        }, 300); 
      });
    },

    addSVNumber() {
      this.dialog_edit_client_data = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.svnr.focus();
        }, 300); 
      });
    },

    addAddress() {
      this.dialog_edit_client_data = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.adresse.focus();
        }, 300); // Die Zeit kann je nach der Dauer der Transition/Animation angepasst werden
      });
    },

    newAppointment() {
      this.$refs.new_appointment_dialog.newAppointment();
    },

    async openSendEmailDialog(item) {
      
      this.disable_close_client_details = true;
      
      const index = this.editedInvoices.findIndex(i => i.id === item.id);

      this.$set(this.editedInvoices[index], 'opening_invoice', true);

      let invoice_template = null;
      if (this.invoice_templates.length > 0 && (this.$store.state.client.standard_vorlage !== null || this.editedItem.fk_standard_vorlage !== null)) {
        if (this.editedItem.fk_standard_vorlage !== null) {
          invoice_template = this.invoice_templates.find((template) => template.id === this.editedItem.fk_standard_vorlage);
        } else {
          invoice_template = this.invoice_templates.find((template) => template.id === this.$store.state.client.standard_vorlage);
        }
      }

      let status = await this.$refs.send_invoice_dialog.openSendEmail(item, invoice_template);

      if (status && status.status !== "success" && status.type === "missing_tags") {
        // es fehlen Klienten Daten
        if (status.missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').length > 0) {

          this.disable_close_client_details = false;

          this.$router.push({
            path: '/klienten' + '/' + this.editedIndex,
            query: { missing: status.missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').join(',') }
          });

          this.$emit('showError', { 
            message: 'Es fehlen Daten für eine vollständige Rechnung.',
            timeout: 5000
          });

          this.dialog_edit_client_data = true;
          
          this.$nextTick(() => {
            let required_fields = this.requiredFields;
            required_fields.forEach(field => {
              this.$refs[field].validate(true);
            });
          });
        } else {
          // es fehlen Praxis Daten
          this.disable_close_client_details = false;
          this.missing_tags = status.missing_tags;
          this.dialog_missing_data = true;
        }
      } else if (status && status.status !== "success" && status.type === "undefined_tags") {
        this.$emit('showError', {
          message: 'Deine Rechnungsvorlage enthält nicht definierte Platzhalter. Bitte entferne die folgenden Platzhalter aus deiner Rechnungsvorlage:\n' + status.undefined_tags.join(', ') + '\nMelde dich gerne unter contact@zeipsy.com wenn du Unterstützung benötigst.',
        });
      }

      this.$set(this.editedInvoices[index], 'opening_invoice', false);
      this.disable_close_client_details = false;
    },

    async downloadInvoice(invoice, to_pdf) {
      this.disable_close_client_details = true;

      const index = this.editedInvoices.findIndex(i => i.id === invoice.id);
      this.$set(this.editedInvoices[index], 'downloading_invoice', true);

      let invoice_template = null;
      if (this.invoice_templates.length > 0 && (this.$store.state.client.standard_vorlage !== null || invoice.fk_standard_vorlage !== null)) {
        if (invoice.fk_standard_vorlage !== null) {
          invoice_template = this.invoice_templates.find((template) => template.id === invoice.fk_standard_vorlage);
        } else {
          invoice_template = this.invoice_templates.find((template) => template.id === this.$store.state.client.standard_vorlage);
        }
      }

      let status = await invoiceHelper.createInvoice(this, invoice, to_pdf, invoice_template);
      this.$set(this.editedInvoices[index], 'downloading_invoice', false);
      this.disable_close_client_details = false;

      if (status && status.status !== "success" && status.type === "missing_tags") {
        // es fehlen Klienten Daten
        if (status.missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').length > 0) {
          this.$router.push({
            path: '/klienten' + '/' + this.editedIndex,
            query: { missing: status.missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').join(',') }
          });

          this.$emit('showError', { 
            message: 'Es fehlen Daten für eine vollständige Rechnung.',
            timeout: 5000
          });

          this.dialog_edit_client_data = true;
          
          this.$nextTick(() => {
            let required_fields = this.requiredFields;      
            required_fields.forEach(field => {
              this.$refs[field].validate(true);
            });
          });
        } else {
          // es fehlen Praxis Daten
          this.missing_tags = status.missing_tags;
          this.dialog_missing_data = true;
        }
      } else if (status && status.status !== "success" && status.type === "undefined_tags") {
        this.$emit('showError', {
          message: 'Deine Rechnungsvorlage enthält nicht definierte Platzhalter. Bitte entferne die folgenden Platzhalter aus deiner Rechnungsvorlage:\n' + status.undefined_tags.join(', ') + '\nMelde dich gerne unter contact@zeipsy.com wenn du Unterstützung benötigst.',
        });
      }
    },

    isAfterNow(dateString) {
        const parsed_date = dayjs.tz(dateString, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna')
        const now = dayjs().tz('Europe/Vienna')
        return parsed_date.isAfter(now)
    },

    async saveInvoice() {
      this.updating_invoice = true

      let invoice = JSON.parse(JSON.stringify(this.editedItemInvoice))

      if (this.editedIndex === -1 && this.editedItem.id === 1) {
        this.$emit('showError', { message: "Fehler beim Erstellen der Rechnung." })
        this.updating_invoice = false
        return
      }

      invoice.fk_klienten_id = this.editedIndex;

      /*
      uid: invoice.uid,
      fk_klienten_id: invoice.fk_klienten_id,
      datum: invoice.datum,
      nummer: invoice.nummer,
      termin_ids: appointments
      */

      // check if invoice datum is in format YYYY-MM-DD
      if (invoice.datum.includes('T')) {
        invoice.datum = this.editedItemInvoice.datum.split('T')[0]
      } 
      
      // check date format using regex
      let date_regex = /^\d{4}-\d{2}-\d{2}$/
      if (!date_regex.test(invoice.datum)) {
        this.$emit('showError', { message: "Das Datum muss im Format YYYY-MM-DD sein." })
        this.updating_invoice = false
        return
      }

      // check if invoice number is entered.
      if (invoice.nummer === '' || invoice.nummer === null) {
          this.$emit('showError', { 'message': "Eine Rechungsnummer muss vergeben werden." })
          this.updating_invoice = false
          return
      }

      // check if invoice number is in correct format
      if (this.$store.state.client.rechnungsnummer_format) {
          const invoice_regex = invoiceHelper.generateInvoiceRegex(this.$store.state.client.rechnungsnummer_format);
          if (!invoice_regex.test(invoice.nummer)) {
              this.$emit('showError', { 'message': "Die Rechungsnummer muss folgendes Format haben: " + this.$store.state.client.rechnungsnummer_format });
              this.updating_invoice = false;
              return;
          }
      } else if (this.$store.state.client.eigenes_schema) {
                // do nothing, as we do not have a client schema
      } else {
          const invoice_regex = invoiceHelper.generateInvoiceRegex('{NR}/{JAHR}');
          if (!invoice_regex.test(invoice.nummer)) {
              this.$emit('showError', { 'message': "Die Rechungsnummer muss folgendes Format haben: NR/JJJJ (zb. 1/2022)." });
              this.updating_invoice = false;
              return;
          }
      }

      // create new
      let updated_appointments = JSON.parse(JSON.stringify(this.editedItemInvoice)).selected_appointments.map(appointment => appointment.id)

      if (updated_appointments.length === 0) {
          this.$emit('showError', { 
            message: "Mindestens ein Termin muss für eine Rechnung ausgewählt werden.",
            timeout: 5000,
          });
          this.updating_invoice = false;
          return;
      }

      let invoice_id = await connector.createInvoice(this, invoice, updated_appointments);
      if (!invoice_id) {
        // error has already been displayed
        this.updating_invoice = false;
        return;
      }

      this.editedAppointments = await this.loadAppointments();
      this.editedInvoices = await this.getInvoicesForClient();
      this.updating_invoice = false;
      this.dialog_create_invoice = false;
      this.$emit('showInfo', { 
        message: "Die Rechnung wurde erfolgreich erstellt.",
        timeout: 5000
      });
    },

    async newInvoice() {
      // we need to do a deepcopy here, otherwise the selected_appointments does not get cleared.
      this.editedItemInvoice = JSON.parse(JSON.stringify(this.defaultItemInvoice));
      let invoice_numbers = await this.getAllInvoicesNumbers();

      if (this.$store.state.client.rechnungsnummer_format) {
        // check if a custom format is set properly
        if (!invoiceHelper.isValidFormat(this.$store.state.client.rechnungsnummer_format) ) {
          this.$emit('showError', { message: 'Das Rechnungsnummer-Format ist nicht korrekt. Bitte überprüfe das Format in den Einstellungen.' });
          return;
        } 

        try {
          this.editedItemInvoice.nummer = invoiceHelper.getNextInvoiceNumber(invoice_numbers, this.$store.state.client.rechnungsnummer_format);
        } catch (error) {
          this.$emit('showError', { message: error.message, timeout: 8000 });
        }

      } else if (this.$store.state.client.eigenes_schema) {
        // do nothing, as we do not have a client schema
      } else {
        // use default format
        try {
          this.editedItemInvoice.nummer = invoiceHelper.getNextInvoiceNumber(invoice_numbers, '{NR}/{JAHR}');
        } catch (error) {
          this.$emit('showError', { message: error.message, timeout: 8000 });
        }
      }

      this.dialog_create_invoice = true;
    },

    async refreshAppointments() {
      this.editedAppointments = await this.loadAppointments();
    },

    truncateText(text, length = 40) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },

    showDiagnosisDialog() {
      this.dialog_edit_diagnosis = true;
      this.updateCardWidth();
    },

    updateCardWidth() {
      this.$nextTick(() => {
        if (this.$refs.card && this.$refs.card.$el) {
          this.cardWidth = Math.max(this.$refs.card.$el.offsetWidth - 40, 400);
        } else {
          this.cardWidth = 400;
        }
        if (this.$refs.clientData && this.$refs.clientDocuments && this.$vuetify.breakpoint.lgAndUp) {
          this.tableHeight = Math.max(this.$refs.clientData.$el.clientHeight + this.$refs.clientDocuments.$el.clientHeight - 91, 300);
        } else {
          this.tableHeight = 300;
        }
      });
    },

    keyDownInlineEditing(event, client_property) {
      if (event.key === 'Enter') {
        this.saveInlineClientInfo(client_property);
      }
    },

    filterActive() {
      this.filter_archived = false;
      this.filter_waitlist = false;
    },

    filterArchived() {
      this.filter_archived = true;
      this.filter_waitlist = false;
    },

    filterWaitlist() {
      this.filter_archived = false;
      this.filter_waitlist = true;
    },

    select(option) {
      if (this.selected_receiver === option) {
        // If the same checkbox was already selected, deselect it
        this.selected_receiver = null;
        this.editedItem.rechnungs_empfänger = null; // Reset or update your string accordingly
      } else {
        // Select the new checkbox and update your string accordingly
        this.selected_receiver = option;
        this.editedItem.rechnungs_empfänger = option; // Assign a value based on your requirements
      }
    },

    selectInsuredWith(option) {
      if (this.selected_insured_with === option) {
        // If the same checkbox was already selected, deselect it
        this.selected_insured_with = null;
        this.editedItem.mitversichert_bei = null; // Reset or update your string accordingly
      } else {
        // Select the new checkbox and update your string accordingly
        this.selected_insured_with = option;
        this.editedItem.mitversichert_bei = option; // Assign a value based on your requirements
      }
    },

    async loadAvailableTags() {
      let available_tags = await connector.getDataOnly(this, 'vwmerkmale', 'merkmal', true);
      if (available_tags !== -1) {
        this.available_tags = available_tags;
      }
      // error has already been shown in else case, and we do not set it, we keep the previously saved.
    },

    async addNewTag() {
      let inserted_id = await connector.insertRow(this, 'merkmale', {
        merkmal: this.newTag,
        uid: this.session.user.id
      });
      if (inserted_id) {
        this.newTag = '';
        this.loadAvailableTags();
      }
    },

    deleteTag(tag) {
      this.tagToDelete = tag;
      this.confirmDeleteDialog = true; // Öffnen des Bestätigungsdialogs
    },

    editTag(index) {
      // this also disables the close button
      this.inlineEditTag = true;

      this.tagToEdit = Object.assign({}, this.available_tags[index]);
      this.$set(this.available_tags, index, { ...this.available_tags[index], editing: true });

      // set all other tags to not editing using the $set method
      this.available_tags.forEach((t, i) => {
        if (i !== index) {
          this.$set(this.available_tags, i, { ...this.available_tags[i], editing: false });
        }
      });
    },

    async confirmDeleteTag() {
      let deleted = await connector.delete(this, 'merkmale', 'id', this.tagToDelete.id);
      if (!deleted) return;

      await this.loadAvailableTags();
      this.initialize();
      this.confirmDeleteDialog = false;
      this.tagToDelete = {
        id: null,
        merkmal: null,
        n_klienten: null,
      };

    },

    parseICDCodesFromZusatztext() {
      if (this.editedItem.zusatztext && this.editedItem.zusatztext.length > 0) {
        const codes = this.icdCodes.map(icd => icd.code);
        this.selectedICDCodes = codes.filter(code => this.editedItem.zusatztext.includes(code));
      } else {
        this.selectedICDCodes = [];
      }
    },

    difference(a, b) {
        let diff = a.filter(x => !b.includes(x))
        return diff;
    },

    addDiagnosesToText(newVal) {

      try {

        if (newVal.length === 0) {
          this.editedItem.zusatztext = this.editedItem.zusatztext.replace('Diagnose(n): ', '')
            .replace('Diagnose: ', '')
            .replace('Diagnosen: ', '');

          // itereate over all icd codes and remove them from the text
          this.icdCodes.forEach(icd => {
            this.editedItem.zusatztext = this.editedItem.zusatztext.replace(icd.code, '').trim();
          });
          return;

        } else if (newVal.length > 0 && (this.editedItem.zusatztext === null || this.editedItem.zusatztext === '' || this.editedItem.zusatztext === undefined)) {
          this.editedItem.zusatztext = 'Diagnose(n): ' + newVal.join(', ');
          return;

        } else {

          // current codes in the text
          const codes = this.icdCodes.map(icd => icd.code);
          let current_codes = codes.filter(code => this.editedItem.zusatztext.includes(code));

          let deleted_diagnoses = [...this.difference(current_codes, newVal)];
          if (deleted_diagnoses.length > 0) {
            deleted_diagnoses.forEach(diagnosis => {
              this.editedItem.zusatztext = this.editedItem.zusatztext.replace(diagnosis + ', ', '').replace(', ' + diagnosis, '').replace(diagnosis, '').trim();
            });
          }

          current_codes = codes.filter(code => this.editedItem.zusatztext.includes(code));

          let lastIndex = -1;
          let currentIndex = -1;

          // iterate over all codes and check if they are in the text
          codes.forEach(code => {
            if (this.editedItem.zusatztext.includes(code)) {
              currentIndex = this.editedItem.zusatztext.indexOf(code);
              if (currentIndex > lastIndex) {
                lastIndex = currentIndex;
              }
            }
          });

          if (lastIndex > -1) {

            const beforeLastDiagnosis = this.editedItem.zusatztext.substring(0, lastIndex);
            const afterLastDiagnosis = this.editedItem.zusatztext.substring(lastIndex);

            let newDiagnosis = [...this.difference(newVal, current_codes)];
            if (newDiagnosis.length > 0) {
              this.editedItem.zusatztext = beforeLastDiagnosis + newDiagnosis.join(', ') + ', ' + afterLastDiagnosis;
            }
          } else {
            // Wenn keine Diagnose vorhanden ist, füge die neue Diagnose einfach am Ende hinzu
            this.editedItem.zusatztext += (this.editedItem.zusatztext ? ', ' : 'Diagnose(n): ') + newVal.join(', ');
            connector.logError(this, 'No diagnosis found in zusatztext - this should not happen.');
          }
        }

      } catch (error) {
        connector.logError(this, error);
      }
    },

    async openDialogNewClient() {
      if (this.$store.state.aes_key === null) {
        this.dialog_enter_safe_key = true
        return
      }

      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem)
      this.selected_receiver = null;
      this.selected_insured_with = null;
      this.reminderEnabled = false;

      this.dialog = true;

      this.$nextTick(() => {
        this.dialog_edit_client_data = true;
      });
    },

    save_safe_key() {
      this.loading_key = true;

      localStorage.data_key = this.$store.state.data_key;

      cipher.getAESKeys(this)
        .then(() => (this.loading_key = false));

      // also set the last_safe_key_reminder to now minus 15 days, so that the reminder is not shown again immediately
      localStorage.last_safe_key_reminder = moment().subtract(15, 'days').toISOString()
    },

    async initialize() {

      this.loading = true;

      // delete localStorage if contains old headers
      if (localStorage.clients_headers && JSON.parse(localStorage.clients_headers).map((header) => ['Ort', 'PLZ', 'Straße & Nr.', 'Telefon', 'E-Mail', 'Vorname'].includes(header.text)).includes(true)) {
        localStorage.removeItem('clients_headers')
      }

      // delete localStorage if contains old headers (big UI update on 8. Mai 2024)
      if (localStorage.clients_headers && JSON.parse(localStorage.clients_headers).filter((header) => header.text === 'Kommentar').length === 0) {
        localStorage.removeItem('clients_headers')
      }

      if (localStorage.clients_headers) {
        this.headers = JSON.parse(localStorage.clients_headers)
      }

      let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true)

      if (customers.length > 0) {
        this.$store.commit('setOnboardingClientStatus', true)
      } else {
        this.$store.commit('setOnboardingClientStatus', false)
      }

      this.institutions = await connector.getDataOnly(this, 'vwinstitutionen', 'name', true);

      this.invoice_templates = await invoiceHelper.getInvoiceTemplates(this);

      if (this.$route.params.id) {
        // load sync
        this.customers = await cipher.decryptDataSync(this, customers);
        this.loading = false;
        if (this.routeEditItem) {
          
          this.editItem(this.routeEditItem);
          this.$nextTick(() => {
            this.dialog_edit_client_data = true;
            // if (this.requiredFields.length > 0) this.dialog_edit_client_data = true;
            
            this.$nextTick(() => {
              let required_fields = this.requiredFields;      
              required_fields.forEach(field => {
                this.$refs[field].validate(true);
              });
            });
          });
        } 

      } else {
        this.customers = await cipher.decryptDataSync(this, customers);
        this.loading = false;
      }      
      this.updateCardWidth();
    },

    hasPerson(item) {
      return item.vater_vorname || item.vater_nachname || item.vater_telefon || item.vater_email || item.mutter_vorname || item.mutter_nachname || item.mutter_telefon || item.mutter_email || item.partner_vorname || item.partner_nachname || item.partner_telefon || item.partner_email;
    },

    async loadAppointments() {
      this.loading_appointments = true;
      let appointments = await this.getAppointmentsForClient();
      
      this.loading_appointments = false;

      // reset series
      this.series = [];
      // set the chart data
      // to do so, get the appointments for the last 12 months
      let chart_appointments = appointments.filter(appointment => dayjs(appointment.datum).isAfter(dayjs().subtract(1, 'year')));

      let chart_default_values = {};
      // fill the default values with 0 for each of the last 12 months
      for (let i = 0; i < 12; i++) {
        chart_default_values[dayjs().subtract(i, 'month').startOf('month').format('YYYY-MM-DD')] = 0;
      }

      if (chart_appointments.length > 0) {
        chart_appointments.forEach(appointment => {
          let first_of_month = dayjs(appointment.datum).startOf('month').format('YYYY-MM-DD');
          if (chart_default_values[first_of_month]) {
            chart_default_values[first_of_month] += 1;
          } else {
            chart_default_values[first_of_month] = 1;
          }
        });
      }

      let sorted_series = [];
      let sorted_categories = [];
      // sort the chart data by date, date is a dayjs object as key
      
      Object.keys(chart_default_values).sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()).forEach(key => {
        let k = dayjs(key).format('YYYY-MM-DD');
        sorted_series.push(chart_default_values[k]);
        sorted_categories.push(dayjs(k).format('MMM YY'));
      });

      this.series = [{
        name: 'Termine',
        data: sorted_series
      }];

      this.options = {
        ...this.options,
          xaxis: {
            categories: sorted_categories
          }
      };

      // donut chart
      let paid_invoices = appointments.filter(appointment => appointment.fk_rechnungs_id && appointment.bezahlt).reduce((a, b) => a + b.preis, 0);
      let unpaid_invoices = appointments.filter(appointment => appointment.fk_rechnungs_id && !appointment.bezahlt).reduce((a, b) => a + b.preis, 0);

      let not_billed = appointments.filter(appointment => appointment.fk_rechnungs_id === null && !dayjs(appointment.datum).isAfter(dayjs())).reduce((a, b) => a + b.preis, 0);
      let planned_appointments = appointments.filter(appointment => dayjs(appointment.fk_rechnungs_id === null && appointment.datum).isAfter(dayjs())).reduce((a, b) => a + b.preis, 0);
      this.donutSeries = [paid_invoices, unpaid_invoices, not_billed, planned_appointments]

      return appointments;

    },

    async editItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.selected_receiver = this.editedItem.rechnungs_empfänger;
      this.selected_insured_with = this.editedItem.mitversichert_bei;
      this.reminderEnabled = this.editedItem.termin_erinnerung ? true : false;
      this.loading_appointments = true;
      this.loading_invoices = true;

      this.dialog = true;

      this.updateCardWidth();
      
      this.editedAppointments = await this.loadAppointments();
      this.editedInvoices = await this.getInvoicesForClient();
      this.loading_invoices = false;
      
      this.updateCardWidth();
    },

    deleteItem(item) {
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    editAppointment(item) {
      if (item.fk_rechnungs_id) {
          this.$emit('showError', {
            message: 'Der Termin kann nicht bearbeitet werden, da er bereits verrechnet ist. Lösche zuerst die Rechnung um den Termin zu bearbeiten.'
          });
        return;
      }
      this.$refs.new_appointment_dialog.editAppointment(item);
    },

    deleteInvoice(item) {
      this.invoiceToDelete = Object.assign({}, item);
      this.dialog_delete_invoice = true;
    },

    async handleInvoiceDeleted(deletedInvoiceId) {
      this.editedInvoices = await this.getInvoicesForClient();
      this.editedAppointments = await this.loadAppointments();
      this.dialog_delete_invoice = false;
    },

    closeDeleteInvoice() {
      this.dialog_delete_invoice = false;
      this.invoiceToDelete = {};
    },

    deleteAppointment(item) {
      if (item.fk_rechnungs_id) {
          this.$emit('showError', {
            message: 'Der Termin kann nicht gelöscht werden, da er bereits verrechnet ist.'
          });
        return;
      }
      this.$refs.new_appointment_dialog.deleteAppointment(item);
    },

    async deleteInstitutionConfirm() {
      this.deleting_entry = true;
      let deleted = await connector.deleteInstitution(this, this.session.user.id, this.selectedInstitution.id);
      if (!deleted) {
        this.deleting_entry = false;
        return;
      };

      await this.initialize();

      // check if the selected institution is also set as rechnungs_empfänger, if so, set it to null
      if (this.editedItem.rechnungs_empfänger === 'institution') {
        this.editedItem.rechnungs_empfänger = null;
        this.selected_receiver = null;
      }

      // set the selected institution to null, as we are always deleting the selected one
      this.editedItem.fk_institution_id = null;
      
      this.delete_institution_dialog = false;
      this.deleting_entry = false;
      this.$emit('showInfo', {
        message: 'Institution erfolgreich gelöscht.',
        timeout: 5000
      });
    },

    async archiveItem(item) {
      let updated = await connector.updateRow(this, 'klienten', {
        archiviert: true,
        warteliste: false
      }, item.id)
      if (updated && updated.length > 0) {
        
        let index = this.customers.findIndex(customer => customer.id === item.id);
        this.$set(this.customers[index], 'warteliste', updated[0].warteliste);
        this.$set(this.customers[index], 'archiviert', updated[0].archiviert);

        this.$emit('showInfo', {
          message: 'Klient erfolgreich archiviert.',
          timeout: 5000
        });
      }
    },

    async waitlistItem(item) {
      let updated = await connector.updateRow(this, 'klienten', {
        warteliste: true,
        archiviert: false
      }, item.id)
      if (updated && updated.length > 0) {

        let index = this.customers.findIndex(customer => customer.id === item.id);
        this.$set(this.customers[index], 'warteliste', updated[0].warteliste);
        this.$set(this.customers[index], 'archiviert', updated[0].archiviert);

        this.$emit('showInfo', {
          message: 'Klient erfolgreich auf Warteliste gesetzt.',
          timeout: 5000
        });
      }
    },

    async unarchiveItem(item) {
      let updated = await connector.updateRow(this, 'klienten', {
        archiviert: false,
        warteliste: false
      }, item.id)
      if (updated && updated.length > 0) {
        
        let index = this.customers.findIndex(customer => customer.id === item.id);
        this.$set(this.customers[index], 'warteliste', updated[0].warteliste);
        this.$set(this.customers[index], 'archiviert', updated[0].archiviert);
        
        this.$emit('showInfo', {
          message: 'Klient erfolgreich aktiviert.',
          timeout: 5000
        });
      }
    },

    async getAppointmentsForClient() {
      let appointments = await connector.getDataOnlyFiltered(this, 'vwtermine', 'eq', 'fk_klienten_id', this.editedIndex, 'datum', false);
      if (appointments === -1) {
        // an error occurred
        return [];
      }

      let decrypted_appointments = await Promise.all(appointments.map(appointment => {
        let decryted_appointment = cipher.decryptObject(this, this.$store.state.aes_key, appointment);
        decryted_appointment.downloading_timeconfirmation = false;
        return decryted_appointment;
      }));

      return decrypted_appointments;
    },

    async getInvoicesForClient() {
      let encrypted_invoices = await connector.getDataOnlyFiltered(this, 'vwrechnungen', 'eq', 'fk_klienten_id', this.editedIndex, 'datum', false);
      if (encrypted_invoices === -1) {
        // an error occurred
        return [];
      }

      let institution_invoices = await connector.getDataOnlyFiltered(this, 'vwrechnungeninstitutionen', 'eq', 'fk_klienten_id', this.editedIndex, 'datum', false);
      if (institution_invoices === -1) {
        // an error occured
        return [];
      }

      let invoices = await Promise.all(encrypted_invoices.map(invoice => {
        let decryted_invoice = cipher.decryptObject(this, this.$store.state.aes_key, invoice);
        decryted_invoice.downloading_invoice = false;
        decryted_invoice.opening_invoice = false;
        return decryted_invoice;
      }));

      return invoices.concat(institution_invoices);
    },

    async getAllInvoicesNumbers() {
      let invoice_numbers = await connector.getDataOnly(this, 'vwrechnungen', 'nummer', false, 'nummer');
      if (invoice_numbers === -1) {
        // an error occurred
        return [];
      } else {
        return invoice_numbers;
      }
    },

    async deleteItemConfirm() {
      this.deleting_entry = true;

      // delete files from the corresponding bucket (if any)
      if (this.session.user.id && this.editedIndex) {
          let path = this.session.user.id + '/' + this.editedIndex + '/';
          let deleted_files = await connector.deleteAllFilesInBucket(this, 'documentation', path);
          if (!deleted_files) {
            // error has already been shown
            // stop deleting
            this.deleting_entry = false;
            return;
          }
      }

      let deleted = await connector.delete(this, 'klienten', 'id', this.editedIndex);
      if (!deleted) {
        // error was already shown
        this.deleting_entry = false;
        return;
      };

      await this.initialize();
      this.deleting_entry = false;
      this.closeDelete();
      this.$emit('showInfo', {
        message: 'Der Klient wurde erfolgreich gelöscht.',
        timeout: 5000
      });
    },

    close() {  
      this.dialog = false;

      // if (this.$route.params.id) {
      //   // now we need to clear the query params and the route params
      //   this.$router.push({ path: '/klienten' });
      // }
      
      // close inline editing in case it is open
      this.inline_edit_birthday = false;
      this.inline_edit_email = false;
      this.inline_edit_telefon = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedAppointments = [];
        this.editedInvoices = [];
        this.editedIndex = -1
        this.tabs_appointments_invoices = null;
        this.tabs_persons_invoice_template = null;
      })
    },

    closeEditClientData(revert=false) {
      this.dialog_edit_client_data = false;
      this.personDialogState.show = false;
      this.personDialogState.type = null;
      this.dialog_edit_institution = false;
      this.dialog_edit_diagnosis = false;

      if (revert) this.revertInlineClientInfo();
      if (this.$route.params.id) {
        // now we need to clear the query params and the route params
        this.$router.push({ path: '/klienten' });
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    filterName(value, search, item) {
      if (!search) return true;

      // Convert search term to lowercase for case-insensitive comparison
      const searchTerm = search.toLowerCase().trim();

      // First check the specific column value (faster)
      if (value && typeof value === 'string' && value.toLowerCase().includes(searchTerm)) {
        return true;
      }
      
      const searchableFields = {
        // Client basic info
        name: `${item.titel_vorgestellt || ''} ${item.vorname || ''} ${item.nachname || ''} ${item.titel_nachgestellt || ''}`,
        name_reverse: `${item.titel_vorgestellt || ''} ${item.nachname || ''} ${item.vorname || ''} ${item.titel_nachgestellt || ''}`,
        contact: `${item.email || ''} ${item.telefon || ''} ${item.adresse || ''} ${item.plz || ''} ${item.ort || ''}`,
        ids: `${item.svnr || ''}`,
        
        // Father info
        father: `${item.vater_titel_vorgestellt || ''} ${item.vater_vorname || ''} ${item.vater_nachname || ''} ${item.vater_titel_nachgestellt || ''} ${item.vater_telefon || ''} ${item.vater_email || ''} ${item.vater_adresse || ''} ${item.vater_plz || ''} ${item.vater_ort || ''} ${item.vater_svnr || ''}`,
        father_reverse: `${item.vater_titel_vorgestellt || ''} ${item.vater_nachname || ''} ${item.vater_vorname || ''} ${item.vater_titel_nachgestellt || ''} ${item.vater_telefon || ''} ${item.vater_email || ''} ${item.vater_adresse || ''} ${item.vater_plz || ''} ${item.vater_ort || ''} ${item.vater_svnr || ''}`,
        
        // Mother info
        mother: `${item.mutter_titel_vorgestellt || ''} ${item.mutter_vorname || ''} ${item.mutter_nachname || ''} ${item.mutter_titel_nachgestellt || ''} ${item.mutter_telefon || ''} ${item.mutter_email || ''} ${item.mutter_adresse || ''} ${item.mutter_plz || ''} ${item.mutter_ort || ''} ${item.mutter_svnr || ''}`,
        mother_reverse: `${item.mutter_titel_vorgestellt || ''} ${item.mutter_nachname || ''} ${item.mutter_vorname || ''} ${item.mutter_titel_nachgestellt || ''} ${item.mutter_telefon || ''} ${item.mutter_email || ''} ${item.mutter_adresse || ''} ${item.mutter_plz || ''} ${item.mutter_ort || ''} ${item.mutter_svnr || ''}`,
        
        // Partner info
        partner: `${item.partner_titel_vorgestellt || ''} ${item.partner_vorname || ''} ${item.partner_nachname || ''} ${item.partner_titel_nachgestellt || ''} ${item.partner_telefon || ''} ${item.partner_email || ''} ${item.partner_adresse || ''} ${item.partner_plz || ''} ${item.partner_ort || ''} ${item.partner_svnr || ''}`,
        partner_reverse: `${item.partner_titel_vorgestellt || ''} ${item.partner_nachname || ''} ${item.partner_vorname || ''} ${item.partner_titel_nachgestellt || ''} ${item.partner_telefon || ''} ${item.partner_email || ''} ${item.partner_adresse || ''} ${item.partner_plz || ''} ${item.partner_ort || ''} ${item.partner_svnr || ''}`,
        
        // Additional info
        additional: `${item.kommentar || ''}`,

        // Merkmale
        tags: item.merkmale.map((merkmal) => merkmal.merkmal).join(' ')
      };
      
      // Check if any of the searchable fields contain the search term
      return Object.values(searchableFields).some(field => 
        field.toLowerCase().includes(searchTerm)
      );
    },

    getAge(date) {
      return dayjs().diff(dayjs(date, "DD.MM.YYYY"), 'years')
    },

    getAgeYearsAndMonths(date) {
      let age = dayjs().diff(dayjs(date, "DD.MM.YYYY"), 'years')
      let months = dayjs().diff(dayjs(date, "DD.MM.YYYY"), 'months') % 12
      return age + ' Jahre ' + months + ' Monate'
    },

    toggleAndSaveHeaders() {
      localStorage.clients_headers = JSON.stringify(this.headers)
    },

    async saveInlineClientInfo(client_property) {

      switch (client_property) {
        case 'geburtsdatum': {
          if (!this.valid_inline_editing_birthday) return;
          this.inline_edit_birthday = false;
          break;
        }
        case 'email': {
          if (!this.valid_inline_editing_email) return;
          this.inline_edit_email = false;
          break;
        }
        case 'telefon': {
          // if (!this.valid_inline_editing_telefon) return;
          this.inline_edit_telefon = false;
          break;
        }
      }

      await this.updateClientInfo();

    },

    revertInlineClientInfo(client_property) {

      if (this.editedIndex === -1) {
        // also close the main dialog
        this.close();
        return;
      }

      let client = this.customers.find((c) => c.id === this.editedItem.id);

      if (!client) {
        this.$emit('showError', {
          message: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu. (Klient nicht gefunden)',
        });
        return;
      }

      switch (client_property) {
        case 'geburtsdatum': {
          this.editedItem.geburtsdatum = client.geburtsdatum;
          this.inline_edit_birthday = false;
          break;
        }
        case 'email': {
          this.editedItem.email = client.email;
          this.inline_edit_email = false;
          break;
        }
        case 'telefon': {
          this.editedItem.telefon = client.telefon;
          this.inline_edit_telefon = false;
          break;
        }
        default: {
          // reset the editedItem to the client
          this.editedItem = Object.assign({}, client);
          return;
        }
      }
    },

    async updateClientInfo() {

      let toInsert = Object.assign({}, this.editedItem);
      delete toInsert.id;
      delete toInsert.merkmale;

      delete toInsert.termin_erinnerung;
      delete toInsert.telefon_erinnerung;

      let obj = await cipher.encryptObject(this.$store.state.aes_key, toInsert)
      
      // check if termin_erinnerung is set, if so, update the telefon_erinnerung with the corresponding telefon number (as we need it in unencrypted form)
      // we need to also update it here, as the telefon number might have changed
      if (this.editedItem.termin_erinnerung) {
        obj.telefon_erinnerung = this.editedItem[this.editedItem.termin_erinnerung];
      }

      let updated = await connector.update(this, 'klienten', obj, this.editedItem.id)
      if (updated) {
        await this.initialize();
        // get invoivces again, as the address data etc. might have changed
        this.editedInvoices = await this.getInvoicesForClient();
        return updated;
      }
      // the error has already been shown in this case.
      connector.logError(this, {
        uid: this.session.user.id,
        message: 'LOG: Error updating client.',
      });
      // initialize and close anyway
      await this.initialize();
      // get invoivces again, as the address data etc. might have changed
      this.editedInvoices = await this.getInvoicesForClient();
      return null;
    },

    async savePersons() {

      let toInsert = Object.assign({}, this.editedItem)
      delete toInsert.id
      delete toInsert.merkmale // this is a virtual column, so we need to delete it

      // check if rechnungs_empfänger is set, if so, check if the respective fields (vorname, nachname, adresse, plz, ort) are set
      // if not, show an error message
      if (toInsert.rechnungs_empfänger === 'vater' && (!toInsert.vater_vorname || !toInsert.vater_nachname || !toInsert.vater_adresse || !toInsert.vater_plz || !toInsert.vater_ort)) {
        this.$emit('showError', {
          message: 'Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, Adresse, PLZ und Ort) für den Rechnungsempfänger "Vater" aus.',
        });
        return;
      }

      if (toInsert.rechnungs_empfänger === 'mutter' && (!toInsert.mutter_vorname || !toInsert.mutter_nachname || !toInsert.mutter_adresse || !toInsert.mutter_plz || !toInsert.mutter_ort)) {
        this.$emit('showError', {
          message: 'Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, Adresse, PLZ und Ort) für den Rechnungsempfänger "Mutter" aus.',
        });
        return;
      }

      if (toInsert.rechnungs_empfänger === 'partner' && (!toInsert.partner_vorname || !toInsert.partner_nachname || !toInsert.partner_adresse || !toInsert.partner_plz || !toInsert.partner_ort)) {
        this.$emit('showError', {
          message: 'Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, Adresse, PLZ und Ort) für den Rechnungsempfänger "Partner" aus.',
        });
        return;
      }

      if (toInsert.mitversichert_bei === 'vater' && (!toInsert.vater_vorname || !toInsert.vater_nachname || !toInsert.vater_svnr)) {
        this.$emit('showError', {
          message: 'Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, und Sozialversicherungsnummer) für den Versicherten "Vater" aus.',
        });
        return;
      }

      if (toInsert.mitversichert_bei === 'mutter' && (!toInsert.mutter_vorname || !toInsert.mutter_nachname || !toInsert.mutter_svnr)) {
        this.$emit('showError', {
          message: 'Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, und Sozialversicherungsnummer) für den Versicherten "Mutter" aus.',
        });
        return;
      }

      if (toInsert.mitversichert_bei === 'partner' && (!toInsert.partner_vorname || !toInsert.partner_nachname || !toInsert.partner_svnr)) {
        this.$emit('showError', {
          message: 'Bitte füllen Sie alle notwendigen Felder (Vorname, Nachname, und Sozialversicherungsnummer) für den Versicherten "Partner" aus.',
        });
        return;
      }
      
      // check if termin_erinnerung is set and if the phone number is invalid
      // if so, show an error message
      if (this.editedItem.termin_erinnerung) {
        if(!this.checkPhoneNumber(this.editedItem[this.editedItem.termin_erinnerung])) {
          this.$emit('showError', {
            message: 'Die angegebene Telefonnummer muss mit eier Ländervorwahl (z.B. +43) beginnen und darf keine Leerzeichen enthalten.',
            timeout: 10000
          });
          return;
        }
      }

      this.saving_persons = true;

      let updated = await this.updateClientInfo();
      if (updated) {
        this.personDialogState.show = false;
        this.personDialogState.type = null;
        this.dialog_edit_institution = false;
        this.$emit('showInfo', {
          message: 'Der Eintrag wurde erfolgreich gespeichert.',
          timeout: 5000
        });
      }
      this.saving_persons = false;

    },

    async loadInvoices() {
      this.loading_invoices = true;
      this.editedInvoices = await this.getInvoicesForClient();
      this.loading_invoices = false;
    },

    async save() {

      this.saving_client = true;
      let toInsert = Object.assign({}, this.editedItem)
      delete toInsert.id
      delete toInsert.merkmale // this is a virtual column, so we need to delete it

      delete toInsert.termin_erinnerung // this column is unencrypted, so we need to delete it
      delete toInsert.telefon_erinnerung // this column is unencrypted, so we need to delete it

      // check if termin_erinnerung is set and if the phone number is empty
      // if so, show an error message
      if (this.editedItem.termin_erinnerung) {
        if(!this.checkPhoneNumber(this.editedItem[this.editedItem.termin_erinnerung])) {
          this.$emit('showError', {
            message: 'Die angegebene Telefonnummer muss mit einer Ländervorwahl (z.B. +43) beginnen und darf keine Leerzeichen enthalten.',
            timeout: 10000
          });
          this.saving_client = false;
          return;
        }
      }

      if (this.editedIndex > -1) {

        // check if vorname & nachname is set - can be that it is deleted now.

        if (!toInsert.vorname || !toInsert.nachname) {
          this.$emit('showError', {
            message: 'Vor- und Nachname dürfen nicht leer sein.',
            timeout: 5000
          });
          this.saving_client = false;
          return;
        }

        // updated 
        let obj = await cipher.encryptObject(this.$store.state.aes_key, toInsert);
        // check if termin_erinnerung is set, if so, update the telefon_erinnerung with the corresponding telefon number (as we need it in unencrypted form)
        // we need to also update it here, as the telefon number might have changed
        if (this.editedItem.termin_erinnerung) {
          obj.telefon_erinnerung = this.editedItem[this.editedItem.termin_erinnerung];
        }

        let updated = await connector.update(this, 'klienten', obj, this.editedItem.id);
        if (updated === null) {
          // error has already been shown
          this.saving_client = false;
          return;
        }

        await this.initialize();
        this.saving_client = false;
        // get invoivces again, as the address data etc. might have changed
        this.editedInvoices = await this.getInvoicesForClient();
        this.closeEditClientData();

      } else {
        // create new
        let obj = await cipher.encryptObject(this.$store.state.aes_key, toInsert)

        // check if termin_erinnerung is set, if so, populate the telefon_erinnerung with the telefon number (as we need it in unencrypted form)
        // if (toInsert.termin_erinnerung === true) {
        //   obj.telefon_erinnerung = toInsert.telefon;
        // }

        let inserted_id = await connector.insertRow(this, 'klienten', obj)

        if (!inserted_id) {
          // error has already been shown
          this.saving_client = false;
          return;
        }

        this.editedIndex = inserted_id;
        this.editedItem.id = inserted_id;
        await this.initialize();
        this.saving_client = false;
        this.closeEditClientData();
      }
    }
  }
}
</script>

<style scoped>
.v-data-table >>> .no-hover:hover {
  background-color: transparent !important;
  cursor: default;
}
.clickable-rows ::v-deep tbody tr {
  cursor: pointer;
}

.custom-tab--active {
  color: black !important;
}

/* Additional styles for the file info container */
.file-info-container {
  min-width: 0;
  /* This ensures that the container can shrink as needed */
}

/* Ensure text truncation is applied */
.text-truncate-file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.person-card {
  transition: box-shadow .3s ease;
  cursor: pointer;
}

.add-person-card {
  border: dashed 1px lightgrey !important;
  transition: box-shadow .3s ease;
  cursor: pointer;
}

.client-prop {
  font-size: 1.15rem;
  font-weight: 400;
}

.v-sheet.v-card {
  border-radius: 6px;
}

.active-tab-left {
  background-color: #897f7c;
  color: white;
  border-top-left-radius: 4px;
}

.active-tab-right {
  background-color: #897f7c;
  color: white;
  border-top-right-radius: 4px;
}

.active-tab-both {
  background-color: #897f7c;
  color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.upload-area {
  border: dashed 2px lightgrey !important;
  border-radius: 20px;
  text-align: center;
}
.email-link:hover {
  color: #00a152; /* Dunkler Text */
}
.active {
  border: 2px solid #00a152 !important;
}
</style>

<style>
.search-highlight {
  background-color: rgba(255, 213, 79, 0.4); /* Light yellow highlight */
  padding: 0 0px;
  border-radius: 2px;
  font-weight: bold;
}
.pointer-cell {
  cursor: pointer !important;
}
.v-slide-group__prev--disabled {
  display: none !important;
}

.v-slide-group__next--disabled {
  display: none !important;
}
</style>