<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col class="d-flex align-center justify-space-between my-0 py-0">
                <span class="text-h6 text-sm-h5">Dokumentation</span>
            </v-col>
        </v-row>

        <v-row v-if="selected_client === null" class="mt-0">
            <v-col>
                <v-card class="pa-5">

                    <p class="text-h6 mb-3">
                        Klient auswählen
                    </p>

                    <v-autocomplete 
                        prepend-inner-icon="mdi-account" 
                        v-model="selected_client" 
                        :items="formattedClientsNotArchived"
                        label="Klient" 
                        item-text="name" 
                        item-value="id" 
                        outlined 
                        return-object 
                        dense
                        clear-icon="mdi-close-circle" 
                        clearable 
                        placeholder="Suchbegriff..." 
                        persistent-placeholder
                        :loading="loading_clients"
                        loader-height="4"
                        @change="onClientChange"
                    >
                        <template v-slot:item="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.name }}</span>
                                <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>{{
                                    merkmal.merkmal }}</v-chip>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.name }}</span>
                                <v-chip v-for="merkmal in item.merkmale" :key="'chip-selection-' + merkmal.id" class="ml-2"
                                    small>{{
                                        merkmal.merkmal }}</v-chip>
                            </div>
                        </template>
                    </v-autocomplete>

                    <p class="text-h6 mb-3" v-if="selected_client === null">
                        Schnellauswahl
                    </p>
                    <v-row v-if="!loading_clients">
                        <v-col v-for="client in clientsQuickSelect" :key="client.id" cols="12" md="3">
                            <v-hover v-slot="{ hover }">
                                <v-card 
                                    outlined 
                                    class="file-card"
                                    @click="onClientChange(formattedClients.find(c => c.id === client.id))"
                                    :elevation="hover ? '2' : '0'"
                                >
                                    <v-card-title>
                                        <v-icon left>
                                            mdi-account
                                        </v-icon>
                                        <span class="text-truncate">{{ client.name }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <p class="mb-0">Nicht dokumentierte Termine: {{ client.nicht_dokumentiert }}</p>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" md="3" v-for="n in 4" :key="'skeleton-'+n">
                            <v-skeleton-loader type="image" height="104"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else class="mb-9 mt-0">
            <v-col cols="12" md="6">
                <v-card class="pa-5">
                    <div class="d-flex flex-column align-center">
                        <v-autocomplete 
                            v-model="selected_client" 
                            :items="formattedClientsNotArchived" 
                            outlined 
                            append-icon="mdi-magnify"
                            item-text="name" 
                            item-value="id" 
                            return-object 
                            full-width 
                            style="width: 100%;" 
                            clear-icon="mdi-close-circle" 
                            clearable placeholder="Suchbegriff..." 
                            persistent-placeholder
                            @change="onClientChange"
                        >
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <span>{{ item.name }}</span>
                                    <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>
                                        {{ merkmal.merkmal }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center justify-center" style="width: 100%;">
                                    <v-icon left large>
                                        mdi-account
                                    </v-icon>
                                    <span class="text-h6">
                                        {{ item.name }} {{ selected_client.alter ? '(' + selected_client.alter + ')' : '' }}
                                    </span>
                                    <v-chip v-if="item.archiviert" :color="$store.state.theme.primary" class="ml-2" small dark>Archiviert</v-chip>
                                </div>
                            </template>
                        </v-autocomplete>

                        <div class="subtitle-1 text-center">{{ selected_client.adresse }}</div>
                        <div full-width class="d-flex flex-wrap justify-center">
                            <v-chip v-for="merkmal in selected_client.merkmale" :key="'chip-details-' + merkmal.id"
                                class="mr-2 my-1">{{
                                    merkmal.merkmal }}
                            </v-chip>
                            <v-chip class="my-1" v-if="selected_client.svnr">SV-Nr.: {{ selected_client.svnr }}</v-chip>
                        </div>
                    </div>

                    <div v-if="selected_client.zusatztext" class="mt-3 text-center">{{ selected_client.zusatztext }}</div>

                    <v-row class="mt-3">
                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="d-flex justify-center align-center text-body-1">
                                <v-icon left small color="black">
                                    mdi-sofa-single-outline
                                </v-icon>
                                Termine
                            </div>
                            <div class="text-h6">
                                {{ selectedClientStatistics.appointment_count }} ({{selectedClientStatistics.appointment_sum }} {{ $vuetify.breakpoint.xsOnly ? 'Min.' : 'Minuten' }})
                            </div>
                        </v-col>

                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="d-flex justify-center align-center text-body-1">
                                <v-icon left small color="black">
                                    mdi-sync
                                </v-icon>
                                {{ $vuetify.breakpoint.xsOnly ? 'Frequenz' : 'Behandlungsfrequenz' }}
                            </div>
                            <div class="text-h6">
                                {{ selectedClientStatistics.appointment_frequency }}x / Monat
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="d-flex justify-center align-center text-body-1">
                                <v-icon left small color="black">
                                    mdi-calendar-start-outline
                                </v-icon>
                                Erste Einheit
                            </div>
                            <div class="text-h6">
                                {{ selectedClientStatistics.first_appointment }}
                            </div>
                        </v-col>

                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="d-flex justify-center align-center text-body-1">
                                <v-icon left small color="black">
                                    mdi-calendar-end-outline
                                </v-icon>
                                Letzte Einheit
                            </div>
                            <div class="text-h6">
                                {{ selectedClientStatistics.last_appointment }}
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <UploadedFilesCard 
                    ref="uploadedFilesCard"
                    :session="session"
                    :clientId="selected_client ? selected_client.id : null"
                    @update:uploadedAppointmentFiles="uploadedAppointmentFiles = $event"
                    @showError="(arg) => $emit('showError', arg)"
                    @showInfo="(arg) => $emit('showInfo', arg)"
                    />
            </v-col>
        </v-row>
        <ClientDocumentation
            v-if="selected_client"
            :clientId="selected_client.id"
            :clientData="selected_client"
            :uploadedFiles="uploadedAppointmentFiles"
            :clientStatistics="selectedClientStatistics"
            :isEditable="true"
            :session="session"
            @select-files="selectFilesAppointments"
            @openFile="openFile"
            @showError="(arg) => $emit('showError', arg)"
            @showInfo="(arg) => $emit('showInfo', arg)"
        />
        <p v-else class="d-flex justify-center align-center mt-5">
            <v-icon left large>
                mdi-chart-timeline-variant-shimmer
            </v-icon>
            <span class="">Wähle einen Klienten aus um die Dokumentation zu sehen.</span>
        </p>
    </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js';
import cipher from '../helpers/cipher.js';
import dayjs from 'dayjs';
import UploadedFilesCard from '../components/UploadedFilesCard.vue';
import ClientDocumentation from '../components/ClientDocumentation.vue';

export default {

    components: {
        UploadedFilesCard,
        ClientDocumentation
    },

    props: ['session'],

    data() {
        return {
            clients: [],
            loading_clients: false,
            selected_client: null,
            uploadedAppointmentFiles: [],
            client_statistics: null,
        }
    },

    async mounted() {
        await this.initialize();
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            async handler(newId) {
                if (newId) {
                    if (this.clients.length === 0) {
                        await this.initialize();
                    }
                    const id = parseInt(newId);
                    this.selected_client = this.formattedClients.find(client => client.id === id);
                    let client_statistics = await connector.getDataOnlyFiltered(this, 'vwklientenstatistik', 'eq', 'fk_klienten_id', id, 'fk_klienten_id');
                    if (client_statistics === -1) {
                        // error has already been shown
                        client_statistics = null;
                    } else if (client_statistics.length > 0) {
                        this.client_statistics = client_statistics[0];
                    } else {
                        this.client_statistics = null;
                    }
                } else {
                    this.selected_client = null;
                }
            }
        }
    },

    methods: {

        openFile(file) {
            if (this.$refs.uploadedFilesCard) {
                this.$refs.uploadedFilesCard.openFile(file);
            }
        },

        onClientChange(value) {
            const targetPath = value?.id ? `/dokumentation/${value.id}` : '/dokumentation';
            
            // Only navigate if target path is different from current path
            if (this.$route.path !== targetPath) {
                // If we're returning to the main view (client was deselected)
                if (!value) {
                    // Reload the clients data to refresh the undocumented appointments
                    this.initialize();
                }
                
                this.$router.push({ path: targetPath });
            }
        },

        selectFilesAppointments(id) {
            if (this.$refs.uploadedFilesCard) {
                this.$refs.uploadedFilesCard.selectFilesForAppointment(id);
            }
        },

        async initialize() {
            this.loading_clients = true;
            
            let clients = await connector.getDataOnly(this, 'vwklientendokumentation', 'id', false);
            if (clients === 1) {
                // error has already been displayed.
                this.clients = [];
            } else {
                this.clients = await cipher.decryptDataSync(this, clients);
            }
            
            this.loading_clients = false;
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            // names must be equal
            return 0
        },
    },

    computed: {

        formattedClients() {
            return this.clients.map(client => {
                return {
                    vorname: client.vorname,
                    nachname: client.nachname,
                    name: client.nachname + ' ' + client.vorname,
                    adresse: client.plz ? client.adresse + ', ' + client.plz + ' ' + client.ort : client.adresse,
                    alter: client.geburtsdatum ? dayjs().diff(dayjs(client.geburtsdatum, 'DD.MM.YYYY'), 'year') : null,
                    svnr: client.svnr ? client.svnr : null,
                    id: client.id,
                    zusatztext: client.zusatztext,
                    merkmale: client.merkmale,
                    archiviert: client.archiviert
                };
            }).sort(this.sortListOfObjectsByString);
        },

        formattedClientsNotArchived() {
            if (this.$route.params.id) {
                // making sure to also include the opend one (as this might be archived)
                const id = parseInt(this.$route.params.id);
                return this.formattedClients.filter(client => !client.archiviert || client.id === id);
            } else {
                return this.formattedClients.filter(client => !client.archiviert);
            }
        },

        selectedClientStatistics() {

            if (this.client_statistics) {
                return this.client_statistics;
            } else {
                return {
                    first_appointment: 'Noch Keine',
                    last_appointment: 'Noch Keine',
                    appointment_count: 0,
                    appointment_sum: 0,
                    appointment_frequency: 0,
                }
            }
        },

        clientsQuickSelect() {
            return this.clients.filter(client => client.letzter_termin_nicht_dokumentiert !== null && !client.archiviert).map(client => {
                return {
                    name: client.nachname + ' ' + client.vorname,
                    id: client.id,
                    termine: client.n_termine,
                    nicht_dokumentiert: client.n_nicht_dokumentiert,
                    letzter_termin_nicht_dokumentiert: client.letzter_termin_nicht_dokumentiert,
                }
            }).sort((a, b) => dayjs(b.letzter_termin_nicht_dokumentiert) - dayjs(a.letzter_termin_nicht_dokumentiert)).slice(0, 12)

        }
    }
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}

::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(0deg) !important;
}

.text-truncate {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}

span.break-words {
    display: block;
    /* Allows word-wrap to take effect */
    word-break: break-all;
    /* Breaks the word at any character */
}
::v-deep .p-container p {
    min-height: 1rem;
    margin-bottom: 0.1em;
}
</style>
