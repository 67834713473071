// calendarService.js
import connector from './supabase-connector';
import cipher from "./cipher";
import ICAL from "ical.js";
import dayjs from "dayjs";
import { supabase } from '../supabase';

export default {
    async loadSubscribedCalendars(component) {
        try {
            const calendars = await connector.getDataOnly(component, "kalender", "created_at", false);
            if (calendars !== -1) {
                return await cipher.decryptRowsData(component, calendars, ["url"], component.$store.state.aes_key_file);
            }
        } catch (error) {
            component.$emit("showError", {
                message: "Fehler beim Laden der abonnierten Kalender. Bitte versuche es erneut.",
                error: error,
                timeout: 7000,
            });
        }
        return [];
    },

    async fetchCalendarEvents(component) {
        try {
            // Get all subscribed calendars first
            const calendars = await this.loadSubscribedCalendars(component);
            let allEvents = [];

            // Process each calendar
            for (const calendar of calendars) {
                try {
                    // Handle webcal URLs by converting them to https
                    let fetchUrl = calendar.url;

                    // in case we could not decrypt url, skip the cal
                    if (fetchUrl === '*******') continue;

                    if (fetchUrl.startsWith("webcal://")) {
                        fetchUrl = fetchUrl.replace("webcal://", "https://");
                    } else if (fetchUrl.startsWith("webcal:")) {
                        fetchUrl = fetchUrl.replace("webcal:", "https:");
                    }

                    // Fetch the iCal data
                    const { data: icalData, error } = await supabase.functions.invoke(`cors-proxy`, {
                        body: {
                            url: fetchUrl
                        }
                    });

                    if (error) throw error;

                    // Parse the iCal data using ICAL.js
                    const jcalData = ICAL.parse(icalData);
                    const comp = new ICAL.Component(jcalData);
                    const events = comp.getAllSubcomponents("vevent");

                    // Transform into a format usable by v-calendar
                    const parsedEvents = events.map((event) => {
                        const icalEvent = new ICAL.Event(event);

                        // Check if all-day event
                        const allday =
                            icalEvent.startDate.isDate ||
                            (!icalEvent.startDate.hour &&
                                !icalEvent.startDate.minute &&
                                !icalEvent.startDate.second &&
                                (!icalEvent.endDate || icalEvent.endDate.isDate));

                        let start, end;

                        if (allday) {
                            start = dayjs(icalEvent.startDate.toJSDate()).format("YYYY-MM-DD");

                            if (icalEvent.endDate) {
                                const endDate = icalEvent.endDate.toJSDate();
                                if (!icalEvent.endDate.isDate) {
                                    end = dayjs(endDate).format("YYYY-MM-DD");
                                } else {
                                    end = dayjs(endDate).subtract(1, "day").format("YYYY-MM-DD");
                                }
                            } else {
                                end = start;
                            }
                        } else {
                            start = dayjs(icalEvent.startDate.toJSDate()).format("YYYY-MM-DDTHH:mm");

                            if (icalEvent.endDate) {
                                end = dayjs(icalEvent.endDate.toJSDate()).format("YYYY-MM-DDTHH:mm");
                            } else {
                                end = dayjs(icalEvent.startDate.toJSDate()).add(1, "hour").format("YYYY-MM-DDTHH:mm");
                            }
                        }

                        return {
                            id: icalEvent.uid || `generated-${calendar.id}-${Math.random().toString(36).substr(2, 9)}`,
                            summary: icalEvent.summary || "Unbenannter Termin",
                            description: icalEvent.description || "",
                            location: icalEvent.location || "",
                            start,
                            end,
                            allday,
                            color: calendar.color || "#4285F4",
                            calendar_id: calendar.id,
                        };
                    });

                    allEvents = [...allEvents, ...parsedEvents];
                } catch (error) {
                    console.error(`Error fetching events for calendar ${calendar.id}:`, error);
                    component.$emit("showError", {
                        message: `Fehler beim Laden der Termine aus dem Kalender "${calendar.name}".`,
                        timeout: 7000,
                    });
                }
            }

            return allEvents;
        } catch (error) {
            component.$emit("showError", {
                message: "Fehler beim Laden der Kalendertermine. Bitte versuche es erneut.",
                error: error,
                timeout: 7000,
            });
            return [];
        }
    },

    async validateCalendarUrl(url) {
        try {
            // Convert webcal URLs to https for validation
            let fetchUrl = url;
            if (fetchUrl.startsWith("webcal://")) {
                fetchUrl = fetchUrl.replace("webcal://", "https://");
            } else if (fetchUrl.startsWith("webcal:")) {
                fetchUrl = fetchUrl.replace("webcal:", "https:");
            }

            try {
                // First check if URL is valid
                new URL(fetchUrl);
            } catch (error) {
                return { valid: false, error: 'Ungültige Kalender-URL. Bitte prüfe ob deine Kalender-URL korrekt ist.' };
            }

            // Then try to fetch the calendar
            // Fetch the iCal data
            const { data: icalData, error } = await supabase.functions.invoke(`cors-proxy`, {
                body: {
                    url: fetchUrl
                }
            });

            if (error) throw error;

            // Try to parse the data with ICAL.js
            try {
                const jcalData = ICAL.parse(icalData);
                const comp = new ICAL.Component(jcalData);

                // Try to get the calendar name
                let title = "";
                const calendarProp = comp.getFirstPropertyValue("x-wr-calname");
                if (calendarProp) {
                    title = calendarProp;
                }

                return { valid: true, title };
            } catch (parseError) {
                return { valid: false, error: "Ungültiges Format bzw. keine Berechtigung" };
            }
        } catch (error) {
            return { valid: false, error: error.message };
        }
    },
};
