<template>
  <v-container fluid>
    <v-dialog v-model="dialog_quick_rechnung" fullscreen hide-overlay persistent> 
      <QuickRechnung 
        :session="session" 
        :customers="customersForQuickInvoice" 
        :appointments="appointments" 
        :invoice_number="computeLatestYearlyInvoiceNumber" 
        :invoices="invoices" 
        :dialog_quick_rechnung="dialog_quick_rechnung"
        :invoice_templates="invoice_templates"
        @missingTags="displayMissingTags"
        @close="closeQuickInvoice" 
        @showError="(arg) => $emit('showError', arg)" 
        @showInfo="(arg) => $emit('showInfo', arg)" 
        @updateInvoices="updateInvoices" 
      />
    </v-dialog>
    <v-dialog v-model="dialog" persistent :max-width="editedIndex > -1 ? '600px' : '1000px'" :fullscreen="$vuetify.breakpoint.xsOnly">
      <!-- :customers="editedIndex > -1 ? customerForEditing : customers" :n_customers="n_customers" -->
      <!-- The above line is needed, since if we edit an invoice, it can be tat the customers (which only contain verrechenbare klienten), may not include the edited customer, and therefore the autocomplete is empty  -->
      <!-- Therefore, we just pass the selected customer in a list. -->
      <DialogMarkInvoice @close="close" @initialize="initialize" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" :editedItem="editedItem" :editedIndex="editedIndex" 
        :appointments="appointments" :n_appointments="n_appointments" :session="session"
        :customers="editedIndex > -1 ? customerForEditing : customers" :n_customers="n_customers" :appointments_filtered="appointments_filtered" :invoices="invoices" />
    </v-dialog>

    <v-dialog v-model="dialogPaidInvoice" persistent max-width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
      <DialogInvoicePaid @close="close" @initialize="initialize" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" :editedItem="editedItem" :editedIndex="editedIndex" :session="session" />
    </v-dialog>
    
    <v-row no-gutters :class="$vuetify.breakpoint.xsOnly ? 'pt-3' : ''">
      <v-col class="d-flex align-center justify-space-between">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" clearable clear-icon="mdi-close-circle" label="Suche" single-line hide-details outlined dense/>
        <v-menu offset-y v-if="!$store.getters.hasActiveSubscription">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-5" elevation="1" :dark="$vuetify.breakpoint.mdAndUp && loadedInvoiceData" :color="$store.state.theme.primary" :disabled="!loadedInvoiceData" v-bind="attrs" v-on="on" :icon="$vuetify.breakpoint.smAndDown">
              <div class="hidden-sm-and-down">
                <v-icon left>mdi-lightning-bolt</v-icon>
                Monatsabrechnung
              </div>
              <div class="hidden-md-and-up">
                <v-icon>mdi-lightning-bolt</v-icon>
              </div>
            </v-btn>
          </template>
          <v-card class="pb-3" flat color="white" max-width="300">
            <v-card-title>
              <span class="text-subtitle-2">Schnell-Abrechnung</span>
            </v-card-title>
            <v-card-text class="d-flex">
              <v-icon color="yellow darken-1" class="mr-4">
                mdi-lock
              </v-icon>
              <span class="text-caption">
                Schalte die Schnell-Abrechnung und vieles mehr mit einem Abo frei.
              </span>
            </v-card-text>
            <v-card-actions class="d-flex justify-center mt-0 pt-0">
              <v-btn small link to="/upgrade" :color="$store.state.theme.primary" dark elevation="0">
                Freischalten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn v-if="$store.getters.hasActiveSubscription && $vuetify.breakpoint.smAndUp" class="mx-5" elevation="1" :dark="loadedInvoiceData" :color="$store.state.theme.primary" :disabled="!loadedInvoiceData" @click="showQuickInvoice">
          <v-icon left>mdi-lightning-bolt</v-icon>
          Monatsabrechnung
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.smAndUp" :color="$store.state.theme.green" elevation="1" @click="openNewInvoice()" :disabled="!loadedInvoiceData" :dark="loadedInvoiceData">
          <v-icon left>mdi-plus</v-icon>
          Neue Rechnung
        </v-btn>
     </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        :color="$store.state.theme.green"
        dark
        fixed
        bottom
        right
        fab
        @click="openNewInvoice()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-data-table 
      :headers="headers" 
      :items="computedInvoices" 
      :search="search" 
      :custom-filter="filterName" 
      :custom-sort="customSort" 
      :sort-by="defaultSortColumn"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50, 100, -1],
      }"
      sort-desc 
      class="invoice-table elevation-1 mt-3"
      @update:items-per-page="saveItemsPerPage"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center" :style="{backgroundColor: $store.state.theme.background_tabs}">
          <v-tabs dense show-arrows color="grey darken-4" :background-color="$store.state.theme.background_tabs">
            <v-tabs-slider :color="$store.state.theme.primary"></v-tabs-slider>
            <v-tab @click="clickAll">
              Alle <v-badge inline :content="nAllInvoices.toString()" :color="$store.state.theme.primary" />
            </v-tab>
            <v-tab @click="clickPaid">
              Bezahlt <v-badge inline :content="nPaidInvoices.toString()" :color="$store.state.theme.green" />
            </v-tab>
            <v-tab  @click="clickUnpaid">
              Unbezahlt <v-badge inline :content="nUnpaidInvoices.toString()" :color="$store.state.theme.red" />
            </v-tab>
          </v-tabs>
          <v-progress-circular color="primary" size="20" class="mr-5" v-if="!loadedInvoiceData" indeterminate />
        </div>
        <v-divider/>
        <DeleteInvoiceDialog
          :show="dialogDelete"
          :item="editedItem"
          @close="closeDelete"
          @invoiceDeleted="onInvoiceDeleted"
          @showError="(arg) => $emit('showError', arg)"
          @showInfo="(arg) => $emit('showInfo', arg)"
        />
        <v-dialog v-model="dialogDeleteReceipt" max-width="600px" persistent>
          <v-card>
            <v-card-title class="text-h5">
              Beleg Nr. {{ editedItem.beleg_nummer }} löschen?
            </v-card-title>
            <v-card-text class="text-body-1">
              Soll der Beleg Nr. {{ editedItem.beleg_nummer }} wirklich gelöscht werden?
              Wenn das nicht der letzte Beleg war, dann können dadurch Löcher in der fortlaufenden Nummerierung deiner Belege entstehen.
            </v-card-text>
            <v-card-actions class="px-5 pb-5">
              <v-spacer/>
              <v-btn :color="$store.state.theme.primary" :disabled="deleting_receipt" text @click="closeDelete">Nein</v-btn>
              <v-btn :color="$store.state.theme.red" :loading="deleting_receipt" outlined @click="deleteReceiptConfirm">Beleg löschen</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip v-if="item.bezahlt && item.bar" left open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" elevation="0" icon small :color="item.beleg_nummer ? $store.state.theme.green : $store.state.theme.primary" v-bind="attrs" v-on="on" @click="downloadReceipt(item, false)" :disabled="!loadedInvoiceData || item.disabling_invoice" :loading="item.downloading_receipt">
                <v-icon>
                    {{ !item.beleg_nummer ? 'mdi-receipt-text-plus-outline' : 'mdi-receipt-text-check-outline' }}
                </v-icon>
              </v-btn>
            </template>
            <span v-if="!item.beleg_nummer">Einen Beleg für die Barzahlung erstellen.</span>
            <span v-else>Den Beleg erneut herunterladen.</span>
        </v-tooltip>
        <v-tooltip v-else left open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" elevation="0" icon small :color="item.versendet ? $store.state.theme.green : $store.state.theme.primary" v-bind="attrs" v-on="on" @click="openSendEmail(item)" :disabled="!loadedInvoiceData || item.disabling_invoice || item.fk_institution_id !== null" :loading="item.opening_invoice">
                <v-icon>
                    {{ item.versendet ? 'mdi-email-check' : 'mdi-email-newsletter' }}
                </v-icon>
              </v-btn>
            </template>
            <span v-if="item.versendet">Rechnung wurde per E-Mail verschickt.</span>
            <span v-else>Rechnung per E-Mail verschicken.</span>
        </v-tooltip>
        <v-tooltip left open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" elevation="0" icon small color="#F40F02" v-bind="attrs" v-on="on" @click="checkAndCreateInvoice(item, true)" :disabled="!loadedInvoiceData || item.fk_institution_id !== null" :loading="item.downloading_invoice">
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
          <span>Rechnung herunterladen (.pdf)</span>
        </v-tooltip>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="row-more-btn" v-bind="attrs" v-on="on" icon small elevation="0" :color="$store.state.theme.primary">
              <v-icon >mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="editItem(item)">
              <v-list-item-icon>
                <v-icon :color="$store.state.theme.primary">mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Rechnung bearbeiten</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="item.bezahlt && item.bar" @click="openSendEmail(item, true)" :disabled="!loadedInvoiceData || item.fk_institution_id !== null">
              <v-list-item-icon>
                <v-icon :color="item.versendet ? $store.state.theme.green : $store.state.theme.primary">
                    {{ item.versendet ? 'mdi-email-check' : 'mdi-email-newsletter' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.versendet ? 'Rechnung erneut per E-Mail verschicken' : 'Rechnung per E-Mail verschicken' }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="checkAndCreateInvoice(item, false)" :disabled="!loadedInvoiceData || item.fk_institution_id !== null" :loading="item.downloading_invoice">
              <v-list-item-icon>
                <v-icon color="#2B7CD3">mdi-microsoft-word</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Rechnung als Word-Dokument herunterladen</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="!!item.beleg_nummer" @click="deleteReceipt(item)">
              <v-list-item-icon>
                <v-icon :color="$store.state.theme.red">mdi-receipt-text-remove-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Beleg löschen</v-list-item-title>
            </v-list-item>

            <v-list-item @click="deleteInvoice(item)">
              <v-list-item-icon>
                <v-icon :color="$store.state.theme.red">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Rechnung löschen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:no-data >
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <div v-else>
          <p class="mt-5">Es wurden noch keine Rechnungen ausgestellt.</p>
        </div>
      </template>
      <template v-slot:item.nachname="{ item }">
        <span v-if="item.fk_klienten_id">{{ item.nachname }} {{ item.vorname }}</span>
        <span v-else>{{ item.empfänger_name }}</span>
        <br/>
        <v-chip class="mr-2 mb-2" small :key="'rechnungs-chip-' + merkmal.id" v-for="merkmal in item.merkmale">
          <v-tooltip bottom v-if="merkmal.merkmal.length > 20">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ truncateText(merkmal.merkmal, 20) }}</span>
            </template>
            <span>{{ merkmal.merkmal }}</span>
          </v-tooltip>
          <span v-else>{{ merkmal.merkmal }}</span>
        </v-chip>
      </template>
      <template v-slot:item.datum="{ item }">
        {{ new Date(item.datum).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit'}) }}
      </template>
      <template v-slot:header.bezahlt="{ header }">
          <span>{{ header.text }}</span>

          <v-menu offset-y :close-on-content-click="false" v-if="hasCashPayments">
            <template v-slot:activator="{ on, attrs }">
              <v-badge :color="$store.state.theme.primary" overlap
                :content="selectedFiltersForPaymentMethods.length.toString()" :value="selectedFiltersForPaymentMethods.length > 0">
                <v-btn icon v-bind="attrs" v-on="on" small class="mx-2">
                  <v-icon>{{ selectedFiltersForPaymentMethods.length > 0 ? 'mdi-filter' : 'mdi-filter-outline' }}</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-list dense>
              <v-subheader class="text-overline mx-3">Filter</v-subheader>
              <v-list-item dense>
                <v-checkbox dense
                  v-model="selectedFiltersForPaymentMethods"
                  :label="'Barzahlung'"
                  :value="'bar'"
                  :color="$store.state.theme.primary"
                />
              </v-list-item>
              <v-list-item dense>
                <v-checkbox dense
                  v-model="selectedFiltersForPaymentMethods"
                  :label="'Überweisung'"
                  :value="'bank'"
                  :color="$store.state.theme.primary"
                />
              </v-list-item>
              <v-list-item dense v-if="selectedFiltersForPaymentMethods.length > 0">
                <v-btn text dense @click="selectedFiltersForPaymentMethods = []">
                  Filter entfernen
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>
      <template v-slot:item.bezahlt="{ item }">
        <v-tooltip bottom open-delay="300" v-if="item.bezahlt">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small rounded elevation="0" color="green" dark style="cursor: default;" v-bind="attrs" v-on="on">
              <v-icon small left>
                {{ item.bar ? 'mdi-cash-register' : 'mdi-bank-check' }}
              </v-icon>
              <span>{{ new Date(item.bezahlt).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit'}) }}</span>
            </v-btn>
          </template>
          <span>{{ item.bar ? 'Der Betrag wurde in Bar erhalten.' : 'Der Betrag wurde mittels Banküberweisung erhalten.' }}</span>
        </v-tooltip>
        <v-btn v-else small rounded color="red" dark elevation="0" @click="markAsPaid(item)">
          <v-icon left>
            mdi-hand-coin-outline
          </v-icon>
          <span>Unbezahlt</span>
          <v-menu bottom offset-y open-on-hover right nudge-left="100">
            <template v-slot:activator="{ on, attrs }">
              <v-icon right class="row-mark-paid-btn" v-bind="attrs" v-on="on" @click.stop>
                mdi-chevron-down
              </v-icon>
            </template>
            <v-list dense>
              <v-list-item @click="markAsPaid(item)">
                <v-list-item-title>
                  <v-icon left :color="$store.state.theme.green">mdi-cash-check</v-icon>
                  Rechnung wurde bezahlt
                </v-list-item-title>
              </v-list-item>
              <v-list-item :disabled="item.fk_institution_id !== null" @click="openSendEmail(item)">
                <v-list-item-title>
                  <v-icon left>mdi-email-newsletter</v-icon> 
                  {{ item.versendet ? 'Rechnung erneut verschicken' : 'Rechnung per E-Mail verschicken' }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </template>
      
      <template v-slot:item.rechnungs_betrag="{ item }">
        <span>€ {{ item.rechnungs_betrag }} ({{ item.anzahl_termine }} {{ item.anzahl_termine === 1 ? 'Termin' : 'Termine' }})</span>
      </template>
      <template v-slot:item.nummer="{ item }">
        {{ item.nummer }}<br/>
        <span v-if="item.beleg_nummer">
          <v-icon small>mdi-receipt-text-outline</v-icon>
          {{ 'Beleg Nr. ' + item.beleg_nummer }}
        </span>
      </template>
    </v-data-table>
    <SendInvoiceDialog eager ref="send_invoice_dialog" :session="session" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" @loadInvoices="updateInvoices"/>

    <div class="text-center">
      <v-dialog v-model="dialog_warning" persistent width="600" >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Daten unvollständig
          </v-card-title>
          <v-card-text class="my-5 text-body-1">

            <p v-if="is_in_quick_invoice">
              <strong>Die Rechnung wurde erstellt, es fehlen jedoch folgende Angaben um die Rechnung per E-Mail versenden zu können:</strong>
            </p>
            <span v-else>Es fehlen noch folgende Angaben für eine vollständige Rechnung:</span>
            <div class="mt-3" v-if="missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').length > 0">
              <ul>
                <li v-for="(tag, index) in missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung')" :key="index">
                  {{ lookup_missing_data[tag] }}
                </li>
              </ul>
            </div>
            <div v-else class="mt-3">
              <ul>
                <li v-for="(tag, index) in missing_tags.filter(tag => tag.startsWith('praxis_') || tag === 'bezeichnung')" :key="index">
                  {{ lookup_missing_data[tag] }}
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions class="px-6 pb-5">
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.theme.red" text @click="dialog_warning = false" >
              {{ is_in_quick_invoice ? 'E-Mail Versand Überspringen' :  'Abbrechen' }}
            </v-btn>
            <v-btn :color="$store.state.theme.green" outlined @click="navigateToLocation" >
              Zu {{ navigateToClients ? 'Klient' : 'Einstellungen' }} navigieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
import QuickRechnung from '@/components/QuickRechnung.vue'
import DialogMarkInvoice from '@/components/DialogMarkInvoice.vue'
import DialogInvoicePaid from '@/components/DialogInvoicePaid.vue'
import invoiceHelper from '../helpers/invoices.js'
import SendInvoiceDialog from '../components/SendInvoiceDialog.vue';
import DeleteInvoiceDialog from '@/components/DeleteInvoiceDialog.vue';

  export default {

    props: ['session'],
    components: { QuickRechnung, DialogMarkInvoice, SendInvoiceDialog, DialogInvoicePaid, DeleteInvoiceDialog },
    emits: ['showError', 'showInfo'],

    data() { 
      return {

        itemsPerPage: parseInt(localStorage.getItem('items-per-page')) || 10,

        selectedFiltersForPaymentMethods: [],
        invoice_templates: [],
        is_in_quick_invoice: false,

        deleting_invoice: false,
        deleting_receipt: false,

        defaultSortColumn: null,
        loadedInvoiceData: false,
        search:'',
        n_customers: null,
        n_appointments: null,
        
        loading: false,
        dialog: false,
        dialogPaidInvoice: false,
        dialog_warning: false,
        missing_tags: [],
        client_id_to_edit: null,

        lookup_missing_data: {
          'adresse': 'Adresse des Klienten',
          'plz': 'Postleitzahl des Klienten',
          'ort': 'Ort des Klienten',
          'vater_adresse': 'Adresse des Vaters',
          'vater_plz': 'Postleitzahl des Vaters',
          'vater_ort': 'Ort des Vaters',
          'mutter_adresse': 'Adresse der Mutter',
          'mutter_plz': 'Postleitzahl der Mutter',
          'mutter_ort': 'Ort der Mutter',
          'partner_adresse': 'Adresse des Partners',
          'partner_plz': 'Postleitzahl des Partners',
          'partner_ort': 'Ort des Partners',

          'praxis_name': 'Name deiner Praxis',
          'praxis_telefon': 'Telefonnummer deiner Praxis',
          'praxis_adresse': 'Adresse deiner Praxis',
          'praxis_plz': 'Postleitzahl deiner Praxis',
          'praxis_ort': 'Ort deiner Praxis',
          'praxis_iban': 'IBAN des Praxiskontos',
          'praxis_bic': 'BIC des Praxiskontos',
          'bezeichnung': 'Berufsbezeichnung',
        },

        dialogDelete: false,
        dialogDeleteReceipt: false,

        dialog_quick_rechnung: false,

        filter_paid: false,
        filter_unpaid: false,
        headers: [
          { text: 'Name', value: 'nachname' },
          // { text: 'Vorname', value: 'vorname' },
          { text: 'Rechnungs-Nr.', value: 'nummer' },
          { text: 'Rechnungsdatum', value: 'datum' },
          //{ text: 'Termine', value: 'anzahl_termine' },
          { text: 'Rechnungsbetrag', value: 'rechnungs_betrag' },
          { text: 'Bezahlt', value: 'bezahlt', align: 'center' },
          { text: 'Aktionen', value: 'actions', sortable: false, align: 'center' },
        ],

        customers: [],
        invoices: [],
        appointments: [],
        appointments_filtered: [],
        editedIndex: -1,
        editedItem: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          fk_institution_id: null,
          selected_customer: null,
          selected_appointments: [],
          nachname: null,
          vorname: null,
          datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
          nummer: null,
          bezahlt: null,
          bar: null,
          rechnungs_betrag: null,
          anzahl_termine: null
        },
        defaultItem: {
          id: null,
          uid: this.session.user.id,
          fk_klienten_id: null,
          fk_institution_id: null,
          selected_customer: null,
          selected_appointments: [],
          nachname: null,
          vorname: null,
          datum: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
          nummer: null,
          bezahlt: null,
          bar: null,
          rechnungs_betrag: null,
          anzahl_termine: null
        },
      }
    },

    computed: {

      hasCashPayments() {
        return this.invoices.some((invoice) => invoice.bar);
      },

      customersForQuickInvoice() {
        // Filter out instutions, as we cannot display send invoice dialog, so it does not make sense to show them in the quick invoice
        return this.customers.filter(customer => !customer.item_id.startsWith('institution'));
      },

      nAllInvoices() {
        return this.invoices.length;
      },

      nPaidInvoices() {
        return this.invoices.filter((invoice) => invoice.bezahlt).length;
      },

      nUnpaidInvoices() {
        return this.invoices.filter((invoice) => !invoice.bezahlt).length;
      },

      navigateToClients() {
        return this.missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').length > 0;
      },

      customerForEditing() {
        return [this.editedItem.selected_customer]
      },
  
      computedInvoices() {
        return this.invoices.filter((invoice) => {
          if (this.filter_paid) {
            return invoice.bezahlt
          } else if (this.filter_unpaid) {
            return !invoice.bezahlt
          } else {
            return true
          }
        }).filter((invoice) => {

          if (this.selectedFiltersForPaymentMethods.length === 0) {
            // in this case no filter is selected, so we show all records
            return true;
          }

          let payment_method = null;
          if (invoice.bezahlt && invoice.bar) {
            payment_method = 'bar';
          } else if (invoice.bezahlt) {
            payment_method = 'bank';
          }

          return this.selectedFiltersForPaymentMethods.includes(payment_method);
        })
      },

      appointmentsOfSelectedClient() {
        return this.appointments.filter(appointment => appointment.fk_klienten_id == this.editedItem.fk_klienten_id)
      },

      appointmentsOfSelectedClientUntilToday() {
        return this.appointments.filter(appointment => appointment.fk_klienten_id === this.editedItem.fk_klienten_id && dayjs(appointment.datum) <= dayjs())
      },

      computeLatestYearlyInvoiceNumber() {
        if (this.$store.state.client.rechnungsnummer_format) {
          // check if a custom format is set properly
          if (!invoiceHelper.isValidFormat(this.$store.state.client.rechnungsnummer_format) ) {
            this.$emit('showError', { message: 'Das Rechnungsnummer-Format ist nicht korrekt. Bitte überprüfe das Format in den Einstellungen.' });
            return '';
          } 

          try {
            return invoiceHelper.getNextInvoiceNumber(this.invoices, this.$store.state.client.rechnungsnummer_format);
          } catch (error) {
            this.$emit('showError', { message: error.message, timeout: 8000 });
            return '';
          }

        } else if (this.$store.state.client.eigenes_schema) {
          // do nothing, as we do not have a client schema
        } else {
          // use default format
          try {
            return invoiceHelper.getNextInvoiceNumber(this.invoices, '{NR}/{JAHR}');
          } catch (error) {
            this.$emit('showError', { message: error.message, timeout: 8000 });
            return '';
          }
        }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogDeleteReceipt (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler(val){
          
          if (this.editedItem.selected_customer) {
            this.editedItem.fk_klienten_id = this.editedItem.selected_customer.fk_klienten_id
            // update appointments since seleted customer changed probably
            if (this.editedItem.selected_customer.item_id.startsWith('client')) {
              this.appointments_filtered = this.appointments.filter(appointment => appointment.fk_klienten_id === this.editedItem.fk_klienten_id)
            } else {
              this.appointments_filtered = this.appointments.filter(appointment => appointment.fk_institution_id === this.editedItem.selected_customer.fk_institution_id && appointment.rechnungs_empfänger === 'institution')
            }

            // remove all selected_appointments where the fk_klienten_id is not the same as the selected_customer
            // this may happen if a user selects a customer, selects appointments, and then changes the customer
            // to avoid an infinite loop, we have to check if there are appointments selected that do not belong to the selected customer and only then update the selected_appointments
            // if(this.editedItem.selected_appointments && this.editedItem.selected_appointments.filter((appointment) => appointment.fk_klienten_id !== this.editedItem.fk_klienten_id).length > 0) {
            //   this.editedItem.selected_appointments = this.editedItem.selected_appointments.filter(appointment => appointment.fk_klienten_id === this.editedItem.fk_klienten_id)
            // }
            //this.editedItem.selected_appointments =  this.appointmentsOfSelectedClientUntilToday - BUG: always selects all appointments, do not this this
          } else {
            this.appointments_filtered = [];
          }
        },
        deep: true
      },
    },

    async mounted () {
      await this.initialize();
    },

    methods: {

      saveItemsPerPage(value) {
        localStorage.setItem('items-per-page', value.toString());
      },

      customSort(items, sortBy, sortDesc, locale, customSorters) {
        const direction = sortDesc[0] ? -1 : 1;

        return items.sort((a, b) => {
          if (sortBy[0] === 'nummer') {
            try {
              const formatA = invoiceHelper.getInvoiceFormat(a.nummer);
              const formatB = invoiceHelper.getInvoiceFormat(b.nummer);
              
              const { year: yearA, number: numberA } = invoiceHelper.parseInvoiceNumber(a.nummer, formatA);
              const { year: yearB, number: numberB } = invoiceHelper.parseInvoiceNumber(b.nummer, formatB);
              
              if (yearA !== yearB) {
                return direction * (yearA - yearB);
              }
              return direction * (numberA - numberB);
            } catch (error) {
              // console.log(`Unable to parse invoice number ${a.nummer}, ${b.nummer}, falling back to date sorting:`, a.datum, b.datum);
              // Fall back to sorting by date
              const dateA = new Date(a.datum);
              const dateB = new Date(b.datum);
              
              if (dateA < dateB) return direction * -1;
              if (dateA > dateB) return direction;
              return 0;
            }
          } else if (sortBy[0] === 'nachname') {
            let val_a = a.nachname + a.vorname;
            if (!a.fk_klienten_id) val_a = a['empfänger_name'];

            let val_b = b.nachname + b.vorname;
            if (!b.fk_klienten_id) val_b = b['empfänger_name'];

            if (val_a < val_b) return direction * -1;
            if (val_a > val_b) return direction;
            return 0;
          } else {
            // For other columns, use default sorting
            // the null checks are needed for the bezahlt column
            if (a[sortBy[0]] === null && b[sortBy[0]] === null) return 0;
            if (a[sortBy[0]] === null) return direction * -1;
            if (b[sortBy[0]] === null) return direction;
            if (a[sortBy[0]] < b[sortBy[0]]) return direction * -1;
            if (a[sortBy[0]] > b[sortBy[0]]) return direction;
            return 0;
          }
        });
      },

      async downloadReceipt(item, show_progress=false) {
        const index = this.invoices.findIndex(i => i.id === item.id);
        this.$set(this.invoices[index], 'downloading_receipt', true);

        if (show_progress) {
        // Emit a showInfo event indicating that the receipt is being downloaded, with progress indicator
          this.$emit('showInfo', { 
            message: 'Der Beleg wird heruntergeladen...', 
            timeout: 0, 
            progress: true 
          });
        }

        // First, create or update the receipt in the database
        if (!item.beleg_nummer) {
            let creationStatus = await invoiceHelper.createNewReceipt(this, item, this.invoices);
            if (creationStatus.status === "success") {
                this.$set(this.invoices[index], 'beleg_nummer', creationStatus.beleg_nummer);
                // This line is not strictly necessary, but it doesn't hurt and can be useful for clarity - as we pass row as item.
                item['beleg_nummer'] = creationStatus.beleg_nummer;
            } else {
                this.$set(this.invoices[index], 'downloading_receipt', false);
                // in this case, the error already has been displayed.
                return;
            }
        }

        let status = await invoiceHelper.getReceiptDocument(this, item);

        this.$set(this.invoices[index], 'downloading_receipt', false);

        if (status && status.status === "success") {
          // Hide the info message by showing new one.
          this.$emit('showInfo', {
            message: 'Der Beleg wurde erfolgreich heruntergeladen.',
            timeout: 5000,
          });
        } else if (status && status.status !== "success" && status.type === "missing_tags") {
          this.missing_tags = status.missing_tags;
          this.client_id_to_edit = item.fk_klienten_id;
          this.is_in_quick_invoice = false;
          this.dialog_warning = true;
        } 
      },

      truncateText(text, length = 40) {
        return text.length > length ? text.substring(0, length) + '...' : text;
      },

      async openSendEmail(item, show_progress=false) {
        try {
          
          if (show_progress) {
            this.$emit('showInfo', {
              message: 'Die Rechnung wird zum Versand vorbereitet...',
              timeout: 0, 
              progress: true 
            });
          } 

          const index = this.invoices.findIndex(i => i.id === item.id);
          this.$set(this.invoices[index], 'opening_invoice', true);
          // set all other invoices to false
          this.invoices.forEach((invoice, i) => {
            if (i !== index) {
              this.$set(this.invoices[i], 'disabling_invoice', true);
            }
          });

          let invoice_template = null;
          if (this.invoice_templates.length > 0 && (this.$store.state.client.standard_vorlage !== null || item.fk_standard_vorlage !== null)) {
            if (item.fk_standard_vorlage !== null) {
              invoice_template = this.invoice_templates.find((template) => template.id === item.fk_standard_vorlage);
            } else {
              invoice_template = this.invoice_templates.find((template) => template.id === this.$store.state.client.standard_vorlage);
            }
          }

          let status = await this.$refs.send_invoice_dialog.openSendEmail(item, invoice_template);

          if (status && status.status !== "success" && status.type === "missing_tags") {
            this.missing_tags = status.missing_tags;
            this.client_id_to_edit = item.fk_klienten_id;
            this.is_in_quick_invoice = false;
            this.dialog_warning = true;
          } else if (status && status.status !== "success" && status.type === "undefined_tags") {
            this.$emit('showError', {
              message: 'Deine Rechnungsvorlage enthält nicht definierte Platzhalter. Bitte entferne die folgenden Platzhalter aus deiner Rechnungsvorlage:\n' + status.undefined_tags.join(', ') + '\nMelde dich gerne unter contact@zeipsy.com wenn du Unterstützung benötigst.',
            });
          }

          // set opening_invoice and disabling_invoice to false
          this.invoices.forEach((invoice, i) => {
            this.$set(this.invoices[i], 'opening_invoice', false);
            this.$set(this.invoices[i], 'disabling_invoice', false);
          });

          // hide progress
          if (show_progress) {
            this.$emit('showInfo', false);
          }

        } catch (error) {
          // set opening_invoice and disabling_invoice to false
          this.invoices.forEach((invoice, i) => {
            this.$set(this.invoices[i], 'opening_invoice', false);
            this.$set(this.invoices[i], 'disabling_invoice', false);
          });
          this.$emit('showError', { message: 'Fehler beim Erstellen der Rechnung. ' + error });
        }
      },

      async updateInvoices(callback = null) {
          let encrypted_invoices = await connector.getDataOnly(this, 'vwrechnungen', 'datum', false);
          this.invoices = await cipher.decryptDataSync(this, encrypted_invoices);
          if (callback) callback(this.invoices);
      },

      async initialize(onlyUpdateCustomers=false) {
        this.loading = true;
        this.loadedInvoiceData = false;

        if (onlyUpdateCustomers === false) {
          let invoices = await connector.getDataOnly(this, 'vwrechnungen', 'datum', false);
          if (invoices === -1) {
            // error has already been shown
            invoices = [];
          }

          invoices = invoices.map((invoice) => {
            invoice.downloading_receipt = false;
            invoice.downloading_invoice = false;
            invoice.opening_invoice = false;
            invoice.disabling_invoice = false;
            return invoice;
          });

          this.invoices = await cipher.decryptDataSync(this, invoices);

          let client = await connector.getDataOnly(this, 'vwkunden', 'id', true);
          if(client !== -1 && client.length > 0) {
            this.$store.state.client = client[0];

            this.defaultSortColumn = this.$store.state.client.eigenes_schema ? 'datum' : 'nummer'
            if (this.$store.state.client.eigenes_schema) {
              this.headers = this.headers.map((header) => {
                if(header.value === 'nummer') {
                  header.sort = null
                  return header
                } else {
                  return header
                }
              })
            }
          } 

          this.invoice_templates = await invoiceHelper.getInvoiceTemplates(this);
          this.loading = false;
        }

        let appointments = await connector.getDataOnly(this, 'vwrechnungstermine', 'datum', false);
        if (appointments === -1) {
          // error has already been shown
          appointments = [];
        }
        this.checkReturnValueOfAppointments(appointments);

        let customers = await connector.getDataOnly(this, 'vwrechnungskunden', 'fk_klienten_id', true);
        if (customers === -1) {
          // error has already been shown
          customers = [];
        }
        let customers_institutions = await connector.getDataOnly(this, 'vwrechnungskundeninstitutionen', 'fk_institution_id', true);
        if (customers_institutions === -1) {
          customers_institutions = [];
        }
        this.checkReturnValueOfCustomers(customers, customers_institutions);
        this.loadedInvoiceData = true;
      },

      clickAll() {
        this.filter_paid = false;
        this.filter_unpaid = false;
      },

      clickPaid() {
        this.filter_paid = true;
        this.filter_unpaid = false; 
      },

      clickUnpaid() {
        this.filter_unpaid = true;
        this.filter_paid = false;
      },

      navigateToLocation() {
        if (this.navigateToClients) {
          this.$router.push({
            path: '/klienten' + (this.client_id_to_edit ? '/' + this.client_id_to_edit : ''),
            query: { missing: this.missing_tags.filter(tag => !tag.startsWith('praxis_') && tag !== 'bezeichnung').join(',') }
          });
        } else {
          this.$router.push({
            path: '/einstellungen',
            query: { missing: this.missing_tags.filter(tag => tag.startsWith('praxis_') || tag === 'bezeichnung').join(',') }
          })
        }
      },

      async displayMissingTags(status, invoice) {
        if (status && status.status !== "success" && status.type === "missing_tags") {
          this.missing_tags = status.missing_tags;
          this.client_id_to_edit = invoice.fk_klienten_id;
          this.is_in_quick_invoice = true;
          this.dialog_warning = true;
        } else if (status && status.status !== "success" && status.type === "undefined_tags") {
          this.$emit('showError', {
            message: 'Deine Rechnungsvorlage enthält nicht definierte Platzhalter. Bitte entferne die folgenden Platzhalter aus deiner Rechnungsvorlage:\n' + status.undefined_tags.join(', ') + '\nMelde dich gerne unter contact@zeipsy.com wenn du Unterstützung benötigst.',
            timeout: 10000
          });
        }
      },

      async checkAndCreateInvoice(item, to_pdf) {
        
        this.dialog_warning = false;
        
        const index = this.invoices.findIndex(i => i.id === item.id);
        this.$set(this.invoices[index], 'downloading_invoice', true);

        let invoice_template = null;
        if (this.invoice_templates.length > 0 && (this.$store.state.client.standard_vorlage !== null || item.fk_standard_vorlage !== null)) {
          if (item.fk_standard_vorlage !== null) {
            invoice_template = this.invoice_templates.find((template) => template.id === item.fk_standard_vorlage);
          } else {
            invoice_template = this.invoice_templates.find((template) => template.id === this.$store.state.client.standard_vorlage);
          }
        }

        let status = await invoiceHelper.createInvoiceDocument(this, item, to_pdf, true, invoice_template);

        this.$set(this.invoices[index], 'downloading_invoice', false);

        if (status && status.status !== "success" && status.type === "missing_tags") {
          this.missing_tags = status.missing_tags;
          this.client_id_to_edit = item.fk_klienten_id;
          this.is_in_quick_invoice = false;
          this.dialog_warning = true;
        } else if (status && status.status !== "success" && status.type === "undefined_tags") {
          this.$emit('showError', {
            message: 'Deine Rechnungsvorlage enthält nicht definierte Platzhalter. Bitte entferne die folgenden Platzhalter aus deiner Rechnungsvorlage:\n' + status.undefined_tags.join(', ') + '\nMelde dich gerne unter contact@zeipsy.com wenn du Unterstützung benötigst.',
          });
        }
         else if (status && status.status === "success") {
          this.$emit('showInfo', {
            message: 'Die Rechnung wurde erfolgreich heruntergeladen.',
            timeout: 5000
          });
        } // else, the error has been already shown (most likely :P)
      },

      checkReturnValueOfAppointments(appointments) {
        if (appointments === -1) {
          // wrong key
          this.appointments = []
          this.n_appointments = 0
        }
        else {
          this.appointments = []
          this.n_appointments = appointments.length
          appointments.map((obj) => cipher.decryptObject(this, this.$store.state.aes_key, obj).then((dec) => this.appointments.push(dec)))
        }
      },

      checkReturnValueOfCustomers(customers, customers_institutions) {
        if (customers === -1 || customers_institutions === -1) {
          // wrong key
          this.customers = [];
          this.n_customers = 0;
        }
        else {
          this.customers = [];
          this.n_customers = customers.length + customers_institutions.length;
          customers.map((obj) => cipher.decryptObject(this, this.$store.state.aes_key, obj).then((dec) => {
            let customer = dec;
            customer.name = customer.nachname + ' ' + customer.vorname;
            customer.type = 'client';
            customer.item_id = `client-${customer.fk_klienten_id}`;
            this.customers.push(customer);
          }));
          customers_institutions.map((obj) => {
            let customer = obj;
            customer.name = customer.institution_name;
            customer.type = 'institution';
            customer.item_id = `institution-${customer.fk_institution_id}`;
            this.customers.push(customer);
          });
        }
      },

      openNewInvoice() {
        this.appointments_filtered = [];
        if (this.$store.state.client.rechnungsnummer_format) {
          // check if a custom format is set properly
          if (!invoiceHelper.isValidFormat(this.$store.state.client.rechnungsnummer_format) ) {
            this.$emit('showError', { message: 'Das Rechnungsnummer-Format ist nicht korrekt. Bitte überprüfe das Format in den Einstellungen.' });
            return;
          } 

          try {
            this.editedItem.nummer = invoiceHelper.getNextInvoiceNumber(this.invoices, this.$store.state.client.rechnungsnummer_format);
          } catch (error) {
            this.$emit('showError', { message: error.message, timeout: 8000 });
          }

        } else if (this.$store.state.client.eigenes_schema) {
          // do nothing, as we do not have a client schema
        } else {
          // use default format
          try {
            this.editedItem.nummer = invoiceHelper.getNextInvoiceNumber(this.invoices, '{NR}/{JAHR}');
          } catch (error) {
            this.$emit('showError', { message: error.message, timeout: 8000 });
          }
        }
        this.dialog = true;
      },

      showQuickInvoice() {
        this.dialog_quick_rechnung = true;
      },

      closeQuickInvoice() {
        this.initialize();
        this.dialog_quick_rechnung = false;
      },
      
      editItem (item) {
        this.editedIndex = item.id;
        this.editedItem = Object.assign({}, item);

        // properly set the selected_customer

        if (this.editedItem.fk_klienten_id) {
          this.editedItem.selected_customer = {
            type: 'client',
            name: this.editedItem.nachname + ' ' + this.editedItem.vorname,
            fk_klienten_id: this.editedItem.fk_klienten_id,
            item_id: `client-${this.editedItem.fk_klienten_id}`,
            vorname: this.editedItem.vorname,
            nachname: this.editedItem.nachname,
            merkmale: this.editedItem.merkmale
          }
        } else {
          this.editedItem.selected_customer = {
            type: 'institution',
            name: this.editedItem.empfänger_name,
            fk_institution_id: this.editedItem.fk_institution_id,
            item_id: `institution-${this.editedItem.fk_institution_id}`,
            merkmale: []
          }
        }

        this.dialog = true;
      },

      markAsPaid (item) {
        this.editedIndex = item.id
        this.editedItem = Object.assign({}, item);

        // set the default value of the paid date to today
        this.editedItem.bezahlt = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);

        // properly set the selected_customer
        if (this.editedItem.fk_klienten_id) {
          this.editedItem.selected_customer = {
            type: 'client',
            name: this.editedItem.nachname + ' ' + this.editedItem.vorname,
            fk_klienten_id: this.editedItem.fk_klienten_id,
            item_id: `client-${this.editedItem.fk_klienten_id}`,
            vorname: this.editedItem.vorname,
            nachname: this.editedItem.nachname,
            merkmale: this.editedItem.merkmale
          }
        } else {
          this.editedItem.selected_customer = {
            type: 'institution',
            name: this.editedItem.empfänger_name,
            fk_institution_id: this.editedItem.fk_institution_id,
            item_id: `institution-${this.editedItem.fk_institution_id}`,
            merkmale: []
          }
        }

        this.dialogPaidInvoice = true;
      },

      deleteInvoice (item) {
        this.editedIndex = item.id;
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      async onInvoiceDeleted(deletedInvoiceId) {
        // Remove the deleted invoice from the list
        this.invoices = this.invoices.filter(invoice => invoice.id !== deletedInvoiceId);
        await this.initialize(true);
        this.closeDelete();
      },

      closeDelete () {
        this.dialogDelete = false;
        this.dialogDeleteReceipt = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },  

      deleteReceipt (item) {
        this.editedIndex = item.id;
        this.editedItem = Object.assign({}, item);
        this.dialogDeleteReceipt = true;
      },

      async deleteReceiptConfirm() {
        this.deleting_receipt = true;
        let updated = await connector.update(this, 'rechnungen', {
          beleg_nummer: null
        }, this.editedIndex);
        
        if (updated === null) {
          this.deleting_receipt = false;
          return;
        };

        await this.initialize();
        this.closeDelete();
        this.deleting_receipt = false;
        this.$emit('showInfo', {
          message: 'Der Beleg wurde erfolgreich gelöscht.',
          timeout: 5000
        });
      },

      close () {
        this.dialog = false;
        this.dialogPaidInvoice = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      filterName (value, search, item) {

        const dateString = new Date(item.datum).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' });

        let name = '';
        if (item.fk_klienten_id) {
          name = item.nachname.toLowerCase() + ' ' + item.vorname.toLowerCase();
        } else {
          name = item.empfänger_name.toLowerCase();
        }

        return value != null &&
          search != null &&
          typeof value === 'string' &&
          (value.toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
            (name).indexOf(search.toLowerCase()) !== -1 ||
            dateString.includes(search)
          )
      }, 
    }
  }
</script>

<style scoped>
.invoice-table >>> tbody tr .row-more-btn {
  border: 1px solid transparent;
}
.invoice-table >>> tbody tr:hover .row-more-btn {
  border-color: currentColor;
}

.invoice-table >>> tbody tr .row-mark-paid-btn {
  border: 1px solid transparent;
  border-radius: 20px;
}
.invoice-table >>> tbody tr:hover .row-mark-paid-btn {
  border-color: currentColor;
  border-radius: 20px;
}

.v-sheet.v-card {
  border-radius: 6px;
}
</style>